import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  stepper: {
    justifyContent: 'center',
    paddingTop: theme.spacing(7),
    paddingRight: '0',
    paddingBottom: theme.spacing(7),
    paddingLeft: '0',
    background: 'transparent'
  },
  stepperMobile: {
    paddingLeft: 0
  },
  labelTextMobile: {
    alignItems: 'flex-start',
    textAlign: 'left'
  },
  iconText: {
    fill: '#fff'
  },
  labelText: {
    hyphens: 'auto'
  }
}))

/**
 * FreeToPremiumStepper
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const FreeToPremiumStepper = props => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()
  const steps = [
    t('form.0.label.steps.1'),
    t('form.0.label.steps.2'),
    t('form.0.label.steps.3')
  ]

  /**
   * return
   */
  return (
    <div className={classes.root}>
      {matches && (
        <Stepper
          nonLinear
          activeStep={props.zeroBasedStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                className={classes.labelText}
                StepIconProps={{ classes: { text: classes.iconText } }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {!matches && (
        <Stepper
          activeStep={props.zeroBasedStep}
          orientation='vertical'
          className={classes.stepperMobile}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                className={classes.labelTextMobile}
                StepIconProps={{ classes: { text: classes.iconText } }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </div>
  )
}

export default FreeToPremiumStepper
