export const externalLinkTitles = {
  IMPRINT: 'Impressum',
  DATAPROTECTION: 'Datenschutz',
  PRODUCT_OVERVIEW: 'Produktübersicht',
  FUNCTIONS: 'Funktionen',
  PRICES: 'Preise',
  BLOG: 'Blog',
  SUPPORT: 'Support',
  ABOUT: 'Über uns',
  CONDITIONS: 'AGB',
  REGISTER: 'Registrieren',
  TERMS: 'Nutzungsbedingungen',
  REFERRALPROGRAM: 'Empfehlungsprogramm'
}

/**
 * getAbsoluteLink
 *
 * When title is omitted, the first link within the category will be taken,
 * this is useful if only a single link within the category is expected.
 *
 * @param cmsLinks
 * @param category
 * @param title
 * @returns {string}
 */
export const getAbsoluteLink = (cmsLinks, category, title = null) => {
  if (cmsLinks !== undefined && cmsLinks !== null) {
    let match = ''
    for (let j = 0; j < cmsLinks.length; j++) {
      if (
        typeof cmsLinks[j].Title !== 'undefined' &&
        cmsLinks[j].Title === category
      ) {
        if (title === null) {
          if (!cmsLinks[j].Links) {
            match = ''
          } else {
            match = cmsLinks[j].Links[0].AbsoluteLink
          }
          break
        } else {
          if (!cmsLinks[j].Links) {
            match = ''
          } else {
            for (
              let i = 0, length = cmsLinks[j].Links.length;
              i < length;
              i++
            ) {
              if (cmsLinks[j].Links[i].Title === title) {
                match = cmsLinks[j].Links[i].AbsoluteLink
                break
              }
            }
          }
        }
      }
    }
    return match
  } else {
    return ''
  }
}
