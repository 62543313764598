import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import LoadingOverlay from "../LoadingOverlay";
import GlobalContext from "../../lib/GlobalContext";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import { useTranslation } from "react-i18next";
import GetSafe from "../../lib/GetSafe";
import DataUrlToFileConverter from "../../lib/DataUrlToFileConverter";
import { DropzoneDialogBase } from "material-ui-dropzone";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import { useMutation } from "urql";
import {
  Edit as EditIcon,
  Add as AddIcon,
  DragIndicator as DragIndicatorIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import {
  schema,
  updateCompanyMutation,
  REFERENCE_DESCRIPTION_LENGTH,
} from "./CompanyReferencesShared";

const CompanyReferencesForm = ({
  classes,
  formValues,
  setFormValues,
  setFormValueErrors,
  formValueErrors,
  initialFormValues,
  initialFormValueErrorsState,
  references,
  setReferences,
  dialogContent,
  introText,
}) => {
  const { t } = useTranslation();
  const [editReferenceDialogOpen, setEditReferenceDialogOpen] = useState(false);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [referenceImagesObjects, setReferenceImagesObjects] = useState([]);
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);
  const { user, unsetUser, token, unsetToken, setMessage } =
    useContext(GlobalContext);

  const months = [
    t("general.label.january"),
    t("general.label.february"),
    t("general.label.march"),
    t("general.label.april"),
    t("general.label.may"),
    t("general.label.june"),
    t("general.label.july"),
    t("general.label.august"),
    t("general.label.september"),
    t("general.label.october"),
    t("general.label.november"),
    t("general.label.december"),
  ];

  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);

  /**
   * useEffect
   */
  useEffect(() => {
    setReferences(user.getData().company.micrositeReferences);
  }, [setReferences, user]);

  /**
   * reorder
   *
   * @param list
   * @param startIndex
   * @param endIndex
   * @returns {unknown[]}
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * handleReferenceImagesSave
   *
   * @param images
   */
  const handleReferenceImagesSave = (images) => {
    setFormValues({ ...formValues, Images: formatImagesObject(images) });
  };

  /**
   * onDragEnd
   *
   * @param result
   */
  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedListData = reorder(
      references,
      result.source.index,
      result.destination.index
    );

    setReferences(reorderedListData);
    setLoadingOverlayOpen(true);

    // Convert the images to base64 to push them into the API
    const asyncRes = convertImagesToBase64(reorderedListData);

    Promise.all(asyncRes).then((completedReorderedList) => {
      executeCompanyUpdateMutation({
        hashId: user.getData().company.hashID,
        references: completedReorderedList,
      }).then((result) => {
        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyReferences",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          const micrositeReferences = [];
          result.data.updateCompany[0].MicrositeReferences.edges.map((item) => {
            delete item.node.__typename;
            const referenceImages = [];
            item.node.MicrositeReferencesImages.edges.map((image) => {
              delete image.node.__typename;
              referenceImages.push(image.node);
              return image;
            });
            item.node.MicrositeReferencesImages = referenceImages;
            micrositeReferences.push(item.node);
            return micrositeReferences;
          });
          user.setData({
            company: {
              micrositeReferences: micrositeReferences,
            },
          });
          setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
        }
      });
    });
  };

  /**
   * onImagesDragEnd
   *
   * @param result
   */
  const onImagesDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      referenceImagesObjects,
      result.source.index,
      result.destination.index
    );

    setReferenceImagesObjects(items);
    handleReferenceImagesSave(items);
  };

  /**
   * getMonths
   *
   * @returns {[]}
   */
  const getMonths = () => {
    const menuItems = [];
    months.map((item, index) => {
      menuItems.push(
        <MenuItem key={index} value={index + 1}>
          {item}
        </MenuItem>
      );
      return item;
    });
    return menuItems;
  };

  /**
   * getYears
   *
   * @returns {[]}
   */
  const getYears = () => {
    const years = [];
    const lowEnd = 1900;
    const highEnd = new Date().getFullYear();
    for (let i = highEnd; i >= lowEnd; i--) {
      years.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return years;
  };

  /**
   * handleReferenceTitleChange
   *
   * @param event
   */
  const handleReferenceTitleChange = (event) => {
    setFormValues({ ...formValues, Title: event.target.value });
  };

  /**
   * handleReferenceButtonTitleChange
   *
   * @param event
   */
  const handleReferenceButtonTitleChange = (event) => {
    setFormValues({ ...formValues, ButtonTitle: event.target.value });
  };

  /**
   * handleReferenceButtonLinkChange
   *
   * @param event
   */
  const handleReferenceButtonLinkChange = (event) => {
    setFormValues({ ...formValues, ButtonLink: event.target.value });
  };

  /**
   * handleReferenceContentChange
   *
   * @param event
   */
  const handleReferenceContentChange = (event) => {
    setFormValues({ ...formValues, Content: event.target.value });
  };

  /**
   * handleReferenceLocationChange
   *
   * @param event
   */
  const handleReferenceLocationChange = (event) => {
    setFormValues({ ...formValues, Location: event.target.value });
  };

  /**
   * handleMonthChange
   *
   * @param event
   */
  const handleMonthChange = (event) => {
    setFormValues({ ...formValues, Month: event.target.value });
  };

  /**
   * handleYearChange
   *
   * @param event
   */
  const handleYearChange = (event) => {
    setFormValues({ ...formValues, Year: event.target.value });
  };

  /**
   * handleDeleteReference
   *
   * @param item
   */
  const handleDeleteReference = (item) => {
    const clearedArray = references.filter(
      (element) => element.Sort !== item.Sort
    );

    user.setData({
      company: {
        micrositeReferences: clearedArray,
      },
    });
    setLoadingOverlayOpen(true);

    // Convert the images to base64 to push them into the API
    const asyncRes = convertImagesToBase64(clearedArray);

    Promise.all(asyncRes).then((convertedReferences) => {
      executeCompanyUpdateMutation({
        hashId: user.getData().company.hashID,
        references: convertedReferences,
      }).then((result) => {
        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyReferences",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          const micrositeReferences = [];
          result.data.updateCompany[0].MicrositeReferences.edges.map((item) => {
            delete item.node.__typename;
            const referenceImages = [];
            item.node.MicrositeReferencesImages.edges.map((image) => {
              delete image.node.__typename;
              referenceImages.push(image.node);
              return image;
            });
            item.node.MicrositeReferencesImages = referenceImages;
            micrositeReferences.push(item.node);
            return micrositeReferences;
          });
          user.setData({
            company: {
              micrositeReferences: micrositeReferences,
            },
          });
          setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.delete.success"));
        }
      });
    });
  };

  /**
   * editReferenceDialog
   */
  const editReferenceDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        className={classes.dialogRoot}
        open={editReferenceDialogOpen}
      >
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          <IconButton
            onClick={() => {
              setEditReferenceDialogOpen(false);
              setFormValues(initialFormValues);
            }}
          >
            <CloseIcon id="closeDialog" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div
            ref={(node) => {
              dialogContent = node;
            }}
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              margin="normal"
              padding="normal"
              className={classes.gridContainer}
            >
              <Grid item className={clsx(classes.gridItem, classes.itemHeader)}>
                <h1 className={classes.title}>
                  {t("company.label.references")}
                </h1>
                <p>{introText}</p>
              </Grid>
              <Grid item className={classes.gridItem}>
                <TextField
                  id="inputReferenceTitle"
                  variant="outlined"
                  align="left"
                  error={GetSafe(() => formValueErrors.Title.hasError, false)}
                  label={t("form.label.title")}
                  value={formValues.Title}
                  helperText={GetSafe(() => formValueErrors.Title.message, "")}
                  onChange={handleReferenceTitleChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item className={classes.gridItem}>
                <TextField
                  id="inputReferenceContent"
                  variant="outlined"
                  multiline
                  inputProps={{
                    // this has to be in lowerCase inputProps to work properly
                    maxLength: REFERENCE_DESCRIPTION_LENGTH,
                  }}
                  align="left"
                  error={GetSafe(() => formValueErrors.Content.hasError, false)}
                  label={t("form.label.text")}
                  value={formValues.Content}
                  helperText={
                    `${
                      formValues.Content?.length || 0
                    }/${REFERENCE_DESCRIPTION_LENGTH}` +
                    " " +
                    GetSafe(() => formValueErrors.Content.message, "")
                  }
                  onChange={handleReferenceContentChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item className={classes.gridItem}>
                <TextField
                  id="inputReferenceLocation"
                  variant="outlined"
                  multiline
                  align="left"
                  error={GetSafe(
                    () => formValueErrors.Location.hasError,
                    false
                  )}
                  label={t("form.label.location")}
                  value={formValues.Location}
                  helperText={GetSafe(
                    () => formValueErrors.Location.message,
                    ""
                  )}
                  onChange={handleReferenceLocationChange}
                  margin="normal"
                  fullWidth
                />
              </Grid>
             
              <Grid item className={classes.gridItem}>
                <TextField
                  className={classes.textFieldSmall}
                  id="selectMonth"
                  label={t("form.label.month")}
                  variant="outlined"
                  select
                  align="left"
                  value={formValues.Month}
                  error={GetSafe(() => formValueErrors.Month.hasError, false)}
                  helperText={GetSafe(() => formValueErrors.Month.message, "")}
                  onChange={handleMonthChange}
                  margin="normal"
                >
                  {getMonths()}
                </TextField>
                <TextField
                  className={classes.textFieldSmall}
                  id="selectYear"
                  label={t("form.label.year")}
                  variant="outlined"
                  select
                  align="left"
                  value={formValues.Year}
                  error={GetSafe(() => formValueErrors.Year.hasError, false)}
                  helperText={GetSafe(() => formValueErrors.Year.message, "")}
                  onChange={handleYearChange}
                  margin="normal"
                >
                  {getYears()}
                </TextField>
              </Grid>
              <Grid item className={classes.gridItem}>
                  <TextField
                    id="inputReferenceButtonTitle"
                    variant="outlined"
                    align="left"
                    error={GetSafe(() => formValueErrors.ButtonTitle.hasError, false)}
                    label={t("form.label.buttontitle")}
                    value={formValues.ButtonTitle}
                    helperText={GetSafe(() => formValueErrors.ButtonTitle.message, "")}
                    onChange={handleReferenceButtonTitleChange}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item className={classes.gridItem}>
                  <TextField
                    id="inputReferenceButtonLink"
                    variant="outlined"
                    align="left"
                    error={GetSafe(() => formValueErrors.ButtonLink.hasError, false)}
                    label={t("form.label.buttonlink")}
                    value={formValues.ButtonLink}
                    helperText={GetSafe(() => formValueErrors.ButtonLink.message, "")}
                    onChange={handleReferenceButtonLinkChange}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              <Grid item className={classes.gridItem}>
                <span className={classes.label}>
                  {t("form.label.images.references")}
                </span>
                <List className={classes.root}>
                  {/* eslint-disable-next-line react/jsx-handler-names */}
                  <DragDropContext onDragEnd={onImagesDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {referenceImagesObjects.map((item, index) => {
                            const internalID = index + 1;
                            return (
                              <React.Fragment key={internalID}>
                                <Draggable
                                  draggableId={internalID}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ListItem
                                      className={classes.listItem}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <ListItemIcon>
                                        <DragIndicatorIcon
                                          className={classes.icon}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        className={classes.location}
                                        primary={
                                          <img
                                            className={classes.logoImg}
                                            src={item.data}
                                            alt={item.file.name}
                                          />
                                        }
                                        secondary={
                                          <Typography
                                            className={classes.locationText}
                                          >
                                            {item.file.name}
                                          </Typography>
                                        }
                                      />
                                      <IconButton
                                        id={"deleteButton" + internalID}
                                        onClick={() => {
                                          const clearedArray =
                                            referenceImagesObjects.filter(
                                              (element) =>
                                                element.data !== item.data
                                            );
                                          setReferenceImagesObjects(
                                            clearedArray
                                          );
                                          handleReferenceImagesSave(
                                            clearedArray
                                          );
                                        }} // eslint-disable-line
                                      >
                                        <DeleteIcon className={classes.icon} />
                                      </IconButton>
                                    </ListItem>
                                  )}
                                </Draggable>
                                <Divider />
                              </React.Fragment>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {referenceImagesObjects.length < 5 && (
                    <Button
                      className={classes.buttonImg}
                      variant="contained"
                      color="primary"
                      onClick={() => setImageUploadDialogOpen(true)}
                    >
                      {t("form.label.reference.images.add")}
                    </Button>
                  )}
                  {GetSafe(() => formValueErrors.Images.hasError, false) && (
                    <div className={classes.imagesError}>
                      {formValueErrors.Images.message}
                    </div>
                  )}
                  <DropzoneDialogBase
                    id="uploadCompanyImages"
                    dialogTitle={<></>}
                    dropzoneText={t("form.label.company.images.upload")}
                    acceptedFiles={["image/jpeg", "image/png", "image/svg+xml"]}
                    filesLimit={5}
                    fileObjects={referenceImagesObjects}
                    cancelButtonText={t("general.label.abort")}
                    submitButtonText={t("general.label.save")}
                    maxFileSize={800000}
                    open={imageUploadDialogOpen}
                    onAdd={(newFileObjs) => {
                      setReferenceImagesObjects(
                        [].concat(referenceImagesObjects, newFileObjs)
                      );
                    }}
                    onDelete={(deleteFileObj) => {
                      const clearedArray = referenceImagesObjects.filter(
                        (element) => element.data !== deleteFileObj.data
                      );
                      setReferenceImagesObjects(clearedArray);
                    }}
                    onClose={() => setImageUploadDialogOpen(false)}
                    onSave={() => {
                      setImageUploadDialogOpen(false);
                      handleReferenceImagesSave(referenceImagesObjects);
                    }}
                    showPreviews
                    showFileNamesInPreview={false}
                    alertSnackbarProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                      },
                    }}
                    getFileAddedMessage={(fileName) => {
                      return t("company.info.logo.added", {
                        fileName: fileName,
                      });
                    }}
                    getFileRemovedMessage={(fileName) => {
                      return t("company.info.logo.removed", {
                        fileName: fileName,
                      });
                    }}
                    getFileLimitExceedMessage={(filesLimit) => {
                      return t("company.info.logo.filesLimit", {
                        filesLimit: filesLimit,
                      });
                    }}
                    getDropRejectMessage={(
                      rejectedFile,
                      acceptedFiles,
                      maxFileSize
                    ) => {
                      return t("company.info.logo.rejected", {
                        rejectedFile: rejectedFile.name,
                        acceptedFiles: acceptedFiles,
                        maxFileSize: maxFileSize / 1000,
                      });
                    }}
                  />
                </List>
              </Grid>
              <Grid item className={classes.gridItem}>
                <Button
                  id="buttonFormSubmit"
                  variant="contained"
                  color="primary"
                  className={classes.startButton}
                  onClick={handleFormSubmit}
                  disabled={companyIsUpdating}
                >
                  {t("general.label.save")}
                  {companyIsUpdating && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * handleEditReference
   *
   * @param item
   */
  const handleEditReference = (item) => {
    // Convert the images to base64 to push them into the API
    const asyncRes = convertImagesToBase64([item]);

    Promise.all(asyncRes).then(() => {
      setFormValues({
        Sort: item.Sort,
        Title: item.Title,
        ButtonTitle: item.ButtonTitle,
        ButtonLink: item.ButtonLink,
        Content: item.Content,
        Location: item.Location,
        Month: item.Month,
        Year: item.Year,
        Images: item.MicrositeReferencesImages,
      });

      if (item.MicrositeReferencesImages !== undefined) {
        const reformattedImages = [];
        item.MicrositeReferencesImages.map((element) => {
          if (element.Src.indexOf('missing-') === -1) {
            reformattedImages.push({
              data: element.Src,
              file: DataUrlToFileConverter(element.Src, element.Title),
            });
          }
          
          return element;
        });

        if (reformattedImages.length > 0) {
          setReferenceImagesObjects(reformattedImages);
        }
      }

      setEditReferenceDialogOpen(true);
    });
  };

  /**
   * convertImagesToBase64
   *
   * @param referenceListData array of objects
   * @returns {*}
   */
  const convertImagesToBase64 = (referenceListData) => {
    return referenceListData.map(async (item) => {
      delete item.__typename;

      const referenceImages = [];
      item.MicrositeReferencesImages.map((element) => {
        referenceImages.push(element.Src);
        return element;
      });

      const fileObjs = await Promise.all(
        referenceImages.map(async (referenceImage) => {
          let file;
          
          if (typeof referenceImage === "string") {
            file = await createFileFromUrl(referenceImage);
          } else {
            file = referenceImage;
          }

          const data = await readFile(file);

          return {
            file,
            data,
          };
        })
      );
      
      item.MicrositeReferencesImages = formatImagesObject(fileObjs);
      return item;
    });
  };

  /**
   * formatImagesObject
   *
   * @param images
   * @returns {[]}
   */
  const formatImagesObject = (images) => {
    const formattedImagesObject = [];
    
    images.map((item, index) => {
      formattedImagesObject.push({
        Src: item.data || `missing-${index}`,
        Title: item.file?.name || `missing-${index}`,
        Alt: item.file?.name || `missing-${index}`,
      });
      
      return item;
    });
    
    return formattedImagesObject;
  };

  /**
   * readFile
   *
   * @param file
   * @returns {Promise<unknown>}
   */
  const readFile = (file) => {
    if (!file) {
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      
      reader.onload = (event) => {
        if (event && event.target) {
          resolve(event.target.result);
        }
      };
      
      reader.onerror = (event) => {
        reader.abort();
        reject(event);
      };
      
      reader.readAsDataURL(file);
    });
  };

  /**
   * createFileFromUrl
   *
   * @param url
   * @returns {Promise<File>}
   */
  const createFileFromUrl = async (url) => {
    const response = await window.fetch(url);
    const data = await response.blob();
    const metadata = { type: data.type };
    const filename = url.replace(/\?.+/, "").split("/").pop();
    return new window.File([data], filename, metadata);
  };

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = () => {
    // Convert the images to base64 to push them into the API
    const asyncRes = convertImagesToBase64(references);

    Promise.all(asyncRes).then((convertedReferences) => {
      const formData = {
        Sort: formValues.Sort,
        Title: formValues.Title,
        Content: formValues.Content,
        Location: formValues.Location,
        ButtonTitle: formValues.ButtonTitle,
        ButtonLink: formValues.ButtonLink,
        Month: formValues.Month?.toString(),
        Year: formValues.Year?.toString(),
        MicrositeReferencesImages: formValues.Images,
      };
      const { error } = schema.validate(formData, {
        abortEarly: false,
      });
      if (error) {
        const formErrors = FormValueErrorsBuilder(error, t);
        setFormValueErrors({ ...formErrors });
        if (dialogContent) {
          dialogContent.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      } else {
        setFormValueErrors(initialFormValueErrorsState);

        let newReferences = [];
        if (formData.Sort === null) {
          // it is a new reference
          const highestSortValue = Math.max.apply(
            Math,
            convertedReferences.map((object) => {
              return object.Sort;
            })
          );
          formData.Sort = highestSortValue + 1;
          newReferences = convertedReferences.concat(formData);
        } else {
          // it is an existing reference
          const index = convertedReferences.findIndex(
            (element) => element.Sort === formData.Sort
          );
          // if we got an index, replace
          if (~index) {
            convertedReferences[index] = formData;
          }
          newReferences = convertedReferences;
        }

        newReferences.map((item) => {
          delete item.__typename;
          return item;
        });

        executeCompanyUpdateMutation({
          hashId: user.getData().company.hashID,
          references: newReferences,
        }).then((result) => {
          if (result.error) {
            if (result.error.message.indexOf("User forced logout") !== -1) {
              setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
              unsetUser();
              unsetToken();
              navigate("/");
            } else {
              setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
              ExternalErrorLogger.log({
                text: "Error submitting data on CompanyReferences",
                data: {
                  token: JSON.stringify(token.getData()),
                  user: JSON.stringify(user.getData()),
                  errorMessage: result.error.message,
                },
              });
            }
          } else {
            const micrositeReferences = [];
            result.data.updateCompany[0].MicrositeReferences.edges.map(
              (item) => {
                delete item.node.__typename;
                const referenceImages = [];
                item.node.MicrositeReferencesImages.edges.map((image) => {
                  delete image.node.__typename;
                  referenceImages.push(image.node);
                  return image;
                });
                item.node.MicrositeReferencesImages = referenceImages;
                micrositeReferences.push(item.node);
                return micrositeReferences;
              }
            );
            user.setData({
              company: {
                micrositeReferences: micrositeReferences,
              },
            });
            setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
          }
        });
      }
    });
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={classes.gridItem}>
        <List className={classes.root}>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          {references !== null && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {references.map((item, index) => {
                      const internalID = item.Sort + 1;
                      return (
                        <React.Fragment key={internalID}>
                          <Draggable draggableId={internalID} index={index}>
                            {(provided) => (
                              <ListItem
                                className={classes.listItem}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItemIcon>
                                  <DragIndicatorIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                  className={classes.location}
                                  primary={item.Title}
                                  secondary={
                                    <>
                                      <span className={classes.locationText}>
                                        {item.Content}
                                      </span>
                                      <br />
                                      <span className={classes.cityAndDate}>
                                        {item.Location}{" "}
                                        {item.Month
                                          ? item.Month.toString().length === 1
                                            ? 0 + item.Month.toString() + "."
                                            : item.Month + "."
                                          : ""}
                                        {item.Year}
                                      </span>
                                      <br />
                                      {item.MicrositeReferencesImages !==
                                        undefined &&
                                        item.MicrositeReferencesImages.map(
                                          (image) => {
                                            return image.Src && image.Src.length > 0 ? (
                                              <img
                                                key={image.Src}
                                                className={classes.img}
                                                src={image.Src}
                                                alt={image.Alt}
                                              />
                                            ) : null;
                                          }
                                        )}
                                    </>
                                  }
                                />
                                <IconButton
                                  id={"editButton" + item.Sort}
                                  onClick={() => handleEditReference(item)}
                                >
                                  <EditIcon className={classes.icon} />
                                </IconButton>
                                <IconButton
                                  id={"deleteButton" + item.Sort}
                                  onClick={() => handleDeleteReference(item)}
                                >
                                  <DeleteIcon className={classes.icon} />
                                </IconButton>
                              </ListItem>
                            )}
                          </Draggable>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {references !== null && references.length < 4 && (
            <ListItem
              className={clsx(classes.listItem, classes.listItemPointer)}
              onClick={() => setEditReferenceDialogOpen(true)}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.location}
                primary={t("company.label.references.add")}
              />
            </ListItem>
          )}
          {editReferenceDialog()}
        </List>
        {loadingOverlayOpen && (
          <LoadingOverlay text={t("company.label.references.in.progress")} />
        )}
      </Grid>
    </>
  );
};

export default CompanyReferencesForm;
