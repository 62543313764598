import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  fetchingProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999
  },
  text: {
    backgroundColor: '#3EE1FA',
    width: 500,
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 10,
    textAlign: 'center',
    borderRadius: 3
  }
}))

/**
 * LoadingOverlay
 *
 * @returns {*}
 * @constructor
 */
const LoadingOverlay = props => {
  const classes = useStyles()

  /**
   * return
   */
  return (
    <div className={classes.overlay}>
      <CircularProgress className={classes.fetchingProgress} />
      {props.text !== undefined && (
        <div className={classes.text}>{props.text}</div>
      )}
    </div>
  )
}

export default LoadingOverlay
