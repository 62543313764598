import React from 'react'
import { makeStyles, Grid, Button } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import MicrositeDetailsWizardStepper from './MicrositeDetailsWizardStepper'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  item: {
    position: 'relative',
    width: '100%',
    maxWidth: 330,
    margin: '0 auto'
  },
  itemStepper: {
    maxWidth: 600
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: 'left'
    }
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '30px'
  },
  buttonBack: {
    width: '50%',
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonNext: {
    width: '50%',
    color: '#fff',
    marginRight: 'auto',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(4)
  }
}))

const CompleteWizardStep = ({ step, setStep }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleButtonBack = () => {
    setStep(step - 1 || 0)
  }

  const handleButtonNext = () => {
    console.log('sumbit and redirect to company/profile')
  }

  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t('microsite.details.wizard.title')}</h1>
        <p className={classes.intro}>
          {t('microsite.details.wizard.step.5.intro')}
        </p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <MicrositeDetailsWizardStepper step={step} />
      </Grid>
      <Grid item className={classes.item}>
        CompleteWizardStep
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id='buttonFormBack'
          color='secondary'
          className={classes.buttonBack}
          onClick={handleButtonBack}
        >
          {t('form.label.back')}
        </Button>
        <Button
          id='buttonFormNext'
          variant='contained'
          color='primary'
          align='left'
          className={classes.buttonNext}
          onClick={handleButtonNext}
        >
          {t('form.label.submit')}
        </Button>
      </Grid>
    </>
  )
}

export default CompleteWizardStep
