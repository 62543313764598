import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import GetSafe from '../../lib/GetSafe'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS } from '../../config'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginRight: 0,
    marginLeft: 0
  },
  autocompleteResultWrapper: {
    position: 'relative',
    textAlign: 'center',
    zIndex: 2
  },
  autocompleteResult: {
    position: 'relative', // Could be 'absolute' for hovering autosuggest-result-list
    padding: theme.spacing(3),
    width: '100%'
  }
}))

// Google search options
const searchOptions = {
  componentRestrictions: { country: 'ch' }
}

/**
 * GooglePlacesAutosuggestAddress
 *
 * @returns {*}
 * @constructor
 */
const GooglePlacesAutosuggestAddress = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [googleSearchValue, setGoogleSearchValue] = useState('')

  // Check for valid callback
  if (typeof props.onSuggestionSelect === 'undefined') {
    throw Error('No onSuggestionSelect defined')
  }

  /**
   * useEffect
   */
  useEffect(() => {
    if (typeof props.presetValue !== 'undefined' && props.presetValue !== '') {
      setGoogleSearchValue(props.presetValue)
    }
  }, [props.presetValue])

  /**
   * handleAddressSelect
   *
   * @param address
   */
  const handleAddressSelect = address => {
    setGoogleSearchValue(address)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        if (props.onSuggestionSelect) {
          props.onSuggestionSelect({
            address,
            latLng
          })
        }
      })
      .catch(error => {
        ExternalErrorLogger.log({
          text: 'Error handle address select on GooglePlacesAutosuggestAddress',
          data: {
            address: JSON.stringify(address),
            error: JSON.stringify(error)
          }
        })
      })
  }

  /**
   * return
   */
  return (
    <PlacesAutocomplete
      value={googleSearchValue}
      onChange={setGoogleSearchValue}
      onSelect={handleAddressSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            {...getInputProps()}
            id='locationEditAddress'
            label={t('location.label.address')}
            className={classes.textField}
            margin='normal'
            variant='outlined'
            error={GetSafe(() => props.autoSuggestError, false)}
            helperText={GetSafe(
              () =>
                props.autoSuggestError ? t('error.no.address.results') : '',
              ''
            )}
          />
          {loading && (
            <div className={classes.autocompleteResultWrapper}>
              <Paper className={classes.autocompleteResult}>
                <List dense>
                  <ListItem id='autocomplete-places-result-loading'>
                    {t('general.label.loading')}
                  </ListItem>
                </List>
              </Paper>
            </div>
          )}
          {!loading && suggestions.length > 0 && (
            <div className={classes.autocompleteResultWrapper}>
              <Paper className={classes.autocompleteResult}>
                <List dense>
                  {suggestions.map((suggestion, index) => (
                    <ListItem
                      key={index}
                      {...getSuggestionItemProps(suggestion)}
                      id={`autocomplete-places-result-${index}`}
                      button
                      component='a'
                    >
                      {suggestion.description}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default withAccessControl(
  GooglePlacesAutosuggestAddress,
  ACL_COMPONENTS.GOOGLE_PLACES_AUTOSUGGEST_FIELD
)
