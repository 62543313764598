import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import GlobalContext from "../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import AppBar from "@material-ui/core/AppBar";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import UserTypes from "./Onboarding/UserTypes";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "calc(100% - 250px)",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: "#525252",
    fontSize: 14,
    height: "100%",
    width: "100%",
  },
  tab: {
    height: 64,
    width: "100%",
    maxWidth: 188,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableTabsHeader: {
    background: "#F7F9FA",
    boxShadow: "none",
  },
  buttonColor: {
    color: theme.palette.text.primary,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  newMarker: {
    width: 5,
    height: 5,
    display: "inline-block",
    position: "relative",
    top: "-10px",
    right: "-3px",
    borderRadius: "50%",
  },
}));

export const BUILDING_APPLICATION_TYPE = {
  PROJECT: "Baugesuch",
  PUBLICATION: "Baupublikation",
  SUBMISSION: "Submission",
};

/**
 * BuildingApplicationTypeTabs
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const BuildingApplicationTypeTabs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    selectedBuildingApplicationTypeTabValue,
    setSelectedBuildingApplicationTypeTabValue,
  } = useContext(GlobalContext);

  /**
   * set selected tab value useEffect
   */
  useEffect(() => {
    if (!selectedBuildingApplicationTypeTabValue) {
      setSelectedBuildingApplicationTypeTabValue(
        BUILDING_APPLICATION_TYPE.PROJECT
      );
    }
  }, [
    selectedBuildingApplicationTypeTabValue,
    setSelectedBuildingApplicationTypeTabValue,
  ]);

  /**
   * return
   */
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.tableTabsHeader}>
        <ToggleButtonGroup
          size="small"
          exclusive
          onChange={props.handleApplicationTypeTabChange}
          aria-label="Building Application type switch"
          value={selectedBuildingApplicationTypeTabValue}
        >
          <ToggleButton
            className={classes.buttonColor}
            value={BUILDING_APPLICATION_TYPE.PROJECT}
          >
            {t("projects.label")}
            {props.hasNewApplications && (
              <span
                className={classes.newMarker}
                style={{
                  background:
                    selectedBuildingApplicationTypeTabValue ===
                    BUILDING_APPLICATION_TYPE.PROJECT
                      ? "#fff"
                      : "#ff1744",
                }}
              ></span>
            )}
          </ToggleButton>
          {user.getData().mode !== UserTypes.PRO && (
            <Tooltip
              title={t("projects.free.route.premium.pro")}
              aria-label={t("projects.free.route.premium.pro")}
              arrow
            >
              <span>
                <ToggleButton
                  disabled
                  className={classes.buttonColor}
                  value={BUILDING_APPLICATION_TYPE.SUBMISSION}
                >
                  {t("submissions.label")}
                  {props.hasNewSubmissions && (
                    <span
                      className={classes.newMarker}
                      style={{
                        background:
                          selectedBuildingApplicationTypeTabValue ===
                          BUILDING_APPLICATION_TYPE.SUBMISSION
                            ? "#fff"
                            : "#ff1744",
                      }}
                    ></span>
                  )}
                </ToggleButton>
              </span>
            </Tooltip>
          )}
          {user.getData().mode === UserTypes.PRO && (
            <ToggleButton
              className={classes.buttonColor}
              value={BUILDING_APPLICATION_TYPE.SUBMISSION}
            >
              {t("submissions.label")}
              {props.hasNewSubmissions && (
                <span
                  className={classes.newMarker}
                  style={{
                    background:
                      selectedBuildingApplicationTypeTabValue ===
                      BUILDING_APPLICATION_TYPE.SUBMISSION
                        ? "#fff"
                        : "#ff1744",
                  }}
                ></span>
              )}
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </AppBar>
    </div>
  );
};

export default BuildingApplicationTypeTabs;
