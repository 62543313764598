import Dialog from '@material-ui/core/Dialog'
import { DialogTitle } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import React from 'react'

const styles = {
  dialogTitleCloseIcon: {
    marginLeft: 'auto',
    padding: 0,
    paddingRight: 23,
    paddingTop: 10
  },
  dialog: {
    width: '100%',
    paddingBottom: 30
  },
  dialogButton: {
    color: '#ffffff',
    width: '50%',
    marginTop: '20px',
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

/**
 * UpdateCompanyDataDialog
 * @param setLoadingOverlayOpen function to set loadingOverlayOpen
 * @param loadingOverlayOpen boolean value for opened or closed dialog
 * @param t function to provide translations
 * @returns {JSX.Element}
 * @constructor
 */
const UpdateCompanyDataDialog = ({
  setLoadingOverlayOpen,
  loadingOverlayOpen,
  t
}) => {
  /**
   * handleClose
   */
  const handleClose = () => {
    setLoadingOverlayOpen(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='update-business-activities-dialog'
      open={loadingOverlayOpen}
    >
      <DialogTitle
        id='update-business-activities-dialog-title'
        style={styles.dialogTitleCloseIcon}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon id='closeDialog' />
        </IconButton>
      </DialogTitle>
      <DialogContent style={styles.dialog}>
        <Typography
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: t('general.label.update.business.activities')
              .split('\n')
              .join('<br />')
          }}
        ></Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color='primary'
          variant='contained'
          style={styles.dialogButton}
        >
          {t('general.label.acknowledged')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateCompanyDataDialog
