import React from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'
import withAccessControl from '../HOC/AccessControl'
import InvoiceForm from './InvoiceForm'
import { ACL_COMPONENTS } from '../../config'

/**
 * Invoice
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Invoice = props => {
  /**
   * return
   */
  return (
    <StripeProvider apiKey={props.stripePublicKey}>
      <Elements>
        <InvoiceForm submitIsDisabled={props.submitIsDisabled} />
      </Elements>
    </StripeProvider>
  )
}

export default withAccessControl(Invoice, ACL_COMPONENTS.CREDIT_CARD)
