import React, { useContext, useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Card,
  CircularProgress,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import MicrositeDetailsWizardStepper from "./MicrositeDetailsWizardStepper";
import CompanyInfoForm from "../Company/CompanyInfoForm";
import GlobalContext from "../../lib/GlobalContext";
import { green } from "@material-ui/core/colors";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import { useMutation, useQuery } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import LoadingOverlay from "../LoadingOverlay";
import FormatHelper from "../../lib/FormatHelper";
import {
  initialFormValuesState,
  initialFormValueErrorsState,
  createFileFromUrl,
  readFile,
  updateCompanyMutation,
  formatImagesArray,
  schema,
  LOADING_OVERLAY_TIMEOUT,
  querySmartconextConf,
} from "../Company/CompanyInfoShared";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  intro: {
    margin: 20,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#e0f6fa",
    // backgroundColor: '#fdf4e5'
  },
  introItem: {
    padding: 10,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  introContainer: {
    flexWrap: "nowrap",
  },
  item: {
    position: "relative",
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
  },
  itemStepper: {
    maxWidth: 600,
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  buttonBack: {
    width: "50%",
    color: theme.palette.primary.main,
    marginLeft: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonNext: {
    width: "50%",
    color: "#fff",
    marginRight: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  gridContainer: {
    width: "100%",
    padding: "16px 0",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "100%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  checkboxGridItem: {
    // width: '50%',
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    color: "#ffffff",
    width: "100%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formLabel: {
    color: theme.palette.text.primary,
  },
  input: {
    marginLeft: 60,
  },
  logoImg: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonImg: {
    color: "#ffffff",
    marginBottom: "20px",
  },
  label: {
    color: theme.palette.text.primary,
  },
  root: {
    width: "100%",
    // maxWidth: 444
  },
  membershipsLogo: {
    width: 80,
    height: "auto",
    paddingRight: 20,
  },
  dialogRoot: {
    flexGrow: 1,
  },
  dialog: {
    width: "100%",
    paddingBottom: 56,
    "&::before": theme.watermarkLogo,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  startButton: {
    width: "100%",
    color: "#fff",
    fontWeight: "bold",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: 0,
  },
  listItemPointer: {
    cursor: "pointer",
  },
  tooltipItem: {
    maxWidth: 350,
    zIndex: 1333,
    borderRadius: 5,
    background: "#006A7A",
    fontSize: 16,
    textAlign: "center",
    "& div": {
      margin: 0,
      color: "#fff",
    },
  },
  tooltipInfo: {
    maxWidth: "100%",
    background: theme.palette.background.default,
    "& div": {
      maxWidth: "100%",
      color: theme.palette.text.primary,
      fontSize: 12,
      lineHeight: "20px",
    },
    "@media (min-width:768px)": {
      maxWidth: 450,
    },
  },
  tooltipTitle: {
    display: "flex",
    alignItems: "center",
  },
}));

const CompanyInfoStep = ({ step, setStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, setUser, token, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);
  const [currentFileObjects, setCurrentFileObjects] = useState([]);
  const [colorSchemes, setColorSchemes] = useState([]);
  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );
  const [result, executeQuerySmartconextConf] = useQuery({
    query: querySmartconextConf,
    pause: true,
  });

  /**
   * useEffect executeQuerySmartconextConf
   */
  useEffect(() => {
    if (colorSchemes.length === 0) {
      executeQuerySmartconextConf();
    }
  }, [colorSchemes, executeQuerySmartconextConf]);

  /**
   * useEffect set FormData
   */
  useEffect(() => {
    const userData = user.getData();
    setFormValues({
      directoryName:
        userData.company.urlSegment || initialFormValuesState.directoryName,
      employeeCount:
        userData.company.employeeCount || initialFormValuesState.employeeCount,
      legalForm: userData.company.legalForm || initialFormValuesState.legalForm,
      foundingYear:
        userData.company.foundingYear || initialFormValuesState.foundingYear,
      masterOperation:
        FormatHelper.boolToString(userData.company.masterOperation) ||
        initialFormValuesState.masterOperation,
      trainingCompany:
        FormatHelper.boolToString(userData.company.trainingCompany) ||
        initialFormValuesState.trainingCompany,
      colorScheme: initialFormValuesState.colorScheme,
      memberships:
        userData.company.memberships || initialFormValuesState.memberships,
      furtherMemberships: initialFormValuesState.furtherMemberships,
      micrositeColorScheme:
        userData.company.micrositeColorScheme ||
        initialFormValuesState.micrositeColorScheme,
      micrositeDescription:
        userData.company.micrositeDescription ||
        initialFormValuesState.micrositeDescription,
      micrositeEmailApplicationText:
        userData.company.micrositeEmailApplicationText ||
        initialFormValuesState.micrositeEmailApplicationText,
      micrositeLogo:
        userData.company.micrositeLogo || initialFormValuesState.micrositeLogo,
      micrositeImages:
        userData.company.micrositeImages ||
        initialFormValuesState.micrositeImages,
      additionalMemberships:
        userData.company.additionalMemberships ||
        initialFormValuesState.additionalMemberships,
    });
  }, [user]);

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching smartconext conf on CompanyInfo",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readSmartconextConf !== "undefined"
        ) {
          if (colorSchemes.length === 0) {
            setColorSchemes(
              result.data.readSmartconextConf[0].microsite_color_schemes
            );
            setFormValues({
              ...formValues,
              colorScheme: user.getData().company.micrositeColorScheme || "3", // Default colorScheme = "Schweiz"
            });
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const handleButtonBack = () => {
    setStep(step - 1 || 0);
  };

  const handleButtonNext = async () => {
    formValues.memberships.map((item) => {
      delete item.__typename;
      return item;
    });

    formValues.micrositeImages.map((item) => {
      delete item.__typename;
      return item;
    });

    const formattedImagesArray = formatImagesArray(formValues);

    const fileObjs = await Promise.all(
      formattedImagesArray.map(async (initialFile) => {
        let file;
        if (typeof initialFile.Src === "string") {
          file = await createFileFromUrl(initialFile.Src);
        } else {
          file = initialFile.Src;
        }
        const data = await readFile(file);

        return {
          file,
          data,
        };
      })
    );

    const convertedImagesArray = [];
    fileObjs.map((item) => {
      if (item.data) {
        convertedImagesArray.push({
          Src: item.data,
          Title: item.file.name,
          Alt: item.file.name,
        });
      } else {
        convertedImagesArray.push(item);
      }
      return item;
    });

    const logoData = { Src: "", Alt: "", Title: "" };
    if (currentFileObjects[0]) {
      logoData.Src = currentFileObjects[0].data;
      logoData.Alt = currentFileObjects[0].file.name;
      logoData.Title = currentFileObjects[0].file.name;
    } else if (
      formValues.micrositeLogo.Src !== null &&
      formValues.micrositeLogo.Src !== "" &&
      formValues.micrositeLogo.Src !== undefined
    ) {
      const logoArray = [formValues.micrositeLogo];
      const logoFile = await Promise.all(
        logoArray.map(async (initialFile) => {
          let file;
          if (typeof initialFile.Src === "string") {
            file = await createFileFromUrl(initialFile.Src);
          } else {
            file = initialFile.Src;
          }
          const data = await readFile(file);

          return {
            file,
            data,
          };
        })
      );
      const convertedLogoArray = [];
      logoFile.map((item) => {
        if (item.data) {
          convertedLogoArray.push({
            Src: item.data,
            Title: item.file.name,
            Alt: item.file.name,
          });
        } else {
          convertedLogoArray.push(item);
        }
        return item;
      });
      logoData.Src = convertedLogoArray[0].Src;
      logoData.Alt = convertedLogoArray[0].Alt;
      logoData.Title = convertedLogoArray[0].Title;
    }

    const formData = {
      hashID: user.getData().company.hashID,
      employeeCount: formValues.employeeCount,
      legalForm: formValues.legalForm,
      foundingYear: formValues.foundingYear,
      masterOperation: formValues.masterOperation,
      micrositeDescription: formValues.micrositeDescription,
      memberships: formValues.memberships,
      trainingCompany: formValues.trainingCompany,
      micrositeImages: convertedImagesArray,
      micrositeLogo: logoData,
      additionalMemberships: formValues.additionalMemberships,
      micrositeEmailApplicationText: formValues.micrositeEmailApplicationText,
      micrositeColorScheme: formValues.colorScheme,
    };

    const { error } = schema.validate(formData, { abortEarly: false });

    if (error) {
      console.log(error);
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      window.scrollTo(0, 0);
    } else {
      setFormValueErrors(initialFormValueErrorsState);
      const showLoadingOverlayTimeout = setTimeout(
        () => setLoadingOverlayOpen(true),
        LOADING_OVERLAY_TIMEOUT
      );

      executeCompanyUpdateMutation({
        hashId: user.getData().company.hashID,
        numberOfEmployees: formValues.employeeCount,
        legalForm: formValues.legalForm,
        foundingYear: formValues.foundingYear,
        masterBusiness: formValues.masterOperation,
        trainingCompany: formValues.trainingCompany,
        description: formValues.micrositeDescription,
        memberships: formValues.memberships,
        colorScheme: formValues.colorScheme,
        images: convertedImagesArray,
        logo: logoData,
        additionalMemberships: formValues.additionalMemberships,
        emailApplicationText: formValues.micrositeEmailApplicationText,
      }).then((result) => {
        clearTimeout(showLoadingOverlayTimeout);
        setLoadingOverlayOpen(false);

        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyInfo",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          const micrositeImages = [];
          result.data.updateCompany[0].MicrositeImages.edges.map((item) => {
            micrositeImages.push(item.node);
            return micrositeImages;
          });
          user.setData({
            company: {
              ...formData,
              ...{ micrositeImages: micrositeImages },
            },
          });
          setStep((prevActiveStep) => prevActiveStep + 1);
          setUser(user);
        }
      });
    }
  };

  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>
          {t("microsite.details.wizard.step.2.title")}
        </h1>
        <Card variant="outlined" className={classes.intro}>
          <Grid
            container
            className={classes.introContainer}
            alignItems="center"
          >
            <Grid item className={classes.introItem}>
              <InfoOutlinedIcon className={classes.icon} />
            </Grid>
            <Grid item className={classes.introItem}>
              {t("microsite.details.wizard.step.2.intro")}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <MicrositeDetailsWizardStepper step={step} />
      </Grid>
      <Grid item className={classes.item}>
        <CompanyInfoForm
          user={user}
          classes={classes}
          formValues={formValues}
          formValueErrors={formValueErrors}
          setFormValues={setFormValues}
          createFileFromUrl={createFileFromUrl}
          readFile={readFile}
          setCurrentFileObjects={setCurrentFileObjects}
          colorSchemes={colorSchemes}
          currentFileObjects={currentFileObjects}
        />
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id="buttonFormBack"
          color="secondary"
          className={classes.buttonBack}
          onClick={handleButtonBack}
          disabled={companyIsUpdating}
        >
          {t("form.label.back")}
        </Button>
        <Button
          id="buttonFormNext"
          variant="contained"
          color="primary"
          align="left"
          className={classes.buttonNext}
          onClick={handleButtonNext}
          disabled={companyIsUpdating}
        >
          {t("form.label.next")}
          {companyIsUpdating && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        {loadingOverlayOpen && (
          <LoadingOverlay text={t("general.label.be.ready.soon")} />
        )}
      </Grid>
    </>
  );
};

export default CompanyInfoStep;
