import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "urql";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TablePaginationActions from "../components/Table/TablePaginationActions";
import GlobalContext from "../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import withAccessControl from "../components/HOC/AccessControl";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../config";
import { Chip, Grid } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import clsx from "clsx";
import CompanyDirectoryNameDialog from "../components/Company/CompanyDirectoryNameDialog";
import CustomSCTableRow from "../components/CustomSC/CustomSCTableRow";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    [theme.breakpoints.up("lg")]: {
      overflowX: "hidden",
    },
  },
  tableColumnsHead: {
    height: 96,
  },
  tableCellColumnsHeader: {
    padding: "40px 14px 16px 14px",
    fontSize: theme.typography.fontSize,
    color: "#999999",
    fontWeight: "bold",
    "&:first-of-type": {
      paddingLeft: 24,
    },
    "@media (max-width:640px)": {
      "&:first-of-type": {
        paddingLeft: 14,
      },
    },
  },
  tableCellColumnsBody: {
    padding: "20px 14px",
  },
  tableCell: {
    height: 64,
  },
  tableRows: {
    paddingLeft: 36,
  },
  locationHead: {
    fontWeight: "bold",
  },
  icon: {
    padding: 8,
  },
  listContent: {
    paddingLeft: 36,
  },
  paginationInput: {
    height: 28,
    width: 25,
  },
  paginationSelectIcon: {
    color: theme.palette.primary.main,
  },
  searchFilterGroup: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "auto",
      maxWidth: 200,
    },
  },
  searchFilterItem: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    "@media (max-width:810px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  searchFilterButtonGroup: {
    display: "inline-block",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchFilterButton: {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 14,
    fontWeight: "normal",
    [theme.breakpoints.down("xs")]: {
      marginTop: 4,
      marginBottom: 4,
    },
    "&.MuiChip-colorPrimary": {
      color: "#fff",
    },
    "&.active": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
  },
  noBottomBorder: {
    borderBottom: 0,
    paddingBottom: 0,
  },
  export: {
    cursor: "pointer",
  },
  exportHolder: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  exportLink: {
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: 14,
    textTransform: "uppercase",
  },
}));

const queryProjects = `
  query ReadElasticProjects (
    $locationID: ID,
    $radius: Int,
    $lat: Float,
    $long: Float,
    $limit: Int,
    $offset: Int,
    $filterFor: [String]
  ) {
    readElasticProjects (
      sortBy: [{field: Date, direction: DESC}],
      Filter: {LocationID: $locationID, Radius: $radius, Lat: $lat, Long: $long},
      limit: $limit,
      offset: $offset,
      ConstructionTypeContains: $filterFor
    ){
      HashID
      Final_title
      Date
      Canton
      Content
      Calculated_distance
      Distance_calculated_from
      Read
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Phone
        Email
        Website
        Email_other
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
    }
  }
`;


/**
 * CustomSC
 *
 * @returns {*}
 * @constructor
 */
const CustomSC = () => {
  const classes = useStyles();
  const {
    // favoriteData,
    // setFavoriteData,
    token,
    setToken,
    user,
    setMessage,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [querySearchFilter, setQuerySearchFilter] = useState([]);
  const [queryVariables, setQueryVariables] = useState({
    userHashID: user.getData().hashID,
    filterFor: [],
  });
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(true);
  const [showNoResultMessage, setShowNoResultMessage] = useState(false);
  const columns = [
    { id: "actions", label: t("general.label.actions"), minWidth: 200 },
    { id: "projects", label: t("general.label.projects"), minWidth: 100 },
    {
      id: "city",
      label: t("general.label.address"),
      minWidth: 120,
      align: "left",
    },
    {
      id: "route",
      label: t("general.label.distance"),
      minWidth: 120,
      align: "left",
    },
    {
      id: "date",
      label: t("general.label.date"),
      minWidth: 120,
      align: "left",
    },
  ];

  // GraphQL query for building applications, gets triggered by calling "executeBuildingApplicationsQuery()"
  const [result, executeBuildingApplicationsQuery] = useQuery({
    query: queryProjects,
    variables: queryVariables,
    requestPolicy: "network-only",
    pause: true,
  });

  const prevQuerySearchFilter = useRef(querySearchFilter);

  /* eslint-disable */
  useEffect(() => {
    if (prevQuerySearchFilter.current !== querySearchFilter) {
      setQueryVariables({ ...queryVariables, filterFor: querySearchFilter });
      prevQuerySearchFilter.current = querySearchFilter;
    }
  }, [querySearchFilter]);

  /**
   * useEffect
   */
  useEffect(() => {
    executeBuildingApplicationsQuery();
  }, [queryVariables, user, token, setToken]);
  /* eslint-enable */

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else if (
          result.error.message.indexOf("Your query did not return any results")
        ) {
          if (!showNoResultMessage) {
            setShowNoResultMessage(true);
          }
          setData([]);
        }
      } else {
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readElasticProjects !== "undefined"
        ) {
          // Add the favorites to the global context
          setData(result.data.readElasticProjects);
          setShowNoResultMessage(false);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, queryVariables, user, token, setToken])

  /**
   * handleChangePage
   *
   * @param event MouseEvent
   * @param newPage number
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * handleChangeRowsPerPage
   *
   * @param event
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * toggleQuerySearchFilterValue
   *
   * @param string filterValue
   */
  const toggleQuerySearchFilterValue = (filterValue) => {
    let newQuerySearchFilter = [];
    const currentQuerySearchFilter = [];

    for (let index = 0; index < querySearchFilter.length; index++) {
      currentQuerySearchFilter.push(querySearchFilter[index]);
    }

    if (currentQuerySearchFilter.includes(filterValue)) {
      newQuerySearchFilter = currentQuerySearchFilter.filter(
        (element) => element !== filterValue
      );
    } else {
      currentQuerySearchFilter.push(filterValue);
      newQuerySearchFilter = currentQuerySearchFilter;
    }

    setQuerySearchFilter(newQuerySearchFilter);
  };

  /**
   * return
   */
  return (
    <>
      <CompanyDirectoryNameDialog
        formValues={{}}
        setFormValues={() => {}}
        verifyDialogOpen={verifyDialogOpen}
      />
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow
                className={clsx(classes.tableHead, classes.tableHeadSearchBody)}
              >
                <TableCell
                  align="right"
                  className={clsx(
                    classes.tableCellHeader,
                    classes.tableCellHeaderSearchBody,
                    classes.noBottomBorder
                  )}
                  colSpan={5}
                >
                  <Grid container className={classes.searchFilterGroup}>
                    <Grid item className={classes.searchFilterItem}>
                      <Grid item className={classes.searchFilterButtonGroup}>
                        Filter:
                      </Grid>
                      <Chip
                        color={
                          querySearchFilter.includes("new")
                            ? "primary"
                            : "default"
                        }
                        label={t("projects.search.new")}
                        component="a"
                        clickable
                        icon={
                          querySearchFilter.includes("new") ? (
                            <DoneIcon />
                          ) : null
                        }
                        className={classes.searchFilterButton}
                        onClick={(event) => {
                          toggleQuerySearchFilterValue("new");
                        }}
                      />
                      <Chip
                        color={
                          querySearchFilter.includes("modification")
                            ? "primary"
                            : "default"
                        }
                        label={t("projects.search.modification")}
                        component="a"
                        clickable
                        icon={
                          querySearchFilter.includes("modification") ? (
                            <DoneIcon />
                          ) : null
                        }
                        className={classes.searchFilterButton}
                        onClick={(event) => {
                          toggleQuerySearchFilterValue("modification");
                        }}
                      />
                      <Chip
                        color={
                          querySearchFilter.includes("demolition")
                            ? "primary"
                            : "default"
                        }
                        label={t("projects.search.demolition")}
                        component="a"
                        clickable
                        icon={
                          querySearchFilter.includes("demolition") ? (
                            <DoneIcon />
                          ) : null
                        }
                        className={classes.searchFilterButton}
                        onClick={(event) => {
                          toggleQuerySearchFilterValue("demolition");
                        }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableColumnsHead}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ maxWidth: column.maxWidth }}
                    className={classes.tableCellColumnsHeader}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!result.fetching && showNoResultMessage && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{
                      paddingTop: 32,
                      paddingBottom: 32,
                      fontSize: 16,
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                  >
                    {t("general.label.no.data")}
                  </TableCell>
                </TableRow>
              )}
              {result.fetching && (
                <React.Fragment key={0}>
                  <TableRow>
                    <TableCell
                      colSpan="5"
                      component="th"
                      scope="row"
                      className={classes.tableRows}
                    >
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )}
              {!result.fetching &&
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <CustomSCTableRow
                      filterFor={queryVariables.filterFor}
                      key={row.HashID}
                      data={row}
                      classes={classes}
                      location={user.getData().company.canton}
                      originCoordinates={{
                        lat: user.getData().company.latitude,
                        lon: user.getData().company.longitude,
                      }}
                      setVerifyDialogOpen={setVerifyDialogOpen}
                    />
                  ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: false,
                    renderValue: () => <></>,
                  }}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from} - ${to === -1 ? count : to}`;
                  }}
                  labelRowsPerPage={
                    <>
                      {rowsPerPage} {t("general.label.rows")}
                    </>
                  }
                  classes={{
                    input: classes.paginationInput,
                    selectIcon: classes.paginationSelectIcon,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </>
  );
};

// TODO: access control
export default withAccessControl(CustomSC, ACL_COMPONENTS.FAVORITES);
