import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider as URQLProvider } from 'urql'
import URQLClientSilverstripe from './lib/URQLClientSilverstripe'
import './eelConfig'

ReactDOM.render(
  <Suspense fallback={null}>
    <URQLProvider value={URQLClientSilverstripe}>
      <App />
    </URQLProvider>
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
