import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GlobalContext from "../../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import { ACL_COMPONENTS, CANTON_LIST } from "../../config";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogTitle } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useMutation } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import withAccessControl from "../HOC/AccessControl";
import GooglePlacesAPIHelper from "../../lib/GooglePlacesAPIHelper";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

const useStyles = makeStyles((theme) => ({
  changeLocation: {
    color: theme.palette.primary.main,
    width: 200,
    height: "100%",
    fontWeight: "bold",
  },
  dialogTitleCloseIcon: {
    marginLeft: "auto",
    padding: 0,
    paddingRight: 23,
    paddingTop: 10,
  },
  root: {
    width: "100%",
    maxWidth: 444,
  },
  rootNotice: {
    position: "relative",
    marginTop: 20,
    marginBottom: 20,
    padding: "35px 25px",
    background: theme.palette.info.main,
    color: theme.palette.text.primary,
    "&::before": {
      position: "absolute",
      top: "-7px",
      left: "50%",
      width: 14,
      height: 14,
      background: theme.palette.info.main,
      transform: "translate(-50%, 0) rotate(45deg)",
      content: '""',
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  addButtonText: {
    paddingTop: 3,
    paddingRight: 15,
    paddingBottom: 3,
    paddingLeft: 15,
    fontWeight: "bold",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 24,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
  },
  listItem: {
    padding: 0,
  },
  dialogContent: {
    minWidth: "100%",
    padding: "0 20px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32,
    },
  },
  dialogActions: {
    justifyContent: "flex-start",
    padding: "0 20px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32,
    },
  },
  location: {
    paddingRight: 50,
    color: "#525252",
  },
  textField: {
    width: "100%",
    marginRight: 0,
    marginLeft: 0,
  },
  menu: {
    width: 200,
  },
}));

/**
 * updateCompanyMutation
 *
 * @type {string}
 */
const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $canton: String,
    $latitude: Float,
    $longitude: Float
  ) {
    updateCompany(
      HashID: $hashId, 
      Canton: $canton,
      Latitude: $latitude,
      Longitude: $longitude
    ) {
      Name
      Street
      Zip
      City
      Canton
      Phone
      Fax
      Website
      Email
      BusinessActivityID
      BusinessSpecialities{
        edges{
          node{
            ID
            Title
          }
        }
      }
    }
  }
`;

/**
 * ProjectsFreeLocationsHandler
 *
 * @returns {*}
 * @constructor
 */
const ProjectsFreeLocationsHandler = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    setUser,
    canton,
    setCanton,
    token,
    setMessage,
    setProjectData,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [{ fetching }, executeMutation] = useMutation(updateCompanyMutation);

  /**
   * handleClickManageLocations
   */
  const handleClickManageLocations = () => {
    setOpenDialog(true);
  };

  /**
   * handleCloseManageLocations
   */
  const handleCloseManageLocations = () => {
    setOpenDialog(false);
  };

  /**
   * handleChangeCanton
   */
  const handleChangeCanton = (event) => {
    GooglePlacesAPIHelper.getCoordsByAddress(
      CANTON_LIST[event.target.value]
    ).then((result) => {
      const lat = result.results[0].geometry.location.lat;
      const lon = result.results[0].geometry.location.lng;

      executeMutation({
        hashId: user.getData().company.hashID,
        canton: CANTON_LIST[event.target.value],
        latitude: lat,
        longitude: lon,
      }).then((result) => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            ExternalErrorLogger.log({
              text: "Error change canton on ProjectsFreeLocationsHandler",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          if (result.error) {
            // Check if the user need to be logged out
            if (result.error.message.indexOf("User forced logout") !== -1) {
              setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
              unsetUser();
              unsetToken();
              navigate("/");
            } else {
              setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
              ExternalErrorLogger.log({
                text: "Error change canton on ProjectsFreeLocationsHandler",
                data: {
                  token: JSON.stringify(token.getData()),
                  user: JSON.stringify(user.getData()),
                  errorMessage: result.error.message,
                },
              });
            }
          } else {
            setCanton(event.target.value);
            user.setData({
              company: {
                canton: CANTON_LIST[event.target.value],
                latitude: lat,
                longitude: lon,
              },
            });
            setUser(user);
            if (!fetching) {
              setMessage(
                MESSAGE_TYPES.SUCCESS,
                t("general.label.save.success")
              );
              handleCloseManageLocations();
              setProjectData([]);
            }
          }
        }
      });
    });
  };

  /**
   * menuItems
   *
   * @returns {[]}
   */
  const menuItems = () => {
    const menuItemArray = [];
    Object.keys(CANTON_LIST).forEach((index) => {
      menuItemArray.push(
        <MenuItem key={index} value={index}>
          {CANTON_LIST[index]}
        </MenuItem>
      );
    });
    return menuItemArray;
  };

  /**
   * LocationDataEditDialog
   */
  const locationDataEditDialog = () => (
    <>
      <DialogTitle
        id="manage-locations-title"
        className={classes.dialogTitleCloseIcon}
      >
        <IconButton onClick={handleCloseManageLocations}>
          <CloseIcon id="closeDialog" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          id="canton-select"
          select
          label={t("general.label.canton")}
          className={classes.textField}
          value={canton}
          onChange={(event) => {
            handleChangeCanton(event);
          }}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
          variant="outlined"
        >
          {menuItems()}
        </TextField>
        <Paper className={classes.rootNotice}>
          <Typography component="p">
            {t("projects.free.benefit.premium.canton.change")}
          </Typography>
        </Paper>
      </DialogContent>
    </>
  );

  /**
   * return
   */
  return (
    <>
      <Button
        id="buttonChangeLocation"
        className={classes.changeLocation}
        onClick={handleClickManageLocations}
      >
        {t("location.label.change.canton")}
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseManageLocations}
        aria-describedby="manage-locations-description"
        maxWidth="xs"
      >
        {locationDataEditDialog()}
      </Dialog>
    </>
  );
};

export default withAccessControl(
  ProjectsFreeLocationsHandler,
  ACL_COMPONENTS.PROJECTS_FREE_LOCATION_HANDLER
);
