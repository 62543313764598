import Joi from '@hapi/joi'
/**
 * ProjectManagementAddressDataValidator
 *
 * @returns {{isValid: (function(*): boolean)}}
 * @constructor
 */
const ProjectManagementAddressDataValidator = () => {
  /**
   * Joi validation schema tokenData
   */
  const schemaAddressData = Joi.object({
    Street: Joi.string().allow(''),
    Number: Joi.string().allow(''),
    Zip: Joi.string().required(),
    City: Joi.string().allow(''),
    Geo: Joi.object(),
    __typename: Joi.string().allow('')
  })

  /**
   * isValid
   *
   * @param addressData
   * @returns {boolean}
   */
  const isValid = addressData => {
    // Check if address data is well-formed
    let { error } = schemaAddressData.validate(addressData, {
      abortEarly: false
    })

    if (addressData.Zip === '') {
      error = true
    }

    // Check how many properties are empty
    let emptyPropertiesCount = 0
    for (const property in addressData) {
      if (addressData[property] === '') {
        emptyPropertiesCount++
      }
    }

    // when only the zip is given, we do not want to get an output
    if (emptyPropertiesCount === 3) {
      error = true
    }

    return !error
  }

  /**
   * return
   */
  return {
    isValid
  }
}

export default ProjectManagementAddressDataValidator()
