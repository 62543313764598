import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  AppBar,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import clsx from "clsx";
import Applications from "./Applications";
import Submissions from "./Submissions";
import DownloadApplications from "./DownloadedApplications";
import SendApplications from "./SendApplications";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  tabs: {
    color: "#525252",
    fontSize: 14,
    height: "100%",
    width: "100%",
  },
  tab: {
    height: 64,
    width: "100%",
    maxWidth: 188,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableTabsHeader: {
    background: "#F7F9FA",
    boxShadow: "none",
  },
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    margin: 0,
    padding: "16px 24px",
  },
  gridItem: {
    padding: "8px 0",
    width: "100%",
  },
  gridItemStat: {
    padding: "8px 0",
    width: "100%",
    cursor: "grab",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    [theme.breakpoints.up("lg")]: {
      overflowX: "hidden",
    },
  },
  tableColumnsHead: {
    height: 96,
  },
  tableCell: {
    height: 64,
  },
  tableCellHeader: {
    position: "relative",
    height: 64,
    fontWeight: "bold",
    zIndex: 1,
  },
  statisticActiveButton: {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 14,
    fontWeight: "normal",
    [theme.breakpoints.down("xs")]: {
      marginTop: 4,
      marginBottom: 4,
    },
    "&.MuiChip-colorPrimary": {
      color: "#fff",
    },
    "&.active": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
  },
  noBottomBorder: {
    borderBottom: 0,
    paddingBottom: 0,
  },
}));

/**
 * Statistics
 *
 * @returns {*}
 * @constructor
 */
const Statistics = () => {
  const classes = useStyles();
  const { t /* , i18n */ } = useTranslation();
  const { user } = useContext(GlobalContext);

  const [activeStatisticGroup, setActiveStatisticGroup] = useState(1);

  return user.hasZipSearchStatistic() && user.getData().company.adminUserID === user.getData().id ? (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell
                align="left"
                padding="none"
                className={classes.tableCellHeader}
                colSpan={5}
              >
                <AppBar
                  position="static"
                  color="default"
                  className={classes.tableTabsHeader}
                >
                  <Tabs
                    value={activeStatisticGroup}
                    onChange={(event, tabValue) =>
                      setActiveStatisticGroup(tabValue)
                    }
                    className={classes.tabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      id={1}
                      key={1}
                      label={t(
                        "statistics.selector.applicationsandsubmissions"
                      )}
                      value={1}
                      className={classes.tab}
                    />
                    <Tab
                      id={2}
                      key={2}
                      label={t("statistics.selector.apply.download")}
                      value={2}
                      className={classes.tab}
                    />
                    <Tab
                      id={3}
                      key={3}
                      label={t("statistics.selector.apply.email")}
                      value={3}
                      className={classes.tab}
                    />
                  </Tabs>
                </AppBar>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableColumnsHead}>
              <TableCell
                align="right"
                className={clsx(
                  classes.tableCellHeader,
                  classes.tableCellHeaderSearchBody,
                  classes.noBottomBorder
                )}
              >
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  justify="center"
                  alignItems="center"
                  margin="normal"
                  padding="normal"
                  className={classes.gridContainer}
                >
                  <Grid item className={classes.gridItemStat}>
                    {activeStatisticGroup === 1 && (
                      <Applications />
                    )}
                    {activeStatisticGroup === 1 && (
                      <Submissions />
                    )}
                    {activeStatisticGroup === 2 && (
                      <DownloadApplications />
                    )}
                    {activeStatisticGroup === 3 && (
                      <SendApplications />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  ) : null;
};

export default Statistics;
