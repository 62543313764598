import React, { useEffect, useContext } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Divider from '@material-ui/core/Divider'
import { useTranslation } from 'react-i18next'
import AccountProfile from '../components/Account/AccountProfile'
import AccountSubscriptions from '../components/Account/AccountSubscriptions'
import AccountInvoices from '../components/Account/AccountInvoices'
import { navigate, getWorkingPath } from 'hookrouter'
import withAccessControl from '../components/HOC/AccessControl'
import UserTypes from '../components/Onboarding/UserTypes'
import Tooltip from '@material-ui/core/Tooltip'
import GlobalContext from '../lib/GlobalContext'
import { ACL_COMPONENTS } from '../config'
import AccountUsers from '../components/Account/AccountUsers'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tabs: {
    fontSize: 14,
    height: '100%',
    width: '100%'
  },
  tab: {
    height: 64,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#525252'
  }
}))

/**
 * Account
 *
 * @returns {*}
 * @constructor
 */
const Account = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    user,
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen,
    accountTabValue,
    setAccountTabValue
  } = useContext(GlobalContext)

  /**
   * useEffect
   */
  useEffect(() => {
    switch (getWorkingPath()) {
      case '/account/invoices':
        setAccountTabValue(3)
        break
      case '/account/subscriptions':
        setAccountTabValue(2)
        break
      case '/account/users':
        setAccountTabValue(1)
        break
      case '/account/profile':
        setAccountTabValue(0)
        break
      default:
        navigate('/account/profile')
        setAccountTabValue(0)
        break
    }
  })

  /**
   * handleTabChange
   *
   * @param event
   * @param newValue
   */
  const handleTabChange = (event, newValue) => {
    setAccountTabValue(newValue)
    switch (newValue) {
      case 3:
        if (user.getData().mode === UserTypes.FREE) {
          setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
        } else {
          navigate('/account/invoices')
        }
        break
      case 2:
        navigate('/account/subscriptions')
        break
      case 1:
        if (user.getData().mode !== UserTypes.PRO) {
          setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
        } else {
          navigate('/account/users')
        }
        break
      case 0:
      default:
        navigate('/account/profile')
        break
    }
  }

  /**
   * getContentRelatedToActiveTab
   *
   * @returns {*}
   */
  const getContentRelatedToActiveTab = () => {
    switch (accountTabValue) {
      case 3:
        return <AccountInvoices />
      case 2:
        return <AccountSubscriptions />
      case 1:
        return <AccountUsers />
      case 0:
      default:
        return <AccountProfile />
    }
  }

  /**
   * return
   */
  return (
    <Paper className={classes.root}>
      <Tabs
        value={accountTabValue}
        className={classes.tabs}
        indicatorColor='primary'
        onChange={handleTabChange}
      >
        <Tab
          id='accountProfileTab'
          label={t('account.label.profile')}
          value={0}
          className={classes.tab}
        />
        {user.getData().mode !== UserTypes.PRO && (
          <Tooltip
            title={t('projects.free.route.premium.pro')}
            aria-label={t('projects.free.route.premium.pro')}
          >
            <Tab
              id='accountUsersTab'
              label={t('company.label.users')}
              value={1}
              className={classes.tab}
            />
          </Tooltip>
        )}
        {user.getData().mode === UserTypes.PRO && (
          <Tab
            id='companyUsersTab'
            label={t('company.label.users')}
            value={1}
            className={classes.tab}
          />
        )}
        <Tab
          id='accountSubscriptionsTab'
          label={t('account.label.subscriptions')}
          value={2}
          className={classes.tab}
        />
        {user.getData().mode === UserTypes.FREE && (
          <Tooltip
            title={t('projects.free.route.premium')}
            aria-label={t('projects.free.route.premium')}
          >
            <Tab
              id='accountInvoicesTab'
              label={t('account.label.invoices')}
              value={3}
              className={classes.tab}
            />
          </Tooltip>
        )}
        {user.getData().mode !== UserTypes.FREE && (
          <Tab
            id='accountInvoicesTab'
            label={t('account.label.invoices')}
            value={3}
            className={classes.tab}
          />
        )}
      </Tabs>
      <Divider />
      {getContentRelatedToActiveTab()}
    </Paper>
  )
}

export default withAccessControl(Account, ACL_COMPONENTS.ACCOUNT)
