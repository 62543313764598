import React, { useContext, useEffect, useState } from "react";
import { injectStripe } from "react-stripe-elements";
import { useQuery } from "urql";
import GlobalContext from "../../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  buttonNext: {
    width: "100%",
    color: "#fff",
    marginRight: "auto",
    marginBottom: 20,
    fontWeight: "bold",
  },
  cardHolder: {
    width: "100%",
    margin: "20px 0",
    background: "#fff",
    borderRadius: "4px",
    border: "1px solid #D3D3D3",
  },
  cardElement: {
    display: "inline-block",
    width: "auto",
    marginLeft: 0,
    padding: "0",
    color: "#a6a6a6",
    fontSize: 12,
    textAlign: "left",
  },
  cardItem: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  stripeElement: {
    display: "block",
    margin: 0,
    maxWidth: "100%",
    padding: "10px 0",
    boxShadow: 0,
    border: 0,
    outline: 0,
    borderRadius: 4,
    background: "#fff",
  },
  InputElement: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  infoText: {
    paddingBottom: 10,
  },
}));

const queryStripeSetupIntent = `
  {
    readStripeSetupIntent {
      client_secret
    }
  }
`;

/**
 * InvoiceForm
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const InvoiceForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setPaymentMethodId, user } =
    useContext(GlobalContext);

  const [stripeSubmitClicked, setStripeSubmitClicked] = useState(false);

  const [result, executeQueryStripeSetupIntent] = useQuery({
    query: queryStripeSetupIntent,
    pause: true,
  });

  /**
   * useEffect
   */
  useEffect(() => {
    executeQueryStripeSetupIntent();
  }, [executeQueryStripeSetupIntent, user]);

  /**
   * handleSubmit
   */
  const handleSubmit = () => {
    setStripeSubmitClicked(true);
    setPaymentMethodId("invoice");
  };

  /**
   * return
   */
  return (
    <>
      <div
        className={classes.infoText}
        dangerouslySetInnerHTML={{
          __html: t("general.label.invoice.info.text")
            .split("\n")
            .join("<br />"),
        }}
      />
      <Button
        id="buttonFormNext"
        variant="contained"
        color="primary"
        align="left"
        className={classes.buttonNext}
        onClick={handleSubmit}
        disabled={
          stripeSubmitClicked || props.submitIsDisabled || result.fetching
        }
      >
        {t("general.label.premium.buy")}
      </Button>
    </>
  );
};

export default injectStripe(InvoiceForm);
