import React, { useCallback, useContext, useEffect, useState } from "react";
import withAccessControl from "../HOC/AccessControl";
import { ACL_COMPONENTS } from "../../config";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "urql";
import GlobalContext from "../../lib/GlobalContext";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import Button from "@material-ui/core/Button";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import CompanyBusinessActivitiesForm from "./CompanyBusinessActivitiesForm";
import {
  queryBranches,
  updateCompanyMutation,
  initialFormValuesState,
  initialFormValueErrorsState,
  schema,
} from "./CompanyBasicSettingsShared";
import UpdateCompanyDataDialog from "../UpdateCompanyDataDialog";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    color: "#ffffff",
    width: "100%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  dialogButton: {
    color: "#ffffff",
    width: "50%",
    marginTop: "20px",
    marginBottom: "40px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  branchSubCategories: {
    width: "50%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  input: {
    marginLeft: 60,
  },
  checkboxControlIndented: {
    marginLeft: 20,
  },
  dialog: {
    width: "100%",
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: 0,
  },
  dialogTitleCloseIcon: {
    marginLeft: "auto",
    padding: 0,
    paddingRight: 23,
    paddingTop: 10,
  },
}));

/**
 * CompanyBusinessActivities
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyBusinessActivities = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, token, setUser, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);
  const [branchData, setBranchData] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);

  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);

  // GraphQL query for branches, gets triggered by calling "executeBranchesQuery()"
  const [result, executeBranchesQuery] = useQuery({
    query: queryBranches,
    variables: {},
    pause: true,
  });

  /**
   * executeBranchesQueryCallback
   * Implementation of https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   */
  const executeBranchesQueryCallback = useCallback(() => {
    if (!result.fetching && !branchData) {
      executeBranchesQuery();
    }
  }, [result.fetching, branchData, executeBranchesQuery]);

  /**
   * useEffect
   */
  useEffect(() => {
    const userData = user.getData();
    setFormValues({
      id: userData.company.hashID,
      branchCategory:
        userData.company.branchCategory ||
        initialFormValuesState.branchCategory,
      branchSubs:
        userData.company.branchSubs || initialFormValuesState.branchSubs,
      otherBusinessActivities: userData.company.otherBusinessActivities,
      otherBusinessSpecialities: userData.company.otherBusinessSpecialities,
    });
    executeBranchesQueryCallback();
  }, [user, executeBranchesQueryCallback]);

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching branches on CompanyBasicSettings",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.branches"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readBusinessActivities !== "undefined" &&
          !branchData
        ) {
          // Without business branches/activities we cannot continue
          if (result.data.readBusinessActivities.length === 0) {
            throw Error("No business branches/activities found");
          }

          // Add the projects to the global context
          setBranchData(result.data.readBusinessActivities);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = () => {
    const formData = {
      hashID: user.getData().company.hashID,
      branchCategory: formValues.branchCategory,
      branchSubs: formValues.branchSubs,
      otherBusinessActivities: formValues.otherBusinessActivities,
      otherBusinessSpecialities: formValues.otherBusinessSpecialities,
    };
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      window.scrollTo(0, 0);
    } else {
      user.setData({ company: { ...formData } });
      setFormValueErrors(initialFormValueErrorsState);

      let branchSubsRefined = [];
      formValues.branchSubs.map((item) => {
        branchSubsRefined.push({
          ID: item,
        });
        return item;
      });
      if (!branchSubsRefined.length) {
        branchSubsRefined = null;
      }

      let otherBranchesRefined = [];
      formValues.otherBusinessActivities.map((item) => {
        otherBranchesRefined.push({
          ID: item,
        });
        return item;
      });
      if (!otherBranchesRefined.length) {
        otherBranchesRefined = null;
      }

      let otherBusinessSpecialitiesRefined = [];
      formValues.otherBusinessSpecialities.map((item) => {
        otherBusinessSpecialitiesRefined.push({
          ID: item,
        });
        return item;
      });
      if (!otherBusinessSpecialitiesRefined.length) {
        otherBusinessSpecialitiesRefined = null;
      }

      setLoadingOverlayOpen(true);
      handleUpdateCompanyMutation(
        branchSubsRefined,
        otherBranchesRefined,
        otherBusinessSpecialitiesRefined
      ).then();
    }
  };

  /**
   * handleUpdateCompanyMutation
   *
   * @param branchSubsRefined
   * @param otherBranchesRefined
   * @param otherBusinessSpecialitiesRefined
   * @returns {Promise<void>}
   */
  const handleUpdateCompanyMutation = async (
    branchSubsRefined,
    otherBranchesRefined,
    otherBusinessSpecialitiesRefined
  ) => {
    await executeCompanyUpdateMutation({
      hashId: user.getData().company.hashID,
      businessActivityID: formValues.branchCategory,
      businessSpecialties: branchSubsRefined,
      otherBusinessActivities: otherBranchesRefined,
      otherBusinessSpecialities: otherBusinessSpecialitiesRefined,
    }).then((result) => {
      if (result.error) {
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else if (result.error.message.indexOf("Failed to fetch") === -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error submitting data on CompanyBusinessActivities",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        } else {
          ExternalErrorLogger.log({
            text: "Error on CompanyBusinessActivities - Failed to fetch",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(
          MESSAGE_TYPES.SUCCESS,
          t("company.label.businessActivities.saved")
        );
        setUser(user);
      }
    });
  };

  /**
   * return
   */
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      margin="normal"
      padding="normal"
      className={classes.gridContainer}
    >
      <CompanyBusinessActivitiesForm
        user={user}
        token={token}
        classes={classes}
        formValues={formValues}
        setFormValues={setFormValues}
        formValueErrors={formValueErrors}
        setFormValueErrors={setFormValueErrors}
        initialFormValueErrorsState={initialFormValueErrorsState}
        branchData={branchData}
        setBranchData={setBranchData}
        result={result}
      />
      <Grid item className={classes.gridItem}>
        <Button
          id="buttonFormSubmit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleFormSubmit}
          disabled={companyIsUpdating}
        >
          {!companyIsUpdating && t("account.profile.save.changes")}
          {companyIsUpdating && t("general.label.processing.data")}
        </Button>
        {loadingOverlayOpen &&
          UpdateCompanyDataDialog({
            setLoadingOverlayOpen,
            loadingOverlayOpen,
            t,
          })}
      </Grid>
    </Grid>
  );
};

export default withAccessControl(
  CompanyBusinessActivities,
  ACL_COMPONENTS.COMPANY_BUSINESS_ACTIVITIES
);
