import { authExchange } from "@urql/exchange-auth";
import Token from "../Token";

const exchange = authExchange(async (utils) => {
  // Initially load the token-data from storage
  Token.loadFromLocalStorage();

  return {
    // Add the access-token to all requests
    addAuthToOperation(operation) {
      if (!Token.isSet()) {
        return operation;
      }

      return utils.appendHeaders(operation, {
        Authorization: `${Token.getData().token_type} ${
          Token.getData().access_token
        }`,
      });
    },
    // Check if a access-error happend, this will trigger a refresh-token call
    didAuthError(error, _operation) {
      return error.graphQLErrors.some(
        (e) =>
          e.message.includes(
            "The resource owner or authorization server denied the request"
          ) ||
          e.message.includes("Authorization failed") ||
          e.message.includes("Error Token validity")
      );
    },
    // Refresh the token
    async refreshAuth() {
      Token.loadFromLocalStorage();

      if (Token.getData().refresh_token.length) {
        const refreshResult = await Token.api.refresh(
          Token.getData().refresh_token
        );

        if (refreshResult) {
          Token.setData(refreshResult);
        } else {
          Token.unsetData();
        }
      }
    },
    // Predict if a token-refresh is required
    willAuthError: (operation) => {
      // In case the refrersh-token lifetime is expired, force a refresh by returning "true"
      const currTime = Math.round(new Date().getTime() / 1000);
      const tokenEndOfLife = Token.getData().expired_timestamp;
      return currTime > tokenEndOfLife;
    },
  };
});

export default exchange;
