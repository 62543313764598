import packageJson from "../package.json";

// Client Version
export const VERSION = packageJson.version;

// Default Logo path
export const DEFAULT_LOGO_PATH = "/assets/logoBau-white.svg";

// Google api key
// export const GOOGLE_API_KEY = 'AIzaSyA50Ne2ovR7pu0vdTTT6o9neTWYtJRoNY0'
// smartconext API-Key
export const GOOGLE_API_KEY = "AIzaSyA3yyQAIoWs_9qWiN39i2CunJYReMBKCEo";

// placeholder email for user object consistency
export const EMAIL_PLACEHOLDER = "place@holder345t3drfge5tt3.de";

// Fallback coordinates in the middle of switzerland
export const FallBackCoordinates = {
  lat: 46.818188,
  lon: 8.227512,
};

export const MASQ_COOKIE_NAME = 'app-admin-mask';

// Fallback link for referral program
export const FallBackReferralProgramLink =
  "https://www.smartconext-bau.ch/de/empfehlungsprogramm/";

// Canton list // TODO: get from config API
export const CANTON_LIST = {
  AG: "Aargau",
  AR: "Appenzell Ausserrhoden",
  BL: "Basel-Landschaft",
  BS: "Basel-Stadt",
  BE: "Bern",
  FR: "Freiburg",
  GL: "Glarus",
  GR: "Graubünden",
  LU: "Luzern",
  NW: "Nidwalden",
  OW: "Obwalden",
  SH: "Schaffhausen",
  SZ: "Schwyz",
  SO: "Solothurn",
  SG: "St. Gallen",
  TG: "Thurgau",
  UR: "Uri",
  VS: "Wallis",
  ZG: "Zug",
  ZH: "Zürich",
};

export const DEFAULT_LANGUAGE = "de";

export const DEFAULT_LOCALE_FOR_LANGUAGE = {
  en: "en_GB",
  de: "de_DE",
  fr: "fr_FR",
};

export const DOSSIER_AVAILABLE_LOCALES = {
  de_DE: "de",
  fr_FR: "fr",
};

export const CHARTJS_STATISTIC_OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "[[:TITLE_TEXT_PLACEHOLDER:]]",
    },
    colors: {
      enabled: false,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
      },
      zoom: {
        wheel: {
          enabled: true,
          modifierKey: "ctrl",
        },
        pinch: {
          enabled: false,
        },
        drag: {
          enabled: false,
        },
        mode: "x",
      },
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 1,
      },
    },
    x: {
      position: "right",
      min: 10,
      max: 13,
    },
  },
};

export const COOKIE_LIFETIME_DAYS = 30;
export const companyDirectoryPath = "/de/experten/";

// Define API endpoints and cookie-name based on hostname
const hostname = window.location.hostname;
let devTld = false;
let stageTld = false;
export let apiTld;
export let cookieDomain;
export let cookieDomainRedirect;
export let STRIPE_PLAN_IDS = {
  SMART: {
    YEARLY: "yearly",
    MONTHLY: "monthly",
  },
  PRO: {
    YEARLY: "yearly",
    MONTHLY: "monthly",
  },
};

switch (hostname) {
  // dev
  case "localhost":
    apiTld = "http://smartconext.docker:8086";
    cookieDomain = ".localhost";
    cookieDomainRedirect = ".smartconext.docker";
    devTld = true;
    STRIPE_PLAN_IDS.SMART.YEARLY = "price_1MZVVyAKTM163i3uVYTl38hT";
    STRIPE_PLAN_IDS.PRO.YEARLY = "price_1MZ9maAKTM163i3uX8wm04gg";
    break;
  case "app.smartconext.docker":
    apiTld = "http://smartconext.docker:8086";
    cookieDomain = ".app.smartconext.docker";
    cookieDomainRedirect = ".smartconext.docker";
    devTld = true;
    STRIPE_PLAN_IDS.SMART.YEARLY = "price_1MZVVyAKTM163i3uVYTl38hT";
    STRIPE_PLAN_IDS.PRO.YEARLY = "price_1MZ9maAKTM163i3uX8wm04gg";
    break;
  // stage
  case "app.smartconext.ia.ennit.de":
    apiTld = "https://smartconext.ia.ennit.de";
    cookieDomain = ".app.smartconext.ia.ennit.de";
    cookieDomainRedirect = ".smartconext.ia.ennit.de";
    stageTld = true;
    STRIPE_PLAN_IDS.SMART.YEARLY = "price_1MZVVyAKTM163i3uVYTl38hT";
    STRIPE_PLAN_IDS.PRO.YEARLY = "price_1MZ9maAKTM163i3uX8wm04gg";
    break;
  // live
  case "app.smartconext-bau.ch":
  default:
    apiTld = "https://www.smartconext-bau.ch";
    cookieDomain = ".app.smartconext-bau.ch";
    cookieDomainRedirect = ".www.smartconext-bau.ch";
    STRIPE_PLAN_IDS.SMART.YEARLY = "price_1MYTYmH3mxWGlAeLHXApVu1S";
    STRIPE_PLAN_IDS.PRO.YEARLY = "price_1MYTanH3mxWGlAeLh3Ncj4QU";
}

const graphQlEndpoint = `${apiTld}/graphql`;
const oAuthApiEndpoint = `${apiTld}/oauth2`;

export const isDev = devTld;
export const isStage = stageTld;
export const isNotLive = devTld || stageTld;
export const cmsUrl = apiTld;
export const googlePlacesApiEndpoint = `${apiTld}/places`;

// Silverstripe GraphQL API
export const SILVERSTRIPE_GRAPHQL_API = {
  url: graphQlEndpoint,
};

// Set a forces feature-set (can be 'free', 'smart', 'pro'), use null for ignoring
// ATTENTION: Only works if isDev is set to true
export const FORCE_MODE = null;

// Silverstripe OAuth API
const smartConextClientId = "smartconext0939024792";
export const SILVERSTRIPE_OAUTH_API = {
  urls: {
    authorize: oAuthApiEndpoint + "/token/",
    refresh: oAuthApiEndpoint + "/refresh/",
  },
  data: {
    authorize: {
      grant_type: "password",
      client_id: smartConextClientId,
    },
    refresh: {
      grant_type: "refresh_token",
      client_id: smartConextClientId,
    },
  },
};

// Acl control
export const ACL_ACTIVE = true;

// Acl components
export const ACL_COMPONENTS = {
  ACCOUNT: "Account",
  ACCOUNT_INVOICES: "AccountInvoices",
  ACCOUNT_PROFILE: "AccountProfile",
  ACCOUNT_SUBSCRIPTIONS: "AccountSubscriptions",
  ACCOUNT_USERS: "AccountUsers",
  COMPANY: "Company",
  COMPANY_BASIC_SETTINGS: "CompanyBasicSettings",
  COMPANY_INFO: "CompanyInfo",
  COMPANY_BUSINESS_ACTIVITIES: "CompanyBusinessActivities",
  COMPANY_USERS: "CompanyUsers",
  COMPANY_REFERENCES: "CompanyReferences",
  CREDIT_CARD: "CreditCard",
  FAVORITES: "Favorites",
  CUSTOM_SC: "CustomSC",
  FAVORITES_TABLE_ROW: "FavoritesTableRow",
  FAVORITE_DETAIL_ADDITION: "FavoriteDetailAddition",
  FAVORITE_STATUS: "FavoriteStatus",
  FREE_TO_PREMIUM_DIALOGUE_STEP_0: "FreeToPremiumDialogueStep0",
  FREE_TO_PREMIUM_DIALOGUE_STEP_1: "FreeToPremiumDialogueStep1",
  FREE_TO_PREMIUM_DIALOGUE_STEP_2: "FreeToPremiumDialogueStep2",
  FREE_TO_PREMIUM_DIALOGUE_STEP_3: "FreeToPremiumDialogueStep3",
  FREE_TO_PREMIUM_DIALOGUE_STEP_4: "FreeToPremiumDialogueStep4",
  FREE_TO_PREMIUM_DIALOGUE_STEP_5: "FreeToPremiumDialogueStep5",
  FREE_TO_PREMIUM_STEPPER: "FreeToPremiumStepper",
  GOOGLE_PLACES_AUTOSUGGEST_FIELD: "GooglePlacesAutosuggestField",
  LAYOUT: "Layout",
  LAYOUT_FOOTER: "LayoutFooter",
  LAYOUT_HEADER: "LayoutHeader",
  LAYOUT_HEADER_ACCOUNT: "LayoutHeaderAccount",
  LAYOUT_HEADER_ACCOUNT_FREE: "LayoutHeaderAccountFree",
  LAYOUT_HEADER_SEARCHFIELD: "LayoutHeaderSearchfield",
  LOCATION_TABS: "LocationTabs",
  ONBOARDING: "Onboarding",
  ONBOARDING_FREE_USER_WELCOME_DIALOG: "OnboardingFreeUserWelcomeDialog",
  ONBOARDING_MARKETING: "OnboardingMarketing",
  ONBOARDING_MARKETING_LEFT_PANE: "OnboardingMarketingLeftPane",
  ONBOARDING_MARKETING_RIGHT_PANE: "OnboardingMarketingRightPane",
  ONBOARDING_PREMIUM_USER_WELCOME_DIALOG: "OnboardingPremiumUserWelcomeDialog",
  POPUP_MESSAGES: "PopupMessages",
  PROJECTS: "Projects",
  PROJECTS_FREE: "ProjectsFree",
  PROJECTS_FREE_LOCATION_HANDLER: "ProjectsFreeLocationsHandler",
  PROJECTS_FREE_TABLE_ROW: "ProjectsFreeTableRow",
  PROJECTS_LOCATION_HANDLER: "ProjectsLocationsHandler",
  PROJECTS_TABLE_ROW: "ProjectsTableRow",
  TABLE_PAGINATION_ACTIONS: "TablePaginationActions",
  UPGRADE_TO_PREMIUM: "UpgradeToPremium",
  USER_TYPES: "UserTypes",
};

// Phone/Fax field settings
export const PHONE_COUNTRIES = [
  {
    id: "1",
    name: "CH",
    displayText: "CH",
    locale: "de_CH",
    flag: require("./assets/flags/ch.svg"),
    phonePrefix: "+41",
  },
  {
    id: "2",
    name: "DE",
    displayText: "DE",
    locale: "de_DE",
    flag: require("./assets/flags/de.svg"),
    phonePrefix: "+49",
  },
  {
    id: "3",
    name: "FR",
    displayText: "FR",
    locale: "fr_FR",
    flag: require("./assets/flags/fr.svg"),
    phonePrefix: "+33",
  },
  {
    id: "4",
    name: "IT",
    displayText: "IT",
    locale: "it_IT",
    flag: require("./assets/flags/it.svg"),
    phonePrefix: "+39",
  },
  {
    id: "5",
    name: "AT",
    displayText: "AT",
    locale: "de_AT",
    flag: require("./assets/flags/at.svg"),
    phonePrefix: "+43",
  },
  {
    id: "6",
    name: "LI",
    displayText: "LI",
    locale: "de_LI",
    flag: require("./assets/flags/li.svg"),
    phonePrefix: "+423",
  },
];

export const buildingSumsCluster = [
  {
    id: 1,
    label: "weniger als 100 Tsd. CHF",
  },
  {
    id: 2,
    label: "100 Tsd. – 1 Mio. CHF",
  },
  {
    id: 3,
    label: "1 Mio. – 5 Mio. CHF",
  },
  {
    id: 4,
    label: "mehr als 5 Mio. CHF",
  },
];
