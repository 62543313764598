import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import { navigate } from 'hookrouter'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS } from '../../config'

const useStyles = makeStyles(theme => ({
  userNameHolder: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  avatar: {
    width: 42,
    height: 42,
    marginRight: 7,
    paddingBottom: 2,
    backgroundColor: '#006A7A',
    color: '#ffffff',
    fontSize: 16
  },
  headerAccount: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(2)
    }
  }
}))

/**
 * LayoutHeaderAccount
 *
 * @returns {*}
 * @constructor
 */
const LayoutHeaderAccount = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, unsetUser, unsetToken } = useContext(GlobalContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const initialAccountData = {
    initials: 'NN',
    firstName: t('general.label.please'),
    lastName: t('general.label.login')
  }
  const [componentAccountData, setComponentAccountData] = useState(
    initialAccountData
  )

  /**
   * useEffect
   */
  useEffect(() => {
    if (user.isSet()) {
      const userData = user.getData()
      setComponentAccountData({
        initials: userData.firstName.charAt(0) + userData.lastName.charAt(0),
        firstName: userData.firstName,
        lastName: userData.lastName
      })
    }
  }, [user])

  /**
   * handleClick
   *
   * @param event
   */
  const handleClick = event => setAnchorEl(event.currentTarget)

  /**
   * handleClose
   */
  const handleClose = () => setAnchorEl(null)

  /**
   * handleClickLogout
   */
  const handleClickLogout = () => {
    setAnchorEl(null)
    unsetUser()
    unsetToken()
    setComponentAccountData(initialAccountData)
    navigate('/')
  }

  /**
   * handleClickAccount
   */
  const handleClickAccount = () => {
    setAnchorEl(null)
    navigate('/account')
  }

  /**
   * handleClickCompany
   */
  const handleClickCompany = () => {
    setAnchorEl(null)
    navigate('/company')
  }

  const hoveredStyle = {
    cursor: 'pointer'
  }

  /**
   * return
   */
  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'
        color='#ffffff'
        className={clsx(classes.actions, classes.headerAccount)}
        id='header_account'
        onClick={handleClick}
      >
        <Box style={hoveredStyle}>
          <ArrowDropDown className={classes.arrow} />
        </Box>
        <Box style={hoveredStyle}>
          <Avatar className={classes.avatar}>
            {componentAccountData.initials}
          </Avatar>
        </Box>
        <Box className={classes.userNameHolder} style={hoveredStyle}>
          <Typography>
            {componentAccountData.firstName +
              ' ' +
              componentAccountData.lastName}
          </Typography>
        </Box>
      </Box>
      <Menu
        id='header_account_menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem onClick={handleClickAccount}>
          {t('account.label.my.account')}
        </MenuItem>
        <MenuItem onClick={handleClickCompany}>
          {t('account.label.my.company')}
        </MenuItem>
        <MenuItem onClick={handleClickLogout}>
          {t('account.label.logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default withAccessControl(
  LayoutHeaderAccount,
  ACL_COMPONENTS.LAYOUT_HEADER_ACCOUNT
)
