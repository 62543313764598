import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TextField, Typography } from '@material-ui/core'
import GetSafe from '../../lib/GetSafe'
import { useTranslation } from 'react-i18next'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import UserTypes from '../Onboarding/UserTypes'

const CompanyBusinessActivitiesForm = ({
  user,
  token,
  classes,
  formValues,
  setFormValues,
  formValueErrors,
  setFormValueErrors,
  initialFormValueErrorsState,
  branchData,
  setBranchData,
  result
}) => {
  const { t } = useTranslation()

  const [hasBranchSubData, setHasBranchSubData] = useState(false)

  /**
   * handleBranchCategoryChange
   *
   * @param event
   */
  const handleBranchCategoryChange = event => {
    setFormValueErrors(initialFormValueErrorsState)
    setFormValues(
      Object.assign({}, formValues, {
        branchCategory: event.target.value,
        branchSubs: []
      })
    )
  }

  /**
   * branchCategories
   *
   * @returns {[]}
   */
  const branchCategories = () => {
    const branchCategories = []

    branchCategories.push(
      <MenuItem key={0} value={0}>
        {t('form.label.please.select')}
      </MenuItem>
    )

    Object.keys(branchData).forEach(index => {
      branchCategories.push(
        <MenuItem key={index + 1} value={parseInt(branchData[index].ID)}>
          {branchData[index].Title}
        </MenuItem>
      )
    })

    return branchCategories
  }

  /**
   * branchCategorySubs
   *
   * @returns {[]}
   */
  const branchCategorySubs = () => {
    const branchCategorySubs = []

    Object.keys(branchData).forEach(index => {
      if (
        parseInt(branchData[index].ID) === parseInt(formValues.branchCategory)
      ) {
        for (
          let i = 0;
          i < branchData[index].BusinessSpecialities.edges.length;
          i++
        ) {
          branchCategorySubs.push(
            <Grid item key={index + i} sm={12}>
              <FormControlLabel
                className={classes.checkboxControl}
                control={
                  <Checkbox
                    id={`branch-sub-${branchData[index].BusinessSpecialities.edges[i].node.ID}`}
                    checked={branchSubIsChecked(
                      branchData[index].BusinessSpecialities.edges[i].node.ID
                    )}
                    color='primary'
                    onChange={handleBranchSubChange}
                    value={parseInt(
                      branchData[index].BusinessSpecialities.edges[i].node.ID
                    )}
                  />
                }
                label={`${branchData[index].BusinessSpecialities.edges[i].node.Title}`}
              />
            </Grid>
          )
        }
      }
    })

    if (branchCategorySubs.length > 0 && !hasBranchSubData) {
      setHasBranchSubData(true)
    } else if (branchCategorySubs.length === 0 && hasBranchSubData) {
      setHasBranchSubData(false)
    }
    return branchCategorySubs
  }

  /**
   * isSubBranchChecked
   *
   * @param subBranches
   */
  const isSubBranchChecked = subBranches => {
    let isChecked = false
    subBranches.map(item => {
      if (otherBranchSubIsChecked(item.node.ID)) {
        isChecked = true
      }
      return item
    })

    return isChecked
  }

  /**
   * handleOtherBranchChange
   *
   * @param event
   */
  const handleOtherBranchChange = event => {
    const branchID = parseInt(event.target.value)
    if (event.target.checked) {
      handleOtherBranchAdd(branchID)
    } else {
      handleOtherBranchRemove(branchID)
    }
  }

  /**
   * branchSubIsChecked
   *
   * @param branchSubId
   */
  const branchSubIsChecked = branchSubId => {
    for (let i = 0; i < formValues.branchSubs.length; i++) {
      if (formValues.branchSubs[i] === parseInt(branchSubId)) {
        return true
      }
    }

    return false
  }

  /**
   * otherBranchIsChecked
   *
   * @param branchID
   * @returns {boolean}
   */
  const otherBranchIsChecked = branchID => {
    for (let i = 0; i < formValues.otherBusinessActivities.length; i++) {
      if (formValues.otherBusinessActivities[i] === parseInt(branchID)) {
        return true
      }
    }
    return false
  }

  /**
   * otherBranchSubIsChecked
   *
   * @param branchSubID
   * @returns {boolean}
   */
  const otherBranchSubIsChecked = branchSubID => {
    for (let i = 0; i < formValues.otherBusinessSpecialities.length; i++) {
      if (formValues.otherBusinessSpecialities[i] === parseInt(branchSubID)) {
        return true
      }
    }
    return false
  }

  /**
   * handleBranchSubRemove
   *
   * @param branchSubId
   */
  const handleBranchSubRemove = branchSubId => {
    const newBranchesArray = formValues.branchSubs

    for (let i = 0; i < formValues.branchSubs.length; i++) {
      if (newBranchesArray[i] === parseInt(branchSubId)) {
        newBranchesArray.splice(i, 1)
      }
    }

    setFormValues(
      Object.assign({}, formValues, { branchSubs: newBranchesArray })
    )
  }

  /**
   * handleOtherBranchRemove
   *
   * @param branchId
   */
  const handleOtherBranchRemove = branchId => {
    const newBranchesArray = formValues.otherBusinessActivities

    for (let i = 0; i < formValues.otherBusinessActivities.length; i++) {
      if (newBranchesArray[i] === parseInt(branchId)) {
        newBranchesArray.splice(i, 1)
      }
    }

    setFormValues(
      Object.assign({}, formValues, {
        otherBusinessActivities: newBranchesArray
      })
    )
  }

  /**
   * handleOtherBranchSubRemove
   *
   * @param branchId
   */
  const handleOtherBranchSubRemove = branchId => {
    const newBranchesArray = formValues.otherBusinessSpecialities

    for (let i = 0; i < formValues.otherBusinessSpecialities.length; i++) {
      if (newBranchesArray[i] === parseInt(branchId)) {
        newBranchesArray.splice(i, 1)
      }
    }

    setFormValues(
      Object.assign({}, formValues, {
        otherBusinessSpecialities: newBranchesArray
      })
    )
  }

  /**
   * handleBranchesChange
   *
   * @param event
   */
  const handleBranchSubChange = event => {
    const branchSubId = parseInt(event.target.value)
    if (event.target.checked) {
      handleBranchSubAdd(branchSubId)
    } else {
      handleBranchSubRemove(branchSubId)
    }
  }

  /**
   * handleOtherBranchAdd
   *
   * @param branchId
   */
  const handleOtherBranchAdd = branchId => {
    const otherActivities = formValues.otherBusinessActivities
    otherActivities.push(branchId)
    setFormValues(
      Object.assign({}, formValues, {
        otherBusinessActivities: otherActivities
      })
    )
  }

  /**
   * handleBranchSubAdd
   *
   * @param branchSubId
   */
  const handleBranchSubAdd = branchSubId => {
    const subs = formValues.branchSubs
    subs.push(branchSubId)
    setFormValues(
      Object.assign({}, formValues, {
        branchSubs: subs
      })
    )
  }

  /**
   * handleOtherBranchSubAdd
   *
   * @param branchId
   */
  const handleOtherBranchSubAdd = branchId => {
    const otherSpecialities = formValues.otherBusinessSpecialities
    otherSpecialities.push(branchId)
    setFormValues(
      Object.assign({}, formValues, {
        otherBusinessSpecialities: otherSpecialities
      })
    )
  }

  /**
   * handleOtherBranchSubChange
   *
   * @param event
   */
  const handleOtherBranchSubChange = event => {
    const mainBranchID = event.target.getAttribute('main_branch_id')

    const branchID = parseInt(event.target.value)
    if (event.target.checked) {
      handleOtherBranchSubAdd(branchID)
      handleOtherBranchChange({
        target: { checked: true, value: mainBranchID }
      })
    } else {
      handleOtherBranchSubRemove(branchID)
      handleOtherBranchChange({
        target: { checked: false, value: mainBranchID }
      })
    }
  }

  /**
   * otherBranchCategories
   *
   * @returns {[]}
   */
  const otherBranchCategories = () => {
    // Only PRO users may chose additional activities
    if (user.getData().mode !== UserTypes.PRO) {
      return null
    }

    const branches = []

    Object.keys(branchData).forEach(index => {
      if (
        parseInt(branchData[index].ID) !== parseInt(formValues.branchCategory)
      ) {
        branches.push(
          <Grid item key={branchData[index].ID} sm={12}>
            <FormControlLabel
              className={classes.checkboxControl}
              control={
                <Checkbox
                  id={`other-branch-activity-${branchData[index].ID}`}
                  checked={otherBranchIsChecked(branchData[index].ID)}
                  color='primary'
                  onChange={handleOtherBranchChange}
                  value={parseInt(branchData[index].ID)}
                  disabled={isSubBranchChecked(
                    branchData[index].BusinessSpecialities.edges
                  )}
                />
              }
              label={`${branchData[index].Title}`}
            />
            {branchData[index].BusinessSpecialities.edges.map(item => {
              return (
                <Grid item key={item.node.ID} sm={12}>
                  <FormControlLabel
                    className={classes.checkboxControlIndented}
                    control={
                      <Checkbox
                        id={`branch-${item.node.ID}`}
                        inputProps={{ main_branch_id: branchData[index].ID }}
                        checked={otherBranchSubIsChecked(item.node.ID)}
                        color='primary'
                        onChange={handleOtherBranchSubChange}
                        value={item.node.ID}
                      />
                    }
                    label={item.node.Title}
                  />
                </Grid>
              )
            })}
          </Grid>
        )
      }
    })

    return (
      <>
        <Grid item className={classes.gridItem}>
          <Divider />
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.preciseTag}>
            {t('form.label.other.activity.fields')}
          </Typography>
        </Grid>
        <Grid
          container
          direction='column'
          className={classes.branchSubCategories}
        >
          {branches}
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid item className={classes.gridItem}>
        {result.fetching && !branchData && (
          <CircularProgress size={24} className={classes.loadingProgress} />
        )}
        {!result.fetching && branchData && (
          <TextField
            id='branch-category-select'
            margin='normal'
            fullWidth
            select
            label={t('form.label.main.profession')}
            value={formValues.branchCategory}
            onChange={event => {
              handleBranchCategoryChange(event)
            }}
            variant='outlined'
            align='left'
            error={GetSafe(
              () => formValueErrors.branchCategory.hasError,
              false
            )}
            helperText={GetSafe(
              () => formValueErrors.branchCategory.message,
              ''
            )}
          >
            {branchCategories()}
          </TextField>
        )}
      </Grid>
      <Grid item className={classes.gridItem}>
        {hasBranchSubData && (
          <Typography className={classes.preciseTag}>
            {t('form.label.precise.selection')}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        direction='column'
        className={classes.branchSubCategories}
      >
        {branchCategorySubs()}
      </Grid>
      {otherBranchCategories()}
    </>
  )
}

export default CompanyBusinessActivitiesForm
