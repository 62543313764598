import React, { useContext, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core";
import GlobalContext from "../lib/GlobalContext";
import withAccessControl from "./HOC/AccessControl";
import { useTranslation } from "react-i18next";
import { ACL_COMPONENTS } from "../config";
import AppBar from "@material-ui/core/AppBar";
import LocationTabSearchArea from "./LocationTabSearchArea";
import LocationTab from "./LocationTab";
import { parseConstructionSumList } from "./Projects/ProjectsLocationsHandler";
import GetSafe from "../lib/GetSafe";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    color: "#525252",
    fontSize: 14,
    height: "100%",
    width: "100%",
    maxWidth: 1280,
  },
  tableTabsHeader: {
    background: "#F7F9FA",
    boxShadow: "none",
  },
}));

/**
 * LocationTabs
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LocationTabs = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedLocationTabValue, setSelectedLocationTabValue, user } =
    useContext(GlobalContext);

  /**
   * useEffect
   */
  useEffect(() => {
    if (!selectedLocationTabValue) {
      if (!user.hasZipSearchAreas()) {
        setSelectedLocationTabValue(props.company.hashID);
      } else {
        setSelectedLocationTabValue(props.locations[0]?.HashID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.company.hashID,
    selectedLocationTabValue,
    setSelectedLocationTabValue,
  ]);

  /**
   * return
   */
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        className={classes.tableTabsHeader}
      >
        <Tabs
          value={selectedLocationTabValue}
          className={classes.tabs}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {!user.hasZipSearchAreas() && (
            <LocationTab
              id={props.company.hashID}
              key={props.company.hashID}
              locationHashID={props.company.hashID}
              labelTitle={t("general.label.my.company")}
              isCompanyLocation={true}
              radius={props.company.distance}
              lat={props.company.latitude}
              lon={props.company.longitude}
              constructionSums={parseConstructionSumList(
                GetSafe(() => props.company.constructionSums.edges, [])
              )}
              value={props.company.hashID}
              handleTabChange={props.handleTabChange}
              setHasNewApplications={props.setHasNewApplications}
              setHasNewSubmissions={props.setHasNewSubmissions}
              selectedLocationTabValue={selectedLocationTabValue}
            />
          )}
          {props.locations.map((location) => {
            if (!location.Removed) {
              return user.hasZipSearchAreas() ? (
                <LocationTabSearchArea
                  id={location.HashID}
                  key={location.HashID}
                  locationHashID={location.HashID}
                  labelTitle={location.Title}
                  value={location.HashID}
                  handleTabChange={props.handleTabChange}
                  setHasNewApplications={props.setHasNewApplications}
                  setHasNewSubmissions={props.setHasNewSubmissions}
                  selectedLocationTabValue={selectedLocationTabValue}
                />
              ) : (
                <LocationTab
                  id={location.HashID}
                  key={location.HashID}
                  locationHashID={location.HashID}
                  labelTitle={location.Title}
                  isCompanyLocation={false}
                  radius={location.Radius}
                  lat={location.Latitude}
                  lon={location.Longitude}
                  constructionSums={location?.ConstructionSums}
                  value={location.HashID}
                  handleTabChange={props.handleTabChange}
                  setHasNewApplications={props.setHasNewApplications}
                  setHasNewSubmissions={props.setHasNewSubmissions}
                  selectedLocationTabValue={selectedLocationTabValue}
                />
              );
            } else {
              return null;
            }
          })}
        </Tabs>
      </AppBar>
    </div>
  );
};

export default withAccessControl(LocationTabs, ACL_COMPONENTS.LOCATION_TABS);
