import React from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@material-ui/core'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  dialogRight: {
    background: theme.palette.primary.main,
    color: '#ffffff'
  },
  marginTop: {
    marginTop: 20
  },
  icon: {
    color: '#fff',
    fontSize: 30
  },
  iconSmallHolder: {
    minWidth: 40
  },
  item: {
    position: 'relative',
    width: '100%',
    maxWidth: 330,
    margin: ' 20px auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 'auto',
      padding: '0 20px'
    }
  },
  listItem: {
    paddingLeft: 26,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  nestedList: {
    paddingLeft: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  nestedItem: {
    paddingTop: 0,
    paddingBottom: 0
  }
}))

/**
 * CustomArrowForwardIcon
 */
const CustomArrowForwardIcon = withStyles(() => ({
  root: {
    fontSize: 15
  }
}))(ArrowForwardIcon)

/**
 * OnboardingMarketingRightPane
 *
 * @returns {*}
 * @constructor
 */
const OnboardingMarketingRightPane = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  /**
   * return
   */
  return (
    <Grid
      container
      item
      md={6}
      sm={12}
      spacing={0}
      direction='column'
      align='left'
      justify='center'
      className={classes.dialogRight}
    >
      <Grid item className={classes.item}>
        <List component='ul'>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <LockOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              {t('onboarding.marketing.security.hint')}
            </ListItemText>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <RoomOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              {t('onboarding.marketing.canton.filter.hint')}
            </ListItemText>
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <StarBorderIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              {t('onboarding.marketing.premium.trial.hint')}
            </ListItemText>
          </ListItem>

          <ListItem className={classes.listItem}>
            <List className={classes.nestedList} component='ul' disablePadding>
              <ListItem className={classes.nestedItem}>
                <ListItemIcon
                  className={clsx(classes.icon, classes.iconSmallHolder)}
                >
                  <CustomArrowForwardIcon />
                </ListItemIcon>
                <ListItemText>
                  {t('onboarding.marketing.premium.feature.free.month.hint')}
                </ListItemText>
              </ListItem>
              <ListItem className={classes.nestedItem}>
                <ListItemIcon
                  className={clsx(classes.icon, classes.iconSmallHolder)}
                >
                  <CustomArrowForwardIcon />
                </ListItemIcon>
                <ListItemText>
                  {t('onboarding.marketing.premium.feature.free.tools.hint')}
                </ListItemText>
              </ListItem>
              <ListItem className={classes.nestedItem}>
                <ListItemIcon
                  className={clsx(classes.icon, classes.iconSmallHolder)}
                >
                  <CustomArrowForwardIcon />
                </ListItemIcon>
                <ListItemText>
                  {t('onboarding.marketing.premium.feature.no.obligation.hint')}
                </ListItemText>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default OnboardingMarketingRightPane
