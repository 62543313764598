import React, { useCallback, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Joi from "@hapi/joi";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FreeToPremiumStepper from "./FreeToPremiumStepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import GetSafe from "../../lib/GetSafe";
import { useQuery } from "urql";
import GlobalContext from "../../lib/GlobalContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import withAccessControl from "../HOC/AccessControl";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 160,
    "&:first-of-type": {
      marginRight: 5,
    },
    "&:last-of-type": {
      marginLeft: 5,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        marginRight: 0,
      },
      "&:last-of-type": {
        marginLeft: 0,
      },
    },
  },
  textFieldLong: {
    width: "100%",
    maxWidth: 330,
    "&:first-of-type": {
      marginRight: "0",
    },
    "&:last-of-type": {
      marginLeft: "0",
    },
  },
  checkboxControl: {
    marginRight: 0,
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  intro: {
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
  },
  item: {
    width: "100%",
    maxWidth: 330,
    margin: "0 auto",
  },
  itemStepper: {
    maxWidth: 600,
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  itemSelection: {
    paddingTop: 24,
  },
  buttonBack: {
    width: 147,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonNext: {
    width: 147,
    color: "#fff",
    marginRight: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  preciseTag: {
    width: "100%",
    maxWidth: 330,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  branchCategorySub: {
    width: "100%",
    maxWidth: 330,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  loadingProgress: {
    color: green[500],
    top: "50%",
    left: "50%",
  },
}));

const queryBranches = `
  {
    readBusinessActivities (
      sortBy: [{field: Sort, direction: ASC}],
    ){
      ID,
      Title,
      NogaCode,
      BusinessSpecialities {
        edges {
            node {
            ID,
            Title
          }
        }
      }
    }
  }
`;

const initialFormErrorsState = {
  branchCategory: { hasError: false, message: "" },
  branchSubs: { hasError: false, message: "" },
};

/**
 * FreeToPremiumDialogueStep2
 *
 * @returns {*}
 * @constructor
 */
const FreeToPremiumDialogueStep2 = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { token, setMessage, unsetUser, unsetToken, user } =
    useContext(GlobalContext);
  const [formValues, setFormValues] = useState({
    branchCategory: 0,
    branchSubs: [],
  });
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormErrorsState
  );
  const [branchData, setBranchData] = useState(false);
  const [hasBranchSubData, setHasBranchSubData] = useState(false);

  // GraphQL query for branches, gets triggered by calling "executeBranchesQuery()"
  const [result, executeBranchesQuery] = useQuery({
    query: queryBranches,
    variables: {},
    pause: true,
  });

  /**
   * executeBranchesQueryCallback
   * Implementation of https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   */
  const executeBranchesQueryCallback = useCallback(() => {
    if (!result.fetching && !branchData) {
      executeBranchesQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, branchData]);

  /**
   * useEffect
   */
  useEffect(() => {
    setFormValues(props.formData.step2);
    executeBranchesQueryCallback();
  }, [props.formData.step2, executeBranchesQueryCallback]);

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching branches on FreeToPremiumDialogueStep2",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.projects"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readBusinessActivities !== "undefined" &&
          !branchData
        ) {
          // Without business branches/activities we cannot continue
          if (result.data.readBusinessActivities.length === 0) {
            throw Error("No business branches/activities found");
          }

          // Add the projects to the global context
          setBranchData(result.data.readBusinessActivities);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    branchCategory: Joi.number().integer().min(1).required(),
    branchSubs: Joi.array().items(Joi.number()),
  });

  /**
   * handleFromSubmit
   */
  const handleFormSubmit = (toStep) => {
    if (toStep === 1) {
      props.setStep(toStep);
    } else {
      const { error } = schema.validate(formValues, { abortEarly: false });

      if (error) {
        const formErrors = FormValueErrorsBuilder(error, t);
        setFormValueErrors({ ...formErrors });
      } else {
        props.setFormData(
          Object.assign({}, props.formData, { step2: formValues })
        );
        props.setStep(toStep);
      }
    }
  };

  /**
   * handleBranchCategoryChange
   *
   * @param event
   */
  const handleBranchCategoryChange = (event) => {
    setFormValueErrors(initialFormErrorsState);
    setFormValues(
      Object.assign({}, { branchCategory: event.target.value, branchSubs: [] })
    );
  };

  /**
   * handleBranchesChange
   *
   * @param event
   */
  const handleBranchSubChange = (event) => {
    const branchSubId = parseInt(event.target.value);
    if (event.target.checked) {
      handleBranchSubAdd(branchSubId);
    } else {
      handleBranchSubRemove(branchSubId);
    }
  };

  /**
   * handleBranchSubAdd
   *
   * @param branchSubId
   */
  const handleBranchSubAdd = (branchSubId) => {
    const subs = formValues.branchSubs;
    subs.push(branchSubId);
    setFormValues(
      Object.assign({}, formValues, {
        branchSubs: subs,
      })
    );
  };

  /**
   * handleBranchSubRemove
   *
   * @param branchSubId
   */
  const handleBranchSubRemove = (branchSubId) => {
    const newBranchesArray = formValues.branchSubs;

    for (let i = 0; i < formValues.branchSubs.length; i++) {
      if (newBranchesArray[i] === parseInt(branchSubId)) {
        newBranchesArray.splice(i, 1);
      }
    }

    setFormValues(
      Object.assign({}, formValues, { branchSubs: newBranchesArray })
    );
  };

  /**
   * branchCategories
   *
   * @returns {[]}
   */
  const branchCategories = () => {
    const branchCategories = [];

    branchCategories.push(
      <MenuItem key={0} value={0}>
        {t("form.label.please.select")}
      </MenuItem>
    );

    Object.keys(branchData).forEach((index) => {
      branchCategories.push(
        <MenuItem key={index + 1} value={parseInt(branchData[index].ID)}>
          {branchData[index].Title}
        </MenuItem>
      );
    });

    return branchCategories;
  };

  /**
   * branchSubIsChecked
   *
   * @param branchSubId
   * @returns {boolean}
   */
  const branchSubIsChecked = (branchSubId) => {
    for (let i = 0; i < formValues.branchSubs.length; i++) {
      if (formValues.branchSubs[i] === parseInt(branchSubId)) {
        return true;
      }
    }

    return false;
  };

  /**
   * branchCategorySubs
   */
  const branchCategorySubs = () => {
    const branchCategorySubs = [];

    Object.keys(branchData).forEach((index) => {
      if (parseInt(branchData[index].ID) === formValues.branchCategory) {
        for (
          let i = 0;
          i < branchData[index].BusinessSpecialities.edges.length;
          i++
        ) {
          branchCategorySubs.push(
            <Grid item key={index + i} className={classes.item}>
              <FormControlLabel
                className={classes.checkboxControl}
                control={
                  <Checkbox
                    id={`branch-sub-${branchData[index].BusinessSpecialities.edges[i].node.ID}`}
                    checked={branchSubIsChecked(
                      branchData[index].BusinessSpecialities.edges[i].node.ID
                    )}
                    color="primary"
                    onChange={handleBranchSubChange}
                    value={parseInt(
                      branchData[index].BusinessSpecialities.edges[i].node.ID
                    )}
                  />
                }
                label={`${branchData[index].BusinessSpecialities.edges[i].node.Title}`}
              />
            </Grid>
          );
        }
      }
    });

    if (branchCategorySubs.length > 0 && !hasBranchSubData) {
      setHasBranchSubData(true);
    } else if (branchCategorySubs.length === 0 && hasBranchSubData) {
      setHasBranchSubData(false);
    }
    return branchCategorySubs;
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.label.premium.upgrade")}</h1>
        <p className={classes.intro}>{t("onboarding.intro")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <FreeToPremiumStepper zeroBasedStep={props.step - 1} />
      </Grid>
      <Grid item className={classes.item}>
        {result.fetching && !branchData && (
          <CircularProgress size={24} className={classes.loadingProgress} />
        )}
        {!result.fetching && branchData && (
          <TextField
            id="branch-category-select"
            className={clsx(classes.textField, classes.textFieldLong)}
            select
            label={t("form.label.profession")}
            value={formValues.branchCategory}
            onChange={(event) => {
              handleBranchCategoryChange(event);
            }}
            variant="outlined"
            align="left"
            error={GetSafe(
              () => formValueErrors.branchCategory.hasError,
              false
            )}
            helperText={GetSafe(
              () => formValueErrors.branchCategory.message,
              ""
            )}
          >
            {branchCategories()}
          </TextField>
        )}
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemSelection)}>
        {hasBranchSubData && (
          <Typography className={classes.preciseTag}>
            {t("form.label.precise.selection")}
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.item}>
        <div className={classes.branchCategorySub}>{branchCategorySubs()}</div>
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id="buttonFormBack"
          color="primary"
          className={classes.buttonBack}
          onClick={() => {
            handleFormSubmit(1);
          }}
          disabled={result.fetching || !branchData}
        >
          {t("form.label.back")}
        </Button>
        <Button
          id="buttonFormNext"
          variant="contained"
          color="primary"
          className={classes.buttonNext}
          onClick={() => {
            handleFormSubmit(3);
          }}
          disabled={result.fetching || !branchData}
        >
          {t("form.label.next")}
        </Button>
      </Grid>
    </>
  );
};

export default withAccessControl(
  FreeToPremiumDialogueStep2,
  ACL_COMPONENTS.FREE_TO_PREMIUM_DIALOGUE_STEP_2
);
