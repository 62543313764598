import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Divider, TextField, Typography } from '@material-ui/core'
import GetSafe from '../../lib/GetSafe'
import Button from '@material-ui/core/Button'
import { DropzoneDialogBase } from 'material-ui-dropzone'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import List from '@material-ui/core/List'
import clsx from 'clsx'
import AddIcon from '@material-ui/icons/Add'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import Info from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import { Trans, useTranslation } from 'react-i18next'
import MenuItem from '@material-ui/core/MenuItem'
import { initialFormValuesState } from './CompanyInfoShared'

const COMPANY_DESCRIPTION_LENGTH = 1000

// TODO: No API connection yet but data should be the same as in the cms.
const forms = [
  {
    id: 0,
    name: ''
  },
  {
    id: 1,
    name: 'Einzelunternehmen'
  },
  {
    id: 2,
    name: 'GmbH'
  },
  {
    id: 3,
    name: 'AG'
  },
  {
    id: 4,
    name: 'Ausländische Zweigniederlassung'
  },
  {
    id: 5,
    name: 'Genossenschaft'
  },
  {
    id: 6,
    name: 'Kollektivgesellschaft'
  },
  {
    id: 7,
    name: 'Schweizerische Zweigniederlassung'
  },
  {
    id: 8,
    name: 'Stiftung'
  },
  {
    id: 9,
    name: 'Verein'
  },
  {
    id: 10,
    name: 'andere Rechtsform'
  }
]

const CompanyInfoForm = ({
  user,
  classes,
  formValues,
  formValueErrors,
  setFormValues,
  readFile,
  createFileFromUrl,
  currentFileObjects,
  setCurrentFileObjects,
  colorSchemes,
  readOnly = false
}) => {
  const { t } = useTranslation()
  const [currentImagesObject, setCurrentImagesObject] = useState([])
  const [logoUploadDialogOpen, setLogoUploadDialogOpen] = useState(false)
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false)
  const [logoData, setLogoData] = useState({ path: '', name: '' })

  /**
   * useEffect set logo data
   */
  useEffect(() => {
    if (user.getData().company.micrositeLogo.Src) {
      setLogoData(user.getData().company.micrositeLogo)
    }
  }, [user])

  /**
   * legalForms
   */
  const legalForms = () => {
    const legalForms = []

    Object.keys(forms).forEach(index => {
      legalForms.push(
        <MenuItem key={index} value={forms[index].id}>
          {forms[index].name}
        </MenuItem>
      )
    })

    return legalForms
  }

  /**
   * handleEmployeeCountChange
   *
   * @param event
   */
  const handleEmployeeCountChange = event => {
    setFormValues({ ...formValues, employeeCount: event.target.value })
  }

  /**
   * handleLegalFormChange
   *
   * @param event
   */
  const handleLegalFormChange = event => {
    setFormValues({ ...formValues, legalForm: event.target.value })
  }

  /**
   * handleFoundingYearChange
   *
   * @param event
   */
  const handleFoundingYearChange = event => {
    setFormValues({ ...formValues, foundingYear: event.target.value })
  }

  /**
   * handleMasterOperationChange
   *
   * @param event
   */
  const handleMasterOperationChange = event => {
    setFormValues({ ...formValues, masterOperation: event.target.value })
  }

  /**
   * handleTrainingCompanyChange
   *
   * @param event
   */
  const handleTrainingCompanyChange = event => {
    setFormValues({ ...formValues, trainingCompany: event.target.value })
  }

  /**
   * handleCompanyDescriptionChange
   *
   * @param event
   */
  const handleCompanyDescriptionChange = event => {
    setFormValues({ ...formValues, micrositeDescription: event.target.value })
  }

  /**
   * hasLogoSrc
   *
   * @returns {boolean}
   */
  const hasLogoSrc = () => {
    return !!logoData.Src
  }

  /**
   * handleLogoUploadDialog
   *
   * @returns {Promise<void>}
   */
  const handleLogoUploadDialog = async () => {
    if (hasLogoSrc()) {
      let file
      if (typeof formValues.micrositeLogo.Src === 'string') {
        file = await createFileFromUrl(formValues.micrositeLogo.Src)
      } else {
        file = formValues.micrositeLogo.Src
      }
      const data = await readFile(file)

      setCurrentFileObjects([
        {
          file,
          data
        }
      ])
    }

    setLogoUploadDialogOpen(true)
  }

  /**
   * handleColorSchemeChange
   *
   * @param event
   */
  const handleColorSchemeChange = event => {
    setFormValues({ ...formValues, colorScheme: event.target.value })
  }

  /**
   * getColorSchemes
   *
   * @returns {JSX.Element[]}
   */
  const getColorSchemes = () => {
    const colorSchemesMenuItems = []

    Object.keys(colorSchemes).forEach(index => {
      colorSchemesMenuItems.push(
        <MenuItem key={index + 1} value={colorSchemes[index].Code}>
          {getColorBlocks(colorSchemes[index].Name)}
          &nbsp;
          {convertColorSchemeNames(colorSchemes[index].Name)}
        </MenuItem>
      )
    })

    return colorSchemesMenuItems
  }

  /**
   * getColorBlocks
   *
   * @param colorSchemeName
   * @returns {JSX.Element|null}
   */
  const getColorBlocks = colorSchemeName => {
    switch (colorSchemeName) {
      case 'Yellow-Green | Beige':
      case 'Neuseeland':
        return (
          <>
            <div
              style={{
                width: 15,
                height: 15,
                backgroundColor: '#e5fa26',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
            &nbsp;
            <div
              style={{
                width: 15,
                height: 15,
                backgroundColor: '#b4b797',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
          </>
        )
      case 'Turquoise | White':
      case 'Polar':
        return (
          <>
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'mediumturquoise',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
            &nbsp;
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'white',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
          </>
        )
      case 'Red | Grey':
      case 'Schweiz':
        return (
          <>
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'red',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
            &nbsp;
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'darkslategray',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
          </>
        )
      case 'Lightgrey | Darkgrey':
      case 'Mond':
        return (
          <>
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'silver',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
            &nbsp;
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'dimgray',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
          </>
        )
      case 'Blue | Orange':
      case 'San Francisco':
        return (
          <>
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'steelblue',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
            &nbsp;
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: 'coral',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
          </>
        )
      case 'Pink | Grey':
      case 'Monroe':
        return (
          <>
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: '#e3007a',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
            &nbsp;
            <span
              style={{
                width: 15,
                height: 15,
                backgroundColor: '#262626',
                border: '1px solid black',
                display: 'inline-block'
              }}
            />
          </>
        )
      default:
        return null
    }
  }

  /**
   * convertColorSchemeNames
   *
   * @param colorSchemeName
   * @returns {string|*}
   */
  const convertColorSchemeNames = colorSchemeName => {
    switch (colorSchemeName) {
      case 'Yellow-Green | Beige':
        return 'Neuseeland '
      case 'Turquoise | White':
        return 'Polar'
      case 'Red | Grey':
        return 'Schweiz'
      case 'Lightgrey | Darkgrey':
        return 'Mond'
      case 'Blue | Orange':
        return 'San Francisco'
      case 'Pink | Grey':
        return 'Monroe'
      default:
        return colorSchemeName
    }
  }

  /**
   * reorder
   *
   * @param list
   * @param startIndex
   * @param endIndex
   * @returns {unknown[]}
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * onDragEnd
   *
   * @param result
   */
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      formValues.micrositeImages,
      result.source.index,
      result.destination.index
    )

    setFormValues({ ...formValues, micrositeImages: items })
  }

  /**
   * handleImageUploadDialog
   */
  const handleImageUploadDialog = async () => {
    const initialFiles = []

    formValues.micrositeImages.map(element => {
      initialFiles.push(element.Src)
      return element
    })

    const fileObjs = await Promise.all(
      initialFiles.map(async initialFile => {
        let file
        if (typeof initialFile === 'string') {
          file = await createFileFromUrl(initialFile)
        } else {
          file = initialFile
        }
        const data = await readFile(file)

        return {
          file,
          data
        }
      })
    )

    setCurrentImagesObject(fileObjs)
    setImageUploadDialogOpen(true)
  }

  /**
   * handleCompanyImagesSave
   */
  const handleCompanyImagesSave = () => {
    const formattedImagesArray = []
    currentImagesObject.map(item => {
      formattedImagesArray.push({
        Src: item.data,
        Title: item.file.name,
        Alt: item.file.name
      })
      return item
    })
    setFormValues({ ...formValues, micrositeImages: formattedImagesArray })
    setImageUploadDialogOpen(false)
  }

  /**
   * membershipsChecked
   *
   * @param membershipID
   * @returns {boolean}
   */
  const membershipsChecked = membershipID => {
    for (let i = 0; i < formValues.memberships.length; i++) {
      if (parseInt(formValues.memberships[i].ID) === parseInt(membershipID)) {
        return true
      }
    }
    return false
  }

  /**
   * handleMembershipChange
   *
   * @param event
   */
  const handleMembershipChange = event => {
    const id = event.target.id.replace('membership_checkbox_', '')
    const filteredMembership = user
      .getData()
      .company.allMemberships.filter(item => {
        return item.ID === id
      })
    const memberships = formValues.memberships

    if (event.target.checked) {
      delete filteredMembership[0].__typename
      memberships.push(filteredMembership[0])
      setFormValues(
        Object.assign({}, formValues, {
          memberships: memberships
        })
      )
    } else {
      for (let i = 0; i < formValues.memberships.length; i++) {
        if (parseInt(memberships[i].ID) === parseInt(id)) {
          memberships.splice(i, 1)
        }
      }

      setFormValues(Object.assign({}, formValues, { memberships: memberships }))
    }
  }

  /**
   * handleAdditionalMembershipsChange
   *
   * @param event
   */
  const handleAdditionalMembershipsChange = event => {
    setFormValues({ ...formValues, additionalMemberships: event.target.value })
  }

  /**
   * TooltipInputAdornment
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const TooltipInputAdornment = ({ titleString }) => {
    return (
      <InputAdornment position='end'>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={<Trans i18nKey={titleString} />}
          PopperProps={{
            className: clsx(classes.tooltipItem, classes.tooltipInfo)
          }}
        >
          <span className={classes.tooltipTitle}>
            <Info style={{ fontSize: 30 }} color='primary' />
          </span>
        </Tooltip>
      </InputAdornment>
    )
  }

  const membershipElementTooltipped = (item, titleString) => {
    return (
      <Tooltip
        key={item.ID}
        classes={{ tooltip: classes.tooltip }}
        title={<Trans i18nKey={titleString} />}
        PopperProps={{
          className: clsx(classes.tooltipItem, classes.tooltipInfo)
        }}
      >
        {membershipElement(item)}
      </Tooltip>
    )
  }

  const membershipElement = item => {
    return (
      <FormControlLabel
        disabled={readOnly}
        key={item.ID}
        control={
          <Checkbox
            color='primary'
            checked={
              item.IsBadge
                ? user.getData().company.micrositeCertified
                : membershipsChecked(item.ID)
            }
            onChange={handleMembershipChange}
            name={item.Title}
            id={'membership_checkbox_' + item.ID}
            disabled={item.IsBadge}
          />
        }
        label={
          <Grid
            container
            direction='row'
            justify='center'
            alignItems='center'
            margin='normal'
            padding='normal'
            className={classes.gridContainer}
          >
            <Grid item className={classes.checkboxGridItem}>
              <img
                className={classes.membershipsLogo}
                src={item.LogoUrl}
                alt={item.Title}
              />
            </Grid>
            <Grid item className={classes.checkboxGridItem}>
              {item.Title}
            </Grid>
          </Grid>
        }
      />
    )
  }

  /**
   * inputEmailApplicationTextHelperText
   *
   * @type {string}
   */
  const inputEmailApplicationTextHelperText =
    t('form.label.company.application.text.description') +
    ' {{FIRMEN-KOMPETENZEN}}, {{PROJEKT-NAME}}, {{PROJEKT-ORT}}, {{FIRMEN-GRUENDUNGSJAHR}}, {{FIRMEN-NAME}}, {{FIRMEN-MICROSITE}}, {{FIRMEN-TELEFON}}, {{USER-VORNAME}}, {{USER-NACHNAME}}' +
    '\n' +
    GetSafe(() => formValueErrors.micrositeEmailApplicationText.message, '')

  /**
   * handleEmailApplicationTextChange
   *
   * @param event
   */
  const handleEmailApplicationTextChange = event => {
    setFormValues({
      ...formValues,
      micrositeEmailApplicationText: event.target.value
    })
  }

  return (
    <>
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        margin='normal'
        padding='normal'
        className={classes.gridContainer}
      >
        <Grid item className={classes.gridItem}>
          <TextField
            id='inputDirectoryName'
            variant='outlined'
            align='left'
            label={t('form.label.directory.name')}
            value={formValues.directoryName}
            margin='normal'
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            id='inputEmployeeCount'
            variant='outlined'
            align='left'
            error={GetSafe(() => formValueErrors.employeeCount.hasError, false)}
            label={t('form.label.employee.count')}
            value={formValues.employeeCount}
            helperText={GetSafe(
              () => formValueErrors.employeeCount.message,
              ''
            )}
            onChange={handleEmployeeCountChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          {/* TODO: check if the legal form data can come from an API */}
          <TextField
            id='selectLegalForm'
            label={t('form.label.legal.form')}
            variant='outlined'
            select
            align='left'
            value={formValues.legalForm}
            error={GetSafe(() => formValueErrors.legalForm.hasError, false)}
            helperText={GetSafe(() => formValueErrors.legalForm.message, '')}
            onChange={handleLegalFormChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          >
            {legalForms()}
          </TextField>
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            id='inputFoundingYear'
            variant='outlined'
            align='left'
            error={GetSafe(() => formValueErrors.foundingYear.hasError, false)}
            label={t('form.label.founding.year')}
            value={formValues.foundingYear}
            helperText={GetSafe(() => formValueErrors.foundingYear.message, '')}
            onChange={handleFoundingYearChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <FormControl
            component='fieldset'
            className={classes.formControl}
            disabled={readOnly}
          >
            <FormLabel component='legend' className={classes.formLabel}>
              {t('form.label.master.operation')}
            </FormLabel>
            <RadioGroup
              aria-label='masterOperation'
              name='masterOperation'
              value={formValues.masterOperation}
              onChange={handleMasterOperationChange}
            >
              <FormControlLabel
                value='1'
                control={<Radio color='primary' id='radioMasterOperationYes' />}
                label={t('form.label.yes')}
              />
              <FormControlLabel
                value='0'
                control={<Radio color='primary' id='radioMasterOperationNo' />}
                label={t('form.label.no')}
              />
            </RadioGroup>
            {GetSafe(() => formValueErrors.masterOperation.hasError, false) && (
              <FormHelperText
                id='radioMasterOperation-helper-text'
                className={classes.radioButtonHelperText}
              >
                {formValueErrors.masterOperation.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item className={classes.gridItem}>
          <FormControl
            component='fieldset'
            className={classes.formControl}
            disabled={readOnly}
          >
            <FormLabel component='legend' className={classes.formLabel}>
              {t('form.label.training.company')}
            </FormLabel>
            <RadioGroup
              aria-label='trainingCompany'
              name='trainingCompany'
              value={formValues.trainingCompany}
              onChange={handleTrainingCompanyChange}
            >
              <FormControlLabel
                value='1'
                control={<Radio color='primary' id='radioTrainingCompanyYes' />}
                label={t('form.label.yes')}
              />
              <FormControlLabel
                value='0'
                control={<Radio color='primary' id='radioTrainingCompanyNo' />}
                label={t('form.label.no')}
              />
            </RadioGroup>
            {GetSafe(() => formValueErrors.trainingCompany.hasError, false) && (
              <FormHelperText
                id='radioTrainingCompany-helper-text'
                className={classes.radioButtonHelperText}
              >
                {formValueErrors.trainingCompany.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            id='inputCompanyDescription'
            variant='outlined'
            multiline
            inputProps={{
              // this has to be in lowerCase inputProps to work properly
              maxLength: COMPANY_DESCRIPTION_LENGTH
            }}
            InputProps={{
              // this has to be in Uppercase InputProps to work properly
              endAdornment: (
                <TooltipInputAdornment titleString='form.label.company.description.tooltip' />
              )
            }}
            align='left'
            error={GetSafe(
              () => formValueErrors.micrositeDescription.hasError,
              false
            )}
            label={t('form.label.company.description')}
            value={formValues.micrositeDescription}
            helperText={
              `${formValues.micrositeDescription.length}/${COMPANY_DESCRIPTION_LENGTH}` +
              ' ' +
              GetSafe(() => formValueErrors.micrositeDescription.message, '')
            }
            onChange={handleCompanyDescriptionChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <span className={classes.label}>{t('form.label.logo')}</span>
        </Grid>
        {hasLogoSrc() && (
          <Grid item className={classes.gridItem}>
            <img
              className={classes.logoImg}
              src={logoData.Src}
              alt={logoData.Alt}
            />
          </Grid>
        )}
        <Grid item className={classes.gridItem}>
          <Button
            className={classes.buttonImg}
            variant='contained'
            color='primary'
            onClick={handleLogoUploadDialog}
            disabled={readOnly}
          >
            {hasLogoSrc() && t('form.label.company.logo.change')}
            {!hasLogoSrc() && t('form.label.company.logo.add')}
          </Button>
          <DropzoneDialogBase
            id='uploadCompanyLogo'
            dialogTitle={<></>}
            dropzoneText={t('form.label.company.logo.upload')}
            acceptedFiles={['image/jpeg', 'image/png']}
            filesLimit={2} // Must be at least 2
            fileObjects={currentFileObjects}
            cancelButtonText={t('general.label.abort')}
            submitButtonText={t('general.label.save')}
            maxFileSize={800000}
            open={logoUploadDialogOpen}
            onAdd={newFileObjs => {
              // Workaround because DropzoneAreaBase does not allow a filesLimit of 1
              setCurrentFileObjects(newFileObjs)
              // Multi-file handling would be this:
              // setCurrentFileObjects([].concat(currentFileObjects, newFileObjs))
            }}
            onDelete={deleteFileObj => {
              const clearedArray = currentFileObjects.filter(
                element => element.data !== deleteFileObj.data
              )

              // If this was the last preview element
              if (clearedArray.length === 0) {
                // Delete the logo
                setLogoUploadDialogOpen(false)
                setLogoData({ path: '', name: '' })
                setCurrentFileObjects([])
                setFormValues({
                  ...formValues,
                  micrositeLogo: initialFormValuesState.micrositeLogo
                })
              }

              setCurrentFileObjects(clearedArray)
            }}
            onClose={() => setLogoUploadDialogOpen(false)}
            onSave={() => {
              setLogoUploadDialogOpen(false)
              setLogoData({
                Src: currentFileObjects[0].data,
                Title: currentFileObjects[0].file.name
              })
            }}
            showPreviews
            showFileNamesInPreview={false}
            alertSnackbarProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            }}
            getFileAddedMessage={fileName => {
              return t('company.info.logo.added')
            }}
            getFileRemovedMessage={fileName => {
              return t('company.info.logo.removed')
            }}
            getFileLimitExceedMessage={filesLimit => {
              return t('company.info.logo.filesLimit', {
                filesLimit: filesLimit
              })
            }}
            getDropRejectMessage={(
              rejectedFile,
              acceptedFiles,
              maxFileSize
            ) => {
              return t('company.info.logo.rejected', {
                rejectedFile: rejectedFile.name,
                acceptedFiles: acceptedFiles,
                maxFileSize: maxFileSize / 1000
              })
            }}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            id='selectColorScheme'
            label={t('form.label.color.scheme')}
            variant='outlined'
            select
            align='left'
            value={formValues.colorScheme}
            error={GetSafe(() => formValueErrors.colorScheme.hasError, false)}
            helperText={GetSafe(() => formValueErrors.colorScheme.message, '')}
            onChange={handleColorSchemeChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          >
            {getColorSchemes()}
          </TextField>
        </Grid>
        <Grid item className={classes.gridItem}>
          <span className={classes.label}>{t('form.label.images')}</span>
          <List className={classes.root}>
            {/* eslint-disable-next-line react/jsx-handler-names */}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='list'>
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {formValues.micrositeImages.map((item, index) => {
                      const internalID = index + 1
                      return (
                        <React.Fragment key={internalID}>
                          <Draggable draggableId={internalID} index={index}>
                            {provided => (
                              <ListItem
                                className={classes.listItem}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItemIcon>
                                  <DragIndicatorIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                  className={classes.location}
                                  primary={
                                    <img
                                      id={item.Alt}
                                      className={classes.logoImg}
                                      src={item.Src}
                                      alt={item.Alt}
                                    />
                                  }
                                />
                                <IconButton
                                  id={'deleteButton' + internalID}
                                  onClick={() => {
                                    const clearedArray = formValues.micrositeImages.filter(
                                      element => element.Src !== item.Src
                                    )
                                    setFormValues({
                                      ...formValues,
                                      micrositeImages: clearedArray
                                    })
                                  }} // eslint-disable-line
                                >
                                  <DeleteIcon className={classes.icon} />
                                </IconButton>
                              </ListItem>
                            )}
                          </Draggable>
                          <Divider />
                        </React.Fragment>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {formValues.micrositeImages.length < 5 && (
              <>
                <ListItem
                  className={clsx(classes.listItem, classes.listItemPointer)}
                  onClick={handleImageUploadDialog}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.location}
                    primary={t('form.label.reference.images.add')}
                  />
                </ListItem>
                <Divider />
              </>
            )}
            <DropzoneDialogBase
              id='uploadCompanyImages'
              dialogTitle={<></>}
              dropzoneText={t('form.label.company.images.upload')}
              acceptedFiles={['image/jpeg', 'image/png']}
              filesLimit={5}
              fileObjects={currentImagesObject}
              cancelButtonText={t('general.label.abort')}
              submitButtonText={t('general.label.save')}
              maxFileSize={2500000}
              open={imageUploadDialogOpen}
              onAdd={newFileObjs => {
                const newFiles = [].concat(currentImagesObject, newFileObjs)
                setCurrentImagesObject(newFiles)
              }}
              onDelete={deleteFileObj => {
                const clearedArray = currentImagesObject.filter(
                  element => element.data !== deleteFileObj.data
                )
                setCurrentImagesObject(clearedArray)
              }}
              onClose={() => setImageUploadDialogOpen(false)}
              onSave={handleCompanyImagesSave}
              showPreviews
              showFileNamesInPreview={false}
              alertSnackbarProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              }}
              getFileAddedMessage={fileName => {
                return t('company.info.image.added')
              }}
              getFileRemovedMessage={fileName => {
                return t('company.info.image.removed')
              }}
              getFileLimitExceedMessage={filesLimit => {
                return t('company.info.image.filesLimit', {
                  filesLimit: filesLimit
                })
              }}
              getDropRejectMessage={(
                rejectedFile,
                acceptedFiles,
                maxFileSize
              ) => {
                return t('company.info.image.rejected', {
                  rejectedFile: rejectedFile.name,
                  acceptedFiles: acceptedFiles,
                  maxFileSize: maxFileSize / 1000000
                })
              }}
            />
          </List>
        </Grid>
        <Grid item className={classes.gridItem}>
          <FormControl component='fieldset' disabled={readOnly}>
            <Typography className={classes.label}>
              {t('company.info.label.memberships')}
            </Typography>
            <FormGroup>
              {user.getData().company.allMemberships.map(item => {
                return item.IsBadge
                  ? membershipElementTooltipped(
                      item,
                      'tooltip.membership.badge'
                    )
                  : membershipElement(item)
              })}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            id='inputAdditionalMemberships'
            variant='outlined'
            align='left'
            error={GetSafe(
              () => formValueErrors.additionalMemberships.hasError,
              false
            )}
            label={t('form.label.additionalMemberships')}
            value={formValues.additionalMemberships}
            helperText={GetSafe(
              () => formValueErrors.additionalMemberships.message,
              ''
            )}
            onChange={handleAdditionalMembershipsChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <TextField
            id='inputEmailApplicationText'
            variant='outlined'
            multiline
            InputProps={{
              // this has to be in Uppercase InputProps to work properly
              endAdornment: (
                <TooltipInputAdornment titleString='form.label.company.application.text.tooltip' />
              )
            }}
            align='left'
            error={GetSafe(
              () => formValueErrors.micrositeEmailApplicationText.hasError,
              false
            )}
            label={t('form.label.company.emailApplication')}
            value={formValues.micrositeEmailApplicationText}
            helperText={inputEmailApplicationTextHelperText}
            onChange={handleEmailApplicationTextChange}
            margin='normal'
            fullWidth
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CompanyInfoForm
