export const FavoriteStateConditions = Object.freeze({
  ATTENTION: 'toedit',
  CHECKED: 'applied',
  FOLLOWUP: 'followup',
  FOLLOWED: 'followed',
  THUMB_UP: 'accepted',
  THUMB_DOWN: 'refused',
  EXTERNALAPPLIED: 'externalapplied'
})

const FavoriteStateConditionText = (t, stateCondition) => {
  switch (stateCondition) {
    case FavoriteStateConditions.CHECKED:
      return t('favorites.label.status.checked')
    case FavoriteStateConditions.FOLLOWUP:
      return t('favorites.label.status.followup')
    case FavoriteStateConditions.FOLLOWED:
      return t('favorites.label.status.followed')
    case FavoriteStateConditions.THUMB_UP:
      return t('favorites.label.status.thumb.up')
    case FavoriteStateConditions.THUMB_DOWN:
      return t('favorites.label.status.thumb.down')
    case FavoriteStateConditions.EXTERNALAPPLIED:
      return t('favorites.label.status.externalapplied')
    default:
      return t('favorites.label.status.attention')
  }
}

export { FavoriteStateConditions as default, FavoriteStateConditionText }
