import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import LocalOffer from '@material-ui/icons/LocalOffer'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import GlobalContext from '../../lib/GlobalContext'
import { navigate } from 'hookrouter'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS } from '../../config'

const useStyles = makeStyles(theme => ({
  userNameHolder: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  headerFree: {
    justifyContent: 'flex-end'
  },
  avatar: {
    width: 42,
    height: 42,
    marginRight: 7,
    paddingBottom: 2,
    backgroundColor: '#006A7A',
    color: '#ffffff',
    fontSize: 16
  },
  disabled: {
    opacity: 0.5
  }
}))

/**
 * LayoutHeaderAccount
 *
 * @returns {*}
 * @constructor
 */
const LayoutHeaderAccountFree = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    user,
    unsetUser,
    unsetToken,
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen
  } = useContext(GlobalContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const initialAccountData = {
    initials: 'NN',
    firstName: t('general.label.please'),
    lastName: t('general.label.login')
  }
  const [componentAccountData, setComponentAccountData] = useState(
    initialAccountData
  )

  /**
   * useEffect
   */
  useEffect(() => {
    if (user.isSet()) {
      const userData = user.getData()
      setComponentAccountData({
        initials: userData.firstName.charAt(0) + userData.lastName.charAt(0),
        firstName: userData.firstName,
        lastName: userData.lastName
      })
    }
  }, [user])

  /**
   * handleUpgradeToPremium
   */
  const handleUpgradeToPremium = () => {
    setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
  }

  /**
   * handleClick
   *
   * @param event
   */
  const handleClick = event => {
    if (user.isSet()) {
      setAnchorEl(event.currentTarget)
    }
  }

  /**
   * handleClose
   */
  const handleClose = () => setAnchorEl(null)

  /**
   * handleClickLogout
   */
  const handleClickLogout = () => {
    setAnchorEl(null)
    unsetUser()
    unsetToken()
    setComponentAccountData(initialAccountData)
    navigate('/')
  }

  /**
   * handleClickAccount
   */
  const handleClickAccount = () => {
    setAnchorEl(null)
    navigate('/account')
  }

  const hoveredStyle = {
    cursor: 'pointer'
  }

  /**
   * return
   */
  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        color='#ffffff'
        className={clsx(classes.actions, classes.headerFree)}
        id='header_account'
        onClick={handleClick}
      >
        <Box style={hoveredStyle}>
          <ArrowDropDown className={classes.arrow} />
        </Box>
        <Box style={hoveredStyle}>
          <Avatar className={classes.avatar}>
            {componentAccountData.initials}
          </Avatar>
        </Box>
        <Box className={classes.userNameHolder} style={hoveredStyle}>
          <Typography className={classes.userName}>
            {componentAccountData.firstName +
              ' ' +
              componentAccountData.lastName}
          </Typography>
        </Box>
      </Box>
      <Menu
        id='header_account_menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem onClick={handleClickAccount} id='header_account_menu_account'>
          {t('account.label.my.account')}
        </MenuItem>
        <MenuItem onClick={handleUpgradeToPremium}>
          {t('account.label.upgrade')}
        </MenuItem>
        <MenuItem onClick={handleClose} id='header_account_menu_company'>
          <Tooltip
            arrow
            title={t('account.label.benefit.premium')}
            aria-label={t('account.label.benefit.premium')}
          >
            <Grid container className={classes.root} spacing={2}>
              <Grid item lg='auto'>
                <LocalOffer color='primary' />
              </Grid>
              <Grid className={classes.disabled} item lg='auto'>
                {t('account.label.my.company')}
              </Grid>
            </Grid>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={handleClickLogout}>
          {t('account.label.logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default withAccessControl(
  LayoutHeaderAccountFree,
  ACL_COMPONENTS.LAYOUT_HEADER_ACCOUNT_FREE
)
