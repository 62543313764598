import Joi from '@hapi/joi'

export const updateCompanyMutation = `
mutation UpdateCompany(
    $hashId: String!,
    $references: [MicrositeReferencesInput]
  ) {
    updateCompany(
      HashID: $hashId,
      MicrositeReferences: $references
    ) {
      MicrositeReferences {
        edges {
          node {
            Sort
            Title
            Content
            Location
            Year
            Month
            ButtonTitle
            ButtonLink
            MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * Joi validation schema
 */
export const schema = Joi.object({
  Sort: Joi.number().allow(null),
  Title: Joi.string().required(),
  ButtonLink: Joi.string().allow(''),
  ButtonTitle: Joi.string().allow(''),
  Content: Joi.string().allow(''),
  Location: Joi.string().allow(''),
  Month: Joi.number().allow(''),
  Year: Joi.number().allow(''),
  MicrositeReferencesImages: Joi.array().min(0)
})

export const REFERENCE_DESCRIPTION_LENGTH = 1000
