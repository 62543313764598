import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import Joi from '@hapi/joi'
import FreeToPremiumStepper from './FreeToPremiumStepper'
import FormValueErrorsBuilder from '../../lib/FormValueErrorsBuilder'
import GetSafe from '../../lib/GetSafe'
import withAccessControl from '../HOC/AccessControl'
import GooglePlacesAPIHelper from '../../lib/GooglePlacesAPIHelper'
import PhoneField from '../PhoneField'
import ZefixCompanyAutocompleteField from '../ZefixCompanyAutocompleteField'
import { ACL_COMPONENTS } from '../../config'
import GooglePlacesAutosuggestStreet from '../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestStreet'
import GooglePlacesAutosuggestZip from '../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestZip'
import GooglePlacesAutosuggestCity from '../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestCity'
import { MESSAGE_TYPES } from '../PopupMessages'
import GlobalContext from '../../lib/GlobalContext'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  textField: {
    width: 160,
    '&:first-of-type': {
      marginRight: 5
    },
    '&:last-of-type': {
      marginLeft: 5
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:first-of-type': {
        marginRight: 0
      },
      '&:last-of-type': {
        marginLeft: 0
      }
    }
  },
  textFieldLong: {
    width: '100%',
    maxWidth: 330,
    '&:first-of-type': {
      marginRight: '0'
    },
    '&:last-of-type': {
      marginLeft: '0'
    }
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '30px'
  },
  intro: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'left'
  },
  item: {
    position: 'relative',
    width: '100%',
    maxWidth: 330,
    margin: '0 auto'
  },
  itemStepper: {
    maxWidth: 600
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: 'left'
    }
  },
  input: {
    marginLeft: 60
  },
  itemPhone: {
    height: 80,
    overflow: 'hidden',
    '&.state-phoneField-open': {
      height: 'auto',
      overflow: 'visible',
      zIndex: 3
    }
  },
  itemMobile: {
    height: 80,
    overflow: 'hidden',
    '&.state-mobileField-open': {
      height: 'auto',
      overflow: 'visible',
      zIndex: 3
    }
  },
  itemFax: {
    height: 80,
    overflow: 'hidden',
    '&.state-faxField-open': {
      height: 'auto',
      overflow: 'visible',
      zIndex: 3
    }
  },
  buttonBack: {
    width: 147,
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonNext: {
    width: 147,
    color: '#fff',
    marginRight: 'auto',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(4)
  }
}))

/**
 * FreeToPremiumDialogueStep1
 *
 * @returns {*}
 * @constructor
 */
const FreeToPremiumDialogueStep1 = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setMessage, token, user } = useContext(GlobalContext)
  const [formValues, setFormValues] = useState({
    company: '',
    street: '',
    zip: '',
    city: '',
    phone: '',
    mobile: '',
    fax: '',
    website: '',
    email: ''
  })
  const [formValueErrors, setFormValueErrors] = useState({
    company: { hasError: false, message: '' },
    street: { hasError: false, message: '' },
    zip: { hasError: false, message: '' },
    city: { hasError: false, message: '' },
    phone: { hasError: false, message: '' },
    mobile: { hasError: false, message: '' },
    fax: { hasError: false, message: '' },
    website: { hasError: false, message: '' },
    email: { hasError: false, message: '' }
  })
  const [autoCompleteCompanyData, setAutoCompleteCompanyData] = useState({})
  const [activePhone, setActivePhone] = useState('state-phoneField-closed')
  const [activeMobile, setActiveMobile] = useState('state-mobileField-closed')
  const [activeFax, setActiveFax] = useState('state-faxField-closed')
  const [streetFormValue, setStreetFormValue] = useState('')
  const [zipFormValue, setZipFormValue] = useState('')
  const [cityFormValue, setCityFormValue] = useState('')

  /**
   * useEffect
   */
  useEffect(() => {
    setFormValues(props.formData.step1)
  }, [props.formData.step1])

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    company: Joi.string().required(),
    street: Joi.string()
      .trim()
      .required(),
    zip: Joi.string()
      .trim()
      .required(),
    city: Joi.string()
      .trim()
      .required(),
    lat: Joi.any(),
    lon: Joi.any(),
    phone: Joi.string().allow(''),
    mobile: Joi.string().allow(''),
    fax: Joi.string().allow(''),
    website: Joi.string()
      .regex(
        /https?:\/\/(www\.)?[-a-zA-ZöäüÖÄÜ0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/
      )
      .allow(''),
    email: Joi.string()
      .email({ tlds: false })
      .allow('')
  })

  /**
   * determineCountry
   *
   * checks if googlePlacesResult is in CH.
   *
   * @param googlePlacesResult
   * @returns {number}
   */
  const determineCountry = googlePlacesResult => {
    let isInCH = []
    googlePlacesResult.results.map(item => {
      isInCH = item.address_components.filter(addressComponent => {
        return addressComponent.short_name === 'CH'
      })
      return item
    })
    return isInCH.length
  }

  /**
   * handleFormBack
   */
  const handleFormBack = () => {
    props.setStep(0)
  }

  /**
   * handleFromNext
   */
  const handleFormNext = () => {
    if (formValues.street.trim() === '') {
      formValues.street = streetFormValue
    }
    if (formValues.zip.trim() === '') {
      formValues.zip = zipFormValue
    }
    if (formValues.city.trim() === '') {
      formValues.city = cityFormValue
    }

    if (
      formValues.website !== '' &&
      !formValues.website.includes('http://') &&
      !formValues.website.includes('https://')
    ) {
      formValues.website = 'https://' + formValues.website
    }

    const { error } = schema.validate(formValues, { abortEarly: false })

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t)
      setFormValueErrors({ ...formErrors })
    } else {
      const addressString = `${formValues.street}+${formValues.zip}+${formValues.city}`

      GooglePlacesAPIHelper.getCoordsByAddress(addressString).then(result => {
        if (result.status === 'OK' && determineCountry(result)) {
          formValues.lat = result.results[0].geometry.location.lat
          formValues.lon = result.results[0].geometry.location.lng

          props.setFormData(
            Object.assign({}, props.formData, { step1: formValues })
          )

          props.setStep(2)
        } else if (result.status === 'OK' && !determineCountry(result)) {
          setFormValueErrors({
            street: {
              hasError: true,
              message: t('error.form.address.not.ch')
            },
            zip: { hasError: true, message: t('error.form.address.not.ch') },
            city: { hasError: true, message: t('error.form.address.not.ch') }
          })
        } else if (result.status === 'ZERO_RESULTS') {
          setFormValueErrors({
            street: {
              hasError: true,
              message: t('error.form.invalid.address')
            },
            zip: { hasError: true, message: t('error.form.invalid.address') },
            city: { hasError: true, message: t('error.form.invalid.address') }
          })
        } else {
          let errorMessage = ''
          if (result.error_message !== undefined) {
            ExternalErrorLogger.log({
              text: 'Error get coords by address on FreeToPremiumDialogueStep1',
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                address: JSON.stringify(addressString),
                errorMessage: result.error_message
              }
            })
            errorMessage =
              '<br />' +
              '<span style="font-weight: bold">' +
              t('general.label.error.message') +
              '</span>' +
              ' ' +
              result.error_message
          }
          setMessage(
            MESSAGE_TYPES.ERROR,
            t('error.general.short') +
              '<br />' +
              '<br />' +
              '<span style="font-weight: bold">' +
              t('general.label.status.code') +
              '</span>' +
              ' ' +
              result.status +
              errorMessage +
              '<br />' +
              '<br />' +
              t('general.label.contact.smartconext')
          )
        }
      })
    }
  }

  /**
   * handleCompanyChange
   *
   * @param event
   */
  const handleCompanyChange = event => {
    setFormValues({ ...formValues, company: event.target.value })
  }

  /**
   * handleStreetChange
   *
   * @param autosuggestResult
   */
  const handleStreetChange = autosuggestResult => {
    setFormValues({
      ...formValues,
      street:
        autosuggestResult.addressObject.street +
        ' ' +
        autosuggestResult.addressObject.number,
      zip: autosuggestResult.addressObject.zip,
      city:
        autosuggestResult.addressObject.city === ''
          ? formValues.city
          : autosuggestResult.addressObject.city
    })
  }

  /**
   * handleZipChange
   *
   * @param autosuggestResult
   */
  const handleZipChange = autosuggestResult => {
    setFormValues({
      ...formValues,
      zip: autosuggestResult.addressObject.zip,
      city:
        autosuggestResult.addressObject.city === ''
          ? formValues.city
          : autosuggestResult.addressObject.city
    })
  }

  /**
   * handleCityChange
   *
   * @param autosuggestResult
   */
  const handleCityChange = autosuggestResult => {
    setFormValues({ ...formValues, city: autosuggestResult.addressObject.city })
  }

  /**
   * handlePhoneChange
   *
   * @param event
   */
  const handlePhoneChange = event => {
    setFormValues({ ...formValues, phone: event.target.value })
  }

  /**
   * handleMobileChange
   *
   * @param event
   */
  const handleMobileChange = event => {
    setFormValues({ ...formValues, mobile: event.target.value })
  }

  /**
   * handleFaxChange
   *
   * @param event
   */
  const handleFaxChange = event => {
    setFormValues({ ...formValues, fax: event.target.value })
  }

  /**
   * handleWebsiteChange
   *
   * @param event
   */
  const handleWebsiteChange = event => {
    setFormValues({ ...formValues, website: event.target.value })
  }

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleEmailChange = event => {
    setFormValues({ ...formValues, email: event.target.value })
  }

  /**
   * onAutocompleteSelect
   * Handle the result of the autocomplete
   * @param company
   */
  const onAutocompleteSelect = company => {
    if (company !== autoCompleteCompanyData) {
      setAutoCompleteCompanyData(company)

      setFormValues({
        company: company.Name,
        street: company.Address.AddressInformation.Street,
        zip: company.Address.AddressInformation.SwissZipCode,
        city: company.Address.AddressInformation.Town,
        phone: '',
        mobile: '',
        fax: '',
        website: '',
        email: ''
      })
    }
  }

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t('general.label.premium.upgrade')}</h1>
        <p className={classes.intro}>{t('onboarding.intro')}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <FreeToPremiumStepper zeroBasedStep={props.step - 1} />
      </Grid>
      <Grid item className={classes.item}>
        <ZefixCompanyAutocompleteField
          onAutocompleteSelect={onAutocompleteSelect}
          csrfToken={null}
          companyHashID={null}
        />
        <TextField
          id='inputCompany'
          className={clsx(classes.textField, classes.textFieldLong)}
          label={t('form.label.company')}
          variant='outlined'
          align='left'
          value={formValues.company}
          error={GetSafe(() => formValueErrors.company.hasError, false)}
          helperText={GetSafe(() => formValueErrors.company.message, '')}
          onChange={handleCompanyChange}
          margin='normal'
        />
      </Grid>
      <Grid item className={classes.item}>
        <GooglePlacesAutosuggestStreet
          onSuggestionSelect={handleStreetChange}
          presetValue={formValues.street}
          autoSuggestError={GetSafe(
            () => formValueErrors.street.hasError,
            false
          )}
          helperText={GetSafe(() => formValueErrors.street.message, '')}
          formValue={streetFormValue}
          formValueSetter={setStreetFormValue}
        />
      </Grid>
      <Grid item className={classes.item}>
        <GooglePlacesAutosuggestZip
          onSuggestionSelect={handleZipChange}
          presetValue={formValues.zip}
          autoSuggestError={GetSafe(() => formValueErrors.zip.hasError, false)}
          helperText={GetSafe(() => formValueErrors.zip.message, '')}
          formValue={zipFormValue}
          formValueSetter={setZipFormValue}
        />
        <GooglePlacesAutosuggestCity
          onSuggestionSelect={handleCityChange}
          presetValue={formValues.city}
          autoSuggestError={GetSafe(() => formValueErrors.city.hasError, false)}
          helperText={GetSafe(() => formValueErrors.city.message, '')}
          formValue={cityFormValue}
          formValueSetter={setCityFormValue}
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.item, classes.itemPhone, activePhone)}
        onClick={() => {
          if (activePhone === 'state-phoneField-closed') {
            setActivePhone('state-phoneField-open')
          } else {
            setActivePhone('state-phoneField-closed')
          }
        }}
      >
        <Grid item>
          <PhoneField
            id='inputPhone'
            label={`${t('form.label.phone')} (${t('general.label.optional')})`}
            variant='outlined'
            align='left'
            value={formValues.phone}
            error={GetSafe(() => formValueErrors.phone.hasError, false)}
            helperText={GetSafe(() => formValueErrors.phone.message, '')}
            onChange={handlePhoneChange}
            margin='normal'
            fullWidth
            defaultCountry='CH'
            InputProps={{ classes: { input: classes.input } }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(classes.item, classes.itemMobile, activeMobile)}
        onClick={() => {
          if (activeMobile === 'state-mobileField-closed') {
            setActiveMobile('state-mobileField-open')
          } else {
            setActiveMobile('state-mobileField-closed')
          }
        }}
      >
        <Grid item>
          <PhoneField
            id='inputMobile'
            label={`${t('form.label.mobile')} (${t('general.label.optional')})`}
            variant='outlined'
            align='left'
            value={formValues.mobile}
            error={GetSafe(() => formValueErrors.mobile.hasError, false)}
            helperText={GetSafe(() => formValueErrors.mobile.message, '')}
            onChange={handleMobileChange}
            margin='normal'
            fullWidth
            defaultCountry='CH'
            InputProps={{ classes: { input: classes.input } }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(classes.item, classes.itemFax, activeFax)}
        onClick={() => {
          if (activeFax === 'state-faxField-closed') {
            setActiveFax('state-faxField-open')
          } else {
            setActiveFax('state-faxField-closed')
          }
        }}
      >
        <PhoneField
          id='inputFax'
          label={`${t('form.label.fax')} (${t('general.label.optional')})`}
          variant='outlined'
          align='left'
          value={formValues.fax}
          error={GetSafe(() => formValueErrors.fax.hasError, false)}
          helperText={GetSafe(() => formValueErrors.fax.message, '')}
          onChange={handleFaxChange}
          margin='normal'
          fullWidth
          defaultCountry='CH'
          InputProps={{ classes: { input: classes.input } }}
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id='inputWebsite'
          className={clsx(classes.textField, classes.textFieldLong)}
          label={`${t('form.label.website')} (${t('general.label.optional')})`}
          variant='outlined'
          align='left'
          value={formValues.website}
          error={GetSafe(() => formValueErrors.website.hasError, false)}
          helperText={GetSafe(() => formValueErrors.website.message, '')}
          onChange={handleWebsiteChange}
          margin='normal'
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id='inputEmail'
          className={clsx(classes.textField, classes.textFieldLong)}
          label={`${t('form.label.email')} ${t('general.label.primary')} (${t(
            'general.label.optional'
          )})`}
          variant='outlined'
          align='left'
          value={formValues.email}
          error={GetSafe(() => formValueErrors.email.hasError, false)}
          helperText={GetSafe(() => formValueErrors.email.message, '')}
          onChange={handleEmailChange}
          margin='normal'
        />
      </Grid>

      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id='buttonFormBack'
          color='secondary'
          className={classes.buttonBack}
          onClick={handleFormBack}
        >
          {t('form.label.back')}
        </Button>
        <Button
          id='buttonFormNext'
          variant='contained'
          color='primary'
          align='left'
          className={classes.buttonNext}
          onClick={handleFormNext}
        >
          {t('form.label.next')}
        </Button>
      </Grid>
    </>
  )
}

export default withAccessControl(
  FreeToPremiumDialogueStep1,
  ACL_COMPONENTS.FREE_TO_PREMIUM_DIALOGUE_STEP_1
)
