/**
 * FormValueErrorsBuilder
 *
 * @param error
 * @param t
 * @constructor
 */
const FormValueErrorsBuilder = (error, t) => {
  const formErrors = {}
  /* eslint-disable */
  error.details.map(errorItem => {
    let type = errorItem.type.slice(
      errorItem.type.indexOf('.'),
      errorItem.type.length
    )
    let path = errorItem.path[0]
    // when checking if a checkbox is checked the type is 'invalid' so we use the path value for the text key instead
    let errorKey =
      type === '.invalid' ||
      type === '.base' ||
      type === '.only' ||
      path === 'images'
        ? '.' + path
        : type

    formErrors[path] = {
      hasError: true,
      message: t('error.form' + errorKey)
    }
  })
  /* eslint-enable */
  return formErrors
}

export default FormValueErrorsBuilder
