import React, { useState, useContext, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import NewReleases from "@material-ui/icons/NewReleases";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbDown from "@material-ui/icons/ThumbDown";
import CheckCircle from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import GlobalContext from "../../lib/GlobalContext";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import {
  FavoriteStateConditions,
  FavoriteStateConditionText,
} from "./FavoriteStateConditions";
import withAccessControl from "../HOC/AccessControl";
import { useMutation, useQuery } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import LinearProgress from "@material-ui/core/LinearProgress";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import { MailOutline, OfflinePin, RotateRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: 8,
  },
}));

const updateFavoriteMutation = `
  mutation UpdateFavorite (
    $hashID: String,
    $state: String
  ) {
    updateFavorite (HashID: $hashID, State: $state) {
      HashID
    }
  }
`;

const queryFavorites = `
  query ReadFavorites (
    $userHashID: ID,
    $filterFor: [String]
  ) {
    readFavorites(ID: $userHashID, ConstructionTypeContains: $filterFor) {
      HashID
      ProjectHashID
      Removed
      State
      Canton
      Calculated_distance
      Content
      Final_title
      Import_date
      Date
      Applied
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Phone
        Email
        Website
        Email_other
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      StateHistory {
        edges {
          node {
            Created
            State
          }
        }
      }
      Notes {
        edges {
          node {
            HashID
            Text
            Created
            Removed
          }
        }
      }
    }
  }
`;

/**
 * FavoriteStatus
 *
 * @returns {*}
 * @constructor
 */
const FavoriteStatus = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setMessage,
    user,
    setFavoriteData,
    unsetUser,
    token,
    unsetToken,
  } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formerResult, setFormerResult] = useState([]);

  const [{ fetchingUpdateFavorite }, executeUpdateFavoriteMutation] =
    useMutation(updateFavoriteMutation);

  // GraphQL query for favorites, gets triggered by calling "executeFavoritesQuery()"
  const [result, executeFavoritesQuery] = useQuery({
    query: queryFavorites,
    variables: {
      userHashID: user.getData().hashID,
      filterFor: props.filterFor,
    },
    requestPolicy: "network-only",
    pause: true,
  });

  let state = FavoriteStateConditions.ATTENTION;
  if (props.favorite.State) {
    state = props.favorite.State;
  }

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          if (formerResult.length !== 0) {
            setFormerResult([]);
          }
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readFavorites !== "undefined"
        ) {
          // Add the favorites to the global context
          setFavoriteData(result.data.readFavorites);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * save changes to state and context and close menu
   *
   * @param event
   * @param newStateCondition
   */
  const handleStatusChange = (event, newStateCondition) => {
    event.stopPropagation();

    if (state !== newStateCondition) {
      executeUpdateFavoriteMutation({
        hashID: props.favorite.HashID,
        state: newStateCondition,
      }).then((result) => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error change favorite status on FavoriteStatus",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: JSON.stringify(result.error),
              },
            });
          }
        } else {
          setMessage(MESSAGE_TYPES.INFO, t("projects.favorites.updated"));
          executeFavoritesQuery();
        }
      });
    }
    handleClose();
  };

  /**
   * open context menu
   *
   * @param event
   */
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  /**
   * close context menu
   */
  const handleClose = (event) => {
    if (event !== undefined) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  /**
   * attention icon
   */
  const statusIconAttention = () => {
    return (
      <NewReleases
        color={props.removed ? "disabled" : "primary"}
        style={{
          background: "#fff",
          fill: "#CCA752",
          borderRadius: "50%",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * checked icon
   */
  const statusIconChecked = () => {
    return (
      <CheckCircle
        color={props.removed ? "disabled" : "primary"}
        style={{
          background: "#fff",
          fill: "#B2B3A1",
          borderRadius: "50%",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * follow-up icon
   */
  const statusIconFollowUp = () => {
    return (
      <RotateRight
        color={props.removed ? "disabled" : "primary"}
        style={{
          background: "#fff",
          fill: "#FFA500",
          borderRadius: "50%",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * followed icon
   */
  const statusIconExternalApplied = () => {
    return (
      <MailOutline
        color={props.removed ? "disabled" : "primary"}
        style={{
          background: "#fff",
          fill: "#B0C2C4",
          borderRadius: "50%",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * followed icon
   */
  const statusIconFollowed = () => {
    return (
      <OfflinePin
        color={props.removed ? "disabled" : "primary"}
        style={{
          background: "#fff",
          fill: "#7196C4",
          borderRadius: "50%",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * thumb-up icon
   */
  const statusIconThumbUp = () => {
    return (
      <ThumbUp
        color={props.removed ? "disabled" : "primary"}
        style={{
          border: "2px solid #fff",
          background: "#74CC66",
          borderRadius: "50%",
          padding: "5px",
          fill: "#fff",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * thumb-down icon
   */
  const statusIconThumbDown = () => {
    return (
      <ThumbDown
        color={props.removed ? "disabled" : "primary"}
        style={{
          border: "2px solid #fff",
          background: "#CC6666",
          borderRadius: "50%",
          padding: "5px",
          fill: "#fff",
          fontSize: "32px",
        }}
      />
    );
  };

  /**
   * get the active icon based on state
   */
  const getActiveStatusIcon = () => {
    switch (state) {
      case FavoriteStateConditions.CHECKED:
        return statusIconChecked();
      case FavoriteStateConditions.FOLLOWUP:
        return statusIconFollowUp();
      case FavoriteStateConditions.FOLLOWED:
        return statusIconFollowed();
      case FavoriteStateConditions.THUMB_UP:
        return statusIconThumbUp();
      case FavoriteStateConditions.THUMB_DOWN:
        return statusIconThumbDown();
      case FavoriteStateConditions.EXTERNALAPPLIED:
        return statusIconExternalApplied();
      default:
        return statusIconAttention();
    }
  };

  /**
   * return
   */
  return (
    <>
      {props.removed ? (
        <IconButton
          className={`${classes.icon} ${props.className}`}
          aria-controls={`favorite-state-button-${props.favorite.HashID}`}
        >
          {getActiveStatusIcon()}
        </IconButton>
      ) : (
        <IconButton
          onClick={handleClick}
          className={`${classes.icon} ${props.className}`}
          aria-controls={`favorite-state-button-${props.favorite.HashID}`}
        >
          {getActiveStatusIcon()}
        </IconButton>
      )}
      <Menu
        id={`favorite-status-menu-${props.favorite.HashID}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: "50px",
          },
        }}
      >
        <MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--attention`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.ATTENTION);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconAttention()}</Grid>
            <Grid item>
              {FavoriteStateConditionText(t, FavoriteStateConditions.ATTENTION)}
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--checked`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.CHECKED);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconChecked()}</Grid>
            <Grid item lg>
              {FavoriteStateConditionText(t, FavoriteStateConditions.CHECKED)}
            </Grid>
          </Grid>
        </MenuItem>
        {user.hasApplicationFollowUpTimInDays() && <MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--followup`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.FOLLOWUP);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconFollowUp()}</Grid>
            <Grid item lg>
              {FavoriteStateConditionText(t, FavoriteStateConditions.FOLLOWUP)}
            </Grid>
          </Grid>
        </MenuItem>}
        <MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--followed`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.FOLLOWED);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconFollowed()}</Grid>
            <Grid item lg>
              {FavoriteStateConditionText(t, FavoriteStateConditions.FOLLOWED)}
            </Grid>
          </Grid>
        </MenuItem>
        {user.isPro() && (<MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--externalapplied`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.EXTERNALAPPLIED);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconExternalApplied()}</Grid>
            <Grid item lg>
              {FavoriteStateConditionText(t, FavoriteStateConditions.EXTERNALAPPLIED)}
            </Grid>
          </Grid>
        </MenuItem>)}
        <MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--thumb-up`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.THUMB_UP);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconThumbUp()}</Grid>
            <Grid item>
              {FavoriteStateConditionText(t, FavoriteStateConditions.THUMB_UP)}
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem
          id={`favorite-status-menu-item-${props.favorite.HashID}--thumb-down`}
          onClick={(event) => {
            handleStatusChange(event, FavoriteStateConditions.THUMB_DOWN);
          }}
        >
          <Grid container className={classes.root} spacing={2}>
            <Grid item>{statusIconThumbDown()}</Grid>
            <Grid item>
              {FavoriteStateConditionText(
                t,
                FavoriteStateConditions.THUMB_DOWN
              )}
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
      {fetchingUpdateFavorite && <LinearProgress />}
    </>
  );
};

export default withAccessControl(
  FavoriteStatus,
  ACL_COMPONENTS.FAVORITE_STATUS
);
