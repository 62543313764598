import React, { useContext, useState, useCallback, useEffect } from "react";
import { makeStyles, Grid, Button, Card } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import MicrositeDetailsWizardStepper from "./MicrositeDetailsWizardStepper";
import GlobalContext from "../../lib/GlobalContext";
import CompanyBusinessActivitiesForm from "../Company/CompanyBusinessActivitiesForm";
import { useMutation, useQuery } from "urql";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import LoadingOverlay from "../LoadingOverlay";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import {
  queryBranches,
  updateCompanyMutation,
  initialFormValuesState,
  initialFormValueErrorsState,
  LOADING_OVERLAY_TIMEOUT,
  schema,
} from "../Company/CompanyBasicSettingsShared";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  intro: {
    margin: 20,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#e0f6fa",
    // backgroundColor: '#fdf4e5'
  },
  introItem: {
    padding: 10,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  introContainer: {
    flexWrap: "nowrap",
  },
  item: {
    position: "relative",
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
  },
  itemStepper: {
    maxWidth: 600,
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  buttonBack: {
    width: "50%",
    color: theme.palette.primary.main,
    marginLeft: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonNext: {
    width: "50%",
    color: "#fff",
    marginRight: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "100%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    color: "#ffffff",
    width: "100%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  branchSubCategories: {
    width: "50%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  input: {
    marginLeft: 60,
  },
  checkboxControlIndented: {
    marginLeft: 20,
  },
}));

const CompanyBusinessActivitiesStep = ({ step, setStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, token, setUser, setToken, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);
  const [branchData, setBranchData] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);

  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);

  // GraphQL query for branches, gets triggered by calling "executeBranchesQuery()"
  const [result, executeBranchesQuery] = useQuery({
    query: queryBranches,
    variables: {},
    pause: true,
  });

  /**
   * executeBranchesQueryCallback
   * Implementation of https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   */
  const executeBranchesQueryCallback = useCallback(() => {
    if (!result.fetching && !branchData) {
      executeBranchesQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.fetching, token.api, branchData, executeBranchesQuery, setToken]);

  /**
   * useEffect
   */
  useEffect(() => {
    const userData = user.getData();
    setFormValues({
      id: userData.company.hashID,
      branchCategory:
        userData.company.branchCategory ||
        initialFormValuesState.branchCategory,
      branchSubs:
        userData.company.branchSubs || initialFormValuesState.branchSubs,
      otherBusinessActivities: userData.company.otherBusinessActivities,
      otherBusinessSpecialities: userData.company.otherBusinessSpecialities,
    });
    executeBranchesQueryCallback();
  }, [user, executeBranchesQueryCallback]);

  const handleButtonBack = () => {
    setStep(step - 1 || 0);
  };

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching branches on CompanyBasicSettings",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.branches"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readBusinessActivities !== "undefined" &&
          !branchData
        ) {
          // Without business branches/activities we cannot continue
          if (result.data.readBusinessActivities.length === 0) {
            throw Error("No business branches/activities found");
          }

          // Add the projects to the global context
          setBranchData(result.data.readBusinessActivities);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const handleButtonNext = () => {
    const formData = {
      hashID: user.getData().company.hashID,
      branchCategory: formValues.branchCategory,
      branchSubs: formValues.branchSubs,
      otherBusinessActivities: formValues.otherBusinessActivities,
      otherBusinessSpecialities: formValues.otherBusinessSpecialities,
    };
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      window.scrollTo(0, 0);
    } else {
      user.setData({ company: { ...formData } });
      setFormValueErrors(initialFormValueErrorsState);

      let branchSubsRefined = [];
      formValues.branchSubs.map((item) => {
        branchSubsRefined.push({
          ID: item,
        });
        return item;
      });
      if (!branchSubsRefined.length) {
        branchSubsRefined = null;
      }

      let otherBranchesRefined = [];
      formValues.otherBusinessActivities.map((item) => {
        otherBranchesRefined.push({
          ID: item,
        });
        return item;
      });
      if (!otherBranchesRefined.length) {
        otherBranchesRefined = null;
      }

      let otherBusinessSpecialitiesRefined = [];
      formValues.otherBusinessSpecialities.map((item) => {
        otherBusinessSpecialitiesRefined.push({
          ID: item,
        });
        return item;
      });
      if (!otherBusinessSpecialitiesRefined.length) {
        otherBusinessSpecialitiesRefined = null;
      }

      const showLoadingOverlayTimeout = setTimeout(
        () => setLoadingOverlayOpen(true),
        LOADING_OVERLAY_TIMEOUT
      );

      executeCompanyUpdateMutation({
        hashId: user.getData().company.hashID,
        businessActivityID: formValues.branchCategory,
        businessSpecialties: branchSubsRefined,
        otherBusinessActivities: otherBranchesRefined,
        otherBusinessSpecialities: otherBusinessSpecialitiesRefined,
      }).then((result) => {
        clearTimeout(showLoadingOverlayTimeout);
        setLoadingOverlayOpen(false);

        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyBusinessActivities",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          setStep((prevActiveStep) => prevActiveStep + 1);
          setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
          setUser(user);
        }
      });
    }
  };

  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>
          {t("microsite.details.wizard.step.3.title")}
        </h1>
        <Card variant="outlined" className={classes.intro}>
          <Grid
            container
            className={classes.introContainer}
            alignItems="center"
          >
            <Grid item className={classes.introItem}>
              <InfoOutlinedIcon className={classes.icon} />
            </Grid>
            <Grid item className={classes.introItem}>
              {t("microsite.details.wizard.step.3.intro")}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <MicrositeDetailsWizardStepper step={step} />
      </Grid>
      <Grid item className={classes.item}>
        <CompanyBusinessActivitiesForm
          user={user}
          token={token}
          classes={classes}
          formValues={formValues}
          setFormValues={setFormValues}
          formValueErrors={formValueErrors}
          setFormValueErrors={setFormValueErrors}
          initialFormValueErrorsState={initialFormValueErrorsState}
          branchData={branchData}
          setBranchData={setBranchData}
          result={result}
        />
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id="buttonFormBack"
          color="secondary"
          className={classes.buttonBack}
          onClick={handleButtonBack}
          disabled={companyIsUpdating}
        >
          {t("form.label.back")}
        </Button>
        <Button
          id="buttonFormNext"
          variant="contained"
          color="primary"
          align="left"
          className={classes.buttonNext}
          onClick={handleButtonNext}
          disabled={companyIsUpdating}
        >
          {t("form.label.next")}
          {companyIsUpdating && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        {loadingOverlayOpen && (
          <LoadingOverlay text={t("general.label.be.ready.soon")} />
        )}
      </Grid>
    </>
  );
};

export default CompanyBusinessActivitiesStep;
