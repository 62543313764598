import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Star from "@material-ui/icons/Star";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Info from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import GlobalContext from "../../lib/GlobalContext";
import { MESSAGE_TYPES } from "../PopupMessages";
import GoogleMapComponent from "../../lib/GoogleMapComponent";
import withAccessControl from "../HOC/AccessControl";
import GetSafe from "../../lib/GetSafe";
import FormatHelper from "../../lib/FormatHelper";
import PHE from "print-html-element";
import { renderToString } from "react-dom/server";
import { Table, TableBody, TableHead } from "@material-ui/core";
import { useMutation } from "urql";
import LinearProgress from "@material-ui/core/LinearProgress";
import { A, navigate } from "hookrouter";
import Typography from "@material-ui/core/Typography";
import { ACL_COMPONENTS, DEFAULT_LOGO_PATH } from "../../config";
import Grid from "@material-ui/core/Grid";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import { BUILDING_APPLICATION_TYPE } from "../BuildingApplicationTypeTabs";
import CardMembershipIcon from "@material-ui/icons/CardMembership";

const useStyles = makeStyles((theme) => ({
  airDistance: {
    // backgroundColor: '#e3e3e3',
    padding: 5,
  },
  icon: {
    padding: 8,
    fontSize: 25,
  },
  tableCellColumnsbody: {
    width: "calc(100% * 1 / 5)",
    minWidth: 185,
    padding: 14,
    // '&:nth-child(1)': {
    //   minWidth: 215
    // },
    "&:nth-child(2)": {
      minWidth: 520,
    },
    "&:nth-child(3)": {
      minWidth: 130,
    },
    "@media (max-width:640px)": {
      width: "auto",
      "&:first-of-type": {
        minWidth: "auto",
      },
      "&:nth-child(2)": {
        minWidth: 320,
      },
    },
  },
  toggleTableRow: {
    borderTop: "2px solid #fff",
  },
  toggleTableCell: {
    paddingTop: 0,
    verticalAlign: "top",
  },
  toggleTableCellMap: {
    paddingLeft: 205,
    "@media (max-width:640px)": {
      paddingLeft: 20,
    },
  },
  toggleTitle: {
    color: "#999",
    fontWeight: "bold",
  },
  newLabel: {
    color: "#BD3A00",
    fontWeight: "bold",
  },
  tooltipItem: {
    width: "100%",
    maxWidth: 350,
    borderRadius: 5,
    background: "#006A7A",
    fontSize: 16,
    textAlign: "center",
    "& div": {
      margin: 0,
      color: "#fff",
    },
  },
  tooltipInfo: {
    maxWidth: "100%",
    background: theme.palette.background.default,
    "& div": {
      maxWidth: "100%",
      color: theme.palette.text.primary,
      fontSize: 12,
      lineHeight: "20px",
    },
    "@media (min-width:768px)": {
      maxWidth: 450,
    },
  },
  tooltipTitle: {
    display: "flex",
    alignItems: "center",
  },
  pointer: {
    cursor: "pointer",
  },
  headerWrapper: {
    width: "100%",
    maxWidth: 1320,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: "#4ab3cf",
    "-webkit-print-color-adjust": "exact",
  },
  logo: {
    paddingLeft: 0,
  },
  submissionIcon: {
    paddingTop: 5,
    paddingRight: 4,
    position: "relative",
    top: 5,
    color: theme.palette.text.secondary,
  },
}));

const createFavoriteMutation = `
  mutation CreateFavorite (
    $projectHashID: String
  ) {
    createFavorite (ProjectHashID: $projectHashID) {
      HashID
      ProjectHashID
    }
  }
`;

const updateFavoriteMutation = `
  mutation UpdateFavorite (
    $hashID: String,
    $removed: Boolean
  ) {
    updateFavorite (HashID: $hashID, Removed: $removed) {
      HashID
    }
  }
`;

/**
 * ProjectsFreeTableRow
 *
 * @returns {*}
 * @constructor
 */
const ProjectsTableRow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen,
    setMessage,
    token,
    unsetUser,
    unsetToken,
    setFavoriteData,
    user,
  } = useContext(GlobalContext);
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteID, setFavoriteID] = useState(null);

  const [{ fetchingCreateFavorite }, executeCreateFavoriteMutation] =
    useMutation(createFavoriteMutation);
  const [{ fetchingUpdateFavorite }, executeUpdateFavoriteMutation] =
    useMutation(updateFavoriteMutation);

  /**
   * mark favorites useEffect
   */
  useEffect(() => {
    if (props.favorites !== null) {
      const result = props.favorites.filter((item) => {
        return item.ProjectHashID === row.HashID;
      });

      if (result.length) {
        setFavoriteID(result[0].HashID);
        if (!result[0].Removed) {
          setIsFavorite(true);
        }
      }
    }
  }, [props.favorites, setIsFavorite, row.HashID]);

  /**
   * open context menu
   *
   * @param event
   */
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  /**
   * close context menu
   */
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  /**
   * updateFavoriteExecution
   *
   * @param removed
   */
  const updateFavoriteExecution = (removed = true) => {
    executeUpdateFavoriteMutation({
      hashID: favoriteID,
      removed: removed,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error update favorite on ProjectsSearchTableRow",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.INFO, t("projects.favorites.updated"));
        setIsFavorite(!removed);
      }
    });
  };

  /**
   * handleMakeFavorite
   */
  const handleMakeFavorite = (event) => {
    event.stopPropagation();
    setFavoriteData([]);
    executeCreateFavoriteMutation({
      projectHashID: row.HashID,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          if (result.error.message.includes("already exists")) {
            updateFavoriteExecution(false);
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error save new favorite on ProjectsSearchTableRow",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        }
      } else {
        setMessage(MESSAGE_TYPES.INFO, t("projects.add.to.favorites"));
        setIsFavorite(true);
        setFavoriteID(result.data.createFavorite[0].HashID);
      }
    });
  };

  /**
   * handleRemoveFavorite
   */
  const handleRemoveFavorite = (event) => {
    event.stopPropagation();
    setFavoriteData([]);
    updateFavoriteExecution(true);
  };

  /**
   * handlePrint
   */
  const handlePrint = (event) => {
    event.stopPropagation();
    PHE.printHtml(renderToString(renderPrintAndShare()));
  };

  /**
   * showDistance
   *
   * @returns {*}
   */
  const showDistance = () => {
    if (row.Distance_calculated_from === "Air_distance") {
      return (
        <Tooltip
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
          title={t("tooltip.distance.not.calculated")}
          aria-label={t("tooltip.distance.not.calculated")}
          arrow
        >
          <span className={classes.airDistance}>
            {GetSafe(
              () =>
                FormatHelper.formatDistance(row.Calculated_distance) + " km",
              ""
            )}
          </span>
        </Tooltip>
      );
    } else if (
      row.Distance_calculated_from === "Travel_distance" &&
      row.Calculated_distance === null
    ) {
      return (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={t("tooltip.distance.could.not.be.calculated")}
          aria-label={t("tooltip.distance.could.not.be.calculated")}
          arrow
        >
          <span className={classes.airDistance}>-</span>
        </Tooltip>
      );
    } else if (
      row.Distance_calculated_from !== "Air_distance" &&
      row.Calculated_distance !== null
    ) {
      return GetSafe(
        () => FormatHelper.formatDistance(row.Calculated_distance) + " km",
        ""
      );
    }
  };

  /**
   * renderApprovalDate
   *
   * @returns {false|JSX.Element}
   */
  const renderApprovalDate = () => {
    return (
      row.Approval_date !== undefined &&
      row.Approval_date !== "" &&
      row.Approval_date !== null && (
        <p>
          {user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.expected.date")}
              </span>
              <span className={classes.tooltipTitle}>{row.Approval_date}</span>
            </span>
          )}
          {!user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.expected.date")}
              </span>
              <span
                className={clsx(classes.tooltipTitle, classes.pointer)}
                onClick={handleUpgradeToPremium}
              >
                {t("projects.free.route.premium.pro")}
              </span>
            </span>
          )}
        </p>
      )
    );
  };

  /**
   * renderConstructionSum
   *
   * @returns {false|JSX.Element}
   */
  const renderConstructionSum = () => {
    return (
      row.Construction_sum !== undefined &&
      row.Construction_sum !== "" &&
      row.Construction_sum !== null && (
        <p>
          {user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.construction.sum")}
              </span>
              <span className={classes.tooltipTitle}>
                {row.Construction_sum}
              </span>
            </span>
          )}
          {!user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.construction.sum")}
              </span>
              <span
                className={clsx(classes.tooltipTitle, classes.pointer)}
                onClick={handleUpgradeToPremium}
              >
                {t("projects.free.route.premium.pro")}
              </span>
            </span>
          )}
        </p>
      )
    );
  };

  /**
   * handleUpgradeToPremium
   */
  const handleUpgradeToPremium = () => {
    setUpgradeToPremiumOpen(!upgradeToPremiumOpen);
  };

  /**
   * getDestinationCoordinates
   *
   * @returns {{lon: *, lat: *}}
   */
  const getDestinationCoordinates = () => {
    return {
      lat: GetSafe(() => parseFloat(row.Construction_site.Address.Geo.lat), 0),
      lon: GetSafe(() => parseFloat(row.Construction_site.Address.Geo.lon), 0),
    };
  };

  /**
   * handleOpenDetailsClick
   */
  const handleOpenDetailsClick = () => {
    setOpen(!open);
  };

  /**
   * isProcessable
   *
   * @param {*} value
   */
  const isProcessable = (value) => {
    return !(value === null || value === undefined);
  };

  /**
   * renderPrintAndShare
   **/
  const renderPrintAndShare = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerWrapper} colSpan={4}>
              <Grid container justify="space-between">
                <Grid item xs={12} sm="auto">
                  <A href="/" className={classes.logo}>
                    <img src={DEFAULT_LOGO_PATH} alt="logo" />
                  </A>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{GetSafe(() => row.Final_title, "")}</TableCell>
            <TableCell>
              {GetSafe(() => row.Construction_site.Address.City, "")}{" "}
              {GetSafe(() => row.Construction_site.Address.Street, "")}{" "}
              {GetSafe(() => row.Construction_site.Address.Number, "")}
            </TableCell>
            <TableCell>
              {GetSafe(
                () =>
                  FormatHelper.formatDistance(row.Calculated_distance) + " km",
                ""
              )}
            </TableCell>
            <TableCell>
              {GetSafe(() => FormatHelper.formatDate(row.Date), "")}
            </TableCell>
          </TableRow>
          <TableRow id={`project-details-${row.HashID}`}>
            {/* <TableCell> */}
            {/* <p className={classes.toggleTitle}>{t('projects.label.fastest.route')}</p> */}
            {/* <GoogleMapComponent */}
            {/*  origin={getOriginCoordinates()} */}
            {/*  destination={getDestinationCoordinates()} */}
            {/* /> */}
            {/* </TableCell> */}
            <TableCell>
              <p className={classes.toggleTitle}>{t("projects.label.owner")}</p>
              <p>
                {GetSafe(() => row.Building_owner.Name, "")}
                <br />
                {GetSafe(() => row.Building_owner.Address.Street, "")}{" "}
                {GetSafe(() => row.Building_owner.Address.Number, "")}
                <br />
                {GetSafe(() => row.Building_owner.Address.Zip, "")}{" "}
                {GetSafe(() => row.Building_owner.Address.City, "")}
              </p>
            </TableCell>
            <TableCell colSpan={3}>
              <p className={classes.toggleTitle}>
                {t("projects.label.architect")}
              </p>
              <p>
                {GetSafe(() => row.Project_management.Name, "")}
                <br />
                {GetSafe(() => row.Project_management.Address.Street, "")}{" "}
                {GetSafe(() => row.Project_management.Address.Number, "")}
                <br />
                {GetSafe(() => row.Project_management.Address.Zip, "")}{" "}
                {GetSafe(() => row.Project_management.Address.City, "")}
                <br />
                {GetSafe(() => row.Project_management.Phone, "")}
                <br />
                {GetSafe(() => row.Project_management.Email, "")}
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>{row.Content}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  /**
   * render the detail view of a project
   */
  const projectDetails = () => {
    return (
      <>
        <TableRow
          id={`project-details-${row.HashID}`}
          className={classes.toggleTableRow}
        >
          <TableCell
            className={clsx(
              classes.toggleTableCell,
              classes.toggleTableCellMap
            )}
            colSpan={2}
          >
            <p className={classes.toggleTitle}>
              {t("projects.label.fastest.route")}
            </p>
            <GoogleMapComponent
              origin={props.originCoordinates}
              destination={getDestinationCoordinates()}
              location={props.location}
            />
          </TableCell>
          <TableCell className={classes.toggleTableCell}>
            <p className={classes.toggleTitle}>{t("projects.label.owner")}</p>
            <p>
              {GetSafe(() => row.Building_owner.Name, "")}
              <br />
              {GetSafe(() => row.Building_owner.Address.Street, "")}{" "}
              {GetSafe(() => row.Building_owner.Address.Number, "")}
              <br />
              {GetSafe(() => row.Building_owner.Address.Zip, "")}{" "}
              {GetSafe(() => row.Building_owner.Address.City, "")}
            </p>
            {renderApprovalDate()}
            {renderConstructionSum()}
            {/* <p> */}
            {/*  <Error style={{ fontSize: 30 }} color='primary' /> */}
            {/*  Original Baugesuch */}
            {/* </p> */}
          </TableCell>
          <TableCell className={classes.toggleTableCell} colSpan={2}>
            <p className={classes.toggleTitle}>
              {t("projects.label.architect")}
            </p>
            <p>
              {GetSafe(() => row.Project_management.Name, "")}
              <br />
              {GetSafe(() => row.Project_management.Address.Street, "")}{" "}
              {GetSafe(() => row.Project_management.Address.Number, "")}
              <br />
              {GetSafe(() => row.Project_management.Address.Zip, "")}{" "}
              {GetSafe(() => row.Project_management.Address.City, "")}
              <br />
              {isProcessable(
                GetSafe(() => row.Project_management.Phone, "")
              ) && (
                <a
                  href={
                    "tel:" + GetSafe(() => row.Project_management.Phone, "")
                  }
                >
                  {GetSafe(() => row.Project_management.Phone, "")}
                </a>
              )}
              <br />
              {isProcessable(
                GetSafe(() => row.Project_management.Email, "")
              ) && (
                <a
                  href={
                    "mailto:" + GetSafe(() => row.Project_management.Email, "")
                  }
                >
                  {GetSafe(() => row.Project_management.Email, "")}
                </a>
              )}
            </p>
          </TableCell>
        </TableRow>
        <TableRow className={classes.toggleTableRow}>
          <TableCell colSpan={2} className={classes.toggleTableCell} />
          <TableCell colSpan={3} className={classes.toggleTableCell}>
            {row.Type === BUILDING_APPLICATION_TYPE.SUBMISSION ? (
              <p>
                <span className={classes.toggleTitle}>
                  {t("favorite.submission.id.label")}
                </span>
                <br />
                {row.Notice_number}
              </p>
            ) : null}
            {row.Type !== BUILDING_APPLICATION_TYPE.SUBMISSION ? (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={row.Content}
                PopperProps={{
                  className: clsx(classes.tooltipItem, classes.tooltipInfo),
                }}
              >
                <span className={classes.tooltipTitle}>
                  <Info style={{ fontSize: 30 }} color="primary" />
                  {t("projects.label.original.project")}
                </span>
              </Tooltip>
            ) : null}
          </TableCell>
        </TableRow>
      </>
    );
  };

  /**
   * return
   */
  return (
    <>
      {(fetchingCreateFavorite || fetchingUpdateFavorite) && <LinearProgress />}
      <TableRow onClick={handleOpenDetailsClick} className={classes.pointer}>
        <TableCell className={classes.tableCellColumnsbody}>
          <IconButton
            id={`project-item-details-toggler-${row.HashID}`}
            onClick={() => setOpen(!open)}
            className={classes.icon}
          >
            {open ? (
              <ExpandLess style={{ fontSize: 30 }} color="primary" />
            ) : (
              <ExpandMore style={{ fontSize: 30 }} color="primary" />
            )}
          </IconButton>
          {isFavorite ? (
            <IconButton
              id={`project-item-make-favorite-${row.HashID}`}
              onClick={handleRemoveFavorite}
              className={classes.icon}
            >
              <Star style={{ fontSize: 30 }} color="primary" />
            </IconButton>
          ) : (
            <Tooltip
              arrow
              classes={{ tooltip: classes.tooltip }}
              title={t("projects.label.save.favorite")}
              aria-label={t("projects.label.save.favorite")}
            >
              <IconButton
                id={`project-item-make-favorite-${row.HashID}`}
                onClick={handleMakeFavorite}
                className={classes.icon}
              >
                <StarBorder style={{ fontSize: 30 }} color="primary" />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            aria-controls={`project-menu-${row.HashID}`}
            className={classes.icon}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHoriz style={{ fontSize: 30 }} color="primary" />
          </IconButton>
          <Menu
            id={`project-menu-${row.HashID}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: "42px",
              },
            }}
          >
            {/* <MenuItem */}
            {/*  id={`project-menu-item-${row.HashID}--share`} */}
            {/*  onClick={handleShare} */}
            {/* > */}
            {/*  {t('general.label.forward')} */}
            {/* </MenuItem> */}
            <MenuItem
              id={`project-menu-item-${row.HashID}--print`}
              onClick={handlePrint}
            >
              {t("general.label.print")}
            </MenuItem>
          </Menu>
        </TableCell>
        <TableCell
          className={classes.tableCellColumnsbody}
          style={{
            fontSize: 16,
          }}
        >
          {row.Type === BUILDING_APPLICATION_TYPE.SUBMISSION ? (
            <Tooltip
              title={t("submissions.label.singular")}
              aria-label={t("submissions.label.singular")}
              arrow
            >
              <CardMembershipIcon
                className={classes.submissionIcon}
                color="secondary"
              />
            </Tooltip>
          ) : null}
          {GetSafe(() => row.Final_title, "")}
        </TableCell>
        <TableCell className={classes.tableCellColumnsbody}>
          {GetSafe(() => row.Construction_site.Address.City, "")}{" "}
          {GetSafe(() => row.Construction_site.Address.Street, "")}{" "}
          {GetSafe(() => row.Construction_site.Address.Number, "")}
        </TableCell>
        <TableCell className={classes.tableCellColumnsbody}>
          {showDistance()}
        </TableCell>
        <TableCell className={classes.tableCellColumnsbody}>
          {row.Read !== null ? (
            GetSafe(() => FormatHelper.formatDate(row.Date), "")
          ) : (
            <Typography className={classes.newLabel}>
              {t("general.label.new")}
            </Typography>
          )}
        </TableCell>
      </TableRow>
      {open ? projectDetails() : null}
    </>
  );
};

export default withAccessControl(
  ProjectsTableRow,
  ACL_COMPONENTS.PROJECTS_TABLE_ROW
);
