import React, { useEffect, useState } from 'react'
import { navigate } from 'hookrouter'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Divider from '@material-ui/core/Divider'
import DossierBasicSettings from '../components/Dossier/DossierBasicSettings'
import DossierInfo from '../components/Dossier/DossierInfo'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tabs: {
    fontSize: 14,
    height: '100%',
    width: '100%'
  },
  tab: {
    height: 64,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#525252'
  },
  back: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#525252',
    minWidth: 50,
    width: 50
  }
}))

const Dossier = ({ id, subPage }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [dossierTabValue, setDossierTabValue] = useState(0)
  /**
   * useEffect
   */
  useEffect(() => {
    switch (subPage) {
      case 'references':
        setDossierTabValue(3)
        console.log('REFERENCES')
        break
      case 'activities':
        setDossierTabValue(2)
        console.log('ACTIVITIES')
        break
      case 'info':
        setDossierTabValue(1)
        console.log('INFO')
        break
      case 'profile':
        setDossierTabValue(0)
        console.log('PROFILE')
        break
      default:
        navigate('/dossier/' + id + '/profile')
        setDossierTabValue(0)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * handleTabChange
   *
   * @param event
   * @param newValue
   */
  const handleTabChange = (event, newValue) => {
    setDossierTabValue(newValue)
    switch (newValue) {
      case 99:
        navigate('/dossier-management')
        break
      case 3:
        navigate('/dossier/' + id + '/references')
        break
      case 2:
        navigate('/dossier/' + id + '/activities')
        break
      case 1:
        navigate('/dossier/' + id + '/info')
        break
      case 0:
      default:
        navigate('/dossier/' + id + '/profile')
        break
    }
  }

  /**
   * getContentRelatedToActiveTab
   */
  const getContentRelatedToActiveTab = () => {
    switch (dossierTabValue) {
      case 3:
        // return <CompanyReferences />
        return 'REFERENCES'
      case 2:
        return 'ACTIVITIES'
      case 1:
        return <DossierInfo id={id} />
      case 0:
      default:
        return <DossierBasicSettings id={id} />
    }
  }

  /**
   * return
   */
  return (
    <Paper className={classes.root}>
      <Tabs
        value={dossierTabValue}
        className={classes.tabs}
        indicatorColor='primary'
        onChange={handleTabChange}
      >
        <Tab id='back' label='<' value={99} className={classes.back} />
        <Tab
          id='companyLocationProfileTab'
          label={t('company.label.profile')}
          value={0}
          className={classes.tab}
        />
        <Tab
          id='companyLocationInfoTab'
          label={t('company.label.info')}
          value={1}
          className={classes.tab}
        />
        <Tab
          id='companyLocationBusinessActivities'
          label={t('company.label.businessActivities')}
          value={2}
          className={classes.tab}
        />
        <Tab
          id='companyLocationReferencesTab'
          label={t('company.label.references')}
          value={3}
          className={classes.tab}
        />
      </Tabs>
      <Divider />
      {getContentRelatedToActiveTab()}
    </Paper>
  )
}

export default Dossier
