import { useQuery } from "urql";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GlobalContext from "../lib/GlobalContext";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import LinearProgress from "@material-ui/core/LinearProgress";
import { navigate } from "hookrouter";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { apiTld, companyDirectoryPath } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableHead: {
    width: "100%",
    height: 64,
    background: "#F7F9FA",
    // cursor: 'pointer'
  },
  tableCellHeader: {
    position: "relative",
    height: 64,
    fontWeight: "bold",
    paddingLeft: 24,
  },
  tableColumnsHead: {
    height: 96,
  },
  tableCellColumnsHeader: {
    fontSize: theme.typography.fontSize,
    color: "#999999",
    fontWeight: "bold",
    paddingLeft: 24,
  },
  tableCell: {
    height: 64,
  },
}));

const queryCompanyLocations = `
query ReadCompanyLocations (
  $companyHashId: String
) {
  readCompanyLocations(CompanyHashID: $companyHashId, Locale: "all") { 
    HashID
    TemplateName
    Name
    Email
    ContactPerson
    Locale
  }
}`;

const DossierManagement = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { token, setToken, user, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);
  const [companyLocations, setCompanyLocations] = useState(null);
  const [showNoResultMessage, setShowNoResultMessage] = useState(false);
  const columns = [
    {
      id: "company_location",
      label: t("general.label.company.location"),
      minWidth: 100,
    },
    {
      id: "template_name",
      label: t("general.label.template.name"),
      minWidth: 100,
    },
    {
      id: "contact_person",
      label: t("general.label.contact.person"),
      minWidth: 100,
    },
    {
      id: "contact_email",
      label: t("form.label.email"),
      minWidth: 100,
    },
    {
      id: "location_hashId",
      label: t("general.label.hashId"),
      minWidth: 100,
    },
    {
      id: "location_language",
      label: t("general.label.language"),
      minWidth: 100,
    },
  ];

  const [companyLocationsResult, executeCompanyLocationsQuery] = useQuery({
    query: queryCompanyLocations,
    variables: {
      companyHashId: user.getData().company.hashID,
    },
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * fetch data useEffect
   */
  useEffect(() => {
    executeCompanyLocationsQuery();
  }, [executeCompanyLocationsQuery, setToken, token.api]);

  // GraphQL trigger and companyLocationsResult handling
  useEffect(() => {
    if (!companyLocationsResult.fetching) {
      if (companyLocationsResult.error) {
        // Check if the user need to be logged out
        if (
          companyLocationsResult.error.message.indexOf("User forced logout") !==
            -1 ||
          companyLocationsResult.error.message.indexOf(
            "The resource owner or authorization server denied the request"
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // // The query did not return any results!
          if (!showNoResultMessage) {
            setShowNoResultMessage(true);
          }
        }
      } else {
        // Query not fetching right now
        if (
          typeof companyLocationsResult.data !== "undefined" &&
          typeof companyLocationsResult.data.readCompanyLocations !== "undefined"
        ) {
          if (companyLocations === null) {
            setCompanyLocations(companyLocationsResult.data);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyLocationsResult])

  /**
   * handleCompanyLocationDetailClick
   *
   * @param {string} id
   */
  // const handleCompanyLocationDetailClick = id => {
  //   navigate('/dossier/' + id + '/profile')
  // }

  /**
   * handlePreviewButtonClick
   *
   * @param {string} companyLocationHashId
   */
  const handlePreviewButtonClick = (companyLocationHashId) => {
    if (user.getData().company.urlSegment) {
      window.open(
        apiTld +
          companyDirectoryPath +
          user.getData().company.urlSegment +
          "/template/" +
          companyLocationHashId,
        "_blank"
      );
    } else {
      setMessage(MESSAGE_TYPES.ERROR, t("error.no.microsite"));
    }
  };

  /**
   * return
   */
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableColumnsHead}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ maxWidth: column.maxWidth }}
                  className={classes.tableCellColumnsHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!companyLocationsResult.fetching && showNoResultMessage && (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{
                    paddingTop: 32,
                    paddingBottom: 32,
                    fontSize: 16,
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  {t("general.label.no.dossiers")}
                </TableCell>
              </TableRow>
            )}
            {!companyLocationsResult.fetching &&
              companyLocations !== null &&
              companyLocations.readCompanyLocations.map((companyLocation) => {
                return (
                  <TableRow
                    className={classes.tableHead}
                    // onClick={() =>
                    //   handleCompanyLocationDetailClick(companyLocation.HashID)
                    // }
                    key={companyLocation.HashID}
                  >
                    <TableCell align="left" className={classes.tableCellHeader}>
                      {companyLocation.Name}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellHeader}>
                      {companyLocation.TemplateName}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellHeader}>
                      {companyLocation.ContactPerson}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellHeader}>
                      {companyLocation.Email}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellHeader}>
                      {companyLocation.HashID}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellHeader}>
                      {companyLocation.Locale !== undefined
                        ? companyLocation.Locale.split("_")[0]
                        : ""}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCellHeader}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handlePreviewButtonClick(companyLocation.HashID)
                        }
                      >
                        {t("general.label.preview")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {companyLocationsResult.fetching && (
              <React.Fragment key={0}>
                <TableRow>
                  <TableCell
                    colSpan="6"
                    component="th"
                    scope="row"
                    className={classes.tableRows}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default DossierManagement;
