import React, { useContext, useMemo } from "react";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { CHARTJS_STATISTIC_OPTIONS } from "../../config";
import { useQuery } from "urql";
import getSafe from "../../lib/GetSafe";

ChartJS.register(CategoryScale);
ChartJS.register(LinearScale);
ChartJS.register(BarElement);
ChartJS.register(Title);
ChartJS.register(Tooltip);
ChartJS.register(Legend);
ChartJS.register(zoomPlugin);

let BarOptions = CHARTJS_STATISTIC_OPTIONS;

const queryReadStatisticSearchArea = `
  query ReadStatisticSearchArea ($companyHashID: String!, $buildingApplicationType: String!)
  {
    readStatisticSearchArea (
      CompanyHashID: $companyHashID,
      BuildingApplicationType: $buildingApplicationType
    ) {
      labels
      datasets {
          label
          data
          backgroundColor
      }
    }
  }
`;
/**
 * Submissions
 *
 * @returns {*}
 * @constructor
 */
const Submissions = () => {
  const { t /* , i18n */ } = useTranslation();
  const { user } = useContext(GlobalContext);

  // GraphQL query for statistic data
  const [queryReadStatisticSearchAreaResult] = useQuery({
    query: queryReadStatisticSearchArea,
    variables: {
      companyHashID: user.getData().company.hashID,
      buildingApplicationType: "Submission",
    },
    requestPolicy: "cache-and-network",
  });

  BarOptions = useMemo(() => {
    return JSON.parse(
      JSON.stringify(CHARTJS_STATISTIC_OPTIONS).replace(
        "[[:TITLE_TEXT_PLACEHOLDER:]]",
        t("statistics.selector.submissions")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    queryReadStatisticSearchAreaResult.fetching ||
    !getSafe(
      () => queryReadStatisticSearchAreaResult.data.readStatisticSearchArea,
      false
    )
  ) {
    return <LinearProgress />;
  }

  if (
    queryReadStatisticSearchAreaResult.data.readStatisticSearchArea.datasets
      .length === 0
  ) {
    return (
      <div style={{ textAlign: "left" }}>
        {t("statistics.selector.submissions")}: Keine Daten
      </div>
    );
  }

  return user.getData().company.adminUserID === user.getData().id ? (
    <Bar
      options={BarOptions}
      data={queryReadStatisticSearchAreaResult.data.readStatisticSearchArea}
    />
  ) : null;
};

export default Submissions;
