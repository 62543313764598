import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { AsYouType } from 'libphonenumber-js'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import ReactCustomFlagSelect from 'react-custom-flag-select'
import 'react-custom-flag-select/lib/react-custom-flag-select.min.css'
import { PHONE_COUNTRIES } from '../config'

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%'
    // maxWidth: 330
  },
  itemImage: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: 60,
    padding: 0,
    transform: 'translateY(-20%)',
    zIndex: 1
  },
  itemField: {
    width: '100%',
    // maxWidth: 270,
    padding: 0
  },
  itemTextField: {
    width: '100%'
  },
  flagSelect: {
    '&::focus': {
      outline: 'none'
    }
  },
  flagListContainer: {
    position: 'absolute'
  },
  flagListItem: {
    '&:hover': {
      background: '#f2f3f4',
      color: theme.palette.text.primary
    },
    '& div': {
      flex: '1 1 50%',
      justifyContent: 'flex-start',
      padding: '0 7px',
      textAlign: 'left'
    },
    '& img': {
      borderRadius: 3
    }
  },
  // NOTICE:
  // General country-flag styling must be made via custom attributes
  // on the ReactCustomFlagSelect-element in the render-return-function
  flagArrow: {
    display: 'inline-block',
    '&::after': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      right: 4,
      width: 0,
      borderColor: '#e3e3e3 transparent',
      borderStyle: 'solid',
      borderWidth: '5px 5px 0',
      transform: 'translateY(-50%)',
      content: '""'
    }
  }
}))

/**
 * PhoneField
 *
 * @returns {*}
 * @constructor
 */
const PhoneField = props => {
  const classes = useStyles()

  const { defaultCountry, ...textFieldProps } = props

  const [countryState, setCountryState] = useState(defaultCountry || '')
  const [value, setValue] = useState('')

  /**
   * useEffect
   */
  useEffect(() => {
    preSelectCountry(props.value)
  }, [props.value])

  /**
   * useEffect
   */
  useEffect(() => {
    setValue(
      /* Phone-number lib: https://www.npmjs.com/package/libphonenumber-js */
      new AsYouType().input(
        `${getCountryByCode(countryState).phonePrefix}${filterOutCountryPrefix(
          props.value
        )}`
      )
    )
  }, [props.value, countryState])

  /**
   * getCountryByCode
   * Returns phone-settings for country
   */
  const getCountryByCode = code => {
    for (let i = 0; i < PHONE_COUNTRIES.length; i++) {
      if (PHONE_COUNTRIES[i].name === code) {
        return PHONE_COUNTRIES[i]
      }
    }
    return false
  }

  /**
   * getCountryById
   * Returns phone-settings for country
   */
  const getCountryById = id => {
    for (let i = 0; i < PHONE_COUNTRIES.length; i++) {
      if (PHONE_COUNTRIES[i].id === id) {
        return PHONE_COUNTRIES[i]
      }
    }
    return false
  }

  /**
   * onCountryChange
   * Handles country-change
   */
  const onCountryChange = countryId => {
    setCountryState(getCountryById(countryId).name)
  }

  /**
   * preSelectCountry
   * Pre-selection of country by matching country-phone-prefix
   */
  const preSelectCountry = phoneNumber => {
    if (phoneNumber.indexOf('+') !== -1) {
      for (let i = 0; i < PHONE_COUNTRIES.length; i++) {
        if (phoneNumber.indexOf(PHONE_COUNTRIES[i].phonePrefix) !== -1) {
          setCountryState(PHONE_COUNTRIES[i].name)
          break
        }
      }
    }
  }

  /**
   * filterOutCountryPrefix
   * Removes the country-phone-prefix from a phone-number
   */
  const filterOutCountryPrefix = phoneNumber => {
    if (phoneNumber.indexOf('+') !== -1) {
      for (let i = 0; i < PHONE_COUNTRIES.length; i++) {
        if (phoneNumber.indexOf(PHONE_COUNTRIES[i].phonePrefix) !== -1) {
          return phoneNumber.replace(PHONE_COUNTRIES[i].phonePrefix, '')
        }
      }
      return ''
    }
    return phoneNumber
  }

  /**
   * return
   */
  return (
    <div>
      <Box className={classes.item}>
        <Box className={classes.itemImage}>
          {/* See for additional style-attributes and doc: https://github.com/edwardfhsiao/react-custom-flag-select */}
          <ReactCustomFlagSelect
            tabIndex='1'
            id={`${props.id}-flags` || 'phoneField-flags'}
            name={`${props.id}-flags` || 'phoneField-flags'}
            value={getCountryByCode(countryState).id}
            disabled={props.disabled}
            animate
            optionList={PHONE_COUNTRIES}
            classNameSelect={classes.flagSelect}
            classNameOptionListContainer={classes.flagListContainer}
            classNameOptionListItem={classes.flagListItem}
            customStyleContainer={{ border: 'none', fontSize: '14px' }}
            customStyleSelect={{
              maxWidth: '60px',
              maxHeight: '60%'
            }}
            // Select List Styles
            customStyleOptionListContainer={{
              maxHeight: '200px',
              overflow: 'auto',
              width: '120px',
              marginTop: '11px'
            }}
            onChange={onCountryChange}
            selectHtml={
              <span className={classes.flagArrow}>
                <img
                  style={{ width: 25, borderRadius: 3 }}
                  src={getCountryByCode(countryState).flag}
                  title='Country-Flag'
                  alt='Country-Flag'
                />
              </span>
            }
          />
        </Box>
        <Box className={classes.itemField}>
          <TextField
            className={classes.itemTextField}
            {...textFieldProps}
            value={value}
          />
        </Box>
      </Box>
    </div>
  )
}

export default PhoneField
