import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import GlobalContext from '../../lib/GlobalContext'
import GetSafe from '../../lib/GetSafe'
import withAccessControl from '../HOC/AccessControl'
import FormatHelper from '../../lib/FormatHelper'
import { ACL_COMPONENTS } from '../../config'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  icon: {
    padding: 8,
    fontSize: 25
  },
  tableCellColumnsbody: {
    width: 'calc(100% * 1 / 5)',
    minWidth: 185,
    padding: 14,
    '&:nth-child(2)': {
      minWidth: 520
    },
    '&:nth-child(3)': {
      minWidth: 130
    },
    '@media (max-width:640px)': {
      width: 'auto',
      '&:first-of-type': {
        minWidth: 'auto'
      },
      '&:nth-child(2)': {
        minWidth: 320
      }
    }
  }
}))

/**
 * ProjectsFreeTableRow
 *
 * @returns {*}
 * @constructor
 */
const ProjectsFreeTableRow = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const row = props.row
  const { upgradeToPremiumOpen, setUpgradeToPremiumOpen } = useContext(
    GlobalContext
  )

  /**
   * handleOpenPremiumUpgrade
   */
  const handleOpenPremiumUpgrade = () => {
    setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
  }

  /**
   * tooltipAppendage
   *
   * @returns {string}
   */
  const tooltipAppendage = () => {
    if (props.planTrialData.isPossible) {
      return t('projects.free.tooltip.appendage', {
        trialDays: props.planTrialData.trialDays
      })
    } else {
      return ''
    }
  }

  /**
   * return
   */
  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCellColumnsbody}>
          <Tooltip
            arrow
            title={t('projects.free.route.premium.detail') + tooltipAppendage()}
            aria-label={t('projects.free.route.premium.detail')}
          >
            <IconButton
              id={`project-item-details-toggler-${row.HashID}`}
              onClick={handleOpenPremiumUpgrade}
              className={classes.icon}
            >
              <ExpandMore style={{ fontSize: 30 }} color='primary' />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            title={
              t('projects.free.route.premium.favorites') + tooltipAppendage()
            }
            aria-label={t('projects.free.route.premium.favorites')}
          >
            <IconButton
              onClick={handleOpenPremiumUpgrade}
              className={classes.icon}
            >
              <StarBorder style={{ fontSize: 30 }} color='primary' />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            title={t('projects.free.route.premium.print') + tooltipAppendage()}
            aria-label={t('projects.free.route.premium.print')}
          >
            <IconButton
              aria-controls={`project-menu-${row.HashID}`}
              className={classes.icon}
              aria-haspopup='true'
              onClick={handleOpenPremiumUpgrade}
            >
              <MoreHoriz style={{ fontSize: 30 }} color='primary' />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellColumnsbody}
          style={{
            fontSize: 16
          }}
        >
          {GetSafe(() => row.Final_title, '')}
        </TableCell>
        <TableCell className={classes.tableCellColumnsbody}>
          {GetSafe(() => row.Construction_site.Address.City, '')}
          <br />
          {GetSafe(() => row.Construction_site.Address.Street, '')}{' '}
          {GetSafe(() => row.Construction_site.Address.Number, '')}
        </TableCell>
        <TableCell
          className={classes.tableCellColumnsbody}
          style={{ fontStyle: 'italic' }}
        >
          {t('projects.free.route.premium')}
        </TableCell>
        <TableCell className={classes.tableCellColumnsbody}>
          {GetSafe(() => FormatHelper.formatDate(row.Date), '')}
        </TableCell>
      </TableRow>
    </>
  )
}

export default withAccessControl(
  ProjectsFreeTableRow,
  ACL_COMPONENTS.PROJECTS_FREE_TABLE_ROW
)
