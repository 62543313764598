import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "urql";
import GlobalContext from "../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import OnboardingMarketingLeftPane from "../components/OnboardingMarketing/OnboardingMarketingLeftPane";
import OnboardingMarketingRightPane from "../components/OnboardingMarketing/OnboardingMarketingRightPane";
import UserTypes from "../components/Onboarding/UserTypes";
import { ACL_COMPONENTS, CANTON_LIST, EMAIL_PLACEHOLDER } from "../config";
import withAccessControl from "../components/HOC/AccessControl";
import GooglePlacesAPIHelper from "../lib/GooglePlacesAPIHelper";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  override: {
    MuiCircularProgress: {
      root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
  },
  root: {
    flexGrow: 1,
    maxWidth: false,
  },
  dialog: {
    // display: 'flex',
    marginBottom: 0,
    padding: 0,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  dialogContainer: {
    margin: 0,
  },
  loadingIndicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const queryMarketingCompany = `
  query marketingCompany($hashId: ID) {
    readMarketingCompany(
      HashID: $hashId
    ) {
      MarketingCompany {
        ID
        HashID
        CheNumber
        Name
        Status
        Street
        Zip
        City
        WebsiteUrl
        Branch
        ContactPersonSalutation
        ContactPersonTitle
        ContactPersonFirstName
        ContactPersonLastName
        ContactPersonEmail
        IsRegistered
      }
      Token
    }
  }
`;

/**
 * OnboardingMarketing
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OnboardingMarketing = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [csrfToken, setCsrfToken] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const {
    user,
    openWelcomeDialog,
    token,
    setMessage,
    unsetUser,
    unsetToken,
    setUser,
  } = useContext(GlobalContext);

  /**
   * useEffect
   * ATTENTION: Dont add the "unsetUser" to the dependency array -> Infinite-Loop
   */
  useEffect(() => {
    unsetUser();
    // eslint-disable-next-line
  }, []);

  /**
   * GraphQL query for user, gets triggered by calling "executeMarketingCompanyQuery()"
   */
  const [result, executeMarketingCompanyQuery] = useQuery({
    query: queryMarketingCompany,
    variables: { hashId: props.id },
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching marketing company on OnboardingMarketing",
            data: {
              user: user.getData(),
              token: token.getData(),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.user"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readMarketingCompany !== "undefined" &&
          !user.isSet()
        ) {
          if (result.data.readMarketingCompany.MarketingCompany !== null) {
            if (
              result.data.readMarketingCompany.MarketingCompany.IsRegistered
            ) {
              setIsRegistered(
                result.data.readMarketingCompany.MarketingCompany.IsRegistered
              );
            }

            const marketingCompanyData =
              result.data.readMarketingCompany.MarketingCompany;

            GooglePlacesAPIHelper.getCantonByCity(
              marketingCompanyData.City
            ).then((googlePlacesResult) => {
              user.setData({
                id: parseInt(marketingCompanyData.ID),
                hashID: marketingCompanyData.HashID,
                email:
                  marketingCompanyData.ContactPersonEmail || EMAIL_PLACEHOLDER,
                firstName: marketingCompanyData.ContactPersonFirstName || "",
                lastName: marketingCompanyData.ContactPersonLastName || "",
                mode: UserTypes.FREE,
                company: {
                  id: parseInt(marketingCompanyData.ID),
                  hashID: marketingCompanyData.HashID,
                  name: marketingCompanyData.Name,
                  street: marketingCompanyData.Street || "",
                  zip: marketingCompanyData.Zip || "",
                  city: marketingCompanyData.City || "",
                  canton: CANTON_LIST[googlePlacesResult.canton_short],
                },
              });
              setCsrfToken(result.data.readMarketingCompany.Token);
              setUser(user);
            });
          } else if (!userNotFound) {
            setUserNotFound(true);
          }
        } else {
          if (!user.isSet()) {
            executeMarketingCompanyQuery();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  /**
   * LoadingIndicator
   */
  const LoadingIndicator = (
    <Grid item>
      <CircularProgress />
    </Grid>
  );

  /**
   * UserNotFoundDialog
   */
  const UserNotFoundDialog = (
    <Dialog
      className={classes.root}
      open={openWelcomeDialog}
      scroll="body"
      PaperProps={{
        style: {
          maxWidth: "1000px",
        },
      }}
    >
      <DialogContent className={classes.dialog}>
        <Grid
          container
          direction="row"
          align="center"
          justify="center"
          className={classes.dialogContainer}
        >
          <Grid item>
            <div>{t("error.fetching.user")}</div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  /**
   * return
   */
  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center">
        {(!user.isSet() || result.fetching) && LoadingIndicator}
      </Grid>
      {userNotFound && UserNotFoundDialog}
      {user.isSet() && !result.fetching && (
        <Dialog className={classes.root} open={openWelcomeDialog} maxWidth="md">
          <DialogContent className={classes.dialog}>
            <Grid container direction="row" align="center" justify="center">
              <OnboardingMarketingLeftPane
                csrfToken={csrfToken}
                isRegistered={isRegistered}
                setIsRegistered={setIsRegistered}
              />
              <OnboardingMarketingRightPane />
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default withAccessControl(
  OnboardingMarketing,
  ACL_COMPONENTS.ONBOARDING_MARKETING
);
