import React from 'react'

/**
 * NotFoundPage
 *
 * @returns {*}
 * @constructor
 */
const NotFoundPage = () => {
  return <div>NOT FOUND</div>
}

export default NotFoundPage
