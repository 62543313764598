/**
 * DataUrlToFileConverter
 *
 * Usage: var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt')
 *
 * @param dataUrl
 * @param filename
 * @returns {File}
 */
const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const biteStr = window.atob(arr[1])
  let n = biteStr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = biteStr.charCodeAt(n)
  }

  return new window.File([u8arr], filename, { type: mime })
}

export default dataURLtoFile
