import React, { useContext, useEffect, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import GlobalContext from '../../lib/GlobalContext'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { MESSAGE_TYPES } from '../PopupMessages'
import { navigate } from 'hookrouter'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'
import { FavoriteStateConditions } from './FavoriteStateConditions'
import { useMutation, useQuery } from 'urql'
import parseApplicationText from '../../lib/Application'
import GetSafe from '../../lib/GetSafe'
import FormValueErrorsBuilder from '../../lib/FormValueErrorsBuilder'
import Joi from '@hapi/joi'
import MenuItem from '@material-ui/core/MenuItem'
import {
  DOSSIER_AVAILABLE_LOCALES,
  DEFAULT_LOCALE_FOR_LANGUAGE
} from '../../config'

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    flexGrow: 1
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 0
  },
  dialog: {
    width: '100%',
    paddingBottom: 56,
    '&::before': theme.watermarkLogo,
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  gridContainer: {
    width: '100%',
    maxWidth: 1280,
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  },
  gridItem: {
    width: '50%',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20
    }
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '30px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  },
  startButton: {
    width: '100%',
    color: '#fff',
    fontWeight: 'bold'
  }
}))

const createFavoriteNoteMutation = `
  mutation CreateFavoriteNoteMutation (
    $favoriteHashID: String,
    $text: String
  ) {
    createFavoriteNote (FavoriteHashID: $favoriteHashID, Text: $text) {
      HashID
      FavoriteHashID
      Text
      Created
      Removed
    } 
  }
`

const updateFavoriteMutation = `
  mutation UpdateFavorite (
    $hashID: String,
    $state: String,
    $applied: Boolean
  ) {
    updateFavorite (HashID: $hashID, State: $state, Applied: $applied) {
      HashID
    }
  }
`

const queryFavorites = `
  query ReadFavorites (
    $userHashID: ID,
    $filterFor: [String]
  ) {
    readFavorites(ID: $userHashID, ConstructionTypeContains: $filterFor) {
      HashID
      ProjectHashID
      Removed
      State
      Canton
      Calculated_distance
      Content
      Final_title
      Import_date
      Date
      Applied
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Phone
        Email
        Website
        Email_other
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      StateHistory {
        edges {
          node {
            Created
            State
          }
        }
      }
      Notes {
        edges {
          node {
            HashID
            Text
            Created
            Removed
          }
        }
      }
    }
  }
`

const queryBusinessActivities = `
  query ReadBusinessActivities ($id: ID)
  {
    readBusinessActivities (
      sortBy: [{field: Sort, direction: ASC}],
      Filter: {
        ID__in: [$id]
      }
    ){
      ID,
      Title,
      NogaCode,
      BusinessSpecialities {
        edges {
            node {
            ID,
            Title
          }
        }
      }
    }
  }
`

const queryDossierTemplates = `
  query ReadDossierTemplates (
    $language: String!
  ) {
    readDossierTemplates(Language: $language) {
      HashID
      Email
      Locale
      TemplateName
      BusinessActivityID
      MicrositeEmailApplicationText
      BusinessSpecialities {
        edges {
          node {
            ID
            Title
          }
        }
      }
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeReferences {
        edges {
          node {
            Title
            Content
          }
        }
      }
    }
  }
`

const createApplicationSubmissionMutation = `
  mutation (
    $SubmissionType: String!, 
    $Email: String,
    $RecipientName: String,
    $ApplicationText: String!,
    $ProjectIDHash: String!,
    $SubjectLine: String!,
    $ProjectName: String!,
    $ProjectLocation: String!,
    $Canton: String,
    $Type_of_building: String,
    $Type_of_construction: String,
    $TemplateHashID: String,
    $Language: String
  ) {
    submitApplication(
      SubmissionType: $SubmissionType, 
      Email: $Email, 
      RecipientName: $RecipientName,
      ApplicationText: $ApplicationText, 
      ProjectIDHash: $ProjectIDHash,
      SubjectLine: $SubjectLine,
      ProjectName: $ProjectName,
      ProjectLocation: $ProjectLocation,
      Canton: $Canton,
      TypeOfBuilding: $Type_of_building,
      TypeOfConstruction: $Type_of_construction,
      TemplateHashID: $TemplateHashID,
      Language: $Language
    ) {
      Status
      Error
    }
  }
`

const initialFormValueErrorsState = {
  ApplicationText: { hasError: false, message: '' },
  SubjectLine: { hasError: false, message: '' },
  DossierTemplate: { hasError: false, message: '' },
  Recipient: { hasError: false, message: '' }
}

const initialLanguage = 'de'

/**
 * FavoriteEmailApplyDialog
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FavoriteEmailApplyDialog = props => {
  const classes = useStyles()
  const {
    emailApplyDialog,
    setEmailApplyDialog,
    favoriteHashId,
    filterFor,
    setFavoriteData,
    favorite
  } = props
  const { user, token, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext)
  const { t } = useTranslation()
  const [businessActivities, setBusinessActivities] = useState(false)
  const [emailApplicationText, setEmailApplicationText] = useState(null)
  const [sendEmailFetching, setSendEmailFetching] = useState(false)
  const [subjectLine, setSubjectLine] = useState('')
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  )
  const [emailRecipient, setEmailRecipient] = useState(null)
  const [selectedDossierTemplate, setSelectedDossierTemplate] = useState(null)
  const [dossierTemplates, setDossierTemplates] = useState([])
  const [dossierTemplatesByHashID, setDossierTemplatesByHashID] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage)
  const [enableLanguageSelector, setEnableLanguageSelector] = useState(false)

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    ApplicationText: Joi.string().required(),
    SubjectLine: Joi.string().required(),
    DossierTemplate:
      dossierTemplates.length > 1
        ? Joi.string().allow(null).required()
        : Joi.string().allow(null),
    Recipient: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
  })

  /* eslint-disable */
  const [{ fetchingCreateFavoriteNote }, executeCreateFavoriteNoteMutation] =
    useMutation(createFavoriteNoteMutation)

  const [{ fetchingUpdateFavorite }, executeUpdateFavoriteMutation] =
    useMutation(updateFavoriteMutation)

  const [
    { fetchingUpdateApplicationSubmission },
    executeApplicationSubmissionMutation
  ] = useMutation(createApplicationSubmissionMutation)
  /* eslint-enable */

  // GraphQL query for favorites, gets triggered by calling "executeFavoritesQuery()"
  const [result, executeFavoritesQuery] = useQuery({
    query: queryFavorites,
    variables: {
      userHashID: user.getData().hashID,
      filterFor: filterFor
    },
    requestPolicy: 'network-only',
    pause: true
  })

  // GraphQL query for business-activities, gets triggered by calling "executeBusinessActivitiesQuery()"
  const [queryBusinessActivitiesResult, executeBusinessActivitiesQuery] =
    useQuery({
      query: queryBusinessActivities,
      variables: {
        id: user.getData().company.branchCategory
      },
      requestPolicy: 'cache-and-network',
      pause: true
    })

  // GraphQL query for dossier-templates
  const [queryDossierTemplatesResult] = useQuery({
    query: queryDossierTemplates,
    variables: {
      language: 'all'
    },
    requestPolicy: 'cache-and-network'
  })

  useEffect(() => {
    executeBusinessActivitiesQuery()
  }, [executeBusinessActivitiesQuery])

  /**
   * setApplicationText useEffect
   */
  useEffect(() => {
    if (businessActivities) {
      let text = user.getData().company.micrositeEmailApplicationText

      if (emailApplicationText !== null) {
        text = emailApplicationText
      }

      setEmailApplicationText(parseEmailApplicationText(text))
    }
    // eslint-disable-next-line
  }, [emailApplicationText, user, businessActivities])

  useEffect(() => {
    setDossierTemplates([
      ...[{ HashID: null }],
      ...user.getData().dossierTemplates
    ])
  }, [user])

  useEffect(() => {
    setSelectedDossierTemplate(dossierTemplatesList()[0].props.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dossierTemplates])

  /**
   * In case selected dossier-template changes to a custom one, enable the language-selector
   */
  useEffect(() => {
    let state = false

    if (GetSafe(() => selectedDossierTemplate.HashID, false)) {
      state = true
    }

    setEnableLanguageSelector(state)
  }, [selectedDossierTemplate])

  /**
   * Triggers as soon as the selected dossier-template has been changed
   */
  useEffect(() => {
    let applicationText = null
    let selectedDossierTemplateLangDependant = null

    if (selectedDossierTemplate) {
      selectedDossierTemplateLangDependant =
        getDossierTemplateByHashIdAndLanguage(
          selectedDossierTemplate.HashID,
          selectedLanguage
        )
      applicationText =
        selectedDossierTemplateLangDependant.MicrositeEmailApplicationText
    }

    if (!applicationText) {
      applicationText = user.getData().company.micrositeEmailApplicationText
    }

    setEmailApplicationText(parseEmailApplicationText(applicationText))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDossierTemplate, selectedLanguage, businessActivities])

  /**
   * Read the query result for dossier-templates and assign them to the application-dropdown
   */
  useEffect(() => {
    const dossiersByLocale = {}
    const dossiersByHashID = {}
    const languageDependantDossierTemplates = []

    if (!queryDossierTemplatesResult.fetching) {
      if (
        GetSafe(
          () => queryDossierTemplatesResult.data.readDossierTemplates,
          false
        )
      ) {
        if (queryDossierTemplatesResult.data.readDossierTemplates.length > 0) {
          queryDossierTemplatesResult.data.readDossierTemplates.forEach(
            dossierTemplate => {
              if (
                typeof DOSSIER_AVAILABLE_LOCALES[dossierTemplate.Locale] !==
                'undefined'
              ) {
                languageDependantDossierTemplates.push(dossierTemplate)

                if (dossierTemplate.Locale) {
                  if (
                    typeof dossiersByLocale[dossierTemplate.Locale] ===
                    'undefined'
                  ) {
                    dossiersByLocale[dossierTemplate.Locale] = []
                  }

                  dossiersByLocale[dossierTemplate.Locale].push(dossierTemplate)
                }

                if (dossierTemplate.HashID) {
                  if (
                    typeof dossiersByHashID[dossierTemplate.HashID] ===
                    'undefined'
                  ) {
                    dossiersByHashID[dossierTemplate.HashID] = []
                  }

                  dossiersByHashID[dossierTemplate.HashID].push(dossierTemplate)
                }

                if (
                  selectedDossierTemplate &&
                  dossierTemplate.HashID === selectedDossierTemplate.HashID
                ) {
                  setSelectedDossierTemplate(dossierTemplate)
                }
              }
            }
          )
        }
      }
    }

    setDossierTemplatesByHashID(dossiersByHashID)

    setDossierTemplates([
      ...[{ HashID: null }],
      ...getUniqueDossierTemplatesByHashID(dossiersByHashID)
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDossierTemplatesResult])

  /**
   * Handle dossiert-template change
   *
   * @param event
   */
  const handleDossierTemplateChange = event => {
    let dossierTemplate = null

    if (event.target.value === null || event.target.value === 0) {
      dossierTemplate = event.target.value
    } else {
      dossierTemplate = getDossierTemplateByHashIdAndLanguage(
        event.target.value.HashID,
        initialLanguage
      )
    }

    setSelectedLanguage(initialLanguage)
    setSelectedDossierTemplate(dossierTemplate)
  }

  /**
   * Handle language change
   *
   * @param {*} event
   */
  const handleSelectedLanguageChange = event => {
    setSelectedLanguage(event.target.value)
  }

  /**
   * Taks a array of templates and removes duplicates by HashID
   * In case a duplicate has different languages, it keeps the current selected language duplicate
   *
   * @param {*} dossiersByHashID
   * @returns array
   */
  const getUniqueDossierTemplatesByHashID = dossiersByHashID => {
    const uniqueDossierTemplates = []

    Object.keys(dossiersByHashID).forEach(index => {
      if (dossiersByHashID[index].length > 1) {
        let foundForLanguage = false

        dossiersByHashID[index].forEach(dossierTemplate => {
          if (
            dossierTemplate.Locale ===
            DEFAULT_LOCALE_FOR_LANGUAGE[selectedLanguage]
          ) {
            uniqueDossierTemplates.push(dossierTemplate)
            foundForLanguage = true
          }
        })

        if (!foundForLanguage) {
          uniqueDossierTemplates.push(dossiersByHashID[index][0])
        }
      } else {
        uniqueDossierTemplates.push(dossiersByHashID[index][0])
      }
    })

    return uniqueDossierTemplates
  }

  /**
   * Fetch the selected dossier-template for the selected language from the pool#
   *
   * @param {*} hashID
   * @param {*} language
   * @returns
   */
  const getDossierTemplateByHashIdAndLanguage = (hashID, language) => {
    // Get the selected dossier-template for the selected language
    for (let i = 0; i < dossierTemplatesByHashID[hashID].length; i++) {
      if (
        dossierTemplatesByHashID[hashID][i].HashID === hashID &&
        DOSSIER_AVAILABLE_LOCALES[
          dossierTemplatesByHashID[hashID][i].Locale
        ] === language
      ) {
        return dossierTemplatesByHashID[hashID][i]
      }
    }

    // Since there is no dossier-template for the requested language,
    // fallback to first available dossier-template and its language
    setSelectedLanguage(
      DOSSIER_AVAILABLE_LOCALES[dossierTemplatesByHashID[hashID][0].Locale]
    )
    return dossierTemplatesByHashID[hashID][0]
  }

  /**
   * Generate available language-items for dropdown based on selected dossier-template
   *
   * @returns
   */
  const getDossierTemplateAvailableLanguages = () => {
    const avalableLanguages = {}
    const menuItems = []

    if (selectedDossierTemplate === null || selectedDossierTemplate === 0) {
      return menuItems
    }

    dossierTemplatesByHashID[selectedDossierTemplate.HashID].forEach(
      dossierTemplate => {
        if (
          typeof DOSSIER_AVAILABLE_LOCALES[dossierTemplate.Locale] !==
          'undefined'
        ) {
          avalableLanguages[DOSSIER_AVAILABLE_LOCALES[dossierTemplate.Locale]] =
            dossierTemplate.Locale
        }
      }
    )

    if (avalableLanguages['de']) {
      menuItems.push(
        <MenuItem key={0} value={'de'}>
          Deutsch
        </MenuItem>
      )
    }

    if (avalableLanguages['fr']) {
      menuItems.push(
        <MenuItem key={1} value={'fr'}>
          Französisch
        </MenuItem>
      )
    }

    return menuItems
  }

  useEffect(() => {
    setEmailRecipient(favorite.Project_management.Email)
  }, [favorite.Project_management.Email])

  /**
   * Parse the application text and replace the placeholder with actual values
   */
  const parseEmailApplicationText = emailApplicationTextTemplate => {
    return parseApplicationText(
      user,
      emailApplicationTextTemplate,
      businessActivities,
      favorite,
      t('general.label.and')
    )
  }

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!queryBusinessActivitiesResult.fetching) {
      if (queryBusinessActivitiesResult.error) {
        // Check if the user need to be logged out
        if (
          queryBusinessActivitiesResult.error.message.indexOf(
            'User forced logout'
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        }
      } else {
        if (
          !businessActivities &&
          typeof queryBusinessActivitiesResult.data !== 'undefined' &&
          typeof queryBusinessActivitiesResult.data.readBusinessActivities !==
            'undefined'
        ) {
          // Query not fetching right now
          setBusinessActivities(
            queryBusinessActivitiesResult.data.readBusinessActivities[0]
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryBusinessActivitiesResult])

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf('User forced logout') !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== 'undefined' &&
          typeof result.data.readFavorites !== 'undefined'
        ) {
          // Add the favorites to the global context
          setFavoriteData(result.data.readFavorites)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * closeDialogue
   */
  const closeDialogue = () => {
    setEmailApplyDialog(false)
  }

  /**
   * handleEmailApplicationTextChange
   *
   * @param event
   */
  const handleEmailApplicationTextChange = event => {
    setEmailApplicationText(parseEmailApplicationText(event.target.value))
  }

  /**
   * handleEmailSubjectChange
   *
   * @param event
   */
  const handleEmailSubjectChange = event => {
    setSubjectLine(event.target.value)
  }

  /**
   * handleEmailRecipientChange
   *
   * @param event
   */
  const handleEmailRecipientChange = event => {
    setEmailRecipient(event.target.value)
  }

  /**
   * getCurrentDate
   *
   * @returns {string}
   */
  const getCurrentDate = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()

    return dd + '.' + mm + '.' + yyyy
  }

  /**
   * dossierTemplateList
   *
   * @returns {[]}
   */
  const dossierTemplatesList = () => {
    const dossierTemplatesList = []

    dossierTemplatesList.push(
      <MenuItem key={0} value={0}>
        {t('form.label.please.select')}
      </MenuItem>
    )

    dossierTemplates.map((value, key) =>
      dossierTemplatesList.push(
        <MenuItem key={key + 1} value={value}>
          {value.TemplateName}
        </MenuItem>
      )
    )

    return dossierTemplatesList
  }

  /**
   * addNote
   */
  const addNote = () => {
    executeCreateFavoriteNoteMutation({
      favoriteHashID: favoriteHashId,
      text:
        t('company.label.email.application.date', {
          date: getCurrentDate()
        }) +
        t('form.label.used.recipient', {
          recipient: emailRecipient
        }) +
        emailApplicationText
    }).then(result => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf('User forced logout') !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t('error.save'))
          ExternalErrorLogger.log({
            text: 'Error add note on FavoriteDetailAddition',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error)
            }
          })
        }
      } else {
        changeStatus()
      }
    })
  }

  /**
   * changeStatus
   */
  const changeStatus = () => {
    executeUpdateFavoriteMutation({
      hashID: favoriteHashId,
      state: FavoriteStateConditions.CHECKED,
      applied: true
    }).then(result => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf('User forced logout') !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t('error.save'))
          ExternalErrorLogger.log({
            text: 'Error change favorite status on FavoriteStatus',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error)
            }
          })
        }
      } else {
        executeFavoritesQuery()
        closeDialogue()
      }
    })
  }

  /**
   * submitApplication
   */
  const handleSubmit = () => {
    setSendEmailFetching(true)
    const formData = {
      ApplicationText: emailApplicationText,
      SubjectLine: subjectLine,
      DossierTemplate: selectedDossierTemplate.HashID,
      Recipient: emailRecipient
    }
    const { error } = schema.validate(formData, { abortEarly: false })

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t)
      setSendEmailFetching(false)
      setFormValueErrors({ ...formErrors })
    } else {
      executeApplicationSubmissionMutation({
        SubmissionType: 'email',
        Email: emailRecipient,
        RecipientName: favorite.Project_management.Name || '',
        ApplicationText: emailApplicationText,
        SubjectLine: subjectLine,
        ProjectIDHash: favorite.ProjectHashID,
        ProjectName: favorite.Final_title,
        ProjectLocation: favorite.Construction_site.Address.City,
        Canton: favorite.Canton,
        Type_of_building: favorite.Type_of_building,
        Type_of_construction: favorite.Type_of_construction,
        TemplateHashID: selectedDossierTemplate.HashID,
        Language: selectedLanguage
      }).then(result => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf('User forced logout') !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
            setSendEmailFetching(false)
            unsetUser()
            unsetToken()
            navigate('/')
          } else {
            setSendEmailFetching(false)
            setMessage(MESSAGE_TYPES.ERROR, t('error.save'))
            ExternalErrorLogger.log({
              text: 'Error sending application via email',
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: JSON.stringify(result.error)
              }
            })
          }
        } else {
          if (result.data.submitApplication.Status !== 'true') {
            setMessage(
              MESSAGE_TYPES.ERROR,
              result.data.submitApplication.Error !== ''
                ? result.data.submitApplication.Error === 'Already applied for'
                  ? t('error.form.submit.application.already.applied')
                  : result.data.submitApplication.Error
                : t('error.form.submit.application.not.send')
            )
          } else {
            addNote()
            changeStatus()
            setMessage(
              MESSAGE_TYPES.INFO,
              t('company.label.email.application.submitted')
            )
          }
          setSendEmailFetching(false)
        }
      })
    }
  }

  /**
   * return
   */
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      className={classes.dialogRoot}
      open={emailApplyDialog}
    >
      <DialogTitle id='dialog-title' className={classes.dialogTitle}>
        <IconButton onClick={closeDialogue}>
          <CloseIcon id='closeDialog' />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          margin='normal'
          padding='normal'
          className={classes.gridContainer}
        >
          {(user.hasZipSearchAreas() || user.isPro()) &&
            dossierTemplates.length > 1 && (
              <Grid item className={classes.gridItem}>
                <TextField
                  id='dossierTemplateSelect'
                  select
                  label={t('form.label.select.dossier')}
                  fullWidth
                  value={selectedDossierTemplate}
                  disabled={queryDossierTemplatesResult.fetching}
                  onChange={event => {
                    handleDossierTemplateChange(event)
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin='normal'
                  variant='outlined'
                  error={GetSafe(
                    () => formValueErrors.DossierTemplate.hasError,
                    false
                  )}
                  helperText={GetSafe(
                    () => formValueErrors.DossierTemplate.message,
                    ''
                  )}
                >
                  {dossierTemplatesList()}
                </TextField>
              </Grid>
            )}
          {(user.hasZipSearchAreas() || user.isPro()) &&
            enableLanguageSelector && (
              <Grid item className={classes.gridItem}>
                <TextField
                  id='languageSelect'
                  select
                  label={t('form.label.select.language')}
                  fullWidth
                  value={selectedLanguage}
                  disabled={
                    !enableLanguageSelector ||
                    queryDossierTemplatesResult.fetching
                  }
                  onChange={event => {
                    handleSelectedLanguageChange(event)
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin='normal'
                  variant='outlined'
                  helperText={t('form.description.select.language')}
                >
                  {getDossierTemplateAvailableLanguages()}
                </TextField>
              </Grid>
            )}
          <Grid item className={clsx(classes.gridItem, classes.itemHeader)}>
            <h1 className={classes.title}>
              {t('company.label.email.application')}
            </h1>
            <p>{t('company.label.email.application.intro')}</p>
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id='inputSubjectLine'
              label={t('form.label.subjectLine')}
              className={classes.textField}
              margin='normal'
              variant='outlined'
              fullWidth
              error={GetSafe(() => formValueErrors.SubjectLine.hasError, false)}
              helperText={GetSafe(
                () => formValueErrors.SubjectLine.message,
                ''
              )}
              value={subjectLine}
              onChange={handleEmailSubjectChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id='inputRecipient'
              label={t('form.label.recipient.email')}
              className={classes.textField}
              margin='normal'
              variant='outlined'
              fullWidth
              error={GetSafe(() => formValueErrors.Recipient.hasError, false)}
              helperText={GetSafe(() => formValueErrors.Recipient.message, '')}
              value={emailRecipient}
              onChange={handleEmailRecipientChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id='inputEmailApplicationText'
              variant='outlined'
              align='left'
              multiline
              error={GetSafe(
                () => formValueErrors.ApplicationText.hasError,
                false
              )}
              helperText={GetSafe(
                () => formValueErrors.ApplicationText.message,
                ''
              )}
              label={t('form.label.company.emailApplication')}
              value={emailApplicationText || ''}
              onChange={handleEmailApplicationTextChange}
              margin='normal'
              fullWidth
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button
              id='buttonFormSubmit'
              variant='contained'
              color='primary'
              className={classes.startButton}
              onClick={handleSubmit}
              disabled={sendEmailFetching}
            >
              {t('company.label.email.application.submit')}
              {sendEmailFetching ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : null}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default FavoriteEmailApplyDialog
