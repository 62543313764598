import React, { useContext, useMemo } from "react";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { CHARTJS_STATISTIC_OPTIONS } from "../../config";
import { useQuery } from "urql";
import getSafe from "../../lib/GetSafe";

ChartJS.register(CategoryScale);
ChartJS.register(LinearScale);
ChartJS.register(BarElement);
ChartJS.register(Title);
ChartJS.register(Tooltip);
ChartJS.register(Legend);
ChartJS.register(zoomPlugin);

let BarOptions = CHARTJS_STATISTIC_OPTIONS;

const queryReadStatisticApplications = `
  query ReadStatisticApplications ($companyHashID: String!, $submissionType: String!)
  {
    readStatisticApplications (
      CompanyHashID: $companyHashID,
      SubmissionType: $submissionType
    ) {
      labels
      datasets {
          label
          data
          backgroundColor
      }
    }
  }
`;

/**
 * SendApplications
 *
 * @returns {*}
 * @constructor
 */
const SendApplications = () => {
  const { t /* , i18n */ } = useTranslation();
  const { user } = useContext(GlobalContext);

  // GraphQL query for statistic data
  const [queryReadStatisticApplicationsResult] = useQuery({
    query: queryReadStatisticApplications,
    variables: {
      companyHashID: user.getData().company.hashID,
      submissionType: "email",
    },
    requestPolicy: "cache-and-network",
  });

  BarOptions = useMemo(() => {
    return JSON.parse(
      JSON.stringify(CHARTJS_STATISTIC_OPTIONS).replace(
        "[[:TITLE_TEXT_PLACEHOLDER:]]",
        t("statistics.selector.apply.email")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    queryReadStatisticApplicationsResult.fetching ||
    !getSafe(
      () => queryReadStatisticApplicationsResult.data.readStatisticApplications,
      false
    )
  ) {
    return <LinearProgress />;
  }

  if (
    queryReadStatisticApplicationsResult.data.readStatisticApplications.datasets
      .length === 0
  ) {
    return (
      <div style={{ textAlign: "left" }}>
        {t("statistics.selector.apply.email")}: Keine Daten
      </div>
    );
  }

  return user.getData().company.adminUserID === user.getData().id ? (
    <Bar
      options={BarOptions}
      data={queryReadStatisticApplicationsResult.data.readStatisticApplications}
    />
  ) : null;
};

export default SendApplications;
