import React, { useContext, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import Joi from '@hapi/joi'
import FormValueErrorsBuilder from '../../lib/FormValueErrorsBuilder'
import GetSafe from '../../lib/GetSafe'
import { useMutation } from 'urql'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import { MESSAGE_TYPES } from '../PopupMessages'
import withAccessControl from '../HOC/AccessControl'
import { navigate } from 'hookrouter'
import { ACL_COMPONENTS } from '../../config'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    maxWidth: 1280,
    margin: 0,
    padding: '16px 24px'
  },
  gridItem: {
    width: '50%',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  button: {
    color: '#ffffff',
    width: '100%',
    marginBottom: '40px'
  },
  updatePasswordLink: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.primary.main,
    paddingLeft: '10px',
    cursor: 'pointer'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  textField: {
    width: '100%'
  },
  languageToggle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 24
  },
  languageToggleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#fff'
  },
  languageToggleBoxItem: {
    paddingLeft: 6
  },
  languageStatus: {
    display: 'none',
    appearance: 'none'
  }
}))

const initialFormValueErrorsState = {
  firstName: { hasError: false, message: '' },
  lastName: { hasError: false, message: '' },
  email: { hasError: false, message: '' },
  currentPassword: { hasError: false, message: '' },
  password: { hasError: false, message: '' },
  passwordRepeat: { hasError: false, message: '' }
}

const updateMyselfMutation = `
  mutation UpdateMyself(
    $locale: String,
    $salutation: String, 
    $firstName: String, 
    $lastName: String, 
    $email: String!
  ) {
    updateMyself(
      Locale: $locale,
      Salutation: $salutation,
      Firstname: $firstName,
      Surname: $lastName 
      Email: $email
    ) {
      HashID
      Locale
      Salutation
      Firstname
      Surname
      Email
    }
  }
`

const updateMyselfWithPasswordMutation = `
  mutation UpdateMyself(
    $locale: String,
    $salutation: String, 
    $firstName: String, 
    $lastName: String, 
    $email: String!,
    $password: String!
    $currentPassword: String!
  ) {
    updateMyself(
      Locale: $locale,
      Salutation: $salutation,
      Firstname: $firstName,
      Surname: $lastName ,
      Email: $email,
      Password: $password
      CurrentPassword: $currentPassword
    ) {
      HashID
      Locale
      Salutation
      Firstname
      Surname
      Email
    }
  }
`

/**
 * AccountProfile
 *
 * @returns {*}
 * @constructor
 */
const AccountProfile = () => {
  const classes = useStyles()
  const { t /* , i18n */ } = useTranslation()
  const {
    user,
    setUser,
    token,
    setMessage,
    unsetUser,
    unsetToken
  } = useContext(GlobalContext)
  const [formValues, setFormValues] = useState({
    salutation: 0,
    firstName: '',
    lastName: '',
    email: '',
    locale: 'de',
    currentPassword: '',
    password: '',
    passwordRepeat: '',
    showCurrentPassword: false,
    showPassword: false,
    showPasswordRepeat: false
  })
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  )
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [{ fetching }, executeMutation] = useMutation(updateMyselfMutation)
  const [
    { fetching: fetchingWithPassword },
    executeWithPasswordMutation
  ] = useMutation(updateMyselfWithPasswordMutation)

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    currentPassword: Joi.string().allow(''),
    password: Joi.string().allow(''),
    passwordRepeat: Joi.string().allow(''),
    salutation: Joi.number(),
    locale: Joi.string(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: false })
      .required(),
    showCurrentPassword: Joi.boolean().allow(''),
    showPassword: Joi.boolean().allow(''),
    showPasswordRepeat: Joi.boolean().allow('')
  })

  /**
   * Joi password validation schema
   */
  const passwordValidationSchema = Joi.object({
    currentPassword: Joi.string().required(),
    password: Joi.string().required(),
    passwordRepeat: Joi.string()
      .valid(Joi.ref('password'))
      .required()
  })

  /**
   * useEffect
   */
  useEffect(() => {
    const userData = user.getData()
    setFormValues({
      salutation: userData.salutation || 0,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      locale: userData.locale || 'de'
    })
  }, [user])

  /**
   * handleSalutationChange
   *
   * @param event
   */
  const handleSalutationChange = event => {
    setFormValues({ ...formValues, salutation: event.target.value })
  }

  /**
   * handleFirstNameChange
   *
   * @param event
   */
  const handleFirstNameChange = event => {
    setFormValues({ ...formValues, firstName: event.target.value })
  }

  /**
   * handleLastNameChange
   *
   * @param event
   */
  const handleLastNameChange = event => {
    setFormValues({ ...formValues, lastName: event.target.value })
  }

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleEmailChange = event => {
    setFormValues({ ...formValues, email: event.target.value })
  }

  /**
   * handleCurrentPasswordChange
   *
   * @param event
   */
  const handleCurrentPasswordChange = event => {
    setFormValues({ ...formValues, currentPassword: event.target.value })
  }

  /**
   * handlePasswordChange
   *
   * @param event
   */
  const handlePasswordChange = event => {
    setFormValues({ ...formValues, password: event.target.value })
  }

  /**
   * handlePasswordRepeatChange
   *
   * @param event
   */
  const handlePasswordRepeatChange = event => {
    setFormValues({ ...formValues, passwordRepeat: event.target.value })
  }

  /**
   * handleClickShowCurrentPassword
   */
  const handleClickShowCurrentPassword = () => {
    setFormValues({
      ...formValues,
      showCurrentPassword: !formValues.showCurrentPassword
    })
  }

  /**
   * handleClickShowPassword
   */
  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword })
  }

  /**
   * handleClickShowPasswordRepeat
   */
  const handleClickShowPasswordRepeat = () => {
    setFormValues({
      ...formValues,
      showPasswordRepeat: !formValues.showPasswordRepeat
    })
  }

  /**
   * handleShowPasswordField
   */
  const handleShowPasswordField = () => {
    setShowPasswordField(true)
  }

  /**
   * handleHidePasswordField
   */
  const handleHidePasswordField = () => {
    setFormValues({ ...formValues, password: '' })
    setShowPasswordField(false)
  }

  /**
   * handleMutationResult
   *
   * @param result
   */
  const handleMutationResult = result => {
    if (result.error) {
      // Check if the user need to be logged out
      if (result.error.message.indexOf('User forced logout') !== -1) {
        setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
        unsetUser()
        unsetToken()
        navigate('/')
      } else {
        setMessage(MESSAGE_TYPES.ERROR, t('error.save'))
        ExternalErrorLogger.log({
          text: 'Error saving changes on AccountProfile',
          data: {
            token: JSON.stringify(token.getData()),
            user: JSON.stringify(user.getData()),
            errorMessage: result.error.message
          }
        })
      }
    } else {
      setMessage(MESSAGE_TYPES.SUCCESS, t('general.label.save.success'))
      const modifiedUserData = {
        salutation: formValues.salutation,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email
      }
      user.setData(modifiedUserData)
      setUser(user)
    }
  }

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = () => {
    const { error } = schema.validate(formValues, { abortEarly: false })
    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t)
      setFormValueErrors({ ...formErrors })
    } else {
      if (GetSafe(() => formValues.password, false)) {
        const { error } = passwordValidationSchema.validate(
          {
            currentPassword: formValues.currentPassword,
            password: formValues.password,
            passwordRepeat: formValues.passwordRepeat
          },
          { abortEarly: false }
        )

        if (error) {
          const formErrors = FormValueErrorsBuilder(error, t)
          setFormValueErrors({ ...formErrors })
        } else {
          executeWithPasswordMutation({
            locale: formValues.locale,
            email: formValues.email,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            salutation: formValues.salutation,
            password: formValues.password,
            currentPassword: formValues.currentPassword
          }).then(result => {
            if (result.error) {
              if (
                result.error.message.indexOf('Password change denied') !==
                -1
              ) {
                // Set custom error
                const myCustomError = {
                  details: [
                    {
                      type: 'any.notConfirmed',
                      path: ['currentPassword']
                    }
                  ]
                }
                const formErrors = FormValueErrorsBuilder(myCustomError, t)
                setFormValueErrors({ ...formErrors })
              } else {
                // Something went very wrong
                ExternalErrorLogger.log({
                  text: 'Error submitting changes on AccountProfile',
                  data: {
                    token: JSON.stringify(token.getData()),
                    user: JSON.stringify(user.getData()),
                    errorMessage: result.error.message
                  }
                })
                return <div>{t('error.general')}</div>
              }
            } else {
              handleMutationResult(result)
            }
          })
        }
      } else {
        executeMutation({
          locale: formValues.locale,
          email: formValues.email,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          salutation: formValues.salutation
        }).then(result => {
          handleMutationResult(result)
        })
      }
    }
  }

  /**
   * changeLocale
   *
   * @param event
   */
  // const changeLocale = event => {
  //   const userData = user.getData()
  //   userData.locale = event.target.value
  //   user.setData(userData)
  //   i18n.changeLanguage(event.target.value)
  // }

  /**
   * return
   */
  return (
    <Grid
      container
      spacing={2}
      direction='column'
      justify='center'
      alignItems='center'
      margin='normal'
      padding='normal'
      className={classes.gridContainer}
    >
      {/* <Grid item className={classes.gridItem}> */}
      {/*  <TextField */}
      {/*    id='selectLanguage' */}
      {/*    label={t('form.label.language')} */}
      {/*    variant='outlined' */}
      {/*    select */}
      {/*    align='left' */}
      {/*    value={formValues.locale.substring(0, 2)} */}
      {/*    onChange={changeLocale} */}
      {/*    margin='normal' */}
      {/*    fullWidth */}
      {/*  > */}
      {/*    <MenuItem value='de'>{t('general.label.german')}</MenuItem> */}
      {/*    <MenuItem value='en'>{t('general.label.english')}</MenuItem> */}
      {/*  </TextField> */}
      {/* </Grid> */}
      <Grid item className={classes.gridItem}>
        <TextField
          id='selectSalutation'
          label={t('form.label.salutation')}
          variant='outlined'
          select
          align='left'
          value={formValues.salutation}
          onChange={handleSalutationChange}
          margin='normal'
          fullWidth
        >
          <MenuItem value={0} />
          <MenuItem value={1}>{t('general.label.mr')}</MenuItem>
          <MenuItem value={2}>{t('general.label.mrs')}</MenuItem>
        </TextField>
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputFirstName'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.firstName.hasError, false)}
          label={t('form.label.firstName')}
          value={formValues.firstName}
          helperText={GetSafe(() => formValueErrors.firstName.message, '')}
          onChange={handleFirstNameChange}
          margin='normal'
          fullWidth
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputLastName'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.lastName.hasError, false)}
          label={t('form.label.lastName')}
          value={formValues.lastName}
          helperText={GetSafe(() => formValueErrors.lastName.message, '')}
          onChange={handleLastNameChange}
          margin='normal'
          fullWidth
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputEmail'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.email.hasError, false)}
          label={t('form.label.email')}
          value={formValues.email}
          helperText={GetSafe(() => formValueErrors.email.message, '')}
          onChange={handleEmailChange}
          margin='normal'
          fullWidth
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        {!showPasswordField && (
          <Link
            onClick={handleShowPasswordField}
            variant='body2'
            id='handleShowPasswordField'
          >
            <Typography gutterBottom className={classes.updatePasswordLink}>
              {t('account.profile.update.password')}
            </Typography>
          </Link>
        )}
        {showPasswordField && (
          <>
            <Link
              onClick={handleHidePasswordField}
              variant='body2'
              id='handleHidePasswordField'
            >
              <Typography gutterBottom className={classes.updatePasswordLink}>
                {t('account.profile.update.password.hide')}
              </Typography>
            </Link>
            <TextField
              id='outlined-adornment-current-password'
              className={classes.textField}
              variant='outlined'
              align='left'
              margin='normal'
              error={GetSafe(
                () => formValueErrors.currentPassword.hasError,
                false
              )}
              type={formValues.showCurrentPassword ? 'text' : 'password'}
              label={t('form.label.password.current')}
              helperText={GetSafe(
                () => formValueErrors.currentPassword.message,
                ''
              )}
              onChange={handleCurrentPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      color='primary'
                      aria-label='Toggle current password visibility'
                      onClick={handleClickShowCurrentPassword}
                    >
                      {formValues.showCurrentPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id='outlined-adornment-password'
              className={classes.textField}
              variant='outlined'
              align='left'
              margin='normal'
              error={GetSafe(() => formValueErrors.password.hasError, false)}
              type={formValues.showPassword ? 'text' : 'password'}
              label={t('form.label.password')}
              helperText={GetSafe(() => formValueErrors.password.message, '')}
              onChange={handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      color='primary'
                      aria-label='Toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {formValues.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id='outlined-adornment-password-repeat'
              className={classes.textField}
              variant='outlined'
              align='left'
              margin='normal'
              error={GetSafe(
                () => formValueErrors.passwordRepeat.hasError,
                false
              )}
              type={formValues.showPasswordRepeat ? 'text' : 'password'}
              label={t('form.label.password.repeat')}
              helperText={GetSafe(
                () => formValueErrors.passwordRepeat.message,
                ''
              )}
              onChange={handlePasswordRepeatChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      color='primary'
                      aria-label='Toggle password repeat visibility'
                      onClick={handleClickShowPasswordRepeat}
                    >
                      {formValues.showPasswordRepeat ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </>
        )}
      </Grid>
      <Grid item className={classes.gridItem}>
        <Button
          id='buttonFormSubmit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleFormSubmit}
          disabled={fetching || fetchingWithPassword}
        >
          {t('account.profile.save.changes')}
          {(fetching || fetchingWithPassword) && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </Grid>
  )
}

export default withAccessControl(AccountProfile, ACL_COMPONENTS.ACCOUNT_PROFILE)
