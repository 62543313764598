import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {
  CircularProgress,
  makeStyles,
  Typography,
  DialogTitle
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import withAccessControl from '../HOC/AccessControl'
import StripePlanIdToNameConverter from '../../lib/StripePlanIdToNameConverter'
import Link from '@material-ui/core/Link'
import FormatHelper from '../../lib/FormatHelper'
import { useMutation, useQuery } from 'urql'
import { MESSAGE_TYPES } from '../PopupMessages'
import { navigate } from 'hookrouter'
import { ACL_COMPONENTS } from '../../config'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { green } from '@material-ui/core/colors'
import UserTypes from '../Onboarding/UserTypes'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingTop: '61px',
    paddingBottom: '300px'
  },
  gridItem: {
    width: '50%'
  },
  button: {
    color: '#ffffff',
    marginTop: '10px',
    marginBottom: '10px',
    width: '216px',
    fontWeight: 'bold'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  h5: {
    fontWeight: 'bold'
  },
  deleteAccountLink: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textTransform: 'uppercase'
  },
  bold: {
    fontWeight: 'bold'
  },
  dialogContent: {
    minWidth: '100%',
    padding: '0 20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32
    }
  },
  dialogActions: {
    justifyContent: 'flex-start',
    padding: '0 20px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32
    }
  },
  dialogTitleCloseIcon: {
    marginLeft: 'auto',
    padding: 0,
    paddingRight: 23,
    paddingTop: 10
  },
  rootNotice: {
    position: 'relative',
    marginTop: 20,
    marginBottom: 20,
    padding: '35px 25px',
    background: theme.palette.info.main,
    color: theme.palette.text.primary,
    '&::before': {
      position: 'absolute',
      top: '-7px',
      left: '50%',
      width: 14,
      height: 14,
      background: theme.palette.info.main,
      transform: 'translate(-50%, 0) rotate(45deg)',
      content: '""'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  strikePrice: {
    textDecoration: 'line-through'
  }
}))

const stripeProductsQuery = `
  {
    readProduct {
      id
      description
      metadata {
        FeatureSet
      }
      name
      plans {
        id
        nickname
        amount
        currency
        interval
        interval_count
        active
        trial_period_days
        trial_possible
      }
    },
    readMyselfUpcomingInvoice {
      amount_due
      next_payment_attempt
    }
    readMyself {
      StripePublicKey
      CreditCard {
        id
        name
        last4
        expired
      }
      Company {
        StripeIsTrialing
        StripeTrialingDays
        StripePlanID
        StripeValidThrough
        StripeProductID
        StripeCancelAt
        StripeFuturePlans {
          id
          active
          aggregate_usage
          amount
          amount_decimal
          billing_scheme
          currency
          interval
          interval_count
          nickname
          trial_period_days
          trial_possible
        }
      }
      DossierTemplates {
        edges {
          node {
            HashID
            TemplateName
          }
        }
      }
      FulltextSearchRequests {
        ID
        Title
      }
    }
  }
`

const updateMyselfMutation = `
  mutation UpdateMyself(
    $email: String!
    $removed: Boolean
  ) {
    updateMyself(
      Email: $email
      Removed: $removed
    ) {
      HashID
      Email
      Removed
    }
  }
`

const readStripeCustomerPortalLink = `
  {
    readStripeCustomerPortalLink {
      SessionUrl
    }
  }
`

/**
 * AccountSubscriptions
 *
 * @returns {*}
 * @constructor
 */
const AccountSubscriptions = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen,
    user,
    setMessage,
    unsetUser,
    token,
    setToken,
    unsetToken
  } = useContext(GlobalContext)
  const [planData, setPlanData] = useState(null)
  const stripePlanName = StripePlanIdToNameConverter(
    user.getData().company.stripePlanId
  )
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false)
  const [nextPaymentAttempt, setNextPaymentAttempt] = useState(null)
  const [amountDue, setAmountDue] = useState(null)
  const [futurePlans, setFuturePlans] = useState(null)
  const [actualUserData, setActualUserData] = useState(null)

  const [resultStripeProducts, executeStripeProductsQuery] = useQuery({
    query: stripeProductsQuery,
    requestPolicy: 'network-only',
    pause: true
  })

  const [
    { fetching: fetchingUpdateMyself },
    executeUpdateMyselfMutation
  ] = useMutation(updateMyselfMutation)

  const [
    resultStripeCustomerPortalLink,
    executeStripeCustomerPortalLinkQuery
  ] = useQuery({
    query: readStripeCustomerPortalLink,
    requestPolicy: 'network-only',
    pause: true
  })

  /**
   * useEffect
   */
  useEffect(() => {
    if (planData === null) {
      executeStripeProductsQuery()
    }
  }, [planData, executeStripeProductsQuery, token, setToken])

  // StripeProducts GraphQL trigger and result handling
  useEffect(() => {
    if (!resultStripeProducts.fetching) {
      if (resultStripeProducts.error) {
        // Check if the user need to be logged out
        if (
          resultStripeProducts.error.message.indexOf('User forced logout') !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text: 'Error fetching stripe product data on AccountSubscriptions',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultStripeProducts.error.message
            }
          })
        }
      } else {
        // Query not fetching right now
        if (
          typeof resultStripeProducts.data !== 'undefined' &&
          typeof resultStripeProducts.data.readProduct !== 'undefined' &&
          planData === null
        ) {
          const stripeProduct = resultStripeProducts.data.readProduct.find(
            item => {
              return item.id === user.getData().company.stripeProductId
            }
          )

          const plan = stripeProduct.plans.find(item => {
            return item.id.includes(user.getData().company.stripePlanId)
          })

          setPlanData(plan)
        }

        // upcoming invoice data
        if (
          typeof resultStripeProducts.data !== 'undefined' &&
          typeof resultStripeProducts.data.readMyselfUpcomingInvoice !==
            'undefined' &&
          nextPaymentAttempt === null &&
          amountDue === null
        ) {
          setNextPaymentAttempt(
            resultStripeProducts.data.readMyselfUpcomingInvoice
              .next_payment_attempt * 1000
          )
          setAmountDue(
            resultStripeProducts.data.readMyselfUpcomingInvoice.amount_due
          )
        }

        // future plans
        if (
          typeof resultStripeProducts.data !== 'undefined' &&
          typeof resultStripeProducts.data.readMyself !== 'undefined' &&
          futurePlans === null
        ) {
          setFuturePlans(
            resultStripeProducts.data.readMyself[0].Company.StripeFuturePlans
          )
          user.setData({
            company: {
              stripeFuturePlans:
                resultStripeProducts.data.readMyself[0].Company.StripeFuturePlans
            }
          })
        }

        if (
          typeof resultStripeProducts.data !== 'undefined' &&
          typeof resultStripeProducts.data.readMyself !== 'undefined' &&
          actualUserData === null
        ) {
          // Map the user-data
          user.setData({
            stripePublicKey:
              resultStripeProducts.data.readMyself[0].StripePublicKey || '',
            creditCard: resultStripeProducts.data.readMyself[0].CreditCard,
            company: {
              stripeIsTrialing:
                resultStripeProducts.data.readMyself[0].Company.StripeIsTrialing,
              stripeTrialingDays:
                resultStripeProducts.data.readMyself[0].Company
                  .StripeTrialingDays,
              stripePlanId:
                resultStripeProducts.data.readMyself[0].Company.StripePlanID,
              stripeValidThrough:
                resultStripeProducts.data.readMyself[0].Company
                  .StripeValidThrough,
              stripeProductId:
                resultStripeProducts.data.readMyself[0].Company.StripeProductID,
              stripeCancelAt:
                resultStripeProducts.data.readMyself[0].Company.StripeCancelAt
            }
          })

          setActualUserData(user)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultStripeProducts])

  /**
   * resultStripeCustomerPortalLink handling
   */
  useEffect(() => {
    if (!resultStripeCustomerPortalLink.fetching) {
      if (resultStripeCustomerPortalLink.error) {
        // Check if the user need to be logged out
        if (
          resultStripeCustomerPortalLink.error.message.indexOf(
            'User forced logout'
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text:
              'Error fetching stripe customer portal link on AccountSubscriptions',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultStripeCustomerPortalLink.error.message
            }
          })
        }
      } else {
        // Query not fetching right now
        if (
          typeof resultStripeCustomerPortalLink.data !== 'undefined' &&
          typeof resultStripeCustomerPortalLink.data
            .readStripeCustomerPortalLink !== 'undefined'
        ) {
          window.location.replace(
            resultStripeCustomerPortalLink.data.readStripeCustomerPortalLink
              .SessionUrl
          )
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultStripeCustomerPortalLink])

  /**
   * handleClickManageLocations
   */
  const handleClickDeleteAccount = () => {
    setOpenDeleteAccountDialog(true)
  }

  /**
   * handleCloseManageLocations
   */
  const handleCloseDeleteAccount = () => {
    if (!fetchingUpdateMyself) {
      setOpenDeleteAccountDialog(false)
    }
  }

  /**
   * handleUpgradeToPremium
   */
  const handleUpgradeToPremium = () => {
    setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
  }

  /**
   * handleChangePayment
   */
  const handleChangePayment = () => {
    executeStripeCustomerPortalLinkQuery()
  }

  /**
   * handleAccountDeleteConfirm
   */
  const handleAccountDeleteConfirm = () => {
    if (!fetchingUpdateMyself) {
      // Delete the user (set removed flag)
      executeUpdateMyselfMutation({
        email: user.getData().email,
        removed: true
      }).then(result => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf('User forced logout') !== -1) {
            setMessage(
              MESSAGE_TYPES.ERROR,
              t('general.label.account.deleted')
            )
            unsetUser()
            unsetToken()
            navigate('/')
          }
        }
      })
    }
  }

  /**
   * buildDateString
   *
   * @returns {string}
   */
  const buildDateString = () => {
    return (
      <span className={classes.bold}>
        {FormatHelper.formatDate(new Date(nextPaymentAttempt))}
      </span>
    )
  }

  /**
   * buildDateText
   *
   * @returns {string}
   */
  const buildDateText = () => {
    if (FormatHelper.isValidDate(new Date(nextPaymentAttempt))) {
      return t('account.subscriptions.label.next.invoice') + ' '
    } else {
      return t('account.subscriptions.label.next.invoice.not.determined')
    }
  }

  /**
   * showTrialNotice
   *
   * @returns {boolean}
   */
  const showTrialNotice = () => {
    return !!(
      user.getData().company.stripeIsTrialing &&
      user.getData().mode === UserTypes.SMART
    )
  }

  /**
   * isSubscriptionCanceled
   *
   * @returns {boolean|boolean}
   */
  const isSubscriptionCanceled = () => {
    return user.getData().mode !== UserTypes.FREE && amountDue === 0
  }

  /**
   * determineRemainingDays
   *
   * @returns {number|*}
   */
  const determineRemainingDays = () => {
    if (
      user.getData().company.stripeIsTrialing &&
      user.getData().mode === UserTypes.SMART
    ) {
      return user.getData().company.stripeTrialingDays
    }

    const nowTimestamp = FormatHelper.getTimestampFromDate(new Date())
    const oneDay = 1000 * 60 * 60 * 24
    const difference = nextPaymentAttempt - nowTimestamp

    return Math.round(difference / oneDay)
  }

  /**
   * showRemainingDays
   *
   * @returns {string}
   */
  const showRemainingDays = () => {
    if (showTrialNotice() || isSubscriptionCanceled()) {
      return t('account.subscriptions.label.remaining.term', {
        count: determineRemainingDays()
      })
    } else {
      return ''
    }
  }

  /**
   * showAutomaticRenewal
   *
   * @returns {boolean}
   */
  const showAutomaticRenewal = () => {
    console.log('MOEP', futurePlans)
    if (futurePlans !== null) {
      return !!(
        user.getData().company.stripeFuturePlans.length &&
        user.getData().company.stripeFuturePlans[0].id ===
          user.getData().company.stripePlanId
      )
    }
  }

  /**
   * showNextPaymentAttempt
   *
   * @returns {boolean}
   */
  const showNextPaymentAttempt = () => {
    if (futurePlans !== null) {
      return !!(
        user.getData().company.stripeFuturePlans.length &&
        !user.getData().company.stripeFuturePlans[0].id.includes('gratis')
      )
    }
  }

  /**
   * deleteDialog
   */
  const deleteDialog = () => (
    <>
      <DialogTitle
        id='manage-locations-title'
        className={classes.dialogTitleCloseIcon}
      >
        <IconButton onClick={handleCloseDeleteAccount}>
          <CloseIcon id='closeDialog' />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography gutterBottom className={classes.deleteAccountLink}>
          {t('account.subscriptions.delete.account.confirm')}
        </Typography>
        <Button
          id='buttonConfirmAccountDeletionConfirm'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleAccountDeleteConfirm}
          disabled={fetchingUpdateMyself}
        >
          {t('general.confirm')}
          {fetchingUpdateMyself && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          id='buttonConfirmAccountDeletionClose'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleCloseDeleteAccount}
          disabled={fetchingUpdateMyself}
        >
          {t('general.label.abort')}
        </Button>
      </DialogContent>
    </>
  )

  /**
   * return
   */
  return (
    <>
      <Grid
        container
        spacing={2}
        direction='column'
        justify='center'
        alignItems='center'
        margin='normal'
        padding='normal'
        className={classes.gridContainer}
      >
        <Grid item className={classes.gridItem}>
          <Typography variant='body1'>
            {t('account.label.your.membership')}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography variant='h5' className={classes.h5}>
            {t('general.label.smartconext')}{' '}
            {t(`general.label.${stripePlanName}`)}
          </Typography>
          {showTrialNotice() && t('general.label.test.period')}
          <br />
          {showRemainingDays()}
        </Grid>
        {stripePlanName !== 'free' && (
          <>
            <Grid item className={classes.gridItem}>
              {resultStripeProducts.fetching && <CircularProgress />}
              {typeof planData !== 'undefined' && planData !== null && (
                <Typography
                  className={showTrialNotice() ? classes.strikePrice : ''}
                >
                  {planData.currency.toUpperCase()}{' '}
                  {parseInt(planData.amount).toFixed(2) / 100} /{' '}
                  {planData.interval === 'month'
                    ? t('general.label.month')
                    : t('general.label.year')}
                </Typography>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              {!showTrialNotice() && (
                <>
                  {showAutomaticRenewal() && (
                    <Typography>
                      {t('account.subscriptions.label.automatic.renewal')}
                    </Typography>
                  )}
                  {showNextPaymentAttempt() && nextPaymentAttempt !== null && (
                    <Typography>
                      {buildDateText()}
                      {buildDateString()}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
        <Grid item className={classes.gridItem}>
          <Button
            id='buttonUpgradeToPremium'
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={handleUpgradeToPremium}
          >
            {stripePlanName === 'free'
              ? t('account.label.upgrade')
              : t('account.label.change.subscription')}
          </Button>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Button
            id='buttonChangePayment'
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={handleChangePayment}
          >
            {t('account.label.change.payment')}
          </Button>
        </Grid>
        {stripePlanName === 'free' && (
          <Grid item className={classes.gridItem}>
            <Link onClick={handleClickDeleteAccount} variant='body2'>
              <Typography gutterBottom className={classes.deleteAccountLink}>
                {t('account.subscriptions.delete.account')}
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={openDeleteAccountDialog}
        onClose={handleCloseDeleteAccount}
        aria-describedby='delete-account-description'
        maxWidth='xs'
      >
        {deleteDialog()}
      </Dialog>
    </>
  )
}

export default withAccessControl(
  AccountSubscriptions,
  ACL_COMPONENTS.ACCOUNT_SUBSCRIPTIONS
)
