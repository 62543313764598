import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GlobalContext from "../../lib/GlobalContext";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import GooglePlacesAutosuggestAddress from "../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestAddress";
import { useMutation, useQuery } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Joi from "@hapi/joi";
import GetSafe from "../../lib/GetSafe";
import withAccessControl from "../HOC/AccessControl";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import { CHANGE_TYPES } from "../../pages/Projects";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  changeLocation: {
    color: theme.palette.primary.main,
    width: "auto",
    height: "100%",
    fontWeight: "bold",
  },
  dialogTitleCloseIcon: {
    marginLeft: "auto",
    padding: 0,
    paddingRight: 23,
    paddingTop: 10,
  },
  root: {
    width: "100%",
    maxWidth: 444,
  },
  addButtonText: {
    paddingTop: 3,
    paddingRight: 15,
    paddingBottom: 3,
    paddingLeft: 15,
    fontWeight: "bold",
  },
  editIcon: {
    paddingRight: 5,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 24,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
  },
  iconDisabled: {
    fontSize: 30,
  },
  iconLonely: {
    marginRight: 0,
    [theme.breakpoints.up("sm")]: {
      marginRight: 35,
    },
  },
  listItem: {
    padding: 0,
  },
  dialogContent: {
    minWidth: "100%",
    padding: "0 20px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32,
    },
  },
  dialogActions: {
    justifyContent: "flex-start",
    padding: "0 20px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32,
    },
  },
  location: {
    paddingRight: 50,
    color: "#525252",
  },
  locationText: {
    fontSize: theme.typography.fontSize,
  },
  textField: {
    width: "100%",
    marginRight: 0,
    marginLeft: 0,
  },
  menu: {
    width: 200,
  },
  autocompleteResultWrapper: {
    position: "relative",
    textAlign: "center",
    zIndex: 2,
  },
  autocompleteResult: {
    position: "absolute",
    padding: theme.spacing(3),
    width: "100%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  undo: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontSize: 14,
  },
  link: {
    cursor: "pointer",
  },
  removed: {
    opacity: 0.5,
  },
  bold: {
    display: "block",
    paddingTop: 12,
    paddingBottom: 12,
    color: "#525252",
    fontWeight: "bold",
  },
  homeIcon: {
    color: "#999999",
    fontSize: 30,
  },
  companyHeader: {
    color: "#A6A6A6",
    fontSize: 14,
    marginLeft: 12,
    marginTop: 20,
  },
  companyAddress: {
    color: "#525252",
    fontSize: 16,
    marginLeft: 12,
    marginBottom: 30,
  },
}));

const createLocationMutation = `
  mutation createLocation(
    $title: String!
    $street: String
    $zip: String
    $city: String
    $long: Float
    $lat: Float
    $radius: Int
    $version: Int
    $userHashID: String!
    $constructionSums: [String]
  ) {
    createLocation(
      Title: $title,
      Street: $street,
      Zip: $zip,
      City: $city,
      Longitude: $long,
      Latitude: $lat,
      Radius: $radius,
      Version: $version,
      UserHashID: $userHashID,
      ConstructionSums: $constructionSums
    ) {
      HashID
      Title
      Street
      Zip
      City
      Radius
      Longitude
      Latitude
      Version
      UserHashID
      ConstructionSumList {
        edges {
          node {
            Code
          }
        }
      }
    }
  }
`;

const updateLocationMutation = `
  mutation UpdateUser(
    $hashId: String!,
    $email: String!,
    $locations:[LocationInput]
  ) {
    updateUser(
      HashID: $hashId,
      Email: $email,
      Locations: $locations
    ) {
      HashID
    }
  }
`;

const querySmartconextConf = `
  {
    readSmartconextConf {
      air_distance_radius_list
      construction_sums {
        ID,
        Code,
        MinRange,
        MaxRange,
        Title
      }
    }
  }
`;

const updateCompanyMutation = `
  mutation UpdateCompany (
      $hashID: String!,
      $distance: Int,
      $constructionSums: [String]
    ) {
      updateCompany (
        HashID: $hashID, 
        Distance: $distance,
        ConstructionSums: $constructionSums
      ) {
        Name
        Canton
        Distance
        ConstructionSumList {
          edges {
            node {
              Code
            }
          }
        }
      }
    }
`;
// Parse the gql result edge-node list to an location-construction-sum array
export const parseConstructionSumList = (gqlEdges = []) => {
  const constructionSums = [];

  gqlEdges.forEach((gqlEdge) => {
    constructionSums.push(gqlEdge.node.Code);
  });

  return constructionSums;
};

// Parse the location-construction-sum array to gql result edge-node list
export const reverseParseConstructionSumList = (constructionSumCodes) => {
  const constructionSumEdges = [];

  constructionSumCodes.forEach((constructionSumCode) => {
    constructionSumEdges.push({ node: { Code: constructionSumCode } });
  });

  return { edges: constructionSumEdges };
};

/**
 * ProjectsLocationsHandler
 *
 * @returns {*}
 * @constructor
 */
const ProjectsLocationsHandler = (props) => {
  const classes = useStyles();
  const {
    user,
    setUser,
    token,
    setToken,
    setMessage,
    selectedLocationTabValue,
    setSelectedLocationTabValue,
    unsetUser,
    unsetToken,
    setMarkAsReadByLocation,
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [listData, setListData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [autoSuggestError, setAutoSuggestError] = useState(false);
  const [radiusSelectError, setRadiusSelectError] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [editableElement, setEditableElement] = useState({
    HashID: null,
    Title: "",
    Street: "",
    Radius: "",
    Latitude: "",
    Longitude: "",
    Sort: null,
    ConstructionSums: null,
  });
  const [airDistanceResultList, setAirDistanceResultList] = useState([]);
  const [constructionSumsResultList, setConstructionSumsResultList] = useState(
    []
  );
  const [editCompanyLocation, setEditCompanyLocation] = useState(false);
  const [{ fetchingCreateLocation }, executeCreateLocationMutation] =
    useMutation(createLocationMutation);
  const [{ fetchingUpdateLocation }, executeUpdateLocationMutation] =
    useMutation(updateLocationMutation);
  const [{ fetchingUpdateCompany }, executeUpdateCompanyMutation] = useMutation(
    updateCompanyMutation
  );
  const [result, executeQuerySmartconextConf] = useQuery({
    query: querySmartconextConf,
    pause: true,
  });

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    HashID: Joi.string().allow(null),
    Title: Joi.string().required(),
    Street: Joi.string(),
    Radius: Joi.number().required(),
    Latitude: Joi.number(),
    Longitude: Joi.number(),
    Sort: Joi.number().allow(null),
    ConstructionSums: Joi.array().allow(null),
  });

  /**
   * Joi valiation schema for company change
   */
  const editCompanySchema = Joi.object({
    Radius: Joi.number().required(),
    ConstructionSumList: Joi.object().allow(null, {}),
  });

  /**
   * useEffect
   */
  useEffect(() => {
    setListData(user.getData().locations);
    executeQuerySmartconextConf();
  }, [
    listData,
    editableElement,
    setEditableElement,
    user,
    executeQuerySmartconextConf,
    token,
    setToken,
  ]);

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!result.fetching && token.isSet()) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching smartconext conf on ProjectsLocationsHandler",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readSmartconextConf !== "undefined"
        ) {
          if (airDistanceResultList.length === 0) {
            setAirDistanceResultList(
              result.data.readSmartconextConf[0].air_distance_radius_list
            );
          }
          if (constructionSumsResultList.length === 0) {
            setConstructionSumsResultList(
              result.data.readSmartconextConf[0].construction_sums
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, token]);

  /**
   * handleClickManageLocations
   */
  const handleClickManageLocations = () => {
    setOpenDialog(true);
  };

  /**
   * handleCloseManageLocations
   */
  const handleCloseManageLocations = () => {
    setOpenDialog(false);
    setIsEditable(false);
  };

  /**
   * handleDeleteLocation
   */
  const handleDeleteLocation = (elementId) => {
    let removedItem = null;
    const data = listData.map((item) => {
      if (item.HashID === elementId) {
        item.Removed = true;
        removedItem = item;
      }
      return item;
    });
    user.setData({
      locations: data,
    });

    executeUpdateLocationMutation({
      hashId: user.getData().hashID,
      email: user.getData().email,
      locations: [
        {
          HashID: removedItem.HashID,
          Removed: removedItem.Removed,
        },
      ],
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.delete"));
          ExternalErrorLogger.log({
            text: "Error delete location on ProjectsLocationsHandler",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.delete.success"));
      }
    });

    setUser(user);
    if (elementId === selectedLocationTabValue) {
      setSelectedLocationTabValue(false);
    }
  };

  /**
   * handleUndoDeleteLocation
   *
   * @param elementId
   */
  const handleUndoDeleteLocation = (elementId) => {
    let removedItem = null;
    const data = listData.map((item) => {
      if (item.HashID === elementId) {
        removedItem = item;
      }
      return item;
    });
    user.setData({
      locations: data,
    });

    executeUpdateLocationMutation({
      hashId: user.getData().hashID,
      email: user.getData().email,
      locations: [
        {
          HashID: removedItem.HashID,
          Removed: false,
        },
      ],
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else if (
          result.error.message.includes(
            ` User with HashID '${user.getData().hashID}' not found`
          )
        ) {
          setMessage(MESSAGE_TYPES.INFO, t("error.item.already.deleted"));
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error undo delete location on ProjectsLocationsHandler",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
        const data = listData.map((item) => {
          if (item.HashID === elementId) {
            item.Removed = false;
            removedItem = item;
          }
          return item;
        });
        user.setData({
          locations: data,
        });

        setUser(user);
      }
    });
  };

  /**
   * handleEditCompanyLocation
   */
  const handleEditCompanyLocation = () => {
    setEditableElement({
      Radius: user.getData().company.distance,
      ConstructionSums: parseConstructionSumList(
        GetSafe(() => user.getData().company.constructionSums.edges, [])
      ),
    });
    setEditCompanyLocation(true);
  };

  /**
   * handleEditLocation
   *
   * @param element
   */
  const handleEditLocation = (element) => {
    setEditableElement(element);
    setIsEditable(true);
  };

  /**
   * handleCloseEditLocations
   */
  const handleCloseEditLocations = () => {
    setIsEditable(false);
    setEditCompanyLocation(false);
  };

  /**
   * handleAddressChange
   *
   * @param autosuggestResult
   */
  const handleAddressChange = (autosuggestResult) => {
    setAutoSuggestError(false);
    const addressArray = autosuggestResult.address.split(",");
    const name = addressArray[addressArray.length - 2];
    const newElement = {
      HashID: editableElement.HashID,
      Title: name.trim(),
      Street: addressArray.join(", ").trim(),
      Radius: editableElement.range,
      Latitude: autosuggestResult.latLng.lat,
      Longitude: autosuggestResult.latLng.lng,
      Sort: editableElement.Sort,
      ConstructionSums: editableElement.ConstructionSums,
    };
    setEditableElement(newElement);
  };

  /**
   * handleRangeChenge
   *
   * @param event
   */
  const handleRangeChange = (event) => {
    setRadiusSelectError(false);
    const newRange = event.target.value;
    const newElement = {
      HashID: editableElement.HashID,
      Title: editableElement.Title,
      Street: editableElement.Street,
      Radius: newRange,
      Latitude: editableElement.Latitude,
      Longitude: editableElement.Longitude,
      Sort: editableElement.Sort,
      ConstructionSums: editableElement.ConstructionSums,
    };
    setEditableElement(newElement);
  };

  /**
   * handleSaveEditLocations
   */
  const handleSaveEditLocations = () => {
    setSaveInProgress(true);

    // Validate editableElement data
    const { error } = schema.validate(editableElement, { abortEarly: false });
    if (error) {
      error.details.map((item) => {
        if (item.context.label === "Radius") {
          setRadiusSelectError(true);
        }
        if (item.context.label === "Title") {
          setAutoSuggestError(true);
        }
        setSaveInProgress(false);
        return item;
      });
      return;
    }

    let newListData = [];

    // If it is a new location HashID will be null
    if (editableElement.HashID === null) {
      listData.forEach((element) => {
        newListData.push(element);
      });

      executeCreateLocationMutation({
        title: editableElement.Title,
        street: editableElement.Street,
        zip: "",
        city: "",
        long: editableElement.Longitude,
        lat: editableElement.Latitude,
        radius: editableElement.Radius,
        version: 1,
        userHashID: user.getData().hashID,
        constructionSums: editableElement.ConstructionSums,
      }).then((result) => {
        setSaveInProgress(false);

        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error save location on ProjectsLocationsHandler",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
          const data = result.data.createLocation[0];

          const newElement = {
            HashID: data.HashID,
            Title: data.Title,
            Street: data.Street,
            Radius: data.Radius,
            Latitude: data.Latitude,
            Longitude: data.Longitude,
            ConstructionSums: parseConstructionSumList(
              data.ConstructionSumList.edges
            ),
          };

          newListData.push(newElement);

          user.setData({
            locations: newListData,
          });

          setUser(user);

          // setOpenDialog(false)
          setIsEditable(false);

          // reset pagination
          props.setPage(0);
          props.setChangeType(CHANGE_TYPES.INIT);
        }
      });
      // Otherwise it is an existing location
    } else {
      listData.forEach((element, indexInListData) => {
        if (element.HashID === editableElement.HashID) {
          executeUpdateLocationMutation({
            hashId: user.getData().hashID,
            email: user.getData().email,
            locations: [
              {
                HashID: editableElement.HashID,
                Title: editableElement.Title,
                Street: editableElement.Street,
                Radius: editableElement.Radius,
                Latitude: editableElement.Latitude,
                Longitude: editableElement.Longitude,
                ConstructionSums: editableElement.ConstructionSums,
                Sort: editableElement.Sort,
              },
            ],
          }).then((result) => {
            setSaveInProgress(false);

            if (result.error) {
              // Check if the user need to be logged out
              if (result.error.message.indexOf("User forced logout") !== -1) {
                setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
                unsetUser();
                unsetToken();
                navigate("/");
              } else {
                setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
                ExternalErrorLogger.log({
                  text: "Error update location on ProjectsLocationsHandler",
                  data: {
                    token: JSON.stringify(token.getData()),
                    user: JSON.stringify(user.getData()),
                    errorMessage: result.error.message,
                  },
                });
              }
            } else {
              setMessage(
                MESSAGE_TYPES.SUCCESS,
                t("general.label.save.success")
              );

              setMarkAsReadByLocation({
                hashID: editableElement.HashID,
              });

              newListData = user.getData().locations;
              newListData[indexInListData] = editableElement;

              user.setData({
                locations: newListData,
              });

              setUser(user);
              setIsEditable(false);

              // Reset pagination
              props.setPage(0);
              props.setChangeType(CHANGE_TYPES.INIT);
            }
          });
        } else {
          newListData.push(element);
        }
      });
    }
  };

  /**
   * handleSaveEditCompanyLocation
   */
  const handleSaveEditCompanyLocation = () => {
    setSaveInProgress(true);

    const { error } = editCompanySchema.validate(editableElement, {
      abortEarly: false,
      stripUnknown: true,
    });

    if (error) {
      error.details.map((item) => {
        if (item.context.label === "Radius") {
          setRadiusSelectError(true);
        }
        setSaveInProgress(false);
        return item;
      });
      return;
    }

    executeUpdateCompanyMutation({
      hashID: user.getData().company.hashID,
      distance: editableElement.Radius,
      constructionSums: editableElement.ConstructionSums,
    }).then((result) => {
      setSaveInProgress(false);

      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error update company location on ProjectsLocationsHandler",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));

        setMarkAsReadByLocation({
          hashID: user.getData().company.hashID,
        });

        user.setData({
          company: {
            distance: editableElement.Radius,
            constructionSums: reverseParseConstructionSumList(
              editableElement.ConstructionSums
            ),
          },
        });

        setUser(user);
        setIsEditable(false);
        setEditCompanyLocation(false);
      }
    });
  };

  /**
   * handleAddLocation
   */
  const handleAddLocation = () => {
    setEditableElement({
      HashID: null,
      Title: "",
      Street: "",
      Radius: "",
      Latitude: "",
      Longitude: "",
      ConstructionSum: null,
    });
    setIsEditable(true);
  };

  /**
   * reorder
   *
   * @param list
   * @param startIndex
   * @param endIndex
   * @returns {unknown[]}
   */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * onDragEnd
   *
   * @param result
   */
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedListData = reorder(
      listData,
      result.source.index,
      result.destination.index
    );

    reorderedListData.map((item, index) => {
      item.Sort = index;
      delete item.__typename;
      delete item.ConstructionSumList;
      return item;
    });

    user.setData({
      locations: reorderedListData,
    });
    setUser(user);

    executeUpdateLocationMutation({
      hashId: user.getData().hashID,
      email: user.getData().email,
      locations: reorderedListData,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error save location order on ProjectsLocationsHandler",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));

        user.setData({
          locations: reorderedListData,
        });
        setUser(user);
      }
    });
  };

  /**
   * LocationDataListDialog
   */
  const LocationDataListDialog = (
    <>
      <DialogTitle
        id="manage-locations-title"
        className={classes.dialogTitleCloseIcon}
      >
        <IconButton onClick={handleCloseManageLocations}>
          <CloseIcon id="closeDialog" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <span className={classes.bold}>{t("general.label.my.company")}</span>
        <List className={classes.root}>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <HomeIcon className={classes.homeIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.location}
              primary={user.getData().company.city}
              secondary={
                <Typography className={classes.locationText}>
                  {" "}
                  {`${user.getData().company.street}, ${
                    user.getData().company.distance
                  } ${t("location.label.driving.route")}`}
                </Typography>
              }
            />
            <IconButton
              className={classes.iconLonely}
              id={"editButton" + user.getData().company.hashID}
              onClick={() => handleEditCompanyLocation()}
            >
              <EditIcon className={classes.icon} />
            </IconButton>
          </ListItem>
        </List>
        <span className={classes.bold}>
          {t("general.label.further.searchareas")}
        </span>
        <List className={classes.root}>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {listData.map((item, index) => {
                    if (item.Removed) {
                      return (
                        <ListItem
                          key={item.HashID}
                          className={`${classes.listItem} ${classes.removed}`}
                        >
                          <ListItemIcon>
                            <DragIndicatorIcon
                              className={classes.iconDisabled}
                              color="disabled"
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.location}
                            primary={t("favorites.label.removed")}
                            secondary={
                              <span
                                id={"undoDeleteLocation-" + item.HashID}
                                className={`${classes.undo} ${classes.link}`}
                                onClick={() =>
                                  handleUndoDeleteLocation(item.HashID)
                                } // eslint-disable-line
                              >
                                {t("general.label.undo")}
                              </span>
                            }
                          />
                        </ListItem>
                      );
                    } else {
                      return (
                        <Draggable
                          key={item.HashID}
                          draggableId={item.HashID}
                          index={index}
                        >
                          {(provided) => (
                            <ListItem
                              className={classes.listItem}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItemIcon>
                                <DragIndicatorIcon className={classes.icon} />
                              </ListItemIcon>
                              <ListItemText
                                className={classes.location}
                                primary={item.Title}
                                secondary={
                                  <Typography className={classes.locationText}>
                                    {" "}
                                    {`${item.Street}, ${item.Radius} ${t(
                                      "location.label.kmRadius"
                                    )}`}
                                  </Typography>
                                }
                              />
                              <IconButton
                                id={"editButton" + item.HashID}
                                onClick={() => handleEditLocation(item)}
                              >
                                <EditIcon className={classes.icon} />
                              </IconButton>
                              <IconButton
                                id={"deleteButton" + item.HashID}
                                onClick={() =>
                                  handleDeleteLocation(item.HashID)
                                } // eslint-disable-line
                              >
                                <DeleteIcon className={classes.icon} />
                              </IconButton>
                            </ListItem>
                          )}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          id="addLocationButton"
          onClick={handleAddLocation}
          color="primary"
          variant="outlined"
          className={classes.addLocationButton}
        >
          <AddIcon />
          <Typography className={classes.addButtonText}>
            {t("general.label.add")}
          </Typography>
        </Button>
      </DialogActions>
    </>
  );

  /**
   * getSpinner
   */
  const getSpinner = (
    <CircularProgress size={24} className={classes.buttonProgress} />
  );

  /**
   * handleBuildingSumsCheckboxes
   *
   * @param event
   */
  const handleBuildingSumsCheckboxes = (event) => {
    const constructionSumsReady =
      editableElement.ConstructionSums !== null &&
      typeof editableElement.ConstructionSums !== "undefined";
    let constructionSums = [];

    if (
      constructionSumsReady &&
      !editableElement.ConstructionSums.includes(event.target.name)
    ) {
      constructionSums = editableElement.ConstructionSums.concat([
        event.target.name,
      ]);
    } else if (
      constructionSumsReady &&
      editableElement.ConstructionSums.includes(event.target.name)
    ) {
      const index = editableElement.ConstructionSums.indexOf(event.target.name);
      editableElement.ConstructionSums.splice(index, 1);
      constructionSums = editableElement.ConstructionSums;
    } else {
      constructionSums = [event.target.name];
    }

    const newElement = {
      HashID: editableElement.HashID,
      Title: editableElement.Title,
      Street: editableElement.Street,
      Radius: editableElement.Radius,
      Latitude: editableElement.Latitude,
      Longitude: editableElement.Longitude,
      ConstructionSums: constructionSums,
    };
    setEditableElement(newElement);
  };

  /**
   * handleUpgradeToPremium
   */
  const handleUpgradeToPremium = () => {
    setUpgradeToPremiumOpen(!upgradeToPremiumOpen);
  };

  /**
   * BuildingSumsFilter
   *
   * @param constructionSums
   * @returns {JSX.Element}
   * @constructor
   */
  const BuildingSumsFilter = ({ constructionSums }) => {
    return (
      <FormGroup>
        <FormLabel className={classes.bold}>
          {t("general.label.buildingsums")}
        </FormLabel>
        {user.isPro() &&
          constructionSumsResultList.map((item) => {
            return (
              <FormControlLabel
                key={item.Code}
                control={
                  <Checkbox
                    checked={
                      constructionSums !== null &&
                      typeof constructionSums !== "undefined"
                        ? constructionSums.includes(item.Code)
                        : false
                    }
                    onChange={handleBuildingSumsCheckboxes}
                    name={item.Code.toString()}
                    color="primary"
                  />
                }
                label={item.Title}
              />
            );
          })}
        {!user.isPro() && (
          <span className={classes.link} onClick={handleUpgradeToPremium}>
            {t("projects.free.route.premium.pro")}
          </span>
        )}
      </FormGroup>
    );
  };

  /**
   * LocationDataEditDialog
   */
  const LocationDataEditDialog = (
    <>
      <DialogContent className={classes.dialogContent}>
        <GooglePlacesAutosuggestAddress
          onSuggestionSelect={handleAddressChange}
          presetValue={editableElement.Street}
          autoSuggestError={autoSuggestError}
        />
        <TextField
          id="locationEditRange"
          select
          label={t("location.label.radius")}
          className={classes.textField}
          value={editableElement.Radius || ""}
          onChange={(event) => {
            handleRangeChange(event);
          }}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
          variant="outlined"
          error={GetSafe(() => radiusSelectError, false)}
          helperText={GetSafe(
            () => (radiusSelectError ? t("error.form.empty") : ""),
            ""
          )}
        >
          {airDistanceResultList.map((value, key) => (
            <MenuItem key={key} value={value}>
              {value + " km"}
            </MenuItem>
          ))}
        </TextField>
        <BuildingSumsFilter
          constructionSums={editableElement.ConstructionSums}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          id="closeEditButton"
          onClick={handleCloseEditLocations}
          color="primary"
          className={classes.addLocationButton}
        >
          <Typography className={classes.addButtonText}>
            {t("general.label.abort")}
          </Typography>
        </Button>
        <Button
          id="saveEditButton"
          onClick={handleSaveEditLocations}
          color="primary"
          className={classes.addLocationButton}
          disabled={saveInProgress}
        >
          <Typography className={classes.addButtonText}>
            {t("general.label.save")}
            {fetchingCreateLocation && fetchingUpdateLocation && getSpinner}
          </Typography>
          {saveInProgress && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </>
  );

  /**
   * LocationDataEditCompanyDialog
   */
  const LocationDataEditCompanyDialog = (
    <>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.companyHeader}>
          {t("general.label.my.company")}
        </Typography>
        <Typography className={classes.companyAddress}>
          {user.getData().company.city}, {user.getData().company.street}
        </Typography>
        <TextField
          id="locationEditRange"
          select
          label={t("location.label.max.distance")}
          className={classes.textField}
          value={editableElement.Radius || 0}
          onChange={(event) => {
            handleRangeChange(event);
          }}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
          variant="outlined"
          error={GetSafe(() => radiusSelectError, false)}
          helperText={GetSafe(
            () => (radiusSelectError ? t("error.form.empty") : ""),
            ""
          )}
        >
          {airDistanceResultList.map((value, key) => (
            <MenuItem key={key} value={value}>
              {value + " km"}
            </MenuItem>
          ))}
        </TextField>
        <BuildingSumsFilter
          constructionSums={editableElement.ConstructionSums}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          id="closeEditButton"
          onClick={handleCloseEditLocations}
          color="primary"
          className={classes.addLocationButton}
        >
          <Typography className={classes.addButtonText}>
            {t("general.label.abort")}
          </Typography>
        </Button>
        <Button
          id="saveEditButton"
          onClick={handleSaveEditCompanyLocation}
          color="primary"
          className={classes.addLocationButton}
          disabled={saveInProgress}
        >
          <Typography className={classes.addButtonText}>
            {t("general.label.save")}
            {fetchingUpdateCompany && getSpinner}
          </Typography>
          {saveInProgress && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogActions>
    </>
  );

  /**
   * return
   */
  return (
    <>
      <Button
        id="buttonChangeLocation"
        className={classes.changeLocation}
        onClick={handleClickManageLocations}
      >
        <EditIcon className={classes.editIcon} />
        {t("location.label.manage")}
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseManageLocations}
        aria-describedby="manage-locations-description"
        maxWidth="xs"
      >
        {!isEditable && !editCompanyLocation && LocationDataListDialog}
        {isEditable && LocationDataEditDialog}
        {!isEditable && editCompanyLocation && LocationDataEditCompanyDialog}
      </Dialog>
    </>
  );
};

export default withAccessControl(
  ProjectsLocationsHandler,
  ACL_COMPONENTS.PROJECTS_LOCATION_HANDLER
);
