import { Grid, TextField } from '@material-ui/core'
import clsx from 'clsx'
import GooglePlacesAutosuggestCity from '../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestCity'
import GooglePlacesAutosuggestStreet from '../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestStreet'
import GooglePlacesAutosuggestZip from '../GooglePlacesAutosuggestFields/GooglePlacesAutosuggestZip'
import { useTranslation } from 'react-i18next'
import GetSafe from '../../lib/GetSafe'
import React, { useState } from 'react'
import PhoneField from '../PhoneField'

const CompanyBasicSettingsForm = ({
  classes,
  formValues,
  formValueErrors,
  setFormValues,
  streetFormValue,
  setStreetFormValue,
  zipFormValue,
  setZipFormValue,
  cityFormValue,
  setCityFormValue,
  readOnly = false
}) => {
  const { t } = useTranslation()
  const [activePhone, setActivePhone] = useState('state-phoneField-closed')
  const [activeMobile, setActiveMobile] = useState('state-mobileField-closed')
  const [activeFax, setActiveFax] = useState('state-faxField-closed')

  /**
   * handleStreetChange
   *
   * @param autosuggestResult
   */
  const handleStreetChange = autosuggestResult => {
    setFormValues({
      ...formValues,
      street:
        autosuggestResult.addressObject.street +
        ' ' +
        autosuggestResult.addressObject.number,
      zip: autosuggestResult.addressObject.zip,
      city:
        autosuggestResult.addressObject.city === ''
          ? formValues.city
          : autosuggestResult.addressObject.city
    })
  }

  /**
   * handleZipChange
   *
   * @param autosuggestResult
   */
  const handleZipChange = autosuggestResult => {
    setFormValues({
      ...formValues,
      zip: autosuggestResult.addressObject.zip,
      city:
        autosuggestResult.addressObject.city === ''
          ? formValues.city
          : autosuggestResult.addressObject.city
    })
  }

  /**
   * handleCityChange
   *
   * @param autosuggestResult
   */
  const handleCityChange = autosuggestResult => {
    setFormValues({ ...formValues, city: autosuggestResult.addressObject.city })
  }

  /**
   * handlePhoneChange
   *
   * @param event
   */
  const handlePhoneChange = event => {
    setFormValues({ ...formValues, phone: event.target.value })
  }


  /**
   * handleMobileChange
   *
   * @param event
   */
  const handleMobileChange = event => {
    setFormValues({ ...formValues, mobile: event.target.value })
  }

  /**
   * handleFaxChange
   *
   * @param event
   */
  const handleFaxChange = event => {
    setFormValues({ ...formValues, fax: event.target.value })
  }

  /**
   * handleWebsiteChange
   *
   * @param event
   */
  const handleWebsiteChange = event => {
    setFormValues({ ...formValues, website: event.target.value })
  }

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleEmailChange = event => {
    setFormValues({ ...formValues, email: event.target.value })
  }

  /**
   * handleContactPersonChange
   *
   * @param event
   */
  const handleContactPersonChange = event => {
    setFormValues({ ...formValues, contactPerson: event.target.value })
  }

  return (
    <>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputName'
          variant='outlined'
          align='left'
          label={t('form.label.company')}
          value={formValues.name}
          margin='normal'
          fullWidth
          disabled
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputContactPerson'
          variant='outlined'
          align='left'
          label={t('form.label.contactPerson')}
          value={formValues.contactPerson}
          margin='normal'
          fullWidth
          error={GetSafe(() => formValueErrors.contactPerson.hasError, false)}
          helperText={GetSafe(() => formValueErrors.contactPerson.message, '')}
          onChange={handleContactPersonChange}
          disabled={readOnly}
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Grid item>
          <GooglePlacesAutosuggestStreet
            onSuggestionSelect={handleStreetChange}
            presetValue={formValues.street}
            autoSuggestError={GetSafe(
              () => formValueErrors.street.hasError,
              false
            )}
            helperText={GetSafe(() => formValueErrors.street.message, '')}
            formValue={streetFormValue}
            formValueSetter={setStreetFormValue}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.gridItem}>
        <GooglePlacesAutosuggestZip
          onSuggestionSelect={handleZipChange}
          presetValue={formValues.zip}
          autoSuggestError={GetSafe(() => formValueErrors.zip.hasError, false)}
          helperText={GetSafe(() => formValueErrors.zip.message, '')}
          formValue={zipFormValue}
          formValueSetter={setZipFormValue}
          disabled={readOnly}
        />
        <GooglePlacesAutosuggestCity
          onSuggestionSelect={handleCityChange}
          presetValue={formValues.city}
          autoSuggestError={GetSafe(() => formValueErrors.city.hasError, false)}
          helperText={GetSafe(() => formValueErrors.city.message, '')}
          formValue={cityFormValue}
          formValueSetter={setCityFormValue}
          disabled={readOnly}
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.itemPhone, activePhone)}
        onClick={() => {
          if (!readOnly) {
            if (activePhone === 'state-phoneField-closed') {
              setActivePhone('state-phoneField-open')
            } else {
              setActivePhone('state-phoneField-closed')
            }
          }
        }}
      >
        <PhoneField
          id='inputPhone'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.phone.hasError, false)}
          label={t('form.label.phone')}
          value={formValues.phone}
          helperText={GetSafe(() => formValueErrors.phone.message, '')}
          onChange={handlePhoneChange}
          margin='normal'
          fullWidth
          defaultCountry='CH'
          InputProps={{ classes: { input: classes.input } }}
          disabled={readOnly}
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.itemMobile, activeMobile)}
        onClick={() => {
          if (!readOnly) {
            if (activeMobile === 'state-mobileField-closed') {
              setActiveMobile('state-mobileField-open')
            } else {
              setActiveMobile('state-mobileField-closed')
            }
          }
        }}
      >
        <PhoneField
          id='inputMobile'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.mobile.hasError, false)}
          label={t('form.label.mobile')}
          value={formValues.mobile}
          helperText={GetSafe(() => formValueErrors.mobile.message, '')}
          onChange={handleMobileChange}
          margin='normal'
          fullWidth
          defaultCountry='CH'
          InputProps={{ classes: { input: classes.input } }}
          disabled={readOnly}
        />
      </Grid>
      <Grid
        item
        className={clsx(classes.gridItem, classes.itemFax, activeFax)}
        onClick={() => {
          if (!readOnly) {
            if (activeFax === 'state-faxField-closed') {
              setActiveFax('state-faxField-open')
            } else {
              setActiveFax('state-faxField-closed')
            }
          }
        }}
      >
        <PhoneField
          id='inputFax'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.fax.hasError, false)}
          label={t('form.label.fax')}
          value={formValues.fax}
          helperText={GetSafe(() => formValueErrors.fax.message, '')}
          onChange={handleFaxChange}
          margin='normal'
          fullWidth
          defaultCountry='CH'
          InputProps={{ classes: { input: classes.input } }}
          disabled={readOnly}
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputWebsite'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.website.hasError, false)}
          label={t('form.label.website')}
          value={formValues.website}
          helperText={GetSafe(() => formValueErrors.website.message, '')}
          onChange={handleWebsiteChange}
          margin='normal'
          fullWidth
          disabled={readOnly}
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <TextField
          id='inputEmail'
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.email.hasError, false)}
          label={t('form.label.email')}
          value={formValues.email}
          helperText={GetSafe(() => formValueErrors.email.message, '')}
          onChange={handleEmailChange}
          margin='normal'
          fullWidth
          disabled={readOnly}
        />
      </Grid>
    </>
  )
}

export default CompanyBasicSettingsForm
