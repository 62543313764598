import React, { useEffect } from "react";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { useQuery } from "urql";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  tab: {
    height: 64,
    width: "auto",
    minWidth: "auto",
    maxWidth: "inherit",
    paddingRight: "35px",
    paddingLeft: "35px",
    fontWeight: "bold",
    textAlign: "center",
  },
  badgeItem: {
    "& span": {
      right: -18,
      width: 25,
      height: 25,
      borderRadius: "50%",
    },
  },
}));

const queryNewSearchAreaBuildingApplications = `
  query (
    $SearchAreaHashIDs: [String],
  ) {
    readNewSearchAreaBuildingApplications(
        SearchAreaHashIDs: $SearchAreaHashIDs
    ) {
      NewCountPerSearchArea {
          HashID
          NewCount
          NewCountBaugesuch
          NewCountBaupublikation
          NewCountSubmission
          NewCountBaugesuche
      }
    }
  }
`;

/**
 * LocationTab
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LocationTabSearchArea = ({
  locationHashID,
  labelTitle,
  handleTabChange,
  setHasNewApplications,
  setHasNewSubmissions,
  selectedLocationTabValue
}) => {
  const classes = useStyles();

  const [queryNewSearchAreaBuildingApplicationsResult] = useQuery({
    query: queryNewSearchAreaBuildingApplications,
    variables: {
      SearchAreaHashIDs: [locationHashID],
    },
  });

  /* 
   * Set the unread state for switch-buttons
   */
  useEffect(() => {
    if (selectedLocationTabValue === locationHashID && !queryNewSearchAreaBuildingApplicationsResult.fetching) {
      if (queryNewSearchAreaBuildingApplicationsResult.data?.readNewSearchAreaBuildingApplications?.NewCountPerSearchArea?.find(NewCountSearchArea => NewCountSearchArea.HashID === locationHashID)?.NewCountBaugesuche > 0) {
        setHasNewApplications(true)
      } else {
        setHasNewApplications(false)
      }

      if (queryNewSearchAreaBuildingApplicationsResult.data?.readNewSearchAreaBuildingApplications?.NewCountPerSearchArea?.find(NewCountSearchArea => NewCountSearchArea.HashID === locationHashID)?.NewCountSubmission > 0) {
        setHasNewSubmissions(true)
      } else {
        setHasNewSubmissions(false)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationTabValue, queryNewSearchAreaBuildingApplicationsResult])

  /**
   * handleTabLabel
   *
   * @returns {*}
   */
  const handleTabLabel = (title, newCount = 0) => {
    if (newCount === 0) {
      return title;
    }

    return (
      <Badge
        className={classes.badgeItem}
        badgeContent={newCount}
        color="error"
      >
        {title}
      </Badge>
    );
  };

  /**
   * return
   */
  return (
    <Tab
      id={locationHashID}
      key={locationHashID}
      onClick={(event) => {
        handleTabChange(event, locationHashID);
      }}
      label={handleTabLabel(
        labelTitle,
        queryNewSearchAreaBuildingApplicationsResult.data?.readNewSearchAreaBuildingApplications?.NewCountPerSearchArea?.find(NewCountSearchArea => NewCountSearchArea.HashID === locationHashID)?.NewCount
      )}
      value={locationHashID}
      className={classes.tab}
    />
  );
};

export default LocationTabSearchArea;
