import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import Joi from "@hapi/joi";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import GetSafe from "../../lib/GetSafe";
import { useMutation } from "urql";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MESSAGE_TYPES } from "../PopupMessages";
import withAccessControl from "../HOC/AccessControl";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  startButton: {
    width: "100%",
    maxWidth: 330,
    color: "#fff",
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
  },
  textFieldSmall: {
    width: 160,
    "&:first-of-type": {
      marginRight: 5,
    },
    "&:last-of-type": {
      marginLeft: 5,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        marginRight: 0,
        marginBottom: 20,
      },
      "&:last-of-type": {
        marginLeft: 0,
      },
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  item: {
    width: "100%",
    maxWidth: 330,
    paddingTop: 20,
    paddingBottom: 20,
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  itemPassword: {
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
}));

/**
 * updateMyselfMutation
 *
 * @type {string}
 */
const updateMyselfMutation = `
  mutation UpdateMyself(
    $email: String!,
    $password: String,
    $firstName: String, 
    $lastName: String
  ) {
    updateMyself(
      Email: $email,
      Password: $password,
      Firstname: $firstName,
      Surname: $lastName,
      RegistrationHash: ""
    ) {
      Email
      Firstname
      Surname
    }
  }
`;

/**
 * OnboardingPremiumUserWelcomeDialog
 *
 * @returns {*}
 * @constructor
 */
const OnboardingPremiumUserWelcomeDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    setUser,
    token,
    setMessage,
    upgradeToPremiumClose,
    setUpgradeToPremiumClose,
    setOpenWelcomeDialog,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);
  const [formValues, setFormValues] = useState({
    password: "",
    showPassword: false,
    firstName: "",
    lastName: "",
  });
  const [formValueErrors, setFormValueErrors] = useState({
    password: { hasError: false, message: "" },
    firstName: { hasError: false, message: "" },
    lastName: { hasError: false, message: "" },
  });
  const [{ fetching }, executeMutation] = useMutation(updateMyselfMutation);
  const [mutationDone, setMutationDone] = useState(false);
  const { setUpgradeToPremiumOpen } = props;

  /**
   * useEffect
   */
  useEffect(() => {
    setFormValues({
      password: "",
      showPassword: false,
      firstName: user.getData().firstName,
      lastName: user.getData().lastName,
    });
  }, [user]);

  /**
   * useEffect
   */
  useEffect(() => {
    if (mutationDone) {
      setMutationDone(false);
      setUser(user);
      if (
        setUpgradeToPremiumOpen &&
        user.getData().company.adminUserID === user.getData().id // only show upgrade to premium dialog for admin users
      ) {
        setUpgradeToPremiumOpen(true);
        navigate("/");
      } else {
        if (!upgradeToPremiumClose) {
          setOpenWelcomeDialog(false);
          setUpgradeToPremiumClose(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [mutationDone]);

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    password: Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    showPassword: Joi.boolean(),
  });

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = () => {
    const { error } = schema.validate(formValues, { abortEarly: false });

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
    } else {
      executeMutation({
        email: user.getData().email,
        password: formValues.password,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      }).then((result) => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error update myself on OnboardingPremiumUserWelcomeDialog",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          user.setData({
            firstName: formValues.firstName,
            lastName: formValues.lastName,
          });
          setMutationDone(true);
        }
      });
    }
  };

  /**
   * handlePasswordChange
   *
   * @param event
   */
  const handlePasswordChange = (event) => {
    setFormValues({ ...formValues, password: event.target.value });
  };

  /**
   * handleFirstNameChange
   *
   * @param event
   */
  const handleFirstNameChange = (event) => {
    setFormValues({ ...formValues, firstName: event.target.value });
  };

  /**
   * handleLastNameChange
   *
   * @param event
   */
  const handleLastNameChange = (event) => {
    setFormValues({ ...formValues, lastName: event.target.value });
  };

  /**
   * handleClickShowPassword
   */
  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.greeting")}</h1>
        <p>{t("onboarding.intro")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemPassword)}>
        <TextField
          id="outlined-adornment-password"
          className={classes.textField}
          variant="outlined"
          align="left"
          error={GetSafe(() => formValueErrors.password.hasError, false)}
          type={formValues.showPassword ? "text" : "password"}
          label={t("form.label.password")}
          value={formValues.password}
          helperText={GetSafe(() => formValueErrors.password.message, "")}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id="inputFirstName"
          className={classes.textFieldSmall}
          label={t("form.label.firstName")}
          variant="outlined"
          align="left"
          value={formValues.firstName}
          error={GetSafe(() => formValueErrors.firstName.hasError, false)}
          helperText={GetSafe(() => formValueErrors.firstName.message, "")}
          onChange={handleFirstNameChange}
        />
        <TextField
          id="inputLastName"
          className={classes.textFieldSmall}
          label={t("form.label.lastName")}
          variant="outlined"
          align="left"
          value={formValues.lastName}
          error={GetSafe(() => formValueErrors.lastName.hasError, false)}
          helperText={GetSafe(() => formValueErrors.lastName.message, "")}
          onChange={handleLastNameChange}
        />
      </Grid>
      <Grid item className={classes.item}>
        <Button
          id="buttonFormSubmit"
          variant="contained"
          color="primary"
          className={classes.startButton}
          onClick={handleFormSubmit}
          disabled={fetching}
        >
          {t("form.label.to.premium")}
          {fetching && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </>
  );
};

export default withAccessControl(
  OnboardingPremiumUserWelcomeDialog,
  ACL_COMPONENTS.ONBOARDING_PREMIUM_USER_WELCOME_DIALOG
);
