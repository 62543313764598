import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import CustomSCStatus from './CustomSCStatus'
import CustomSCDetailAddition from './CustomSCDetailAddition'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import GoogleMapComponent from '../../lib/GoogleMapComponent'
import withAccessControl from '../HOC/AccessControl'
import GetSafe from '../../lib/GetSafe'
import FormatHelper from '../../lib/FormatHelper'
import PHE from 'print-html-element'
import { renderToString } from 'react-dom/server'
import { Button, Table, TableBody, TableHead } from '@material-ui/core'
import { A } from 'hookrouter'
import { ACL_COMPONENTS, DEFAULT_LOGO_PATH } from '../../config'
import Tooltip from '@material-ui/core/Tooltip'
import Info from '@material-ui/icons/Info'
import Grid from '@material-ui/core/Grid'
import UserTypes from '../Onboarding/UserTypes'
// import FavoriteEmailApplyDialog from './FavoriteEmailApplyDialog'
// import FavoriteApplicationPrintDialog from './FavoriteApplicationPrintDialog'

const useStyles = makeStyles(theme => ({
  removed: {
    opacity: 0.5
  },
  undo: {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  link: {
    cursor: 'pointer'
  },
  icon: {
    padding: 8
  },
  tableCellColumnsbody: {
    width: 'calc(100% * 1 / 5)',
    minWidth: 100,
    padding: 14,
    '&:nth-child(1)': {
      minWidth: 215
    },
    '&:nth-child(2)': {
      minWidth: 520
    },
    '&:nth-child(3)': {
      minWidth: 130
    },
    '@media (max-width:640px)': {
      '&:first-of-type': {
        minWidth: 'auto'
      },
      '&:nth-child(2)': {
        minWidth: 320
      }
    }
  },
  toggleTitle: {
    color: '#999',
    fontWeight: 'bold'
  },
  toggleTableCell: {
    paddingTop: 0,
    verticalAlign: 'top'
  },
  toggleTableCellMap: {
    paddingLeft: 230,
    '@media (max-width:640px)': {
      paddingLeft: 20
    }
  },
  tableInnerRow: {
    borderTop: '2px solid #fff'
  },
  pointer: {
    cursor: 'pointer'
  },
  tooltipItem: {
    width: '100%',
    maxWidth: 350,
    borderRadius: 5,
    background: '#006A7A',
    fontSize: 16,
    textAlign: 'center',
    '& div': {
      margin: 0,
      color: '#fff'
    }
  },
  tooltipInfo: {
    maxWidth: '100%',
    background: theme.palette.background.default,
    '& div': {
      maxWidth: '100%',
      color: theme.palette.text.primary,
      fontSize: 12,
      lineHeight: '20px'
    },
    '@media (min-width:768px)': {
      maxWidth: 450
    }
  },
  tooltipTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  headerWrapper: {
    width: '100%',
    maxWidth: 1320,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: '#4ab3cf',
    '-webkit-print-color-adjust': 'exact'
  },
  logo: {
    paddingLeft: 0
  },
  button: {
    color: '#ffffff',
    width: '60%',
    marginTop: '20px',
    marginBottom: '40px'
  }
}))

/**
 * CustomSCTableRow
 *
 * @returns {*}
 * @constructor
 */
const CustomSCTableRow = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, upgradeToPremiumOpen, setUpgradeToPremiumOpen } = useContext(
    GlobalContext
  )
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  // const [emailApplyDialog, setEmailApplyDialog] = useState(false)
  // const [applicationPrintDialog, setApplicationPrintDialog] = useState(false)

  /**
   * open context menu
   *
   * @param event
   */
  const handleContextMenuOpen = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  /**
   * close context menu
   */
  const handleContextMenuClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  /**
   * getDestinationCoordinates
   *
   * @returns {{lon: *, lat: *}}
   */
  const getDestinationCoordinates = () => {
    return {
      lat: GetSafe(
        () => parseFloat(props.data.Construction_site.Address.Geo.lat),
        0
      ),
      lon: GetSafe(
        () => parseFloat(props.data.Construction_site.Address.Geo.lon),
        0
      )
    }
  }

  /**
   * isProcessable
   *
   * @param {*} value
   */
  const isProcessable = value => {
    return !(value === null || value === undefined)
  }

  /**
   * showApplyButton
   *
   * @returns {boolean|string}
   */
  const showApplyButton = () => {
    return props.data.Project_management.Email
  }

  /**
   * handleApplyButton
   */
  const handleApplyButton = () => {
    if (user.getData().mode !== UserTypes.PRO) {
      setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
    } else {
      if (
        user.getData().company.urlSegment === '' ||
        user.getData().company.urlSegment === null
      ) {
        props.setVerifyDialogOpen(false)
      } else {
        // setEmailApplyDialog(true)
      }
    }
  }

  /**
   * handleApplicationPrintButton
   */
  const handleApplicationPrintButton = () => {
    if (user.getData().mode !== UserTypes.PRO) {
      setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
    } else {
      if (
        user.getData().company.urlSegment === '' ||
        user.getData().company.urlSegment === null
      ) {
        props.setVerifyDialogOpen(false)
      } else {
        // setApplicationPrintDialog(true)
      }
    }
  }

  /**
   * getApplyButtonText
   *
   * @returns string
   */
  const getApplyButtonText = () => {
    if (props.data.Applied) {
      return t('general.label.already.advertised')
    }

    if (user.getData().mode !== UserTypes.PRO) {
      return t('general.label.applyOnlyForPro')
    }

    return t('general.label.apply')
  }

  /**
   * getApplicationPrintButtonText
   *
   * @returns string
   */
  const getApplicationPrintButtonText = () => {
    if (user.getData().mode !== UserTypes.PRO) {
      return t('general.label.applyPrintOnlyForPro')
    }

    return t('company.label.print.application')
  }

  /**
   * render the detail view of a building application
   */
  const buildingApplicationDetails = () => {
    return (
      <TableRow
        className={classes.tableInnerRow}
        id={`favorite-details-${props.data.HashID}`}
      >
        <TableCell
          className={clsx(classes.toggleTableCell, classes.toggleTableCellMap)}
          colSpan={2}
        >
          <p className={classes.toggleTitle}>
            {t('favorites.label.fastest.route')}
          </p>
          <GoogleMapComponent
            origin={props.originCoordinates}
            destination={getDestinationCoordinates()}
            location={props.location}
          />
          <CustomSCDetailAddition
            favorite={props.data}
            filterFor={props.filterFor}
          />
        </TableCell>
        <TableCell className={classes.toggleTableCell}>
          <p className={classes.toggleTitle}>{t('projects.label.owner')}</p>
          <p>
            {GetSafe(() => props.data.Building_owner.Name, '')}
            <br />
            {GetSafe(() => props.data.Building_owner.Address.Street, '')}{' '}
            {GetSafe(() => props.data.Building_owner.Address.Number, '')}
            <br />
            {GetSafe(() => props.data.Building_owner.Address.Zip, '')}{' '}
            {GetSafe(() => props.data.Building_owner.Address.City, '')}
          </p>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={props.data.Content || ''}
            PopperProps={{
              className: clsx(classes.tooltipItem, classes.tooltipInfo)
            }}
          >
            <span className={classes.tooltipTitle}>
              <Info style={{ fontSize: 30 }} color='primary' />
              {t('projects.label.original.project')}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.toggleTableCell} colSpan={2}>
          <p className={classes.toggleTitle}>{t('projects.label.architect')}</p>
          <p>
            {GetSafe(() => props.data.Project_management.Name, '')}
            <br />
            {GetSafe(
              () => props.data.Project_management.Address.Street,
              ''
            )}{' '}
            {GetSafe(() => props.data.Project_management.Address.Number, '')}
            <br />
            {GetSafe(() => props.data.Project_management.Address.Zip, '')}{' '}
            {GetSafe(() => props.data.Project_management.Address.City, '')}
            <br />
            {isProcessable(
              GetSafe(() => props.data.Project_management.Phone, '')
            ) && (
              <a
                href={
                  'tel:' +
                  GetSafe(() => props.data.Project_management.Phone, '')
                }
              >
                {GetSafe(() => props.data.Project_management.Phone, '')}
              </a>
            )}
            <br />
            {isProcessable(
              GetSafe(() => props.data.Project_management.Email, '')
            ) && (
              <a
                href={
                  'mailto:' +
                  GetSafe(() => props.data.Project_management.Email, '')
                }
              >
                {GetSafe(() => props.data.Project_management.Email, '')}
              </a>
            )}
          </p>
          {showApplyButton() && (
            <Button
              id='buttonApply'
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleApplyButton}
              disabled={props.data.Applied}
            >
              {getApplyButtonText()}
            </Button>
          )}
          <Button
            id='buttonApplicationPrint'
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={handleApplicationPrintButton}
          >
            {getApplicationPrintButtonText()}
          </Button>
          {/* <FavoriteEmailApplyDialog
            emailApplyDialog={emailApplyDialog}
            setEmailApplyDialog={setEmailApplyDialog}
            favoriteHashId={props.data.HashID}
            filterFor={props.filterFor}
            setFavoriteData={setFavoriteData}
            favorite={props.data}
          /> */}
          {/* <FavoriteApplicationPrintDialog
            applicationPrintDialog={applicationPrintDialog}
            setApplicationPrintDialog={setApplicationPrintDialog}
            favoriteHashId={props.data.HashID}
            filterFor={props.filterFor}
            projectManagementData={props.data.Project_management}
            buildingOwnerData={props.data.Building_owner}
            favorite={props.data}
          /> */}
          {/* TODO: remove after presentation! */}
          <br />
          <a href={'/project/' + props.data.HashID}>Deeplink View</a>
        </TableCell>
      </TableRow>
    )
  }

  /**
   * renderPrintAndShare
   **/
  const renderPrintAndShare = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerWrapper} colSpan={4}>
              <Grid container justify='space-between'>
                <Grid item xs={12} sm='auto'>
                  <A href='/' className={classes.logo}>
                    <img src={DEFAULT_LOGO_PATH} alt='logo' />
                  </A>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{GetSafe(() => props.data.Final_title, '')}</TableCell>
            <TableCell>
              {GetSafe(() => props.data.Construction_site.Address.City, '')}{' '}
              {GetSafe(() => props.data.Construction_site.Address.Street, '')}{' '}
              {GetSafe(() => props.data.Construction_site.Address.Number, '')}
            </TableCell>
            <TableCell>
              {GetSafe(
                () =>
                  FormatHelper.formatDistance(props.data.Calculated_distance) +
                  ' km',
                ''
              )}
            </TableCell>
            <TableCell>
              {GetSafe(() => FormatHelper.formatDate(props.data.Date), '')}
            </TableCell>
          </TableRow>
          <TableRow id={`project-details-${props.data.HashID}`}>
            <TableCell>
              <p className={classes.toggleTitle}>{t('projects.label.owner')}</p>
              <p>
                {GetSafe(() => props.data.Building_owner.Name, '')}
                <br />
                {GetSafe(
                  () => props.data.Building_owner.Address.Street,
                  ''
                )}{' '}
                {GetSafe(() => props.data.Building_owner.Address.Number, '')}
                <br />
                {GetSafe(() => props.data.Building_owner.Address.Zip, '')}{' '}
                {GetSafe(() => props.data.Building_owner.Address.City, '')}
              </p>
            </TableCell>
            <TableCell colSpan={3}>
              <p className={classes.toggleTitle}>
                {t('projects.label.architect')}
              </p>
              <p>
                {GetSafe(() => props.data.Project_management.Name, '')}
                <br />
                {GetSafe(
                  () => props.data.Project_management.Address.Street,
                  ''
                )}{' '}
                {GetSafe(
                  () => props.data.Project_management.Address.Number,
                  ''
                )}
                <br />
                {GetSafe(
                  () => props.data.Project_management.Address.Zip,
                  ''
                )}{' '}
                {GetSafe(() => props.data.Project_management.Address.City, '')}
                <br />
                {GetSafe(() => props.data.Project_management.Phone, '')}
                <br />
                {GetSafe(() => props.data.Project_management.Email, '')}
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>{props.data.Content}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  /**
   * handlePrint
   */
  const handlePrint = event => {
    event.stopPropagation()
    PHE.printHtml(renderToString(renderPrintAndShare()))
  }

  /**
   * handleOpenDetailsClick
   */
  const handleOpenDetailsClick = () => {
    setOpen(!open)
  }

  /**
   * return
   */
  return (
    <>
      <TableRow onClick={handleOpenDetailsClick} className={classes.pointer}>
        <TableCell className={classes.tableCellColumnsbody}>
          {
            <IconButton
              id={`favorite-item-details-toggler-${props.data.HashID}`}
              onClick={() => setOpen(!open)}
              className={`${classes.icon}`}
            >
              {open ? (
                <ExpandLess style={{ fontSize: 30 }} color='primary' />
              ) : (
                <ExpandMore style={{ fontSize: 30 }} color='primary' />
              )}
            </IconButton>
          }
          <CustomSCStatus
            filterFor={props.filterFor}
            removed={false}
            favorite={props.data}
            style={{ fontSize: 30 }}
          />
          {
            <IconButton
              aria-controls={`favorite-menu-${props.data.HashID}`}
              className={`${classes.icon}`}
              aria-haspopup='true'
              onClick={handleContextMenuOpen}
            >
              <MoreHoriz style={{ fontSize: 30 }} color='primary' />
            </IconButton>
          }
          <Menu
            id={`favorite-menu-${props.data.HashID}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleContextMenuClose}
            PaperProps={{
              style: {
                marginTop: '42px'
              }
            }}
          >
            <MenuItem
              id={`favorite-menu-item-${props.data.HashID}--print`}
              onClick={handlePrint}
            >
              {t('general.label.print')}
            </MenuItem>
          </Menu>
        </TableCell>
        {
          <>
            <TableCell
              className={classes.tableCellColumnsbody}
              style={{
                fontSize: 16
              }}
            >
              {props.data.Final_title}
            </TableCell>
            <TableCell className={classes.tableCellColumnsbody}>
              {GetSafe(() => props.data.Construction_site.Address.City, '')}{' '}
              {GetSafe(() => props.data.Construction_site.Address.Street, '')}{' '}
              {GetSafe(() => props.data.Construction_site.Address.Number, '')}
            </TableCell>
            <TableCell className={classes.tableCellColumnsbody}>
              {GetSafe(
                () =>
                  FormatHelper.formatDistance(props.data.Calculated_distance) +
                  ' km',
                ''
              )}
            </TableCell>
            <TableCell className={classes.tableCellColumnsbody}>
              {GetSafe(() => FormatHelper.formatDate(props.data.Date), '')}
            </TableCell>
          </>
        }
      </TableRow>
      {open ? buildingApplicationDetails() : null}
    </>
  )
}

// TODO: Access control
export default withAccessControl(
  CustomSCTableRow,
  ACL_COMPONENTS.FAVORITES_TABLE_ROW
)
