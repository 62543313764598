import React, { Component } from 'react'
import User from './User'
import Layout from '../components/Layout'
import GlobalContext from './GlobalContext'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from '../App'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

/**
 * ErrorBoundary
 */
class ErrorBoundary extends Component {
  /**
   * constructor
   *
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  /**
   * componentDidCatch
   *
   * @param error
   * @param info
   */
  componentDidCatch (error, info) {
    this.setState({ hasError: true })
    ExternalErrorLogger.log({
      text: 'The app runs into the Error Boundary',
      data: {
        error: error,
        info: JSON.stringify(info),
        user: JSON.stringify(this.props.user?.getData() || {})
      }
    })
  }

  /**
   * render
   *
   * @returns {React.ReactNode|*}
   */
  render () {
    if (this.state.hasError) {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalContext.Provider
            value={{
              user: User
            }}
          >
            <Layout>
              <h1>Something went wrong!</h1>
            </Layout>
          </GlobalContext.Provider>
        </ThemeProvider>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
