import { useContext, useEffect } from 'react'
import GlobalContext from '../lib/GlobalContext'
import { navigate } from 'hookrouter'

/**
 * Logout
 *
 * @returns {*}
 * @constructor
 */
const Logout = () => {
  const { unsetUser, unsetToken } = useContext(GlobalContext)

  /**
   * useEffect
   */
  /* eslint-disable */
  useEffect(() => {
    unsetUser()
    unsetToken()
    navigate('/')
  }, [])
  /* eslint-enable */

  /**
   * return
   */
  return null
}

export default Logout
