import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import FreeToPremiumStepper from "./FreeToPremiumStepper";
import { makeStyles } from "@material-ui/core/styles";
import withAccessControl from "../HOC/AccessControl";
import GlobalContext from "../../lib/GlobalContext";
import { useQuery } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS, STRIPE_PLAN_IDS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import FormatHelper from "../../lib/FormatHelper";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  intro: {
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
  },
  item: {
    width: "100%",
    maxWidth: 330,
    margin: "0 auto",
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  itemStepper: {
    maxWidth: 600,
  },
  buttonNext: {
    width: 330,
    color: "#fff",
    marginRight: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  main: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const queryMyself = `
{
  readMyself {
    ID
    HashID
    Locale
    Email
    Firstname
    Surname
    Salutation
    StripePublicKey
    CreditCard {
      id
      name
      last4
      expired
    }
    Locations {
      edges {
        node {
          HashID
          Title
          Street
          Zip
          City
          Longitude
          Latitude
          Radius
          Version
          Sort
          Removed
          ConstructionSumList {
            edges {
              node {
                Code
              }
            }
          }
        }
      }
    }
    Company {
      ID
      AdminUserID
      HashID
      FeatureSet
      ZipSearchAreas
      ZipSearchStatistic
      ApplicationFollowUpTimeInDays
      StripeIsTrialing
      StripeTrialingDays
      StripePlanID
      StripeValidThrough
      StripeProductID
      StripeCancelAt
      Name
      URLSegment
      ContactPerson
      Street
      Zip
      City
      Canton
      Distance
      Latitude
      Longitude
      Phone
      Mobile
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
      FoundingYear
      MasterBusiness
      TrainingCompany
      BusinessActivityID
      BusinessSpecialities{
        edges {
          node {
            ID
            Title
            NogaCode
            SCCodeCompetence
            BoostScore
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
      User{
        edges {
          node {
            HashID
            Email
            Firstname
            Surname
            Removed
            SearchAreas {
              HashID
              Title
              ZipList
            }
          }
        }
      }
      Memberships {
        edges {
          node {
            ID
            Title
            LogoUrl
          }
        }
      }
      AllMemberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      MicrositeCertified
      MicrositeCertifiedUntil
      AdditionalMemberships
      MicrositeColorScheme
      MicrositeDescription
      MicrositeEmailApplicationText
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeLogo {
        Src
        Title
        Alt
      }
      MicrositeReferences {
          edges {
            node {
              Sort
              Title
              Content
              Location
              Year
              Month
              ButtonLink
              ButtonTitle
              MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
            }
          }
        }
        ApiAccess {
          edges {
            node {
              RequestUrl
            }
          } 
        }  
    }
    DossierTemplates {
      edges {
        node {
          HashID
          TemplateName
        }
      }
    }
    Permissions {
      Name
      RequireAdminStatus
    }
    FulltextSearchRequests {
      ID
      Title
    }
    SearchAreas {
      HashID
      Title
      ZipList
    }
  }
  readProduct {
    plans {
      id
      trial_possible
    }
  }
}
`;

/**
 * FreeToPremiumDialogueStep5
 *
 * @returns {*}
 * @constructor
 */
const FreeToPremiumDialogueStep5 = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setProjectData, token, user, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);

  const [resultReadMyselfQuery] = useQuery({
    query: queryMyself,
    requestPolicy: "network-only",
  });

  const handleFormClose = () => {
    if (props.closeDialogue) {
      props.closeDialogue();
      navigate("/");
    }
  };

  const parsePermissions = (permissionsFromAPI) => {
    const permissionsArray = [];
    if (Array.isArray(permissionsFromAPI)) {
      for (let i = 0; i < permissionsFromAPI.length; i++) {
        permissionsArray.push(permissionsFromAPI[i].Name);
      }
    }
    return permissionsArray;
  };

  useEffect(() => {
    if (!resultReadMyselfQuery.fetching && token.isSet()) {
      if (resultReadMyselfQuery.error) {
        // Check if the user need to be logged out
        if (
          resultReadMyselfQuery.error.message.indexOf("User forced logout") !==
          -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching myself on FreeToPremiumDialogueStep3",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultReadMyselfQuery.error.message,
            },
          });

          if (
            resultReadMyselfQuery.error.message.indexOf(
              "The selected plan has already been subscribed"
            ) !== -1
          ) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.subsription.exists"));
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
          }
        }
      } else {
        // Query not fetching right now
        if (
          typeof resultReadMyselfQuery.data !== "undefined" &&
          typeof resultReadMyselfQuery.data.readMyself !== "undefined"
        ) {
          const branchSubsData = [];
          resultReadMyselfQuery.data.readMyself[0].Company.BusinessSpecialities.edges.map(
            (item) => {
              branchSubsData.push(parseInt(item.node.ID));
              return branchSubsData;
            }
          );

          const locationData = [];
          resultReadMyselfQuery.data.readMyself[0].Locations.edges.map(
            (item) => {
              const constructionSums = [];

              if (typeof item.node.ConstructionSumList !== 'undefined') {
                item.node.ConstructionSumList.edges.map((constructionSum) => {
                  constructionSums.push(constructionSum.node.Code);
                  return constructionSums;
                });
              }

              item.node.ConstructionSums = constructionSums;

              locationData.push(item.node);
              return locationData;
            }
          );

          locationData.sort((a, b) => {
            return a.Sort - b.Sort;
          });

          const companyUsersData = [];
          resultReadMyselfQuery.data.readMyself[0].Company.User.edges.map(
            (item) => {
              if (
                item.node.HashID !==
                resultReadMyselfQuery.data.readMyself[0].HashID
              ) {
                companyUsersData.push(item.node);
              }
              return companyUsersData;
            }
          );

          const memberships = [];
          resultReadMyselfQuery.data.readMyself[0].Company.Memberships.edges.map(
            (item) => {
              memberships.push(item.node);
              return memberships;
            }
          );

          const allMemberships = [];
          resultReadMyselfQuery.data.readMyself[0].Company.AllMemberships.edges.map(
            (item) => {
              allMemberships.push(item.node);
              return allMemberships;
            }
          );

          const dossierTemplatesData = [];
          resultReadMyselfQuery.data.readMyself[0].DossierTemplates.edges.map(
            (item) => {
              dossierTemplatesData.push(item.node);
              return dossierTemplatesData;
            }
          );

          companyUsersData.sort((a, b) => {
            if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
              return -1;
            }
            if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          let trialPossible = false;

          resultReadMyselfQuery.data.readProduct.map((productItem) => {
            productItem.plans.map((item) => {
              if (item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY)) {
                trialPossible = item.trial_possible;
              }
              return item;
            });
            return productItem;
          });

          // Map the user-data
          user.setData({
            id: resultReadMyselfQuery.data.readMyself[0].ID,
            hashID: resultReadMyselfQuery.data.readMyself[0].HashID,
            locale: resultReadMyselfQuery.data.readMyself[0].Locale || "",
            email: resultReadMyselfQuery.data.readMyself[0].Email,
            firstName: resultReadMyselfQuery.data.readMyself[0].Firstname,
            lastName: resultReadMyselfQuery.data.readMyself[0].Surname,
            salutation:
              resultReadMyselfQuery.data.readMyself[0].Salutation || "",
            mode:
              resultReadMyselfQuery.data.readMyself[0].Company.FeatureSet ===
              null
                ? null
                : resultReadMyselfQuery.data.readMyself[0].Company.FeatureSet.toLowerCase(),
            stripePublicKey:
              resultReadMyselfQuery.data.readMyself[0].StripePublicKey || "",
            creditCard: resultReadMyselfQuery.data.readMyself[0].CreditCard,
            locations: locationData || [],
            company: {
              id: resultReadMyselfQuery.data.readMyself[0].Company.ID,
              hashID: resultReadMyselfQuery.data.readMyself[0].Company.HashID,
              name:
                resultReadMyselfQuery.data.readMyself[0].Company.Name ||
                "TODO COMPANY-NAME", // TODO: Remove fallback company name for production (requires consistent data)
              street:
                resultReadMyselfQuery.data.readMyself[0].Company.Street || "",
              zip: resultReadMyselfQuery.data.readMyself[0].Company.Zip || "",
              city: resultReadMyselfQuery.data.readMyself[0].Company.City || "",
              canton:
                resultReadMyselfQuery.data.readMyself[0].Company.Canton || "",
              distance:
                resultReadMyselfQuery.data.readMyself[0].Company.Distance || 50,
              latitude:
                resultReadMyselfQuery.data.readMyself[0].Company.Latitude ||
                null,
              longitude:
                resultReadMyselfQuery.data.readMyself[0].Company.Longitude ||
                null,
              phone:
                resultReadMyselfQuery.data.readMyself[0].Company.Phone || "",
              mobile:
                resultReadMyselfQuery.data.readMyself[0].Company.Mobile || "",
              fax: resultReadMyselfQuery.data.readMyself[0].Company.Fax || "",
              email:
                resultReadMyselfQuery.data.readMyself[0].Company.Email || "",
              website:
                resultReadMyselfQuery.data.readMyself[0].Company.Website || "",
              employeeCount:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .NumberOfEmployees || "",
              legalForm:
                resultReadMyselfQuery.data.readMyself[0].Company.LegalForm ||
                "",
              foundingYear:
                resultReadMyselfQuery.data.readMyself[0].Company.FoundingYear ||
                "",
              masterOperation:
                FormatHelper.boolToString(
                  resultReadMyselfQuery.data.readMyself[0].Company
                    .MasterBusiness
                ) || "",
              trainingCompany:
                FormatHelper.boolToString(
                  resultReadMyselfQuery.data.readMyself[0].Company
                    .TrainingCompany
                ) || "",
              branchCategory:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .BusinessActivityID || "",
              branchSubs: branchSubsData || [],
              users: companyUsersData || [],
              stripeIsTrialing:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .StripeIsTrialing,
              stripeTrialingDays:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .StripeTrialingDays,
              stripePlanId:
                resultReadMyselfQuery.data.readMyself[0].Company.StripePlanID,
              stripeValidThrough:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .StripeValidThrough,
              stripeProductId:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .StripeProductID,
              stripeCancelAt:
                resultReadMyselfQuery.data.readMyself[0].Company.StripeCancelAt,
              stripeFuturePlans:
                resultReadMyselfQuery.data.readMyself[0].Company
                  .StripeFuturePlans,
              trialPossible: trialPossible,
              memberships: memberships,
              allMemberships: allMemberships,
              zipSearchAreas:
                resultReadMyselfQuery.data.readMyself[0].Company.ZipSearchAreas,
              zipSearchStatistic:
                resultReadMyselfQuery.data.readMyself[0].Company.ZipSearchStatistic,
              applicationFollowUpTimeInDays:
                resultReadMyselfQuery.data.readMyself[0].Company.ApplicationFollowUpTimeInDays
            },
            dossierTemplates: dossierTemplatesData,
            permissions: parsePermissions(
              resultReadMyselfQuery.data.readMyself[0].Permissions
            ),
            fulltextSearchRequests:
              resultReadMyselfQuery.data.readMyself[0].FulltextSearchRequests,
            searchAreas: resultReadMyselfQuery.data.readMyself[0].SearchAreas,
          });

          setProjectData([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultReadMyselfQuery, token]);

  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.label.premium.upgrade")}</h1>
        <p className={classes.intro}>{t("onboarding.intro")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <FreeToPremiumStepper zeroBasedStep={2} />
      </Grid>
      <Grid item className={classes.item}>
        <p className={classes.main}>{t("general.label.premium.done")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id="buttonFormNext"
          variant="contained"
          color="primary"
          className={classes.buttonNext}
          disabled={resultReadMyselfQuery.fetching}
          onClick={() => {
            handleFormClose();
          }}
        >
          {t("general.close")}
          {resultReadMyselfQuery.fetching && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </>
  );
};

export default withAccessControl(
  FreeToPremiumDialogueStep5,
  ACL_COMPONENTS.FREE_TO_PREMIUM_DIALOGUE_STEP_5
);
