import React, { useCallback, useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Joi from '@hapi/joi'
import GlobalContext from '../../lib/GlobalContext'
import { navigate } from 'hookrouter'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'
import FormValueErrorsBuilder from '../../lib/FormValueErrorsBuilder'
import GetSafe from '../../lib/GetSafe'
import ZefixCompanyAutocompleteField from '../../components/ZefixCompanyAutocompleteField'
import { useMutation, useQuery } from 'urql'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green, red } from '@material-ui/core/colors'
import UserTypes from '../Onboarding/UserTypes'
import { CANTON_LIST, EMAIL_PLACEHOLDER, STRIPE_PLAN_IDS } from '../../config'
import { MESSAGE_TYPES } from '../PopupMessages'
import { parsePermissions } from '../HOC/AccessControl'
import { externalLinkTitles, getAbsoluteLink } from '../../lib/CmsLinkHandler'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'
import FormatHelper from '../../lib/FormatHelper'

const useStyles = makeStyles(theme => ({
  dialogLeft: {},
  searchField: {
    width: '100%',
    maxWidth: 330
  },
  textField: {
    width: '100%',
    maxWidth: 155,
    '&:first-of-type': {
      marginRight: 10
    },
    '&:last-of-type': {
      marginLeft: 10
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '20px auto',
      '&:first-of-type': {
        marginRight: 0
      },
      '&:last-of-type': {
        marginLeft: 0
      }
    }
  },
  textFieldLong: {
    width: '100%',
    maxWidth: 330,
    '&:first-of-type': {
      marginRight: '0'
    },
    '&:last-of-type': {
      marginLeft: '0'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '30px'
  },
  intro: {
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'left'
  },
  introData: {
    marginBottom: 0,
    paddingBottom: 0
  },
  item: {
    position: 'relative',
    width: '100%',
    maxWidth: 330,
    margin: ' 20px auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: 'auto',
      padding: '0 20px'
    }
  },
  itemSmall: {
    width: '100%',
    maxWidth: 155,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  itemBox: {
    marginTop: 0,
    marginBottom: 0
  },
  itemHeader: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: 'left'
    }
  },
  boxText: {
    fontSize: 16
  },
  boxTextBold: {
    fontWeight: 'bold'
  },
  buttonContainer: {
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40
    }
  },
  startButton: {
    width: '100%',
    color: '#fff'
  },
  termsPolicyCheckbox: {
    margin: 0,
    hyphens: 'auto'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  alertBox: {
    padding: 20,
    backgroundColor: red[50],
    color: red[900]
  }
}))

const queryBranches = `
  query ReadBusinessActivities (
      $token: String,
      $companyHashID: String
    ) {
      readBusinessActivities (
        sortBy: [{field: Sort, direction: ASC}],
        Token: $token,
        CompanyHashID: $companyHashID
      ) {
        ID,
        Title,
        NogaCode,
        BusinessSpecialities {
          edges {
              node {
              ID,
              Title
            }
          }
        }
      }
    }
`

const mutationMarketingUserCreate = `
  mutation(
    $Email: String!, 
    $Password: String!, 
    $Firstname: String!, 
    $Surname: String!, 
    $CompanyHashID: String!, 
    $Token: String!,
    $Canton: String!
  ) {
    createMarketingUser(
      Email: $Email, 
      Password: $Password, 
      Firstname: $Firstname, 
      Surname: $Surname, 
      CompanyHashID: $CompanyHashID, 
      Token: $Token,
      Canton: $Canton
    ) {
      ID
      HashID
      Email
      Firstname
      Surname
      Company {
        ID
        HashID
        Name
        Street
        Zip
        City
        Canton
      }
    }
  }
`

const queryMyself = `
{
  readMyself {
    ID
    HashID
    Locale
    Email
    Firstname
    Surname
    Salutation
    StripePublicKey
    CreditCard {
      id
      name
      last4
      expired
    }
    Locations {
      edges {
        node {
          HashID
          Title
          Street
          Zip
          City
          Longitude
          Latitude
          Radius
          Version
          Sort
          Removed
          ConstructionSumList {
            edges {
              node {
                Code
              }
            }
          }
        }
      }
    }
    Company {
      ID
      AdminUserID
      HashID
      FeatureSet
      ZipSearchAreas
      ZipSearchStatistic
      ApplicationFollowUpTimeInDays
      StripeIsTrialing
      StripeTrialingDays
      StripePlanID
      StripeValidThrough
      StripeProductID
      StripeCancelAt
      Name
      URLSegment
      ContactPerson
      Street
      Zip
      City
      Canton
      Distance
      Latitude
      Longitude
      Phone
      Mobile
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
      FoundingYear
      MasterBusiness
      TrainingCompany
      BusinessActivityID
      BusinessSpecialities{
        edges {
          node {
            ID
            Title
            NogaCode
            SCCodeCompetence
            BoostScore
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
      User{
        edges {
          node {
            HashID
            Email
            Firstname
            Surname
            Removed
            SearchAreas {
              HashID
              Title
              ZipList
            }
          }
        }
      }
      Memberships {
        edges {
          node {
            ID
            Title
            LogoUrl
          }
        }
      }
      AllMemberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      MicrositeCertified
      MicrositeCertifiedUntil
      AdditionalMemberships
      MicrositeColorScheme
      MicrositeDescription
      MicrositeEmailApplicationText
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeLogo {
        Src
        Title
        Alt
      }
      MicrositeReferences {
          edges {
            node {
              Sort
              Title
              Content
              Location
              Year
              Month
              ButtonLink
              ButtonTitle
              MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
            }
          }
        }
        ApiAccess {
          edges {
            node {
              RequestUrl
            }
          } 
        }  
    }
    DossierTemplates {
      edges {
        node {
          HashID
          TemplateName
        }
      }
    }
    Permissions {
      Name
      RequireAdminStatus
    }
    FulltextSearchRequests {
      ID
      Title
    }
    SearchAreas {
      HashID
      Title
      ZipList
    }
  }
  readProduct {
    plans {
      id
      trial_possible
    }
  }
}
`

const initialFormValues = {
  profession: 0,
  email: '',
  password: '',
  showPassword: false,
  firstName: '',
  lastName: '',
  termsPolicy: false,
  company: '',
  branchCategory: 0,
  canton: ''
}

const initialFormValueErrorsState = {
  email: { hasError: false, message: '' },
  password: { hasError: false, message: '' },
  firstName: { hasError: false, message: '' },
  lastName: { hasError: false, message: '' },
  termsPolicy: { hasError: false, message: '' },
  company: { hasError: false, message: '' },
  branchCategory: { hasError: false, message: '' },
  canton: { hasError: false, message: '' }
}

let triggerMyselfHandling = false

/**
 * OnboardingMarketingLeftPane
 *
 * @returns {*}
 * @constructor
 */
const OnboardingMarketingLeftPane = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    user,
    setUser,
    token,
    setToken,
    setMessage,
    unsetUser,
    unsetToken,
    cmsLinks
  } = useContext(GlobalContext)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  )
  const [rightCompany, setRightCompany] = useState(true)
  const [branchData, setBranchData] = useState(false)

  const [resultBranchQuery, executeBranchesQuery] = useQuery({
    query: queryBranches,
    variables: {
      token: props.csrfToken,
      companyHashID: user.getData().company.hashID
    },
    pause: true
  })

  const [{ fetching: mutationFetching }, executeMutation] = useMutation(
    mutationMarketingUserCreate
  )

  /**
   * executeBranchesQueryCallback
   * Implementation of https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   */
  const executeBranchesQueryCallback = useCallback(() => {
    executeBranchesQuery()
  }, [executeBranchesQuery])

  /**
   * GraphQL query for user-data (myself), gets triggered by calling "executeMyselfQuery()"
   */
  const [resultMyselfQuery, executeMyselfQuery] = useQuery({
    query: queryMyself,
    variables: { token: token.getData().access_token }, // Required as cache-control mechanism
    requestPolicy: 'network-only',
    pause: true
  })

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!mutationFetching && !resultMyselfQuery.fetching && token.isSet()) {
      if (resultMyselfQuery.error) {
        // Check if the user need to be logged out
        if (
          resultMyselfQuery.error.message.indexOf('User forced logout') !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text: 'Error fetch myself on OnboardingMarketingLeftPane',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultMyselfQuery.error.message
            }
          })
        }
      } else {
        // Query not fetching right now
        if (
          triggerMyselfHandling &&
          typeof resultMyselfQuery.data !== 'undefined' &&
          typeof resultMyselfQuery.data.readMyself !== 'undefined'
        ) {
          triggerMyselfHandling = false
          const branchSubsData = []
          resultMyselfQuery.data.readMyself[0].Company.BusinessSpecialities.edges.map(
            item => {
              branchSubsData.push(parseInt(item.node.ID))
              return branchSubsData
            }
          )

          const memberships = []
          resultMyselfQuery.data.readMyself[0].Company.Memberships.edges.map(
            item => {
              memberships.push(item.node)
              return memberships
            }
          )

          const allMemberships = []
          resultMyselfQuery.data.readMyself[0].Company.AllMemberships.edges.map(
            item => {
              allMemberships.push(item.node)
              return allMemberships
            }
          )

          const locationData = []
          resultMyselfQuery.data.readMyself[0].Locations.edges.map(item => {
            const constructionSums = []
            item.node.ConstructionSumList.edges.map(constructionSum => {
              constructionSums.push(constructionSum.node.Code)
              return constructionSums
            })

            item.node.ConstructionSums = constructionSums

            locationData.push(item.node)
            return locationData
          })

          locationData.sort((a, b) => {
            return a.Sort - b.Sort
          })

          const companyUsersData = []
          resultMyselfQuery.data.readMyself[0].Company.User.edges.map(item => {
            if (
              item.node.HashID !== resultMyselfQuery.data.readMyself[0].HashID
            ) {
              companyUsersData.push(item.node)
            }
            return companyUsersData
          })

          const dossierTemplatesData = []
          resultMyselfQuery.data.readMyself[0].DossierTemplates.edges.map(
            item => {
              dossierTemplatesData.push(item.node)
              return dossierTemplatesData
            }
          )

          companyUsersData.sort((a, b) => {
            if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
              return -1
            }
            if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
              return 1
            }
            return 0
          })

          let trialPossible = false

          resultMyselfQuery.data.readProduct.map(productItem => {
            productItem.plans.map(item => {
              if (item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY)) {
                trialPossible = item.trial_possible
              }
              return item
            })
            return productItem
          })

          // Map the user-data
          user.setData({
            id: resultMyselfQuery.data.readMyself[0].ID,
            hashID: resultMyselfQuery.data.readMyself[0].HashID,
            locale: resultMyselfQuery.data.readMyself[0].Locale || '',
            email: resultMyselfQuery.data.readMyself[0].Email,
            firstName: resultMyselfQuery.data.readMyself[0].Firstname,
            lastName: resultMyselfQuery.data.readMyself[0].Surname,
            salutation: resultMyselfQuery.data.readMyself[0].Salutation || '',
            mode:
              resultMyselfQuery.data.readMyself[0].Company.FeatureSet === null
                ? null
                : resultMyselfQuery.data.readMyself[0].Company.FeatureSet.toLowerCase(),
            stripePublicKey:
              resultMyselfQuery.data.readMyself[0].StripePublicKey || '',
            creditCard: resultMyselfQuery.data.readMyself[0].CreditCard,
            locations: locationData || [],
            company: {
              id: resultMyselfQuery.data.readMyself[0].Company.ID,
              hashID: resultMyselfQuery.data.readMyself[0].Company.HashID,
              name:
                resultMyselfQuery.data.readMyself[0].Company.Name ||
                'TODO COMPANY-NAME', // TODO: Remove fallback company name for production (requires consistent data)
              street: resultMyselfQuery.data.readMyself[0].Company.Street || '',
              zip: resultMyselfQuery.data.readMyself[0].Company.Zip || '',
              city: resultMyselfQuery.data.readMyself[0].Company.City || '',
              canton: resultMyselfQuery.data.readMyself[0].Company.Canton || '',
              distance:
                resultMyselfQuery.data.readMyself[0].Company.Distance || 50,
              latitude:
                resultMyselfQuery.data.readMyself[0].Company.Latitude || null,
              longitude:
                resultMyselfQuery.data.readMyself[0].Company.Longitude || null,
              phone: resultMyselfQuery.data.readMyself[0].Company.Phone || '',
              mobile: resultMyselfQuery.data.readMyself[0].Company.Mobile || '',
              fax: resultMyselfQuery.data.readMyself[0].Company.Fax || '',
              email: resultMyselfQuery.data.readMyself[0].Company.Email || '',
              website: resultMyselfQuery.data.readMyself[0].Company.Website || '',
              employeeCount:
                resultMyselfQuery.data.readMyself[0].Company.NumberOfEmployees ||
                '',
              legalForm:
                resultMyselfQuery.data.readMyself[0].Company.LegalForm || '',
              foundingYear:
                resultMyselfQuery.data.readMyself[0].Company.FoundingYear || '',
              masterOperation:
                FormatHelper.boolToString(
                  resultMyselfQuery.data.readMyself[0].Company.MasterBusiness
                ) || '',
              trainingCompany:
                FormatHelper.boolToString(
                  resultMyselfQuery.data.readMyself[0].Company.TrainingCompany
                ) || '',
              branchCategory:
                resultMyselfQuery.data.readMyself[0].Company.BusinessActivityID ||
                '',
              branchSubs: branchSubsData || [],
              users: companyUsersData || [],
              stripeIsTrialing:
                resultMyselfQuery.data.readMyself[0].Company.StripeIsTrialing,
              stripeTrialingDays:
                resultMyselfQuery.data.readMyself[0].Company.StripeTrialingDays,
              stripePlanId:
                resultMyselfQuery.data.readMyself[0].Company.StripePlanID,
              stripeValidThrough:
                resultMyselfQuery.data.readMyself[0].Company.StripeValidThrough,
              stripeProductId:
                resultMyselfQuery.data.readMyself[0].Company.StripeProductID,
              stripeCancelAt:
                resultMyselfQuery.data.readMyself[0].Company.StripeCancelAt,
              stripeFuturePlans:
                resultMyselfQuery.data.readMyself[0].Company.StripeFuturePlans,
              trialPossible: trialPossible,
              memberships: memberships,
              allMemberships: allMemberships,
              zipSearchAreas:
                resultMyselfQuery.data.readMyself[0].Company.ZipSearchAreas,
              zipSearchStatistic:
                resultMyselfQuery.data.readMyself[0].Company.ZipSearchStatistic,
              applicationFollowUpTimeInDays:
                resultMyselfQuery.data.readMyself[0].Company.ApplicationFollowUpTimeInDays
            },
            dossierTemplates: dossierTemplatesData,
            permissions: parsePermissions(
              resultMyselfQuery.data.readMyself[0].Permissions
            ),
            fulltextSearchRequests:
              resultMyselfQuery.data.readMyself[0].FulltextSearchRequests,
            searchAreas: resultMyselfQuery.data.readMyself[0].SearchAreas
          })

          // Add the user to global context
          setUser(user)

          // Redirect
          navigate('/projects-free')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationFetching, resultMyselfQuery, token]);

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: false })
      .required(),
    password: Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    showPassword: Joi.boolean(),
    termsPolicy: Joi.boolean().invalid(false),
    profession: Joi.number(),
    company: Joi.string().allow(''),
    branchCategory: Joi.number()
      .integer()
      .min(1)
      .required(),
    canton: Joi.string().required()
  })

  /**
   * useEffect
   */
  useEffect(() => {
    if (!props.isRegistered) {
      setFormValues({
        ...initialFormValues,
        email:
          user.getData().email === EMAIL_PLACEHOLDER
            ? ''
            : user.getData().email,
        firstName: user.getData().firstName,
        lastName: user.getData().lastName,
        canton: user.getData().company.canton
      })
    } else {
      setRightCompany(false)
    }
  }, [user, props.isRegistered])

  /**
   * executeBranchesQueryCallback useEffect
   */
  useEffect(() => {
    executeBranchesQueryCallback()
  }, [executeBranchesQueryCallback])

  /**
   * handling branchesQuery result
   */
  useEffect(() => {
    if (!resultBranchQuery.fetching) {
      if (resultBranchQuery.error) {
        // Check if the user need to be logged out
        if (
          resultBranchQuery.error.message.indexOf('User forced logout') !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: 'Error fetching branches on OnboardingMarketingLeftPane',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultBranchQuery.error.message
            }
          })
          setMessage(MESSAGE_TYPES.ERROR, t('error.fetching.branches'))
        }
      } else {
        // Query not fetching right now
        if (
          typeof resultBranchQuery.data !== 'undefined' &&
          typeof resultBranchQuery.data.readBusinessActivities !== 'undefined' &&
          !branchData
        ) {
          // Without business branches/activities we cannot continue
          if (resultBranchQuery.data.readBusinessActivities.length === 0) {
            throw Error('No business branches/activities found')
          }

          // Add the projects to the global context
          setBranchData(resultBranchQuery.data.readBusinessActivities)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultBranchQuery])

  /**
   * handleCompanyChange
   *
   * @param event
   */
  const handleCompanyChange = event => {
    event.preventDefault()
    setRightCompany(!rightCompany)
  }

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleEmailChange = event => {
    setFormValues({ ...formValues, email: event.target.value })
  }

  /**
   * handlePasswordChange
   *
   * @param event
   */
  const handlePasswordChange = event => {
    setFormValues({ ...formValues, password: event.target.value })
  }

  /**
   * handleClickShowPassword
   */
  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword })
  }

  /**
   * handleFirstNameChange
   *
   * @param event
   */
  const handleFirstNameChange = event => {
    setFormValues({ ...formValues, firstName: event.target.value })
  }

  /**
   * handleLastNameChange
   *
   * @param event
   */
  const handleLastNameChange = event => {
    setFormValues({ ...formValues, lastName: event.target.value })
  }

  /**
   * handleCheckTermsPolicyChange
   */
  const handleCheckTermsPolicyChange = () => {
    setFormValues({ ...formValues, termsPolicy: !formValues.termsPolicy })
  }

  /**
   * handleSaveAndAuthorize
   */
  const handleSaveAndAuthorize = () => {
    executeMutation({
      Email: formValues.email,
      Password: formValues.password,
      Firstname: formValues.firstName,
      Surname: formValues.lastName,
      CompanyHashID: user.getData().company.hashID,
      Token: props.csrfToken,
      Canton: formValues.canton
    }).then(result => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf('User forced logout') !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          ExternalErrorLogger.log({
            text: 'Error save user on OnboardingMarketingLeftPane',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              formValues: JSON.stringify(formValues),
              errorMessage: result.error.message
            }
          })
        }
      } else {
        try {
          token.api
            .authorize(formValues.email, formValues.password)
            .then(authorizationResponseData => {
              if (authorizationResponseData) {
                token.setData(authorizationResponseData)
                setToken(token)
                triggerMyselfHandling = true
                executeMyselfQuery()
              }
            })
        } catch (error) {
          ExternalErrorLogger.log({
            text: 'Error authorize user on OnboardingMarketingLeftPane',
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              formValues: JSON.stringify(formValues),
              errorMessage: error
            }
          })
        }
      }
    })
  }

  /**
   * handleFromSubmit
   */
  const handleFormSubmit = () => {
    const { error } = schema.validate(formValues, { abortEarly: false })

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t)
      setFormValueErrors({ ...formErrors })
    } else {
      handleSaveAndAuthorize()
    }
  }

  /**
   * onAutocompleteSelect
   * Handle the result of the autocomplete
   * @param company
   */
  const onAutocompleteSelect = company => {
    user.setData({
      id: 0,
      email: EMAIL_PLACEHOLDER,
      firstName: '',
      lastName: '',
      mode: UserTypes.FREE,
      company: {
        id: 0, // Placeholder, must be replaced by real HashID on submit
        chid: company.CHID,
        name: company.Name,
        street: company.Address.AddressInformation.Street,
        zip: company.Address.AddressInformation.SwissZipCode,
        city: company.Address.AddressInformation.Town
      }
    })
    setUser(user)
    setRightCompany(true)
    props.setIsRegistered(false)
  }

  /**
   * handleBranchCategoryChange
   *
   * @param event
   */
  const handleBranchCategoryChange = event => {
    setFormValueErrors(initialFormValueErrorsState)
    setFormValues(
      Object.assign({}, formValues, {
        branchCategory: event.target.value
      })
    )
  }

  /**
   * handleCantonChange
   *
   * @param event
   */
  const handleCantonChange = event => {
    setFormValues({ ...formValues, canton: event.target.value })
  }

  /**
   * branchCategories
   *
   * @returns {[]}
   */
  const branchCategories = () => {
    const branchCategories = []

    branchCategories.push(
      <MenuItem key={0} value={0}>
        {t('form.label.please.select')}
      </MenuItem>
    )

    Object.keys(branchData).forEach(index => {
      branchCategories.push(
        <MenuItem key={index + 1} value={parseInt(branchData[index].ID)}>
          {branchData[index].Title}
        </MenuItem>
      )
    })

    return branchCategories
  }

  /**
   * cantonItems
   *
   * @returns {[]}
   */
  const cantonItems = () => {
    const menuItemArray = []
    Object.keys(CANTON_LIST).forEach(index => {
      menuItemArray.push(
        <MenuItem key={index} value={CANTON_LIST[index]}>
          {CANTON_LIST[index]}
        </MenuItem>
      )
    })
    return menuItemArray
  }

  /**
   * determineDisabled
   *
   * @returns {boolean}
   */
  const determineDisabled = () => {
    return (
      resultMyselfQuery.fetching || mutationFetching
    )
  }

  /**
   * renderCircularProgress
   */
  const renderCircularProgress = (
    <CircularProgress size={24} className={classes.loadingProgress} />
  )

  /**
   * renderSelectTextField
   */
  const renderSelectTextField = (
    <TextField
      id='branch-category-select'
      className={clsx(classes.textField, classes.textFieldLong)}
      select
      label={t('form.label.profession')}
      value={formValues.branchCategory}
      disabled={determineDisabled()}
      onChange={event => {
        handleBranchCategoryChange(event)
      }}
      variant='outlined'
      align='left'
      error={GetSafe(() => formValueErrors.branchCategory.hasError, false)}
      helperText={GetSafe(() => formValueErrors.branchCategory.message, '')}
    >
      {branchCategories()}
    </TextField>
  )

  /**
   * return
   */
  return (
    <Grid
      container
      item
      sm
      spacing={0}
      direction='column'
      align='left'
      justify='center'
      className={classes.dialogLeft}
    >
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>
          {t('onboarding.marketing.free.start')}
        </h1>

        {!props.isRegistered && (
          <p className={classes.intro}>{t('onboarding.intro')}</p>
        )}
        {props.isRegistered && (
          <p className={clsx(classes.intro, classes.alertBox)}>
            {t('onboarding.intro.activated')}
          </p>
        )}
        <p className={clsx(classes.intro, classes.introData)}>
          {t('onboarding.marketing.your.data')}
        </p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemBox)}>
        {rightCompany && (
          <Grid container direction='row' align='left'>
            <Grid item sm={6} className={classes.itemSmall}>
              <Box className={classes.boxText} fontWeight='fontWeightBold'>
                {user.getData().company.name}
              </Box>
              <Box className={classes.boxText}>
                {user.getData().company.street}
              </Box>
              <Box className={classes.boxText}>
                {user.getData().company.zip} {user.getData().company.city}
              </Box>
            </Grid>
            <Grid item sm={6} className={classes.itemSmall} align='right'>
              <Link
                id='buttonChangeCompany'
                component='button'
                variant='body2'
                underline='always'
                onClick={handleCompanyChange}
              >
                {t('onboarding.marketing.not.your.company')}
              </Link>
            </Grid>
          </Grid>
        )}
        {!rightCompany && (
          <Grid item className={classes.item}>
            <ZefixCompanyAutocompleteField
              onAutocompleteSelect={onAutocompleteSelect}
              csrfToken={props.csrfToken}
              companyHashID={user.getData().company.hashID}
              disabled={determineDisabled()}
            />
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.item}>
        {resultBranchQuery.fetching &&
          !branchData &&
          renderCircularProgress}
        {!resultBranchQuery.fetching &&
          branchData &&
          renderSelectTextField}
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id='selectCanton'
          className={clsx(classes.textField, classes.textFieldLong)}
          label={t('form.label.canton')}
          variant='outlined'
          select
          align='left'
          value={formValues.canton}
          onChange={handleCantonChange}
          error={GetSafe(() => formValueErrors.canton.hasError, false)}
          helperText={GetSafe(() => formValueErrors.canton.message, '')}
          disabled={determineDisabled()}
        >
          {cantonItems()}
        </TextField>
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id='inputEmail'
          className={clsx(classes.textField, classes.textFieldLong)}
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.email.hasError, false)}
          label={t('form.label.email')}
          value={formValues.email}
          helperText={GetSafe(() => formValueErrors.email.message, '')}
          onChange={handleEmailChange}
          disabled={determineDisabled()}
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id='outlined-adornment-password'
          className={clsx(classes.textField, classes.textFieldLong)}
          variant='outlined'
          align='left'
          error={GetSafe(() => formValueErrors.password.hasError, false)}
          type={formValues.showPassword ? 'text' : 'password'}
          label={t('form.label.password')}
          value={formValues.password}
          helperText={GetSafe(() => formValueErrors.password.message, '')}
          onChange={handlePasswordChange}
          disabled={determineDisabled()}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  edge='end'
                  color='primary'
                  aria-label='Toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id='inputFirstName'
          className={classes.textField}
          label={t('form.label.firstName')}
          variant='outlined'
          align='left'
          value={formValues.firstName}
          error={GetSafe(() => formValueErrors.firstName.hasError, false)}
          helperText={GetSafe(() => formValueErrors.firstName.message, '')}
          onChange={handleFirstNameChange}
          disabled={determineDisabled()}
        />
        <TextField
          id='inputLastName'
          className={classes.textField}
          label={t('form.label.lastName')}
          variant='outlined'
          align='left'
          value={formValues.lastName}
          error={GetSafe(() => formValueErrors.lastName.hasError, false)}
          helperText={GetSafe(() => formValueErrors.lastName.message, '')}
          onChange={handleLastNameChange}
          disabled={determineDisabled()}
        />
      </Grid>
      <Grid item className={classes.item}>
        <FormControl
          required
          error={GetSafe(() => formValueErrors.termsPolicy.hasError, false)}
          component='fieldset'
          className={classes.formControl}
        >
          <FormControlLabel
            className={classes.checkboxControl}
            control={
              <Checkbox
                id='checkbox-condition-2'
                checked={formValues.termsPolicy}
                color='primary'
                onChange={handleCheckTermsPolicyChange}
                value={1}
              />
            }
            label={
              <div>
                {t('form.label.i.accept')}{' '}
                <Link
                  target='_blank'
                  href={getAbsoluteLink(
                    cmsLinks,
                    'CmsMenu',
                    externalLinkTitles.TERMS
                  )}
                >
                  {t('form.label.terms.of.use')}
                </Link>{' '}
                {t('general.label.and')}{' '}
                <Link
                  target='_blank'
                  href={getAbsoluteLink(
                    cmsLinks,
                    'CmsMenu',
                    externalLinkTitles.DATAPROTECTION
                  )}
                >
                  {t('form.label.privacyPolicy')}.
                </Link>
              </div>
            }
          />
          {GetSafe(() => formValueErrors.termsPolicy.hasError, false) && (
            <FormHelperText id='checkbox-condition-2-helper-text'>
              {formValueErrors.termsPolicy.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id='buttonFormSubmit'
          variant='contained'
          color='primary'
          className={classes.startButton}
          onClick={handleFormSubmit}
          disabled={determineDisabled()}
        >
          {t('form.label.start')}
          {(mutationFetching || resultMyselfQuery.fetching) && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </Grid>
  )
}

export default OnboardingMarketingLeftPane
