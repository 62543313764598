import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "urql";
import withAccessControl from "../../components/HOC/AccessControl";
import { ACL_COMPONENTS, MASQ_COOKIE_NAME, cookieDomain, isNotLive } from "../../config";
import { Grid, LinearProgress, TextField, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import DebugImportantDataFragment from "./DebugImportantDataFragment";
import User from "../../lib/User";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  debugHolder: {
    marginTop: 7,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 3,
    border: '1px solid #edcc4a'
  },
  debugListWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: "#fff4db",
  },
  debugListTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
  },
  debugList: {
    display: "flex",
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  debugTitle: {
    marginRight: 10,
  },
  debugLink: {
    display: "inline-block",
    marginRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    background: "#ffffff",
    border: "1px solid #00b3cf;",
    borderRadius: 3,
    cursor: "pointer",
  },
  debugInfo: {
    display: 'block',
    fontWeight: 'bold',
    color: '#FF0000',
    fontSize: 12
  }
}));

const mutationAddElasticBuildingApplicationsNewStatus = `
  mutation ($Amount: Int) {
    debugAddElasticBuildingApplicationsNewStatus(
      Amount: $Amount
    ){
      Success
    }
  }
`;

const mutationReindexElasticIndividualIndicesOfCompany = `
  mutation {
    debugReindexElasticIndividualIndicesOfCompany {
      Success
    }
  }
`;

/**
 * DebugProjects
 *
 * @returns {*}
 * @constructor
 */
const DebugProjects = () => {
  const classes = useStyles();

  const [masqueradeId, setMasqueradeId] = useState("");
  const [amount, setAmount] = useState(false);
  const [isMutating, setIsMutating] = useState(false);

  const [
    handleMutationAddElasticBuildingApplicationsNewStatus,
    executeMutationAddElasticBuildingApplicationsNewStatus,
  ] = useMutation(mutationAddElasticBuildingApplicationsNewStatus);

  const [
    handleMutationReindexElasticIndividualIndicesOfCompany,
    executeMutationReindexElasticIndividualIndicesOfCompany,
  ] = useMutation(mutationReindexElasticIndividualIndicesOfCompany);

  useEffect(() => {
    setIsMutating(
      (handleMutationAddElasticBuildingApplicationsNewStatus.fetching ||
        handleMutationReindexElasticIndividualIndicesOfCompany.fetching) ??
        false
    );
  }, [
    handleMutationAddElasticBuildingApplicationsNewStatus,
    handleMutationReindexElasticIndividualIndicesOfCompany,
  ]);

  useEffect(() => {
    if (Number.isInteger(amount)) {
      executeMutationAddElasticBuildingApplicationsNewStatus({
        Amount: amount,
      }).then(() => {
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const handleReindex = () => {
    executeMutationReindexElasticIndividualIndicesOfCompany().then(() => {
      window.location.reload();
    });
  };

  const handleMasquerade = () => {
    if (parseInt(masqueradeId) !== '' && parseInt(masqueradeId) !== 0) {
      const cookies = new Cookies();

      const cookieLifetimeDate = new Date();
      cookieLifetimeDate.setDate(
        cookieLifetimeDate.getDate() + 1
      );

      cookies.set(MASQ_COOKIE_NAME, masqueradeId, {
        path: "/",
        domain: cookieDomain,
        expires: cookieLifetimeDate,
        SameSite: true,
      });

      User.deleteLocalStorage()
      window.location.reload();
    }
  }

  return (!User.isMasquerade() && (isNotLive || User.isAppAdmin())) ? (
    <div>
      <Grid item className={classes.debugListWrapper}>
        <Typography className={classes.debugListTitle}>
          Debugging-Tools (Projects-Page){" "}
        </Typography>
         <DebugImportantDataFragment/>
        <div className={classes.debugHolder}>
          <Typography className={classes.debugTitle}>
            Set new/unread amount:{" "}
          </Typography>
          {!isMutating && (
            <div className={classes.debugList}>
              <Link className={classes.debugLink} onClick={() => setAmount(0)}>
                <strong>{"None"}</strong>
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(100)}
              >
                {"Tiny"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(200)}
              >
                {"Small"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(400)}
              >
                {"Some"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(800)}
              >
                {"Many"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(1600)}
              >
                {"Huge"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(3200)}
              >
                {"Bulk"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(6400)}
              >
                {"Collosal"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(12800)}
              >
                {"Gigantic"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(25600)}
              >
                {"Titanic"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(51200)}
              >
                {"Galactic"}
              </Link>
              <Link
                className={classes.debugLink}
                onClick={() => setAmount(999999)}
              >
                <strong>{"All"}</strong>
              </Link>
            </div>
          )}
          {isMutating && (
            <div>
              <LinearProgress />
            </div>
          )}
        </div>
        <div className={classes.debugHolder}>
          <Typography className={classes.debugTitle}>
            Re-Index IDX1 to IDX2 for all users of this company:{" "}
          </Typography>
          {!isMutating && (
            <div className={classes.debugList}>
              <Link className={classes.debugLink} onClick={() => handleReindex()}>
                <strong>{"Start Re-Index"}</strong>
              </Link>
              <span className={classes.debugInfo}>(ATTENTION: This may take some time, be patient!)</span>
            </div>
          )}
          {isMutating && (
            <div>
              <LinearProgress />
            </div>
          )}
        </div>
        <div className={classes.debugHolder}>
          <Typography className={classes.debugTitle}>
            Masquerade as another user:{" "}
          </Typography>
            <div className={classes.debugList}>
              <TextField
                value={masqueradeId}
                onChange={e => setMasqueradeId(e.target.value)}
                placeholder="UserID"
              />
              <span>&nbsp;</span>
              <Link className={classes.debugLink} onClick={() => handleMasquerade()}>
                <strong>{"Masquerade now"}</strong>
              </Link>
            </div>
        </div>
      </Grid>
    </div>
  ) : null;
};

export default withAccessControl(DebugProjects, ACL_COMPONENTS.PROJECTS);
