import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { apiTld, companyDirectoryPath, DEFAULT_LOGO_PATH } from '../../config'
import LayoutHeaderSearchfield from './LayoutHeaderSearchfield'
import LayoutHeaderAccount from './LayoutHeaderAccount'
import LayoutHeaderAccountFree from './LayoutHeaderAccountFree'
import { A, navigate, usePath } from 'hookrouter'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import UserTypes from '../Onboarding/UserTypes'
import Tooltip from '@material-ui/core/Tooltip'
import LayoutHeaderDebugNotice from './LayoutHeaderDebugNotice'

const NavTabs = withStyles(theme => ({
  root: {
    minHeight: 0,
    marginBottom: 0,
    paddingTop: 6,
    borderBottom: 0,
    backgroundColor: 'transparent'
  },
  indicator: {
    backgroundColor: '#fff'
  }
}))(Tabs)

const NavTab = withStyles(theme => ({
  root: {
    paddingTop: 9,
    paddingBottom: 9,
    color: '#ffffff',
    fontWeight: 'bold'
  },
  selected: {}
}))(Tab)

const useStyles = makeStyles(theme => ({
  header: {
    zIndex: 1200
  },
  headerWrapper: {
    width: '100%',
    maxWidth: 1320,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20
  },
  logoItem: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',
      paddingTop: 10
    }
  },
  logoImage: {
    [theme.breakpoints.down('sm')]: {
      width: 180
    }
  },
  logo: {
    paddingLeft: 0
  },
  accountItem: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexBasis: 'auto',
      flexWrap: 'wrap',
      paddingTop: 10
    }
  },
  navList: {},
  statusBar: {
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#006A7A'
  },
  renewLink: {
    paddingLeft: 10,
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: 0
    },
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  menuItem: {
    fontWeight: 'bold'
  },
  micrositeLink: {
    fontWeight: 'bold',
    color: '#ffffff',
    opacity: 0.7,
    padding: '12px 33px 0'
  }
}))

/**
 * LayoutHeader
 *
 * @returns {*}
 * @constructor
 */
const LayoutHeader = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [showStatusBar, setShowStatusBar] = useState(false)
  const [showTrialPossibleBar, setShowTrialPossibleBar] = useState(false)
  const path = usePath()
  const { user, upgradeToPremiumOpen, setUpgradeToPremiumOpen } = useContext(
    GlobalContext
  )

  /**
   * useEffect
   */
  useEffect(() => {
    // Determine if the trial-remaining-days-bar shall be shown
    if (
      user.isSet() &&
      user.getData().company.stripeIsTrialing &&
      user.getData().company.stripeTrialingDays <= 7 &&
      user.getData().company.stripeTrialingDays > 0 &&
      user.getData().mode === UserTypes.SMART
    ) {
      setShowStatusBar(true)
    } else {
      if (showStatusBar !== false) {
        setShowStatusBar(false)
      }
    }

    // Determine if the trial-possible-bar shall be shown
    if (
      user.isSet() &&
      user.getData().company.trialPossible &&
      user.getData().mode === UserTypes.FREE
    ) {
      setShowTrialPossibleBar(true)
    } else {
      if (showTrialPossibleBar !== false) {
        setShowTrialPossibleBar(false)
      }
    }

    switch (path) {
      case '/projects':
      case '/projects-free':
        setValue(0)
        break
      case '/favorites':
        setValue(1)
        break
      case '/custom_sc':
        setValue(2)
        break
      case '/dossier-management':
        setValue(3)
        break
      case '/statistics':
        setValue(4)
        break
      default:
        if (value !== false) {
          setValue(false)
        }
    }
  }, [value, path, user, showStatusBar, showTrialPossibleBar])

  /**
   * handleChange
   *
   * @param event
   * @param newValue
   */
  const handleChange = (event, newValue) => {
    if (user.getData().mode !== UserTypes.FREE) {
      setValue(newValue)

      switch (newValue) {
        case 5:
          handleToMicrositeButton()
          break
        case 4:
          navigate('/statistics')
          break
        case 3:
          navigate('/dossier-management')
          break
        case 2:
          navigate('/custom_sc')
          break
        case 1:
          navigate('/favorites')
          break
        case 0:
        default:
          navigate('/projects')
          break
      }
    } else {
      if (newValue === 0) {
        navigate('/projects-free')
      } else {
        setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
      }
    }
  }

  /**
   * handleUpgradeToPremium
   */
  const handleUpgradeToPremium = () => {
    setUpgradeToPremiumOpen(!upgradeToPremiumOpen)
  }

  /**
   * handleToMicrositeButton
   */
  const handleToMicrositeButton = () => {
    if (user.getData().company.urlSegment) {
      window.open(
        apiTld + companyDirectoryPath + user.getData().company.urlSegment,
        '_blank'
      )
    } else {
      navigate('/company/info')
    }
  }
  /**
   * return
   */
  return (
    <>
      {showStatusBar && (
        <div className={classes.statusBar}>
          {t('account.label.trial.expires', {
            count: user.getData().company.stripeTrialingDays
          })}{' '}
          <span className={classes.renewLink} onClick={handleUpgradeToPremium}>
            {t('account.label.renew.now')}
          </span>
        </div>
      )}
      {showTrialPossibleBar && (
        <div className={classes.statusBar}>
          {t('account.label.trial.possible')}{' '}
          <span className={classes.renewLink} onClick={handleUpgradeToPremium}>
            {t('account.label.start.trial')}
          </span>
        </div>
      )}
      <AppBar elevation={0} position='relative' className={classes.header}>
        <Container
          disableGutters
          maxWidth='lg'
          className={classes.headerWrapper}
        >
          <LayoutHeaderDebugNotice/>
          <Grid container justify='space-between'>
            <Grid item xs={12} md={8}>
              <Grid item xs={12} sm='auto' className={classes.logoItem}>
                <A href='/' className={classes.logo}>
                  <img
                    src={DEFAULT_LOGO_PATH}
                    alt='logo'
                    className={classes.logoImage}
                  />
                </A>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.navList}>
                <Grid
                  container
                  direction='row'
                  justify='flex-start'
                  alignItems='center'
                >
                  <Grid item className={classes.navList}>
                    {user.getData().email === 'dev@haj.tech' ? (
                      <NavTabs value={value} onChange={handleChange}>
                        <NavTab
                          id='tab_custom_sc'
                          label={t('customsc.label')}
                          value={2}
                          className={classes.menuItem}
                        />
                      </NavTabs>
                    ) : (
                      <NavTabs value={value} onChange={handleChange}>
                        <NavTab
                          id='tab_projects'
                          label={t('projects.label.main.tab')}
                          value={0}
                          className={classes.menuItem}
                        />
                        {user.getData().mode === UserTypes.FREE && (
                          <Tooltip
                            arrow
                            title={t('projects.free.route.premium')}
                            aria-label={t('projects.free.route.premium')}
                          >
                            <NavTab
                              id='tab_favorites'
                              label={t('favorites.label')}
                              value={1}
                              className={classes.menuItem}
                            />
                          </Tooltip>
                        )}
                        {user.getData().mode !== UserTypes.FREE && (
                          <NavTab
                            id='tab_favorites'
                            label={t('favorites.label')}
                            value={1}
                            className={classes.menuItem}
                          />
                        )}
                        {user.getData().company.hasExternalApply && (
                          <NavTab
                            id='tab_dossier_management'
                            label={t('dossier.management.label')}
                            value={3}
                            className={classes.menuItem}
                          />
                        )}
                        {user.getData().mode === UserTypes.PRO &&
                        !user.getData().dossierTemplates.length && (
                          // handle -> handleToMicrositeButton
                          <NavTab
                            id='tab_microsite_link'
                            label={t('microsite.label')}
                            value={5}
                            className={classes.menuItem}
                          />
                        )}
                        {user.hasZipSearchStatistic() && user.hasZipSearchAreas() && user.getData().company.adminUserID === user.getData().id && (
                          <NavTab
                            id='tab_statistics'
                            label={t('statistics.label')}
                            value={4}
                            className={classes.menuItem}
                          />
                        )}
                      </NavTabs>
                    )} 
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid item xs={12} sm={12} className={classes.accountItem}>
                {user.isSet() &&
                  (user.getData().mode === UserTypes.FREE ? (
                    <LayoutHeaderAccountFree />
                  ) : (
                    <>
                      <LayoutHeaderAccount />
                      <LayoutHeaderSearchfield />
                    </>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </>
  )
}

export default LayoutHeader
