import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../lib/GlobalContext";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CompanyInfoForm from "../Company/CompanyInfoForm";
import {
  initialFormValueErrorsState,
  initialFormValuesState,
  createFileFromUrl,
  readFile,
} from "../Company/CompanyInfoShared";
import { useQuery } from "urql";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textFieldSmall: {
    width: "50%",
    "&:first-of-type": {
      width: "calc(50% - 24px)",
      marginRight: 24,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        width: "100%",
      },
    },
  },
  button: {
    color: "#ffffff",
    width: "100%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  branchSubCategories: {
    width: "50%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  radioButtonHelperText: {
    color: "#ff1645",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formLabel: {
    color: theme.palette.text.primary,
  },
  input: {
    marginLeft: 60,
  },
  itemPhone: {
    height: 80,
    overflow: "hidden",
    "&.state-phoneField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
  itemFax: {
    height: 80,
    overflow: "hidden",
    "&.state-faxField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
}));

const queryCompanyLocations = `
query ReadCompanyLocations (
  $companyHashId: String,
  $hashId: String
) {
  readCompanyLocations(CompanyHashID: $companyHashId, HashID: $hashId) { 
    HashID
    Name
    ContactPerson
    Street
    Zip
    City
    Phone
    Fax
    Website
    Email
  }
}`;

const DossierInfo = ({ id }) => {
  const classes = useStyles();
  const { googleMapsIsLoaded, user } =
    useContext(GlobalContext);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [newFormDataSet, setNewFormDataSet] = useState(false);
  const [currentFileObjects, setCurrentFileObjects] = useState([]);

  const [companyLocationsResult, executeCompanyLocationsQuery] = useQuery({
    query: queryCompanyLocations,
    variables: {
      companyHashId: user.getData().company.hashID,
      hashId: id,
    },
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * fetch data useEffect
   */
  useEffect(() => {
    executeCompanyLocationsQuery();
  }, [executeCompanyLocationsQuery]);

  // GraphQL trigger and companyLocationsResult handling
  useEffect(() => {
    if (!companyLocationsResult.fetching) {
      if (!companyLocationsResult.error) {
        // Query not fetching right now
        if (
          typeof companyLocationsResult.data !== "undefined" &&
          typeof companyLocationsResult.data.readCompanyLocations !== "undefined"
        ) {
          if (!newFormDataSet) {
            // setFormValues(newFormData)
            setNewFormDataSet(true);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyLocationsResult])

  return (
    googleMapsIsLoaded && (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        margin="normal"
        padding="normal"
        className={classes.gridContainer}
      >
        <CompanyInfoForm
          user={user}
          classes={classes}
          formValues={formValues}
          formValueErrors={initialFormValueErrorsState}
          setFormValues={setFormValues}
          createFileFromUrl={createFileFromUrl}
          readFile={readFile}
          setCurrentFileObjects={setCurrentFileObjects}
          colorSchemes={[]}
          currentFileObjects={currentFileObjects}
          readOnly
        />
      </Grid>
    )
  );
};

export default DossierInfo;
