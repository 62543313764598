import React, { useState } from 'react'
import { makeStyles, Grid, Button, Card } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import MicrositeDetailsWizardStepper from './MicrositeDetailsWizardStepper'
import CompanyReferencesForm from '../Company/CompanyReferencesForm'
import { navigate } from 'hookrouter'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  intro: {
    margin: 20,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'left',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#e0f6fa'
    // backgroundColor: '#fdf4e5'
  },
  introItem: {
    padding: 10
  },
  icon: {
    color: theme.palette.primary.main
  },
  introContainer: {
    flexWrap: 'nowrap'
  },
  item: {
    position: 'relative',
    width: '100%',
    maxWidth: 500,
    margin: '0 auto'
  },
  itemStepper: {
    maxWidth: 600
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: 'left'
    }
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '30px'
  },
  buttonBack: {
    width: '50%',
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonNext: {
    width: '50%',
    color: '#fff',
    marginRight: 'auto',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(4)
  },
  gridContainer: {
    width: '100%',
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  },
  gridItem: {
    width: '100%',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  button: {
    color: '#ffffff',
    width: '100%',
    marginTop: '20px',
    marginBottom: '40px'
  },
  img: {
    width: '20%',
    padding: '8px 8px 8px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  label: {
    color: theme.palette.text.primary
  },
  root: {
    width: '100%'
  },
  dialogRoot: {
    flexGrow: 1
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 0
  },
  dialog: {
    width: '100%',
    paddingBottom: 56,
    '&::before': theme.watermarkLogo,
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  startButton: {
    width: '100%',
    color: '#fff',
    fontWeight: 'bold'
  },
  textFieldSmall: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 24px)',
      marginRight: 24
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:first-of-type': {
        width: '100%'
      }
    }
  },
  buttonImg: {
    color: '#ffffff',
    marginBottom: '20px'
  },
  logoImg: {
    width: '50%',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  imagesError: {
    fontSize: '0.75rem',
    marginLeft: 14,
    marginRight: 14,
    color: '#ff1744'
  },
  addButtonText: {
    paddingTop: 3,
    paddingRight: 15,
    paddingBottom: 3,
    paddingLeft: 15,
    fontWeight: 'bold'
  },
  addReferenceButton: {
    color: theme.palette.primary.main,
    border: '1px solid rgba(0, 179, 207, 0.5)',
    backgroundColor: 'white'
  },
  listItemPointer: {
    cursor: 'pointer'
  },
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const initialFormValues = {
  Sort: null,
  Title: '',
  Content: '',
  Location: '',
  Month: '',
  Year: '',
  Images: []
}

const initialFormValueErrorsState = {
  Title: { hasError: false, message: '' },
  Content: { hasError: false, message: '' },
  Location: { hasError: false, message: '' },
  Month: { hasError: false, message: '' },
  Year: { hasError: false, message: '' },
  Images: { hasError: false, message: '' }
}

const CompanyReferencesStep = ({ step, setStep }) => {
  const dialogContent = null
  const classes = useStyles()
  const { t } = useTranslation()
  const [references, setReferences] = useState(null)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  )

  const handleButtonBack = () => {
    setStep(step - 1 || 0)
  }

  const handleButtonDone = () => {
    navigate('/')
  }

  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>
          {t('microsite.details.wizard.step.4.title')}
        </h1>
        <Card variant='outlined' className={classes.intro}>
          <Grid
            container
            className={classes.introContainer}
            alignItems='center'
          >
            <Grid item className={classes.introItem}>
              <InfoOutlinedIcon className={classes.icon} />
            </Grid>
            <Grid item className={classes.introItem}>
              {t('microsite.details.wizard.step.4.intro')}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <MicrositeDetailsWizardStepper step={step} />
      </Grid>
      <Grid item sm={10} className={classes.item}>
        <CompanyReferencesForm
          classes={classes}
          formValues={formValues}
          setFormValues={setFormValues}
          setFormValueErrors={setFormValueErrors}
          formValueErrors={formValueErrors}
          initialFormValues={initialFormValues}
          initialFormValueErrorsState={initialFormValueErrorsState}
          references={references}
          setReferences={setReferences}
          dialogContent={dialogContent}
          introText={t('company.label.references.tip')}
        />
      </Grid>
      <Grid item className={clsx(classes.item, classes.buttonContainer)}>
        <Button
          id='buttonFormBack'
          color='secondary'
          className={classes.buttonBack}
          onClick={handleButtonBack}
        >
          {t('form.label.back')}
        </Button>
        <Button
          id='buttonFormNext'
          variant='contained'
          color='primary'
          align='left'
          className={classes.buttonNext}
          onClick={handleButtonDone}
        >
          {t('microsite.details.wizard.label.complete')}
        </Button>
      </Grid>
    </>
  )
}

export default CompanyReferencesStep
