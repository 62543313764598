import React, { useState, useEffect } from 'react'
import { Grid, Collapse, FormControlLabel, Checkbox } from '@material-ui/core'
import CreditCard from '../Creditcard/CreditCard'
import Invoice from '../Invoice/Invoice'
import LoadingOverlay from '../LoadingOverlay'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  paymentCardContainer: {
    width: '100%',
    maxWidth: 330,
    margin: '0 auto'
  },
  container: {
    display: 'block',
    width: '100%'
  }
}))

const YEARLY_PLAN = 'yearly'

/**
 * Payment
 *
 * @param {user, result, fetching} props
 * @returns JSX
 */
const Payment = ({ user, fetching, plan }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [invoiceChecked, setInvoiceChecked] = useState(false)
  const [creditCardChecked, setCreditCardChecked] = useState(false)

  /**
   * useEffect
   */
  useEffect(() => {
    if (plan !== YEARLY_PLAN) {
      setCreditCardChecked(true)
    }
  }, [plan])

  /**
   * handleInvoiceChange
   */
  const handleInvoiceChange = () => {
    setInvoiceChecked(prev => {
      if (!prev) {
        setCreditCardChecked(false)
      }
      return !prev
    })
  }

  /**
   * handleCreditCardChange
   */
  const handleCreditCardChange = () => {
    setCreditCardChecked(prev => {
      if (!prev) {
        setInvoiceChecked(false)
      }
      return !prev
    })
  }

  /**
   * return
   */
  return (
    <>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        className={classes.paymentCardContainer}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={creditCardChecked}
              onChange={handleCreditCardChange}
              color='primary'
            />
          }
          label={t('general.label.creditCard')}
        />
        <div className={classes.container}>
          <Collapse in={creditCardChecked}>
            <CreditCard
              stripePublicKey={user.getData().stripePublicKey}
              submitIsDisabled={fetching}
            />
            {(fetching) && <LoadingOverlay />}
          </Collapse>
        </div>
      </Grid>
      {plan === YEARLY_PLAN && (
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          className={classes.paymentCardContainer}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={invoiceChecked}
                onChange={handleInvoiceChange}
                color='primary'
              />
            }
            label={t('general.label.invoice')}
          />
          <div className={classes.container}>
            <Collapse in={invoiceChecked}>
              <Invoice
                stripePublicKey={user.getData().stripePublicKey}
                submitIsDisabled={fetching}
              />
              {fetching && <LoadingOverlay />}
            </Collapse>
          </div>
        </Grid>
      )}
    </>
  )
}

export default Payment
