import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import GlobalContext from "../../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import { FavoriteStateConditionText } from "./FavoriteStateConditions";
import Paper from "@material-ui/core/Paper";
import withAccessControl from "../HOC/AccessControl";
import { useMutation, useQuery } from "urql";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { MESSAGE_TYPES } from "../PopupMessages";
import merge from "deepmerge";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import FormatHelper from "../../lib/FormatHelper";
import moment from "moment";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

const useStyles = makeStyles((theme) => ({
  removed: {
    opacity: 0.5,
  },
  undo: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  link: {
    cursor: "pointer",
  },
  breakLine: {
    margin: 0,
    whiteSpace: "pre-line",
    "& span": {
      fontSize: 16,
    },
  },
  paper: {
    margin: 0,
    boxShadow: "none",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    marginTop: -30,
    marginBottom: 10,
    color: "#fff",
    fontWeight: "bold",
  },
  textarea: {
    marginTop: 30,
    marginBottom: 40,
    "& div": {
      borderColor: "#999",
    },
  },
  input: {
    "&::placeholder": {
      color: "#525252",
      opacity: "1",
    },
  },
  list: {
    marginTop: 20,
    padding: "8px 16px 24px !important",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
  },
  item: {
    width: "100%",
    padding: "0 !important",
    "& button": {
      marginTop: 20,
    },
    "& div": {
      boxShadow: "none",
      border: 0,
    },
  },
  statusText: {
    "& span": {
      fontSize: 14,
    },
  },
  statusDate: {
    flex: "inherit",
    width: 85,
  },
  statusHolder: {
    marginTop: 20,
    padding: 0,
  },
}));

const createFavoriteNoteMutation = `
  mutation CreateFavoriteNoteMutation (
    $favoriteHashID: String,
    $text: String
  ) {
    createFavoriteNote (FavoriteHashID: $favoriteHashID, Text: $text) {
      HashID
      FavoriteHashID
      Text
      Created
      Removed
    } 
  }
`;

const updateFavoriteNoteMutation = `
  mutation UpdateFavoriteNoteMutation (
    $hashID: String,
    $text: String,
    $removed: Boolean
  ) {
    updateFavoriteNote (HashID: $hashID, Text: $text, Removed: $removed) {
      HashID
      FavoriteHashID
      Text
      Created
      Removed
    }
  }
`;

const queryFavorites = `
  query ReadFavorites (
    $userHashID: ID,
    $filterFor: [String]
  ) {
    readFavorites(ID: $userHashID, ConstructionTypeContains: $filterFor) {
      HashID
      ProjectHashID
      Removed
      State
      Canton
      Calculated_distance
      Content
      Final_title
      Import_date
      Date
      Applied
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Phone
        Email
        Website
        Email_other
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      StateHistory {
        edges {
          node {
            Created
            State
          }
        }
      }
      Notes {
        edges {
          node {
            HashID
            Text
            Created
            Removed
          }
        }
      }
    }
  }
`;

/**
 * FavoriteDetails
 *
 * @returns {*}
 * @constructor
 */
const FavoriteDetailAddition = (props) => {
  const classes = useStyles();
  const {
    setFavoriteData,
    setMessage,
    user,
    unsetUser,
    token,
    unsetToken,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const blankNote = {
    id: null,
    text: "",
    date: "",
  };
  const [newNote, setNewNote] = useState(blankNote);
  const [editNote, setEditNote] = useState(blankNote);
  const [formerResult, setFormerResult] = useState([]);

  const [{ fetchingCreateFavoriteNote }, executeCreateFavoriteNoteMutation] =
    useMutation(createFavoriteNoteMutation);

  const [{ fetchingUpdateFavoriteNote }, executeUpdateFavoriteNoteMutation] =
    useMutation(updateFavoriteNoteMutation);

  // GraphQL query for favorites, gets triggered by calling "executeFavoritesQuery()"
  const [result, executeFavoritesQuery] = useQuery({
    query: queryFavorites,
    variables: {
      userHashID: user.getData().hashID,
      filterFor: props.filterFor,
    },
    requestPolicy: "network-only",
    pause: true,
  });

  let notes = [];
  if (props.favorite.Notes.edges) {
    notes = props.favorite.Notes.edges;
  }

  let stateHistory = [];
  if (props.favorite.StateHistory.edges) {
    stateHistory = props.favorite.StateHistory.edges;
  }

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          if (formerResult.length !== 0) {
            setFormerResult([]);
          }
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readFavorites !== "undefined"
        ) {
          // Add the favorites to the global context
          setFavoriteData(result.data.readFavorites);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * save new note change to state
   *
   * @param event
   */
  const handleAddNote = (event) => {
    setNewNote(Object.assign({}, newNote, { text: event.target.value }));
  };

  /**
   * save add note-state to context/api
   */
  const handleAddNoteSubmit = () => {
    executeCreateFavoriteNoteMutation({
      favoriteHashID: props.favorite.HashID,
      text: newNote.text,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error add note on FavoriteDetailAddition",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error),
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.INFO, t("favorites.label.details.note.added"));
        // Reset the note
        setNewNote(blankNote);

        executeFavoritesQuery();
      }
    });
  };

  /**
   * save edited note change to state
   *
   * @param note
   */
  const handleEditNote = (note) => {
    if (editNote.id !== null) {
      if (note.id !== editNote.id) {
        // End text-area-mode
        setEditNote(blankNote);
        // Set current text-area-mode
        setEditNote(note);
      } else {
        // End text-area-mode
        setEditNote(blankNote);
      }
    } else {
      // Start text-area-mode
      setEditNote(note);
    }
  };

  /**
   * reset edit mode if a new note is about to be added
   */
  const handleNewNoteFocus = () => {
    setEditNote(blankNote);
  };

  /**
   * update edited note to state
   *
   * @param event
   */
  const handleUpdateEditNote = (event) => {
    const mergedNote = merge(editNote, { node: { Text: event.target.value } });
    setEditNote(mergedNote);
  };

  /**
   * save edit note-state to context/api
   */
  const handleEditNoteSubmit = () => {
    executeUpdateFavoriteNoteMutation({
      hashID: editNote.node.HashID,
      text: editNote.node.Text,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error edit note on FavoriteDetailAddition",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error),
            },
          });
        }
      } else {
        setMessage(
          MESSAGE_TYPES.INFO,
          t("favorites.label.details.note.changed")
        );
        // Reset the note
        setEditNote(blankNote);
        executeFavoritesQuery();
      }
    });
  };

  /**
   * delete note
   *
   * @param deletedNoteId
   */
  const handleDeleteNote = (deletedNoteId) => {
    executeUpdateFavoriteNoteMutation({
      hashID: deletedNoteId,
      removed: true,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error delete note on FavoriteDetailAddition",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error),
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.INFO, t("favorites.label.removed"));
        // Reset the note
        setEditNote(blankNote);
        executeFavoritesQuery();
      }
    });
  };

  /**
   * restore note
   *
   * @param deletedNoteId
   */
  const handleRestoreNote = (deletedNoteId) => {
    executeUpdateFavoriteNoteMutation({
      hashID: deletedNoteId,
      removed: false,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else if (
          result.error.message.includes(
            `Favorite note with HashID '${deletedNoteId}' not found`
          )
        ) {
          setMessage(MESSAGE_TYPES.INFO, t("error.item.already.deleted"));
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error restore note on FavoriteDetailAddition",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error),
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.INFO, t("favorites.label.details.note.added"));
        // Reset the note
        setEditNote(blankNote);
        executeFavoritesQuery();
      }
    });
  };

  /**
   * edit mode of a note
   */
  const EditNote = () => {
    return (
      <>
        <TextField
          id={`favorites-list-detail-note-edit-input-${props.favorite.HashID}-${editNote.node.HashID}`}
          key={`favorites-list-detail-note-edit-input-${props.favorite.HashID}-${editNote.node.HashID}`}
          multiline
          fullWidth
          variant="outlined"
          rowsMax={8}
          rows={5}
          placeholder={t("favorites.label.details.notes.add")}
          onChange={handleUpdateEditNote}
          value={editNote.node.Text}
        />
        {editNote.node.Text.length > 0 && (
          <Button
            id={`favorites-list-detail-note-edit-submit-${props.favorite.HashID}-${editNote.node.HashID}`}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleEditNoteSubmit}
          >
            {t("general.label.save")}
          </Button>
        )}
      </>
    );
  };

  /**
   * handleNote
   *
   * @param historyEntry
   * @returns {*}
   */
  const handleNote = (historyEntry) => {
    let isRight = false;
    if (editNote.node) {
      isRight = editNote.node.HashID === historyEntry.node.HashID;
    }

    return editNote.node && isRight ? (
      EditNote()
    ) : (
      <Paper className={classes.paper}>
        {!historyEntry.node.Removed && (
          <ListItemText
            className={classes.breakLine}
            primary={historyEntry.node.Text}
          />
        )}
        {historyEntry.node.Removed && (
          <>
            <span className={classes.removed}>
              {t("favorites.label.removed")}
            </span>
            <span>&nbsp;</span>
            <span
              className={`${classes.undo} ${classes.link}`}
              onClick={() => {
                handleRestoreNote(historyEntry.node.HashID);
              }}
            >
              {t("general.label.undo")}
            </span>
          </>
        )}
      </Paper>
    );
  };

  /**
   * favorite details (notes and states-history)
   */
  const HistoryList = () => {
    const historyListItems = [];
    const historyMerged = [];

    for (let i = 0; i < stateHistory.length; i++) {
      const state = stateHistory[i];
      state.type = "state";
      historyMerged.push(state);
    }

    for (let i = 0; i < notes.length; i++) {
      const note = notes[i];
      note.type = "note";
      historyMerged.push(note);
    }

    // Sort history-entries by date
    historyMerged.sort((a, b) => {
      const aDate = moment(a.node.Created).unix();
      const bDate = moment(b.node.Created).unix();
      return bDate > aDate ? 1 : bDate < aDate ? -1 : 0;
    });

    // Put all notes and states into a single storage for sorting
    for (let i = 0; i < historyMerged.length; i++) {
      const historyEntry = historyMerged[i];

      // In case we do not have an id yet due to api request-time,
      // generate a temporary random id
      if (historyEntry.id === null) {
        historyEntry.id = Math.random().toString(36).substring(7);
      }

      // TODO: Add en-US variant
      // Localize date
      const dateLocalized = FormatHelper.formatDate(historyEntry.node.Created);

      if (historyEntry.type === "state") {
        // State
        historyListItems.push(
          <ListItem
            key={`${historyEntry.type}-${moment(
              historyEntry.node.Created
            ).unix()}`}
            className={classes.statusHolder}
          >
            <ListItemText
              secondary={`${dateLocalized}`}
              className={classes.statusDate}
            />
            <ListItemText
              primary={`${t(
                "favorites.label.details.state.changed"
              )} "${FavoriteStateConditionText(t, historyEntry.node.State)}"`}
              className={classes.statusText}
            />
          </ListItem>
        );
      } else {
        // Note (may be editable)
        historyListItems.push(
          <ListItem
            className={classes.list}
            key={`${historyEntry.type}-${historyEntry.node.HashID}`}
          >
            <Grid container className={clsx(classes.root, classes.container)}>
              <Grid item lg={10}>
                <ListItemText
                  className={`${
                    historyEntry.node.Removed ? classes.Removed : ""
                  }`}
                  secondary={`${dateLocalized}`}
                />
              </Grid>
              <Grid item lg={2}>
                <Grid container className={classes.root}>
                  <Grid item>
                    {!historyEntry.node.Removed && (
                      <IconButton
                        id={`favorites-list-detail-note-edit-${props.favorite.HashID}-${historyEntry.node.HashID}`}
                        onClick={() => {
                          handleEditNote(historyEntry);
                        }}
                      >
                        <EditIcon color="primary" className={classes.icon} />
                      </IconButton>
                    )}
                    {historyEntry.node.Removed && (
                      <IconButton
                        className={classes.removed}
                        id={`favorites-list-detail-note-edit-${props.favorite.HashID}-${historyEntry.node.HashID}`}
                      >
                        <EditIcon color="primary" className={classes.icon} />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    {!historyEntry.node.Removed && (
                      <IconButton
                        id={`favorites-list-detail-note-delete-${props.favorite.HashID}-${historyEntry.node.HashID}`}
                        onClick={() => {
                          handleDeleteNote(historyEntry.node.HashID);
                        }}
                      >
                        <DeleteIcon color="primary" className={classes.icon} />
                      </IconButton>
                    )}
                    {historyEntry.node.Removed && (
                      <IconButton
                        id={`favorites-list-detail-note-restore-${props.favorite.HashID}-${historyEntry.node.HashID}`}
                        onClick={() => {
                          handleRestoreNote(historyEntry.node.HashID);
                        }}
                      >
                        <RestoreFromTrashIcon
                          color="primary"
                          className={classes.icon}
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.item}>
                {handleNote(historyEntry)}
              </Grid>
            </Grid>
          </ListItem>
        );
      }
    }

    return <List className={classes.root}>{historyListItems}</List>;
  };

  /**
   * return
   */
  return (
    <>
      <TextField
        id={`favorites-list-detail-note-new-input-${props.favorite.HashID}`}
        multiline
        fullWidth
        variant="outlined"
        rowsMax={8}
        rows={5}
        placeholder={t("favorites.label.details.notes.add")}
        InputProps={{ classes: { input: classes.input } }}
        onChange={handleAddNote}
        onFocus={handleNewNoteFocus}
        value={newNote.text}
        className={classes.textarea}
      />
      {newNote.text.length > 0 && (
        <Button
          id={`favorites-list-detail-note-new-submit-${props.favorite.HashID}`}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleAddNoteSubmit}
        >
          {t("general.label.save")}
        </Button>
      )}
      {(fetchingCreateFavoriteNote || fetchingUpdateFavoriteNote) && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {HistoryList()}
    </>
  );
};

export default withAccessControl(
  FavoriteDetailAddition,
  ACL_COMPONENTS.FAVORITE_DETAIL_ADDITION
);
