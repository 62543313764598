import React, { useState, useEffect, useCallback } from "react";
import { createMuiTheme } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalContext from "./lib/GlobalContext";
import {
  useRoutes,
  useRedirect,
  navigate,
  getWorkingPath,
  useQueryParams,
  setQueryParams,
} from "hookrouter";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import ProjectsSearch from "./pages/ProjectsSearch";
import ProjectsFree from "./pages/ProjectsFree";
import Favorites from "./pages/Favorites";
import NotFoundPage from "./pages/NotFoundPage";
import Onboarding from "./pages/Onboarding";
import OnboardingMarketing from "./pages/OnboardingMarketing";
import OnboardingPasswordReset from "./pages/OnboardingPasswordReset";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Layout from "./components/Layout";
import Account from "./pages/Account";
import MicrositeDetailsWizard from "./pages/MicrositeDetailsWizard";
import Token from "./lib/Token";
import Company from "./pages/Company";
import { useTranslation, withTranslation } from "react-i18next";
import locationDataMock from "./__mocks__/mock__locations";
import User from "./lib/User";
import "./i18n";
import ErrorBoundary from "./lib/ErrorBoundary";
import UpgradeToPremium from "./components/UpgradeToPremium/UpgradeToPremium";
import { useLoadScript } from "@react-google-maps/api";
import { GOOGLE_API_KEY, cookieDomain } from "./config";
import Cookies from "universal-cookie";
import { green } from "@material-ui/core/colors";
import CustomSC from "./pages/CustomSC";
import DossierManagement from "./pages/DossierManagement";
import Dossier from "./pages/Dossier";
import Statistics from "./components/Statistics/Statistics";

// Event listener to keep the user and token data equal between browser tabs
window.addEventListener("storage", (e) => {
  if (e.newValue !== null) {
    switch (e.key) {
      case "user":
        if (JSON.stringify(User.getData()) !== e.newValue) {
          User.setData(JSON.parse(e.newValue));
        }
        break;
      case "token":
        if (JSON.stringify(Token.getData()) !== e.newValue) {
          Token.setData(JSON.parse(e.newValue));
        }
        break;
      default:
        break;
    }
  }
});

const rootStandard = {
  root: {
    fontSize: 14,
    lineHeight: "24px",
    letterSpacing: "0px",

    "@media (min-width:960px)": {
      fontSize: 14,
      lineHeight: "24px",
      letterSpacing: "0px",
    },
  },
};
// Style definitions
export const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paperWidthXs: {
        width: "100%",
        maxWidth: 475,
      },
      paperWidthMd: {
        width: "100%",
        maxWidth: 850,
      },
    },
    MuiTab: rootStandard,
    MuiTableCell: rootStandard,
    MuiTypography: {
      caption: {
        "@media (min-width:960px)": {
          fontSize: 14,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(0,179,207,0.3)",
        },
      },
    },
    MuiInputBase: {
      input: {
        background: "#fff",
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: "10px 35px",
        backgroundColor: "transparent",
        color: "#525252",
        fontSize: 14,
        "@media (max-width:960px)": {
          padding: "5px 20px",
        },
      },
      tooltipPlacementBottom: {
        margin: "0 !important",
      },
      arrow: {
        color: "#3EE1FA",
        fontSize: 12,
      },
      popper: {
        borderRadius: 5,
        background: "#3EE1FA",
      },
    },
    MuiRadio: {
      root: {
        checked: {
          color: "#00b3cf",
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        height: 3,
      },
    },
    MuiDropzoneSnackbar: {
      errorAlert: {
        backgroundColor: "#c4001d",
      },
      successAlert: {
        backgroundColor: green[600],
      },
      infoAlert: {
        backgroundColor: "#006A7A",
      },
    },
    MuiDropzonePreviewList: {
      root: {
        width: "100%",
      },
      image: {
        width: "auto",
        height: "auto",
      },
    },
  },
  palette: {
    primary: {
      main: "#00b3cf",
    },
    secondary: {
      main: "#89FFFF",
    },
    info: {
      main: "#3EE1FA",
    },
    text: {
      primary: "#525252",
      secondary: "#A6A6A6",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#f2f3f4",
      footer: "#D3D3D3",
      userIcon: "#2e7585",
    },
  },
  hover: {
    "&:hover": {
      backgroundColor: "#00B3CF",
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
  },
  watermarkLogo: {
    content: '""',
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    width: "150px",
    height: "180px",
    backgroundImage: `url(${"/assets/watermark-dialog.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    "@media (min-width:640px)": {
      width: "220px",
      height: "250px",
    },
  },
});

// Route definitions
const routes = {
  "/projects": () => (user, token) =>
    user.isSet() && token.isSet() ? <Projects /> : <Login />,
  "/projects-search": () => (user, token) =>
    user.isSet() && token.isSet() ? <ProjectsSearch /> : <Login />,
  "/projects-free": () => (user, token) =>
    user.isSet() && token.isSet() ? <ProjectsFree /> : <Login />,
  "/favorites": () => (user, token) =>
    user.isSet() && token.isSet() ? <Favorites /> : <Login />,
  "/custom_sc": () => (user, token) =>
    user.isSet() && token.isSet() ? <CustomSC /> : <Login />,
  "/dossier-management": () => (user, token) =>
    user.isSet() && token.isSet() ? <DossierManagement /> : <Login />,
  "/account*": () => (user, token) =>
    user.isSet() && token.isSet() ? <Account /> : <Login />,
  "/company*": () => (user, token) =>
    user.isSet() && token.isSet() ? <Company /> : <Login />,
  "/wizard*": () => (user, token) =>
    user.isSet() && token.isSet() ? <MicrositeDetailsWizard /> : <Login />,
  "/onboarding/:id/:refreshToken":
    ({ id, refreshToken }) =>
    () =>
      <Onboarding id={id} refreshToken={refreshToken} />,
  "/onboarding-password-reset/:id/:refreshToken":
    ({ id, refreshToken }) =>
    () =>
      <OnboardingPasswordReset id={id} refreshToken={refreshToken} />,
  "/onboarding-marketing/:id":
    ({ id }) =>
    () =>
      <OnboardingMarketing id={id} />,
  "/project/:id":
    ({ id }) =>
    () =>
      <Project id={id} />,
  "/dossier/:id/:subPage":
    ({ id, subPage }) =>
    () =>
      <Dossier id={id} subPage={subPage} />,
  "/login": () => (user) => user.isSet() ? <Login /> : navigate("/"),
  "/logout": () => () => <Logout />,
  "/statistics*": () => (user, token) =>
    user.isSet() && token.isSet() ? <Statistics /> : <Login />,
  "/*": () => () => <NotFoundPage />,
};

const googleLibraries = ["places"];

/**
 * App
 *
 * @returns {*}
 * @constructor
 */
const App = () => {
  useRedirect("/", "/projects");
  const { i18n } = useTranslation();
  const [removeProjectSearchParameter, setRemoveProjectSearchParameter] =
    useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [projectData, setProjectData] = useState([]);
  const [favoriteData, setFavoriteData] = useState([]);
  const [locationData, setLocationData] = useState(locationDataMock);
  const [componentUser, setComponentUser] = useState(User);
  const [userHash, setUserHash] = useState(User.getHash());
  const [tokenHash, setTokenHash] = useState(Token.getHash());
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(true);
  const [componentToken, setComponentToken] = useState(Token);
  const [upgradeToPremiumOpen, setUpgradeToPremiumOpen] = useState(false);
  const [upgradeToPremiumClose, setUpgradeToPremiumClose] = useState(false);
  const [messageState, setMessageState] = useState(null);
  const [selectedLocationTabValue, setSelectedLocationTabValue] =
    useState(false);
  const [canton, setCanton] = useState("ZH");
  const [googleMapsIsLoaded, setGoogleMapsIsLoaded] = useState(false);
  const [googleMapsLoadError, setGoogleMapsLoadError] = useState(false);
  const [cmsLinks, setCmsLinks] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [visibleProjects, setVisibleProjects] = useState([]);
  const [projectSearchParameter, setProjectSearchParameter] = useState("");
  const [markAsReadByLocation, setMarkAsReadByLocation] = useState({
    hashID: "",
  });
  const [companyTabValue, setCompanyTabValue] = useState(0);
  const [accountTabValue, setAccountTabValue] = useState(0);
  const [debugToolsEnabled, setDebugToolsEnabled] = useState(false);
  const [
    selectedBuildingApplicationTypeTabValue,
    setSelectedBuildingApplicationTypeTabValue,
  ] = useState(false);

  const [queryParams] = useQueryParams();

  /**
   * setToken
   * Implementation of https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   *
   * @param token
   */
  const setToken = useCallback(
    (token) => {
      Token.setData(token.getData());
      setComponentToken(Token);
      const hash = token.getHash();
      if (tokenHash !== hash) {
        setTokenHash(hash);
      }
    },
    [tokenHash]
  );

  /**
   * setUser
   * Implementation of https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
   *
   * @param user
   */
  const setUser = useCallback(
    (user) => {
      User.setData(user.getData());
      setComponentUser({ ...User });
      const hash = user.getHash();
      if (userHash !== hash) {
        setUserHash(hash);
      }
    },
    [userHash]
  );

  /**
   * load script for google maps
   */
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: googleLibraries,
  });

  /**
   * useEffect
   */
  useEffect(() => {
    i18n.changeLanguage(componentUser.getData().locale);

    setGoogleMapsIsLoaded(isLoaded);
    setGoogleMapsLoadError(loadError);

    // Load user from localStore
    if (!componentUser.isSet() && User.getLocalStorage()) {
      componentUser.setData(User.getLocalStorage());
      setUser(componentUser);
    }
    // Load token from localStore
    if (!componentToken.isSet() && componentToken.loadFromLocalStorage()) {
      setToken(componentToken);
    }
  }, [
    i18n,
    componentUser,
    componentToken,
    setToken,
    setUser,
    isLoaded,
    loadError,
  ]);

  /**
   * handle showUpgrade query param useEffect
   */
  useEffect(() => {
    if (
      queryParams.showUpgrade !== undefined &&
      queryParams.showUpgrade === "true"
    ) {
      if (upgradeToPremiumOpen !== true) {
        setUpgradeToPremiumOpen(true);
        setQueryParams({ showUpgrade: "false" });
      }
    }
  }, [queryParams, upgradeToPremiumOpen]);

  /**
   * removeAppRedirectCookie
   */
  const removeAppRedirectCookie = () => {
    const cookies = new Cookies();
    if (typeof cookies.get("appRedirect") !== "undefined") {
      cookies.remove("appRedirect", {
        path: "/",
        domain: cookieDomain,
      });
    }
  };

  /**
   * unsetUser
   */
  const unsetUser = () => {
    componentUser.unsetData();
    setComponentUser({ ...componentUser });
    removeAppRedirectCookie();
  };

  /**
   * setTokenWithoutRehash
   * Token change not triggering re-renders
   */
  const setTokenWithoutRehash = (token) => {
    Token.setData(token.getData());
    setComponentToken(Token);
  };

  /**
   * unsetToken
   * Token changes should not trigger re-renders!
   */
  const unsetToken = () => {
    componentToken.unsetData();
    setComponentToken(componentToken);
    removeAppRedirectCookie();
  };

  /**
   * notifyBeforePremiumDialogueClose
   * Get calls before the upgradeToPremium dialogue gets closed
   */
  const notifyBeforePremiumDialogueClose = () => {
    setUpgradeToPremiumClose(true);
  };

  /**
   * setMessage
   *
   * @param code
   * @param message
   */
  const setMessage = (code, message) => {
    setMessageState({ code, message });
  };

  /**
   * clearMessage
   */
  const clearMessage = () => {
    setMessageState(null);
  };

  /**
   * canUserUpgrade
   *
   * @returns {boolean|boolean}
   */
  const canUserUpgrade = () => {
    return (
      (upgradeToPremiumOpen && !getWorkingPath().includes("onboarding")) ||
      (componentUser.isAbleToUpgrade() &&
        !getWorkingPath().includes("onboarding"))
    );
  };

  /**
   * return
   */
  return (
    <ErrorBoundary user={componentUser}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalContext.Provider
          value={{
            wizardStep,
            setWizardStep,
            removeProjectSearchParameter,
            setRemoveProjectSearchParameter,
            projectData,
            setProjectData,
            favoriteData,
            setFavoriteData,
            locationData,
            setLocationData,
            user: componentUser,
            setUser,
            unsetUser,
            openWelcomeDialog,
            setOpenWelcomeDialog,
            token: componentToken,
            setToken,
            setTokenWithoutRehash,
            unsetToken,
            tokenHash,
            upgradeToPremiumOpen,
            setUpgradeToPremiumOpen,
            upgradeToPremiumClose,
            setUpgradeToPremiumClose,
            message: messageState,
            setMessage,
            clearMessage,
            selectedLocationTabValue,
            setSelectedLocationTabValue,
            canton,
            setCanton,
            googleMapsIsLoaded,
            googleMapsLoadError,
            cmsLinks,
            setCmsLinks,
            paymentMethodId,
            setPaymentMethodId,
            visibleProjects,
            setVisibleProjects,
            projectSearchParameter,
            setProjectSearchParameter,
            markAsReadByLocation,
            setMarkAsReadByLocation,
            companyTabValue,
            setCompanyTabValue,
            accountTabValue,
            setAccountTabValue,
            selectedBuildingApplicationTypeTabValue,
            setSelectedBuildingApplicationTypeTabValue,
            debugToolsEnabled,
          }}
        >
          <Layout setDebugToolsEnabled={setDebugToolsEnabled}>
            {useRoutes(routes)(componentUser, componentToken)}
            {canUserUpgrade() && (
              <UpgradeToPremium
                open={upgradeToPremiumOpen}
                notifyBeforeClose={notifyBeforePremiumDialogueClose}
              />
            )}
          </Layout>
        </GlobalContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default withTranslation("translations")(App);
