import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { navigate } from "hookrouter";
import { Trans, useTranslation } from "react-i18next";
import { Link, makeStyles } from "@material-ui/core";
import GlobalContext from "../../lib/GlobalContext";
import FormatHelper from "../../lib/FormatHelper";
import { MESSAGE_TYPES } from "../PopupMessages";
import { useMutation, useQuery } from "urql";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import { apiTld, companyDirectoryPath } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    flexGrow: 1,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: 0,
  },
  dialog: {
    width: "100%",
    paddingBottom: 56,
    "&::before": theme.watermarkLogo,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  startButton: {
    width: "100%",
    color: "#fff",
    fontWeight: "bold",
  },
  link: {
    cursor: "pointer",
  },
  directoryName: {
    fontWeight: "bold",
    backgroundColor: theme.palette.background.footer,
  },
}));

const createMicrositeMutation = `
  mutation (
    $URLSegment: String!
    $CheckURLSegment: Boolean!
  ) {
     createMicrosite (
       URLSegment: $URLSegment
       CheckURLSegment: $CheckURLSegment
     ) {
       URLSegment
       CheckURLSegment
     }
  }
`;

const queryMyself = `
{
  readMyself {
    Company {
      MicrositeEmailApplicationText
    }
  }
}
`;

/**
 * CompanyDirectoryNameDialog
 *
 * @constructor
 */
const CompanyDirectoryNameDialog = (props) => {
  const classes = useStyles();
  const { formValues, setFormValues } = props;
  const { user, token, setUser, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const [directoryName, setDirectoryName] = useState("");
  const [checkUrlSegment, setCheckUrlSegment] = useState(true);
  const [showDoubleOptInContent, setShowDoubleOptInContent] = useState(false);
  const [directoryNameError, setDirectoryNameError] = useState({
    hasError: false,
    message: "",
  });
  const [showExplanation, setShowExplanation] = useState(false);

  const [{ fetching }, executeCreateMicrositeMutation] = useMutation(
    createMicrositeMutation
  );

  const [result, executeMyselfQuery] = useQuery({
    query: queryMyself,
    variables: { token: token.getData().access_token }, // Required as cache-control mechanism
    requestPolicy: "network-only",
    pause: true,
  });

  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching myself on CompanyDirectoryNameDialog",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readMyself !== "undefined"
        ) {
          setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
          user.setData({
            company: {
              urlSegment: directoryName,
              micrositeEmailApplicationText:
                result.data.readMyself[0].Company.MicrositeEmailApplicationText,
            },
          });
          setUser(user);
          navigate("/wizard");
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * useEffect
   */
  useEffect(() => {
    const { directoryName: contextDirectoryName, name: contextCompanyName } =
      user.getData().company;
    if (contextDirectoryName) {
      setDirectoryName(contextDirectoryName);
    } else {
      const formattedCompanyName =
        FormatHelper.formatDirectoryName(contextCompanyName);
      setDirectoryName(formattedCompanyName.toLowerCase());
    }
  }, [user, setDirectoryName]);

  /**
   * closeDialogue
   */
  const closeDialogue = () => {
    navigate("/company");
  };

  /**
   * handleDirectoryNameChange
   *
   * @param event
   */
  const handleDirectoryNameChange = (event) => {
    setDirectoryName(event.target.value);
  };

  /**
   * isDirectoryNameValid
   *
   * @returns {boolean}
   */
  const isDirectoryNameValid = () => {
    let isValid = true;

    if (
      directoryName === "" ||
      directoryName.match(/[^A-Za-z0-9-]+/u) !== null
    ) {
      isValid = false;
    }

    return isValid;
  };

  /**
   * handleSubmit
   */
  const handleSubmit = () => {
    if (showExplanation) {
      executeMyselfQuery();
    } else if (isDirectoryNameValid()) {
      // Everything is good, start the api call

      executeCreateMicrositeMutation({
        URLSegment: directoryName,
        CheckURLSegment: checkUrlSegment,
      }).then((result) => {
        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            console.log(result.error);
            setMessage(MESSAGE_TYPES.ERROR, t("error.save")); // TODO: set suitable error message!
            setDirectoryNameError({
              hasError: true,
              message: t("company.label.microsite.directory.name.error"),
            });
            setFormValues({ ...formValues, directoryName: "" });
          }
        } else {
          // check if url segment differs from directory name
          if (result.data.createMicrosite[0].URLSegment !== directoryName) {
            setDirectoryNameError({
              hasError: true,
              message: t("company.label.microsite.directory.name.error"),
            });
            setDirectoryName(result.data.createMicrosite[0].URLSegment);
          } else {
            if (checkUrlSegment) {
              // show double opt in content
              setShowDoubleOptInContent(true);
              setCheckUrlSegment(false);
            } else {
              // everything is fine microsite is created
              setShowExplanation(true);
            }
          }
        }
      });
    } else {
      setDirectoryNameError({
        hasError: true,
        message: t("error.form.directory.name.not.valid"),
      });
    }
  };

  /**
   * handleChangeDirectoryNameLink
   *
   * @param event
   */
  const handleChangeDirectoryNameLink = (event) => {
    event.preventDefault();
    setShowDoubleOptInContent(false);
    setCheckUrlSegment(true);
  };

  /**
   * return
   */
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      className={classes.dialogRoot}
      open={!props.verifyDialogOpen}
    >
      <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        <IconButton
          onClick={() => {
            closeDialogue();
          }}
        >
          <CloseIcon id="closeDialog" />
        </IconButton>
      </DialogTitle>
      {showExplanation && (
        <DialogContent className={classes.dialog}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            margin="normal"
            padding="normal"
            className={classes.gridContainer}
          >
            <Grid item className={clsx(classes.gridItem, classes.itemHeader)}>
              <h1 className={classes.title}>
                {t("company.label.microsite.explanation.header")}
              </h1>
            </Grid>
            <Grid item className={classes.gridItem}>
              <p>{t("company.label.microsite.explanation")}</p>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Button
                id="buttonFormSubmit"
                variant="contained"
                color="primary"
                className={classes.startButton}
                onClick={handleSubmit}
              >
                {t("general.label.acknowledged")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {!showExplanation && (
        <DialogContent className={classes.dialog}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            margin="normal"
            padding="normal"
            className={classes.gridContainer}
          >
            <Grid item className={clsx(classes.gridItem, classes.itemHeader)}>
              <h1 className={classes.title}>
                {showDoubleOptInContent
                  ? t("company.label.microsite.are.you.sure")
                  : t("company.label.microsite")}
              </h1>
              <p>
                {showDoubleOptInContent ? (
                  t("company.label.microsite.double.opt.in.intro")
                ) : (
                  <Trans i18nKey="company.label.microsite.intro" />
                )}
              </p>
            </Grid>
            {showDoubleOptInContent && (
              <Grid item className={classes.gridItem}>
                {apiTld + companyDirectoryPath}
                <span className={classes.directoryName}>{directoryName}</span>
              </Grid>
            )}
            <Grid item className={classes.gridItem}>
              {!showDoubleOptInContent && (
                <TextField
                  id="inputDirectoryNameDialog"
                  variant="outlined"
                  align="left"
                  error={directoryNameError.hasError}
                  label={t("form.label.web.address")}
                  value={directoryName}
                  helperText={directoryNameError.message}
                  onChange={handleDirectoryNameChange}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {companyDirectoryPath}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {showDoubleOptInContent && (
                <Link
                  className={classes.link}
                  onClick={handleChangeDirectoryNameLink}
                >
                  {t("company.label.microsite.change.web.address")}
                </Link>
              )}
            </Grid>
            <Grid item className={classes.gridItem}>
              <Button
                id="buttonFormSubmit"
                variant="contained"
                color="primary"
                className={classes.startButton}
                onClick={handleSubmit}
                disabled={fetching}
              >
                {showDoubleOptInContent
                  ? t("company.label.microsite.submit")
                  : t("company.label.microsite.verify")}
                {fetching && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default CompanyDirectoryNameDialog;
