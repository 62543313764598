import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withAccessControl from "../HOC/AccessControl";
import { ACL_COMPONENTS, isNotLive } from "../../config";
import { Grid, Typography } from "@material-ui/core";
import DebugImportantDataFragment from "./DebugImportantDataFragment";
import User from "../../lib/User";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  debugHolder: {
    marginTop: 7,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: 3,
    border: "1px solid #edcc4a",
  },
  debugListWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: "#fff4db",
  },
  debugListTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
  },
  debugTitle: {
    marginRight: 10,
  },
}));

/**
 * DebugFavorites
 *
 * @returns {*}
 * @constructor
 */
const DebugFavorites = () => {
  const classes = useStyles();

  return (!User.isMasquerade() && (isNotLive || User.isAppAdmin())) ? (
    <div>
      <Grid item className={classes.debugListWrapper}>
        <Typography className={classes.debugListTitle}>
          Debugging-Tools (Favorites-Page){" "}
        </Typography>
         <DebugImportantDataFragment/>
      </Grid>
    </div>
  ) : null;
};

export default withAccessControl(DebugFavorites, ACL_COMPONENTS.FAVORITES);
