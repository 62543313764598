import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { VERSION, DEFAULT_LANGUAGE } from './config'

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: DEFAULT_LANGUAGE,
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json?v=' + VERSION
    },
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n
