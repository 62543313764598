import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import FavoriteStatus from "./FavoriteStatus";
import FavoriteDetailAddition from "./FavoriteDetailAddition";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import GoogleMapComponent from "../../lib/GoogleMapComponent";
import withAccessControl from "../HOC/AccessControl";
import GetSafe from "../../lib/GetSafe";
import FormatHelper from "../../lib/FormatHelper";
import PHE from "print-html-element";
import { renderToString } from "react-dom/server";
import { Button, Table, TableBody, TableHead } from "@material-ui/core";
import { MESSAGE_TYPES } from "../PopupMessages";
import { useMutation } from "urql";
import LinearProgress from "@material-ui/core/LinearProgress";
import { A, navigate } from "hookrouter";
import { ACL_COMPONENTS, DEFAULT_LOGO_PATH } from "../../config";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import Grid from "@material-ui/core/Grid";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import UserTypes from "../Onboarding/UserTypes";
import FavoriteEmailApplyDialog from "./FavoriteEmailApplyDialog";
import FavoriteApplicationPrintDialog from "./FavoriteApplicationPrintDialog";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { BUILDING_APPLICATION_TYPE } from "../BuildingApplicationTypeTabs";
import ProjectManagementAddressDataValidator from "../../lib/ProjectManagementAddressDataValidator";

const useStyles = makeStyles((theme) => ({
  removed: {
    opacity: 0.5,
  },
  undo: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  link: {
    cursor: "pointer",
  },
  icon: {
    padding: 8,
  },
  submissionIcon: {
    paddingTop: 5,
    paddingRight: 4,
    position: "relative",
    top: 5,
    color: theme.palette.text.secondary,
  },
  tableCellColumnsbody: {
    width: "calc(100% * 1 / 5)",
    minWidth: 100,
    padding: 14,
    "&:nth-child(1)": {
      minWidth: 215,
    },
    "&:nth-child(2)": {
      minWidth: 520,
    },
    "&:nth-child(3)": {
      minWidth: 130,
    },
    "@media (max-width:640px)": {
      "&:first-of-type": {
        minWidth: "auto",
      },
      "&:nth-child(2)": {
        minWidth: 320,
      },
    },
  },
  toggleTitle: {
    color: "#999",
    fontWeight: "bold",
  },
  toggleTableCell: {
    paddingTop: 0,
    verticalAlign: "top",
  },
  toggleTableCellMap: {
    paddingLeft: 230,
    "@media (max-width:640px)": {
      paddingLeft: 20,
    },
  },
  tableInnerRow: {
    "& td": {
      borderBottom: 0,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  tooltipItem: {
    width: "100%",
    maxWidth: 350,
    borderRadius: 5,
    background: "#006A7A",
    fontSize: 16,
    textAlign: "center",
    "& div": {
      margin: 0,
      color: "#fff",
    },
  },
  tooltipInfo: {
    maxWidth: "100%",
    background: theme.palette.background.default,
    "& div": {
      maxWidth: "100%",
      color: theme.palette.text.primary,
      fontSize: 12,
      lineHeight: "20px",
    },
    "@media (min-width:768px)": {
      maxWidth: 450,
    },
  },
  tooltipTitle: {
    display: "flex",
    alignItems: "center",
  },
  headerWrapper: {
    width: "100%",
    maxWidth: 1320,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: "#4ab3cf",
    "-webkit-print-color-adjust": "exact",
  },
  logo: {
    paddingLeft: 0,
  },
  button: {
    color: "#ffffff",
    width: "60%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  externalLink: {
    textDecoration: "none",
  },
}));

const updateFavoriteMutation = `
  mutation UpdateFavorite (
    $hashID: String,
    $removed: Boolean
  ) {
    updateFavorite (HashID: $hashID, Removed: $removed) {
      HashID
    }
  }
`;

/**
 * FavoritesTableColumn
 *
 * @returns {*}
 * @constructor
 */
const FavoritesTableRow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    favoriteData,
    setFavoriteData,
    setMessage,
    unsetUser,
    token,
    unsetToken,
    user,
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen,
  } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [emailApplyDialog, setEmailApplyDialog] = useState(false);
  const [applicationPrintDialog, setApplicationPrintDialog] = useState(false);

  const [{ fetchingUpdateFavorite }, executeUpdateFavoriteMutation] =
    useMutation(updateFavoriteMutation);

  /**
   * after mounting set the removed flag
   */
  useEffect(() => {
    if (removed !== props.favorite.Removed) {
      setRemoved(props.favorite.Removed);
    }
  }, [props.favorite.Removed, removed]);

  /**
   * open context menu
   *
   * @param event
   */
  const handleContextMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  /**
   * close context menu
   */
  const handleContextMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  /**
   * toggle favorite status
   */
  const handleFavoriteRemoveToggle = (event) => {
    event.stopPropagation();

    if (open) {
      setOpen(false);
    }

    const toggledRemoved = !removed;
    const favorites = favoriteData;

    setFavoriteData(favorites);

    executeUpdateFavoriteMutation({
      hashID: props.favorite.HashID,
      removed: toggledRemoved,
    }).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else if (
          result.error.message.includes(
            `Favorite with HashID '${props.favorite.HashID}' not found`
          )
        ) {
          setMessage(MESSAGE_TYPES.INFO, t("error.item.already.deleted"));
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error remove favorite on FavoritesTableRow",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: JSON.stringify(result.error),
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.INFO, t("projects.favorites.updated"));
        for (let i = 0; i < favorites.length; i++) {
          if (favorites[i].HashID === props.favorite.HashID) {
            favorites[i].Removed = toggledRemoved;
            break;
          }
        }
        setRemoved(toggledRemoved);
      }
    });
  };

  /**
   * removedClass
   *
   * @returns {*}
   */
  const removedClass = () => {
    return props.favorite.Removed ? classes.removed : "";
  };

  /**
   * getDestinationCoordinates
   *
   * @returns {{lon: *, lat: *}}
   */
  const getDestinationCoordinates = () => {
    return {
      lat: GetSafe(
        () => parseFloat(props.favorite.Construction_site.Address.Geo.lat),
        0
      ),
      lon: GetSafe(
        () => parseFloat(props.favorite.Construction_site.Address.Geo.lon),
        0
      ),
    };
  };

  /**
   * isProcessable
   *
   * @param {*} value
   */
  const isProcessable = (value) => {
    return !(value === null || value === undefined);
  };

  /**
   * showApplyButton
   *
   * @returns {boolean|string|string}
   */
  const showApplyButton = () => {
    return true;
  };

  /**
   * handleApplyButton
   */
  const handleApplyButton = () => {
    if (user.getData().mode !== UserTypes.PRO) {
      setUpgradeToPremiumOpen(!upgradeToPremiumOpen);
    } else {
      if (
        user.getData().company.urlSegment === "" ||
        user.getData().company.urlSegment === null
      ) {
        props.setVerifyDialogOpen(false);
      } else {
        setEmailApplyDialog(true);
      }
    }
  };

  /**
   * handleApplicationPrintButton
   */
  const handleApplicationPrintButton = () => {
    if (user.getData().mode !== UserTypes.PRO) {
      setUpgradeToPremiumOpen(!upgradeToPremiumOpen);
    } else {
      if (
        user.getData().company.urlSegment === "" ||
        user.getData().company.urlSegment === null
      ) {
        props.setVerifyDialogOpen(false);
      } else {
        setApplicationPrintDialog(true);
      }
    }
  };

  const getApplyButtonText = () => {
    if (props.favorite.Applied) {
      return t("general.label.already.advertised");
    }

    if (user.getData().mode !== UserTypes.PRO) {
      return t("general.label.applyOnlyForPro");
    }

    return t("general.label.apply");
  };

  const getAppicationPrintButtonText = () => {
    if (user.getData().mode !== UserTypes.PRO) {
      return t("general.label.applyPrintOnlyForPro");
    }

    return t("company.label.print.application");
  };

  /**
   * renderApprovalDate
   *
   * @returns {false|JSX.Element}
   */
  const renderApprovalDate = () => {
    return (
      props.favorite.Approval_date !== undefined &&
      props.favorite.Approval_date !== "" &&
      props.favorite.Approval_date !== null && (
        <p>
          {user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.expected.date")}
              </span>
              <span className={classes.tooltipTitle}>
                {props.favorite.Approval_date}
              </span>
            </span>
          )}
          {!user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.expected.date")}
              </span>
              <span
                className={clsx(classes.tooltipTitle, classes.pointer)}
                onClick={handleApplicationPrintButton}
              >
                {t("projects.free.route.premium.pro")}
              </span>
            </span>
          )}
        </p>
      )
    );
  };

  /**
   * renderConstructionSum
   *
   * @returns {false|JSX.Element}
   */
  const renderConstructionSum = () => {
    return (
      props.favorite.Construction_sum !== undefined &&
      props.favorite.Construction_sum !== "" &&
      props.favorite.Construction_sum !== null &&
      props.favorite.Type !== "Submission" && (
        <p>
          {user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.construction.sum")}
              </span>
              <span className={classes.tooltipTitle}>
                {props.favorite.Construction_sum}
              </span>
            </span>
          )}
          {!user.isPro() && (
            <span>
              <span className={classes.toggleTitle}>
                {t("projects.label.construction.sum")}
              </span>
              <span
                className={clsx(classes.tooltipTitle, classes.pointer)}
                onClick={handleApplicationPrintButton}
              >
                {t("projects.free.route.premium.pro")}
              </span>
            </span>
          )}
        </p>
      )
    );
  };

  /**
   * render the detail view of a favorite
   */
  const favoriteDetails = () => {
    return (
      <TableRow
        className={classes.tableInnerRow}
        id={`favorite-details-${props.favorite.HashID}`}
      >
        <TableCell
          className={clsx(classes.toggleTableCell, classes.toggleTableCellMap)}
          colSpan={2}
        >
          <p className={classes.toggleTitle}>
            {t("favorites.label.fastest.route")}
          </p>
          <GoogleMapComponent
            origin={props.originCoordinates}
            destination={getDestinationCoordinates()}
            location={props.location}
          />
          <FavoriteDetailAddition
            favorite={props.favorite}
            filterFor={props.filterFor}
          />
        </TableCell>
        <TableCell className={classes.toggleTableCell}>
          <p className={classes.toggleTitle}>{t("projects.label.owner")}</p>
          <p>
            {GetSafe(() => props.favorite.Building_owner.Name, "")}
            <br />
            {GetSafe(
              () => props.favorite.Building_owner.Address.Street,
              ""
            )}{" "}
            {GetSafe(() => props.favorite.Building_owner.Address.Number, "")}
            <br />
            {GetSafe(() => props.favorite.Building_owner.Address.Zip, "")}{" "}
            {GetSafe(() => props.favorite.Building_owner.Address.City, "")}
          </p>
          {renderApprovalDate()}
          {renderConstructionSum()}
          {props.favorite.Type === BUILDING_APPLICATION_TYPE.SUBMISSION ? (
            <p>
              <span className={classes.toggleTitle}>
                {t("favorite.submission.id.label")}
              </span>
              <br />
              {props.favorite.Notice_number}
            </p>
          ) : null}
          {props.favorite.Type !== BUILDING_APPLICATION_TYPE.SUBMISSION ? (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={props.favorite.Content || ""}
              PopperProps={{
                className: clsx(classes.tooltipItem, classes.tooltipInfo),
              }}
            >
              <span className={classes.tooltipTitle}>
                <Info style={{ fontSize: 30 }} color="primary" />
                {t("projects.label.original.project")}
              </span>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell className={classes.toggleTableCell} colSpan={2}>
          <p className={classes.toggleTitle}>{t("projects.label.architect")}</p>
          {ProjectManagementAddressDataValidator.isValid(
            props.favorite.Project_management.Address
          ) && (
            <p>
              {GetSafe(() => props.favorite.Project_management.Name, "")}
              <br />
              {GetSafe(
                () => props.favorite.Project_management.Address.Street,
                ""
              )}{" "}
              {GetSafe(
                () => props.favorite.Project_management.Address.Number,
                ""
              )}
              <br />
              {GetSafe(
                () => props.favorite.Project_management.Address.Zip,
                ""
              )}{" "}
              {GetSafe(
                () => props.favorite.Project_management.Address.City,
                ""
              )}
              <br />
              {isProcessable(
                GetSafe(() => props.favorite.Project_management.Phone, "")
              ) && (
                <a
                  href={
                    "tel:" +
                    GetSafe(() => props.favorite.Project_management.Phone, "")
                  }
                >
                  {GetSafe(() => props.favorite.Project_management.Phone, "")}
                </a>
              )}
              <br />
              {isProcessable(
                GetSafe(() => props.favorite.Project_management.Email, "")
              ) && (
                <a
                  href={
                    "mailto:" +
                    GetSafe(() => props.favorite.Project_management.Email, "")
                  }
                >
                  {GetSafe(() => props.favorite.Project_management.Email, "")}
                </a>
              )}
            </p>
          )}
          {props.favorite.Type === BUILDING_APPLICATION_TYPE.SUBMISSION && props.favorite.URL !== "" ? (
            <a
              href={props.favorite.URL}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.externalLink}
            >
              <Button
                id="buttonSimapLink"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {t("submissions.label.simap.link")}
              </Button>
            </a>
          ) : (
            showApplyButton() && (
              <Button
                id="buttonApply"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleApplyButton}
                disabled={props.favorite.Applied}
              >
                {getApplyButtonText()}
              </Button>
            )
          )}
          {props.favorite.Type !== BUILDING_APPLICATION_TYPE.SUBMISSION ? (
            <Button
              id="buttonApplicationPrint"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleApplicationPrintButton}
            >
              {getAppicationPrintButtonText()}
            </Button>
          ) : null}
          <FavoriteEmailApplyDialog
            emailApplyDialog={emailApplyDialog}
            setEmailApplyDialog={setEmailApplyDialog}
            favoriteHashId={props.favorite.HashID}
            filterFor={props.filterFor}
            setFavoriteData={setFavoriteData}
            favorite={props.favorite}
          />
          <FavoriteApplicationPrintDialog
            applicationPrintDialog={applicationPrintDialog}
            setApplicationPrintDialog={setApplicationPrintDialog}
            favoriteHashId={props.favorite.HashID}
            filterFor={props.filterFor}
            projectManagementData={props.favorite.Project_management}
            buildingOwnerData={props.favorite.Building_owner}
            favorite={props.favorite}
          />
        </TableCell>
      </TableRow>
    );
  };

  /**
   * renderPrintAndShare
   **/
  const renderPrintAndShare = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerWrapper} colSpan={4}>
              <Grid container justify="space-between">
                <Grid item xs={12} sm="auto">
                  <A href="/" className={classes.logo}>
                    <img src={DEFAULT_LOGO_PATH} alt="logo" />
                  </A>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {GetSafe(() => props.favorite.Final_title, "")}
            </TableCell>
            <TableCell>
              {GetSafe(() => props.favorite.Construction_site.Address.City, "")}{" "}
              {GetSafe(
                () => props.favorite.Construction_site.Address.Street,
                ""
              )}{" "}
              {GetSafe(
                () => props.favorite.Construction_site.Address.Number,
                ""
              )}
            </TableCell>
            {!user.hasZipSearchAreas() && (
              <TableCell>
                {GetSafe(
                  () =>
                    FormatHelper.formatDistance(
                      props.favorite.Calculated_distance
                    ) + " km",
                  ""
                )}
              </TableCell>
            )}
            <TableCell>
              {GetSafe(() => FormatHelper.formatDate(props.favorite.Date), "")}
            </TableCell>
          </TableRow>
          <TableRow id={`project-details-${props.favorite.HashID}`}>
            <TableCell>
              <p className={classes.toggleTitle}>{t("projects.label.owner")}</p>
              <p>
                {GetSafe(() => props.favorite.Building_owner.Name, "")}
                <br />
                {GetSafe(
                  () => props.favorite.Building_owner.Address.Street,
                  ""
                )}{" "}
                {GetSafe(
                  () => props.favorite.Building_owner.Address.Number,
                  ""
                )}
                <br />
                {GetSafe(
                  () => props.favorite.Building_owner.Address.Zip,
                  ""
                )}{" "}
                {GetSafe(() => props.favorite.Building_owner.Address.City, "")}
              </p>
            </TableCell>
            <TableCell colSpan={3}>
              <p className={classes.toggleTitle}>
                {t("projects.label.architect")}
              </p>
              <p>
                {GetSafe(() => props.favorite.Project_management.Name, "")}
                <br />
                {GetSafe(
                  () => props.favorite.Project_management.Address.Street,
                  ""
                )}{" "}
                {GetSafe(
                  () => props.favorite.Project_management.Address.Number,
                  ""
                )}
                <br />
                {GetSafe(
                  () => props.favorite.Project_management.Address.Zip,
                  ""
                )}{" "}
                {GetSafe(
                  () => props.favorite.Project_management.Address.City,
                  ""
                )}
                <br />
                {GetSafe(() => props.favorite.Project_management.Phone, "")}
                <br />
                {GetSafe(() => props.favorite.Project_management.Email, "")}
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>{props.favorite.Content}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  /**
   * handlePrint
   */
  const handlePrint = (event) => {
    event.stopPropagation();
    PHE.printHtml(renderToString(renderPrintAndShare()));
  };

  /**
   * handleOpenDetailsClick
   */
  const handleOpenDetailsClick = () => {
    if (!removed) {
      setOpen(!open);
    }
  };

  /**
   * return
   */
  return (
    <>
      {fetchingUpdateFavorite && <LinearProgress />}
      <TableRow onClick={handleOpenDetailsClick} className={classes.pointer}>
        <TableCell className={classes.tableCellColumnsbody}>
          {removed ? (
            <IconButton
              id={`favorite-item-details-toggler-${props.favorite.HashID}`}
              className={`${classes.icon} ${removedClass()}`}
            >
              <ExpandMore style={{ fontSize: 30 }} color="disabled" />
            </IconButton>
          ) : (
            <IconButton
              id={`favorite-item-details-toggler-${props.favorite.HashID}`}
              onClick={() => setOpen(!open)}
              className={`${classes.icon} ${removedClass()}`}
            >
              {open ? (
                <ExpandLess style={{ fontSize: 30 }} color="primary" />
              ) : (
                <ExpandMore style={{ fontSize: 30 }} color="primary" />
              )}
            </IconButton>
          )}
          <IconButton
            aria-controls={`favorite-toggle-${props.favorite.HashID}`}
            onClick={handleFavoriteRemoveToggle}
            className={classes.icon}
          >
            {removed ? (
              <StarBorder
                className={classes.iconIgnoreRemoved}
                color="primary"
                style={{ fontSize: 30 }}
              />
            ) : (
              <Star style={{ fontSize: 30 }} color="primary" />
            )}
          </IconButton>
          <FavoriteStatus
            filterFor={props.filterFor}
            removed={removed}
            className={removedClass()}
            favorite={props.favorite}
            style={{ fontSize: 30 }}
          />
          {removed ? (
            <IconButton
              aria-controls={`favorite-menu-${props.favorite.HashID}`}
              className={`${classes.icon} ${removedClass()}`}
              aria-haspopup="true"
            >
              <MoreHoriz style={{ fontSize: 30 }} color="disabled" />
            </IconButton>
          ) : (
            <IconButton
              aria-controls={`favorite-menu-${props.favorite.HashID}`}
              className={`${classes.icon} ${removedClass()}`}
              aria-haspopup="true"
              onClick={handleContextMenuOpen}
            >
              <MoreHoriz style={{ fontSize: 30 }} color="primary" />
            </IconButton>
          )}
          <Menu
            id={`favorite-menu-${props.favorite.HashID}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleContextMenuClose}
            PaperProps={{
              style: {
                marginTop: "42px",
              },
            }}
          >
            <MenuItem
              id={`favorite-menu-item-${props.favorite.HashID}--print`}
              onClick={handlePrint}
            >
              {t("general.label.print")}
            </MenuItem>
          </Menu>
        </TableCell>
        {removed ? (
          <TableCell
            className={classes.tableCellColumnsbody}
            style={{
              fontSize: 16,
            }}
            colSpan={4}
          >
            <span className={removedClass()}>
              {t("favorites.label.removed")}
            </span>
            <span>&nbsp;</span>
            <span
              className={`${classes.undo} ${classes.link}`}
              onClick={handleFavoriteRemoveToggle}
            >
              {t("general.label.undo")}
            </span>
          </TableCell>
        ) : (
          <>
            <TableCell
              className={clsx(classes.tableCellColumnsbody, removedClass())}
              style={{
                fontSize: 16,
              }}
            >
              {props.favorite.Type === BUILDING_APPLICATION_TYPE.SUBMISSION ? (
                <Tooltip
                  title={t("submissions.label.singular")}
                  aria-label={t("submissions.label.singular")}
                  arrow
                >
                  <CardMembershipIcon
                    className={classes.submissionIcon}
                    color="secondary"
                  />
                </Tooltip>
              ) : null}
              {props.favorite.Final_title}
            </TableCell>
            <TableCell
              className={clsx(classes.tableCellColumnsbody, removedClass())}
            >
              {GetSafe(() => props.favorite.Construction_site.Address.City, "")}{" "}
              {GetSafe(
                () => props.favorite.Construction_site.Address.Street,
                ""
              )}{" "}
              {GetSafe(
                () => props.favorite.Construction_site.Address.Number,
                ""
              )}
            </TableCell>
            {!user.hasZipSearchAreas() && (
              <TableCell
                className={clsx(classes.tableCellColumnsbody, removedClass())}
              >
                {GetSafe(
                  () =>
                    FormatHelper.formatDistance(
                      props.favorite.Calculated_distance
                    ) + " km",
                  ""
                )}
              </TableCell>
            )}
            <TableCell
              className={clsx(classes.tableCellColumnsbody, removedClass())}
            >
              {GetSafe(() => FormatHelper.formatDate(props.favorite.Date), "")}
            </TableCell>
          </>
        )}
      </TableRow>
      {open ? favoriteDetails() : null}
    </>
  );
};

export default withAccessControl(
  FavoritesTableRow,
  ACL_COMPONENTS.FAVORITES_TABLE_ROW
);
