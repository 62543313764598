import { apiTld, companyDirectoryPath } from '../config'

/**
 * Parses application text
 *
 * @param user
 * @param applicationTextTemplate
 * @param businessActivities
 * @param favorite
 * @param conjunctionWord
 * @returns string
 */
const parseApplicationText = (
  user,
  applicationTextTemplate,
  businessActivities,
  favorite,
  conjunctionWord
) => {
  const userData = user.getData()
  const companyData = userData.company

  let competencesString = ''
  let applicationTextParsed = applicationTextTemplate

  // Build the competence string (the most complex substitution)
  if (applicationTextTemplate !== null) {
    if (applicationTextTemplate.search('{{FIRMEN-KOMPETENZEN}}') !== -1) {
      if (companyData.branchCategory > 0) {
        if (companyData.branchSubs.length > 0) {
          // const conjunctionWord = t('general.label.and')
          const businessSpecialties = {}

          // Fetch the businesses titles (we dont wanna print ids)
          if (
            businessActivities &&
            businessActivities.BusinessSpecialities.edges.length > 0
          ) {
            for (
              let i = 0;
              i < businessActivities.BusinessSpecialities.edges.length;
              i++
            ) {
              const specialty = businessActivities.BusinessSpecialities.edges[i]
              businessSpecialties[specialty.node.ID] = specialty.node.Title
            }
          }

          // Multiple specialties or not, thats the question...
          if (companyData.branchSubs.length > 1) {
            // Multiple spcialties
            const lastElementIndex = companyData.branchSubs.length - 1
            const commaElements = companyData.branchSubs.slice(
              0,
              lastElementIndex
            )
            let lastElement = companyData.branchSubs.slice(lastElementIndex)

            // Replcace ids with titles
            for (let i = 0; i < commaElements.length; i++) {
              if (
                typeof businessSpecialties[commaElements[i]] !== 'undefined'
              ) {
                commaElements[i] = businessSpecialties[commaElements[i]]
              }
            }

            // Replcace id with title
            if (typeof businessSpecialties[lastElement] !== 'undefined') {
              lastElement = businessSpecialties[lastElement]
            }

            // Concat the string
            competencesString = `${commaElements.join(
              ', '
            )} ${conjunctionWord} ${lastElement}`
          } else {
            // Replcace id with title
            if (
              typeof businessSpecialties[companyData.branchSubs[0]] !==
              'undefined'
            ) {
              competencesString = businessSpecialties[companyData.branchSubs[0]]
            }
          }
        } else {
          // No specialties, just the branch
          competencesString = businessActivities.Title
        }
      }
    }

    // Parse {{FIRMEN-KOMPETENZEN}}
    if (competencesString !== '') {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{FIRMEN-KOMPETENZEN}}',
        competencesString
      )
    }

    // Parse {{PROJEKT-NAME}}
    if (
      typeof favorite.Final_title !== 'undefined' &&
      favorite.Final_title !== ''
    ) {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{PROJEKT-NAME}}',
        favorite.Final_title
      )
    }

    // Parse {{PROJEKT-ORT}}
    if (
      typeof favorite.Construction_site !== 'undefined' &&
      favorite.Construction_site.Address.City !== ''
    ) {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{PROJEKT-ORT}}',
        favorite.Construction_site.Address.City
      )
    }

    // Parse {{FIRMEN-GRUENDUNGSJAHR}}
    if (
      typeof companyData.foundingYear !== 'undefined' &&
      companyData.foundingYear > 0
    ) {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{FIRMEN-GRUENDUNGSJAHR}}',
        companyData.foundingYear
      )
    }

    // Parse {{FIRMEN-NAME}}
    if (typeof companyData.name !== 'undefined' && companyData.name !== '') {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{FIRMEN-NAME}}',
        companyData.name
      )
    }

    // Parse {{FIRMEN-MICROSITE}}
    if (
      typeof companyData.urlSegment !== 'undefined' &&
      companyData.urlSegment !== ''
    ) {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{FIRMEN-MICROSITE}}',
        apiTld + companyDirectoryPath + companyData.urlSegment
      )
    }

    // Parse {{FIRMEN-TELEFON}}
    if (typeof companyData.phone !== 'undefined' && companyData.phone !== '') {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{FIRMEN-TELEFON}}',
        companyData.phone
      )
    }

    // Parse {{USER-VORNAME}}
    if (
      typeof userData.firstName !== 'undefined' &&
      userData.firstName !== ''
    ) {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{USER-VORNAME}}',
        userData.firstName
      )
    }

    // Parse {{USER-NACHNAME}}
    if (typeof userData.lastName !== 'undefined' && userData.lastName !== '') {
      applicationTextParsed = applicationTextParsed.replaceAll(
        '{{USER-NACHNAME}}',
        userData.lastName
      )
    }
  }

  return applicationTextParsed
}

export default parseApplicationText
