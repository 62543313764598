import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isNotLive } from "../../config";
import { Typography } from "@material-ui/core";
import GlobalContext from "../../lib/GlobalContext";
import User from "../../lib/User";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  debugHolder: {
    marginTop: 7,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: 3,
    border: "1px solid #edcc4a",
  },
  debugListWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: "#fff4db",
  },
  debugListTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5,
  },
  debugDataList: {
    display: "block",
    alignItems: "center",
  },
  debugTitle: {
    marginRight: 10,
  },
}));

const DebugImportantDataFragment = () => {
  const classes = useStyles();

  const { user, selectedLocationTabValue } = useContext(GlobalContext);

  /**
   * return
   */
  return (!User.isMasquerade() && (isNotLive || User.isAppAdmin())) ? (
    <div className={classes.debugHolder}>
      <Typography className={classes.debugTitle}>Important data: </Typography>
      <div className={classes.debugDataList}>
        <div>
          Current User IDs: <strong>{user.getData().id} / {user.getData().hashID}</strong>
        </div>
        <div>
          Current Company IDs:{" "}
          <strong>{user.getData().company.id} / {user.getData().company.hashID}</strong>
        </div>
        <div>
          Current Tab Location-HashID:{" "}
          <strong>{selectedLocationTabValue || 'No Tab'}</strong>
        </div>
      </div>
    </div>
  ) : null;
};

export default DebugImportantDataFragment;
