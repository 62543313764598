import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { FallBackReferralProgramLink, VERSION, isNotLive } from "../../config";
import GlobalContext from "../../lib/GlobalContext";
import { getAbsoluteLink, externalLinkTitles } from "../../lib/CmsLinkHandler";
import User from "../../lib/User";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "relative",
    width: "100%",
    backgroundColor: theme.palette.background.footer,
    zIndex: theme.zIndex.drawer + 1,
  },
  footerContainer: {
    position: "relative",
    width: "100%",
    maxWidth: 1320,
    margin: "auto",
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 80,
    paddingLeft: 20,
  },

  link: {
    display: "inline-block",
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "24px",
    textDecoration: "none",
    transition: "all ease 0.5s",
    "&:hover": {
      color: theme.palette.text.secondary,
      textDecoration: "none",
    },
  },
  version: {
    display: "inline-block",
    color: "#525252",
    fontSize: 12,
  },
}));

/**
 * LayoutFooter
 *
 * @returns {*}
 * @constructor
 */
const LayoutFooter = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cmsLinks } = useContext(GlobalContext);

  /**
   * getReferralProgramLink
   *
   * @returns {string|string}
   */
  const getReferralProgramLink = () => {
    const link = getAbsoluteLink(
      cmsLinks,
      "RecommendationProgram",
      externalLinkTitles.REFERRALPROGRAM
    );
    return link !== "" ? link : FallBackReferralProgramLink;
  };

  const getVersionPointerStyle = () => {
    return (!User.isMasquerade() && (isNotLive || User.isAppAdmin())) ? { cursor: "pointer" } : {};
  };

  /**
   * return
   */
  return (
    <footer className={classes.footer}>
      <Grid
        container
        className={classes.footerContainer}
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item>
          <Link
            target="_blank"
            href={getAbsoluteLink(
              cmsLinks,
              "CmsMenu",
              externalLinkTitles.SUPPORT
            )}
            className={classes.link}
          >
            {t("footer.label.faq")}
          </Link>{" "}
          |{" "}
          <Link
            target="_blank"
            href={getReferralProgramLink()}
            className={classes.link}
          >
            {t("footer.label.referral")}
          </Link>{" "}
          |{" "}
          <Link
            target="_blank"
            href={getAbsoluteLink(
              cmsLinks,
              "CmsMenu",
              externalLinkTitles.CONDITIONS
            )}
            className={classes.link}
          >
            {t("footer.label.conditions")}
          </Link>{" "}
          |{" "}
          <Link
            target="_blank"
            href={getAbsoluteLink(
              cmsLinks,
              "CmsMenu",
              externalLinkTitles.TERMS
            )}
            className={classes.link}
          >
            {t("footer.label.terms.of.use")}
          </Link>{" "}
          |{" "}
          <Link
            target="_blank"
            href={getAbsoluteLink(
              cmsLinks,
              "CmsMenu",
              externalLinkTitles.DATAPROTECTION
            )}
            className={classes.link}
          >
            {t("footer.label.dataprotection")}
          </Link>{" "}
          |{" "}
          <Link
            target="_blank"
            href={getAbsoluteLink(
              cmsLinks,
              "CmsMenu",
              externalLinkTitles.IMPRINT
            )}
            className={classes.link}
          >
            {t("footer.label.imprint")}
          </Link>
          <Box
            className={classes.actions}
            display="flex"
            flexDirection="column"
          >
            {t("footer.label.brand")}
          </Box>
        </Grid>
        <Grid item>
          <span
            onClick={() =>
              (!User.isMasquerade() && (isNotLive || User.isAppAdmin())) && props.setDebugToolsEnabled((prev) => !prev)
            }
            className={classes.version}
            style={getVersionPointerStyle()}
          >
            v{VERSION}
          </span>
        </Grid>
      </Grid>
    </footer>
  );
};

export default LayoutFooter;
