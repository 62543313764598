import React, { useContext } from 'react'
import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core'
import CompanyProfileStep from '../components/MicrositeDetailsWizard/CompanyProfileStep'
import CompanyInfoStep from '../components/MicrositeDetailsWizard/CompanyInfoStep'
import CompanyBusinessActivitiesStep from '../components/MicrositeDetailsWizard/CompanyBusinessActivitiesStep'
import CompanyReferencesStep from '../components/MicrositeDetailsWizard/CompanyReferencesStep'
import CompleteWizardStep from '../components/MicrositeDetailsWizard/CompleteWizardStep'
import GlobalContext from '../lib/GlobalContext'

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  dialog: {
    width: '100%',
    paddingBottom: 56,
    '&::before': theme.watermarkLogo,
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  }
}))

const MicrositeDetailsWizard = () => {
  const classes = useStyles()
  const { wizardStep, setWizardStep } = useContext(GlobalContext)

  const getDialogByStep = () => {
    switch (wizardStep) {
      case 4:
        return <CompleteWizardStep step={wizardStep} setStep={setWizardStep} />
      case 3:
        return (
          <CompanyReferencesStep step={wizardStep} setStep={setWizardStep} />
        )
      case 2:
        return (
          <CompanyBusinessActivitiesStep
            step={wizardStep}
            setStep={setWizardStep}
          />
        )
      case 1:
        return <CompanyInfoStep step={wizardStep} setStep={setWizardStep} />
      case 0:
      default:
        return <CompanyProfileStep step={wizardStep} setStep={setWizardStep} />
    }
  }

  return (
    <Dialog fullWidth maxWidth='md' open className={classes.root}>
      <DialogContent className={classes.dialog}>
        <Grid container direction='column' justify='center' alignItems='center'>
          {getDialogByStep()}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default MicrositeDetailsWizard
