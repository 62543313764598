import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "urql";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TablePaginationActions from "../components/Table/TablePaginationActions";
import ProjectsFreeTableRow from "../components/ProjectsFree/ProjectsFreeTableRow";
import GlobalContext from "../lib/GlobalContext";
import ProjectsFreeLocationsHandler from "../components/ProjectsFree/ProjectsFreeLocationsHandler";
import { useTranslation } from "react-i18next";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import withAccessControl from "../components/HOC/AccessControl";
import getCantonShort from "../lib/CantonShortener";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    [theme.breakpoints.up("lg")]: {
      overflowX: "hidden",
    },
  },
  tableHead: {
    width: "100%",
    height: 64,
    background: "#F7F9FA",
  },
  tableColumnsHead: {
    height: 96,
  },
  tableCellHeader: {
    height: 64,
    fontWeight: "bold",
  },
  tableCellColumnsHeader: {
    padding: "40px 14px 16px 14px",
    fontSize: theme.typography.fontSize,
    color: "#999999",
    fontWeight: "bold",
    "&:first-of-type": {
      paddingLeft: 24,
    },
    "@media (max-width:640px)": {
      "&:first-of-type": {
        paddingLeft: 14,
      },
    },
  },
  tableCellColumnsBody: {
    padding: "20px 14px",
  },
  tableCell: {
    height: 64,
  },
  tableRows: {
    paddingLeft: 36,
  },
  tabs: {
    color: "#525252",
    fontSize: 14,
    height: "100%",
    width: "100%",
  },
  tab: {
    height: 64,
    fontWeight: "bold",
    textAlign: "center",
  },
  icon: {
    padding: 8,
  },
  listContent: {
    paddingLeft: 36,
  },
  paginationInput: {
    height: 28,
    width: 25,
  },
  paginationSelectIcon: {
    color: theme.palette.primary.main,
  },
}));

const queryProjects = `
  query ReadElasticProjects (
    $canton: String,
    $limit: Int,
    $offset: Int
  ) {
    readElasticProjects(
      sortBy: [{field: Date, direction: DESC}],
      Filter: {Canton: $canton},
      limit: $limit,
      offset: $offset
    ){
      HashID
      Canton
      Ip
      Final_title
      Date
      Calculated_distance
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
    }
  }
`;

const stripeProductsQuery = `
  {
    readProduct {
      name
      plans {
        trial_period_days
        trial_possible
      }
    }
  }
`;

/**
 * ProjectsFree
 *
 * @returns {*}
 * @constructor
 */
const ProjectsFree = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    projectData,
    setProjectData,
    token,
    user,
    canton,
    setCanton,
    setMessage,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formerResult, setFormerResult] = useState([]);
  const [localCanton, setLocalCanton] = useState(
    getCantonShort(user.getData().company.canton)
  );
  const [planTrialData, setPlanTrialData] = useState({
    isPossible: null,
    trialDays: 0,
  });

  const columns = [
    { id: "actions", label: t("general.label.actions"), minWidth: 200 },
    {
      id: "projects",
      label: t("general.label.projects"),
      minWidth: 100,
      fontSize: 16,
    },
    {
      id: "city",
      label: t("general.label.address"),
      minWidth: 120,
      align: "left",
    },
    {
      id: "route",
      label: t("general.label.distance"),
      minWidth: 120,
      align: "left",
    },
    {
      id: "date",
      label: t("general.label.date"),
      minWidth: 120,
      align: "left",
    },
  ];

  const [result, executeProjectsQuery] = useQuery({
    query: queryProjects,
    variables: {
      canton: localCanton,
      limit: rowsPerPage * 4,
      offset: rowsPerPage * page,
    },
    requestPolicy: "cache-and-network",
    pause: true,
  });

  const [productsQueryResult, executeProductsQuery] = useQuery({
    query: stripeProductsQuery,
    requestPolicy: "cache-and-network",
    pause: true,
  });

  useEffect(() => {
    setCanton(getCantonShort(user.getData().company.canton));
    setLocalCanton(getCantonShort(user.getData().company.canton));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (canton !== localCanton) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canton, localCanton]);

  useEffect(() => {
    if (page % 3 !== 1 && page % 3 !== 2) {
      executeProjectsQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage])

  useEffect(() => {
    executeProductsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token]);

  useEffect(() => {
    executeProjectsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canton, localCanton])

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          setProjectData([]);
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readElasticProjects !== "undefined"
        ) {
          // Add the projects to the global context
          const actualResult = result.data.readElasticProjects;

          if (JSON.stringify(formerResult) !== JSON.stringify(actualResult)) {
            setFormerResult(actualResult);
            const mergedArray = [...projectData, ...actualResult];
            // mergedArray have duplicates, lets remove the duplicates using Set
            const set = new Set();
            const unionArray = mergedArray.filter((item) => {
              if (!set.has(item.HashID)) {
                set.add(item.HashID);
                return true;
              }
              return false;
            }, set);
            setProjectData(unionArray);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  // GraphQL trigger and productsQueryResult handling
  useEffect(() => {
    if (!productsQueryResult.fetching) {
      if (productsQueryResult.error) {
        // Check if the user need to be logged out
        if (
          productsQueryResult.error.message.indexOf("User forced logout") !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          setProjectData([]);
        }
      } else {
        // Query not fetching right now
        if (
          typeof productsQueryResult.data !== "undefined" &&
          typeof productsQueryResult.data.readProduct !== "undefined" &&
          planTrialData.isPossible === null
        ) {
          const productData = productsQueryResult.data.readProduct;

          productData.map((product) => {
            if (product.name === "Smart") {
              setPlanTrialData({
                trialDays: product.plans[0].trial_period_days,
                isPossible:
                  product.plans[0].trial_possible === null
                    ? false
                    : product.plans[0].trial_possible,
              });
            }
            return product;
          });
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsQueryResult])

  /**
   * handleChangePage
   *
   * @param event
   * @param newPage
   */
  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  /**
   * handleChangeRowsPerPage
   *
   * @param event
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * return
   */
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell
                align="left"
                padding="none"
                className={classes.tableCellHeader}
                colSpan={3}
              >
                <Tabs
                  value={user.getData().company.canton}
                  className={classes.tabs}
                  indicatorColor="primary"
                >
                  <Tab
                    label={user.getData().company.canton}
                    value={user.getData().company.canton}
                    className={classes.tab}
                  />
                </Tabs>
              </TableCell>
              <TableCell
                className={classes.tableCellHeader}
                align="right"
                colSpan={2}
              >
                <ProjectsFreeLocationsHandler />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableColumnsHead}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCellColumnsHeader}
                  style={{
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {result.fetching && (
              <React.Fragment key={0}>
                <TableRow>
                  <TableCell
                    colSpan="5"
                    component="th"
                    scope="row"
                    className={classes.tableRows}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
            {!result.fetching &&
              projectData.length !== 0 &&
              projectData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <ProjectsFreeTableRow
                    key={row.HashID}
                    row={row}
                    classes={classes}
                    originCoordinates={{
                      lat: user.getData().company.latitude,
                      lon: user.getData().company.longitude,
                    }}
                    location={user.getData().company.canton}
                    planTrialData={planTrialData}
                  />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={5}
                count={projectData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false,
                  renderValue: () => <></>,
                }}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from} - ${to === -1 ? count : to}`;
                }}
                labelRowsPerPage={
                  <>
                    {rowsPerPage} {t("general.label.rows")}
                  </>
                }
                classes={{
                  input: classes.paginationInput,
                  selectIcon: classes.paginationSelectIcon,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
};

export default withAccessControl(
  ProjectsFree,
  ACL_COMPONENTS.PROJECTS_FREE,
  "projects"
);
