import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { LinearProgress, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { useMutation, useQuery } from "urql";
import GlobalContext from "../../lib/GlobalContext";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import GetSafe from "../../lib/GetSafe";
import Joi from "@hapi/joi";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import { MESSAGE_TYPES } from "../PopupMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import withAccessControl from "../HOC/AccessControl";
import { navigate } from "hookrouter";
import {
  ACL_COMPONENTS,
  DEFAULT_LANGUAGE,
  DOSSIER_AVAILABLE_LOCALES,
} from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import MenuItem from "@material-ui/core/MenuItem";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  gridContainerWrapper: {
    width: "100%",
    maxWidth: 1280,
    margin: 0,
    padding: "60px 24px",
  },
  gridContainer: {
    width: "100%",
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  gridInnerContainer: {
    display: "flex",
  },
  item: {
    display: "block",
    "@media (max-width:600px)": {
      // order: 2,
      width: "100%",
    },
  },
  itemAvatar: {
    width: 42,
    paddingTop: 12,
    paddingBottom: 12,
    "@media (max-width:600px)": {
      display: "none",
    },
  },
  itemName: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 12,
  },
  itemButtons: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:600px)": {
      flexBasis: "25%",
      width: "auto",
      maxWidth: "25%",
      "& svg": {
        padding: 0,
        fontSize: 25,
      },
    },
  },
  itemDivider: {
    width: "100%",
    paddingBottom: 24,
    "@media (max-width:600px)": {
      paddingBottom: 24,
    },
  },
  itemDelete: {
    width: "100%",
    paddingBottom: 16,
    "@media (max-width:600px)": {
      paddingBottom: 24,
      paddingLeft: 12,
    },
  },
  button: {
    marginTop: 16,
    marginBottom: 40,
    width: 189,
  },
  addButtonText: {
    fontWeight: "bold",
    paddingLeft: 15,
    paddingRight: 15,
  },
  buttonWhite: {
    color: theme.palette.primary.main,
    marginBottom: "40px",
  },
  h5: {
    fontWeight: "bold",
  },
  avatar: {
    width: 42,
    height: 42,
    backgroundColor: "#bdbdbd",
    color: "#525252",
    fontSize: 16,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  email: {
    fontSize: 14,
    color: "#999999",
  },
  textButton: {
    fontWeight: "bold",
    paddingLeft: 15,
    paddingRight: 15,
  },
  itemDialog: {
    width: "100%",
    textAlign: "center",
  },
  itemButtonContainer: {
    textAlign: "right",
  },
  textFieldSmall: {
    maxWidth: 160,
    "&:first-of-type": {
      marginRight: 5,
    },
    "&:last-of-type": {
      marginLeft: 5,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        marginRight: 0,
      },
      "&:last-of-type": {
        marginLeft: 0,
      },
    },
  },
  textField: {
    width: "100%",
    maxWidth: 330,
    marginTop: 24,
    marginBottom: 24,
    "&:first-of-type": {
      marginRight: "0",
    },
    "&:last-of-type": {
      marginLeft: "0",
    },
  },
  messageHint: {
    width: "100%",
    maxWidth: 330,
    margin: theme.spacing(2),
    color: "#525252",
    fontSize: 14,
    textAlign: "left",
  },
  dialogContent: {
    padding: 30,
    margin: "auto",
    "&:first-of-type": {
      paddingTop: 30,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16 !important",
    },
  },
  undo: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontSize: 14,
  },
  link: {
    cursor: "pointer",
  },
  removed: {
    opacity: 0.5,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const createUserMutation = `
  mutation CreateUser(
    $email: String!,
    $firstName: String,
    $lastName: String,
    $companyID: Int,
    $searchAreaIds: [String],
    $dossierTemplate: String
  ) {
    createUser(
      Email: $email, 
      Firstname: $firstName,
      Surname: $lastName,
      CompanyID: $companyID,
      SearchAreaIds: $searchAreaIds,
      DossierTemplate: $dossierTemplate
    ) {
      HashID
      Email
      Firstname
      Surname
      SearchAreas {
        HashID
        Title
        ZipList
      },
      DossierTemplates {
        edges {
          node {
            HashID,
            TemplateName
          }
        }
      }
    }
  }
`;

const updateUserMutation = `
  mutation UpdateUser(
    $hashID: String!,
    $email: String!,
    $firstName: String,
    $lastName: String,
    $removed: Boolean,
    $searchAreaIds: [String],
    $dossierTemplate: String,
    $language: String!
  ) {
    updateUser(
      HashID: $hashID,
      Email: $email,
      Firstname: $firstName,
      Surname: $lastName,
      Removed: $removed,
      SearchAreaIds: $searchAreaIds,
      DossierTemplate: $dossierTemplate,
      Language: $language
    ) {
      HashID
      Email
      Firstname
      Surname
      SearchAreas {
        HashID
        Title
        ZipList
      },
      DossierTemplates {
        edges {
          node {
            HashID,
            TemplateName
          }
        }
      }
    }
  }
`;

const queryDossierTemplates = `
  query ReadDossierTemplates (
    $language: String!
  ) {
    readDossierTemplates(Language: $language) {
      HashID
      Email
      Locale
      TemplateName
      BusinessActivityID
      BusinessSpecialities {
        edges {
          node {
            ID
            Title
          }
        }
      }
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeReferences {
        edges {
          node {
            Title
            Content
          }
        }
      }
    }
  }
`;

const DIALOGTYPE = {
  EDIT: "edit",
  ADD: "add",
};

const AccountUsers = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { token, user, setUser, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);

  const [dossierTemplatesSelect, setDossierTemplatesSelect] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState(DIALOGTYPE.EDIT);
  const [formValueErrors, setFormValueErrors] = useState({
    firstName: { hasError: false, message: "" },
    lastName: { hasError: false, message: "" },
    email: { hasError: false, message: "" },
    zipSearchArea: { hasError: false, message: "" },
    dossierTemplate: { hasError: false, message: "" },
  });
  const [companyUsers, setCompanyUsers] = useState(
    user.getData().company.users || []
  );

  const [{ fetching }, executeCreateUserMutation] =
    useMutation(createUserMutation);

  const [{ fetchingUpdateUser }, executeUpdateUserMutation] =
    useMutation(updateUserMutation);

  const [queryDossierTemplatesResult] = useQuery({
    query: queryDossierTemplates,
    variables: {
      language: DEFAULT_LANGUAGE
    },
    pause: !user.hasZipSearchAreas() && !user.isPro()
  });

  const initialZipSearchAreaValue = { zipSearchArea: "0" };
  const initialDossierTemplateValue = { dossierTemplate: "0" };
  const initialDefaultFormValues = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
  };
  const initialFormValues = user.hasZipSearchAreas() || user.isPro()
    ? {
        ...initialDefaultFormValues,
        ...initialZipSearchAreaValue,
        ...initialDossierTemplateValue,
      }
    : initialDefaultFormValues;
  const [formValues, setFormValues] = useState(initialFormValues);

  useEffect(() => {
    setCompanyUsers(
      companyUsers.sort((a, b) => {
        if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
          return -1;
        }
        if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
          return 1;
        }
        return 0;
      })
    );
  }, [user, companyUsers, setCompanyUsers]);

  // Parse the dossier-templates result to make them fit a dropdown
  useEffect(() => {
    if (
      !queryDossierTemplatesResult.fetching &&
      GetSafe(
        () => queryDossierTemplatesResult.data.readDossierTemplates,
        false
      ) &&
      dossierTemplatesSelect.length === 0
    ) {
      const dossierTemplatesParsed = [];
      const dossierTemplatesGrouped = {};

      // Group the dossier-templates by dossier-template id
      queryDossierTemplatesResult.data.readDossierTemplates.forEach(
        (dossierTemplate) => {
          if (
            !GetSafe(() => dossierTemplatesGrouped[dossierTemplate.HashID], false)
          ) {
            dossierTemplatesGrouped[dossierTemplate.HashID] = [];
          }

          dossierTemplatesGrouped[dossierTemplate.HashID].push(dossierTemplate);
        }
      );

      Object.keys(dossierTemplatesGrouped).forEach((dossierHashId) => {
        const languages = [];

        dossierTemplatesGrouped[dossierHashId].forEach((dossier) => {
          // Filter down to supported language variants
          if (GetSafe(() => DOSSIER_AVAILABLE_LOCALES[dossier.Locale], false)) {
            languages.push(dossier.Locale.slice(0, 2));
          }
        });

        // TODO: After the app is multi-language, replace dossierTemplatesGrouped[dossierHashId][0]
        // with dossierTemplatesGrouped[dossierHashId][[LANGUAGE]]
        dossierTemplatesParsed.push({
          value: dossierHashId,
          title:
            dossierTemplatesGrouped[dossierHashId][0].TemplateName
        });
      });

      setDossierTemplatesSelect(dossierTemplatesParsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDossierTemplatesResult])

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    id: Joi.string().allow(""),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string().email({ tlds: false }).required(),
    zipSearchArea: Joi.string().allow("0"),
    dossierTemplate: Joi.string().allow("0"),
  });

  /**
   * handleAddUser
   */
  const handleAddUser = () => {
    setOpenDialog(true);
    setDialogType(DIALOGTYPE.ADD);
    setFormValues(initialFormValues);
  };

  /**
   * handleEditUser
   *
   * @param elementId
   */
  const handleEditUser = (elementId) => {
    setOpenDialog(true);
    setDialogType(DIALOGTYPE.EDIT);

    companyUsers.map((element) => {
      if (element.HashID === elementId) {
        setFormValues({
          id: element.HashID,
          firstName: element.Firstname,
          lastName: element.Surname,
          email: element.Email,
          zipSearchArea:
            element.SearchAreas && element.SearchAreas[0]
              ? element.SearchAreas[0].HashID
              : "0",
          dossierTemplate: GetSafe(
            () => element.DossierTemplates.edges[0].node,
            false
          )
            ? element.DossierTemplates.edges[0].node.HashID
            : "0",
        });
      }
      return element;
    });
  };

  /**
   * handleDeleteUser
   *
   * @param elementId
   */
  const handleDeleteUser = (elementId) => {
    let modifiedUserData = {
      language: DEFAULT_LANGUAGE
    };
    
    companyUsers.map((element) => {
      if (element.HashID === elementId) {
        modifiedUserData = {
          hashID: elementId,
          email: element.Email,
          removed: true,
          language: DEFAULT_LANGUAGE
        };
      }
      
      return element;
    });

    executeUpdateUserMutation(modifiedUserData).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.delete"));
          ExternalErrorLogger.log({
            text: "Error deleting user on CompanyUsers",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.delete.success"));
      }
    });

    const data = companyUsers.map((item) => {
      if (item.HashID === elementId) {
        item.Removed = true;
      }
      return item;
    });

    user.setData({
      company: {
        users: data,
      },
    });
    
    setUser(user);
    setCompanyUsers(data);
  };

  /**
   * handleUndoDeleteUser
   *
   * @param elementId
   */
  const handleUndoDeleteUser = (elementId) => {
    let modifiedUserData = {
      language: DEFAULT_LANGUAGE
    };
    
    companyUsers.map((element) => {
      if (element.HashID === elementId) {
        modifiedUserData = {
          hashID: elementId,
          email: element.Email,
          removed: false,
          language: DEFAULT_LANGUAGE
        };
      }

      return element;
    });

    executeUpdateUserMutation(modifiedUserData).then((result) => {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else if (
          result.error.message.includes(
            `User with HashID '${elementId}' not found`
          )
        ) {
          setMessage(MESSAGE_TYPES.INFO, t("error.item.already.deleted"));
        } else {
          setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
          ExternalErrorLogger.log({
            text: "Error undo deleting user on CompanyUsers",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));

        const data = companyUsers.map((item) => {
          if (item.HashID === elementId) {
            item.Removed = false;
          }
          return item;
        });

        user.setData({
          company: {
            users: data,
          },
        });

        setUser(user);
        setCompanyUsers(data);
      }
    });
  };

  /**
   * handleMutationResult
   *
   * @param result
   */
  const handleMutationResult = (result) => {
    setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));

    const newUsersList = [];
    if (companyUsers.length !== 0) {
      companyUsers.map((item) => {
        if (item.HashID !== formValues.id) {
          newUsersList.push(item);
        }
        return item;
      });
    }

    newUsersList.push({
      HashID: result.HashID,
      Email: result.Email,
      Firstname: result.Firstname,
      Surname: result.Surname,
      SearchAreas: result.SearchAreas ? result.SearchAreas : null,
      DossierTemplates: result.DossierTemplates
        ? result.DossierTemplates
        : null,
    });

    user.setData({
      company: {
        users: newUsersList,
      },
    });
    setUser(user);
  };

  const buildUserMutationData = (formValues) => {
    const selectedZipSearchAreaExtension = user.hasZipSearchAreas() || user.isPro()
      ? {
          searchAreaIds: formValues.zipSearchArea,
          dossierTemplate: formValues.dossierTemplate,
        }
      : null;
    const defaultCreateUserData = {
      language: DEFAULT_LANGUAGE,
      hashID: formValues.id ? formValues.id : null,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      companyID: user.getData().company.id,
    };
    return {
      ...defaultCreateUserData,
      ...selectedZipSearchAreaExtension,
    };
  };

  const handleFormSubmit = () => {
    const { error } = schema.validate(formValues, { abortEarly: false });

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
    } else {
      // Is it a new user?
      if (formValues.id === "") {
        const createUserMutationData = buildUserMutationData(formValues);
        executeCreateUserMutation(createUserMutationData).then((result) => {
          if (
            result.error ||
            typeof result.data === "undefined" ||
            typeof result.data.createUser === "undefined"
          ) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyUsers",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          } else {
            handleMutationResult(result.data.createUser[0]);
          }
        });
        // It is an existing user
      } else {
        const createUserMutationData = buildUserMutationData(formValues);
        
        executeUpdateUserMutation(createUserMutationData).then((result) => {
          if (
            result.error ||
            typeof result.data === "undefined" ||
            typeof result.data.updateUser === "undefined"
          ) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyUsers",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          } else {
            handleMutationResult(result.data.updateUser[0]);
          }
        });
      }
    }
  };

  /**
   * handleFirstNameChange
   *
   * @param event
   */
  const handleFirstNameChange = (event) => {
    setFormValues({ ...formValues, firstName: event.target.value });
  };

  /**
   * handleLastNameChange
   *
   * @param event
   */
  const handleLastNameChange = (event) => {
    setFormValues({ ...formValues, lastName: event.target.value });
  };

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleEmailChange = (event) => {
    setFormValues({ ...formValues, email: event.target.value });
  };

  /**
   * handleZipSearchAreaChange
   *
   * @param event
   */
  const handleZipSearchAreaChange = (event) => {
    setFormValues({ ...formValues, zipSearchArea: event.target.value });
  };

  /**
   * handleDossierTemplateChange
   *
   * @param event
   */
  const handleDossierTemplateChange = (event) => {
    setFormValues({ ...formValues, dossierTemplate: event.target.value });
  };

  /**
   * ManageUserDialog
   */
  const ManageUserDialog = (
    <>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column">
          <Grid item className={classes.itemDialog}>
            <TextField
              id="inputFirstName"
              className={classes.textFieldSmall}
              label={t("form.label.firstName")}
              variant="outlined"
              align="left"
              value={formValues.firstName}
              error={GetSafe(() => formValueErrors.firstName.hasError, false)}
              helperText={GetSafe(() => formValueErrors.firstName.message, "")}
              onChange={handleFirstNameChange}
            />
            <TextField
              id="inputLastName"
              className={(classes.textField, classes.textFieldSmall)}
              label={t("form.label.lastName")}
              variant="outlined"
              align="left"
              value={formValues.lastName}
              error={GetSafe(() => formValueErrors.lastName.hasError, false)}
              helperText={GetSafe(() => formValueErrors.lastName.message, "")}
              onChange={handleLastNameChange}
            />
          </Grid>
          <Grid item className={classes.itemDialog}>
            <TextField
              id="inputEmail"
              className={classes.textField}
              label={t("form.label.email")}
              variant="outlined"
              align="left"
              value={formValues.email}
              error={GetSafe(() => formValueErrors.email.hasError, false)}
              helperText={GetSafe(() => formValueErrors.email.message, "")}
              onChange={handleEmailChange}
            />
          </Grid>
          {(user.hasZipSearchAreas() || user.isPro()) && (
            <Grid item className={classes.itemDialog}>
              {user.hasZipSearchAreas() && (
                <TextField
                  id="selectZipSearchArea"
                  className={classes.textField}
                  label={t("form.label.zip.search.area")}
                  variant="outlined"
                  align="left"
                  select
                  value={formValues.zipSearchArea}
                  error={GetSafe(
                    () => formValueErrors.zipSearchArea.hasError,
                    false
                  )}
                  helperText={GetSafe(
                    () => formValueErrors.zipSearchArea.message,
                    ""
                  )}
                  onChange={handleZipSearchAreaChange}
                >
                  <MenuItem value={"0"}>&nbsp;</MenuItem>
                  {user.getData().searchAreas.map((item) => {
                    return (
                      <MenuItem key={item.HashID} value={item.HashID}>
                        {item.Title}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
              {queryDossierTemplatesResult.fetching && <LinearProgress />}
              {!queryDossierTemplatesResult.fetching && (
                <TextField
                  id="selectDossierTemplate"
                  className={classes.textField}
                  label={t("form.label.dossier.template")}
                  variant="outlined"
                  align="left"
                  select
                  value={formValues.dossierTemplate}
                  error={GetSafe(
                    () => formValueErrors.dossierTemplate.hasError,
                    false
                  )}
                  helperText={GetSafe(
                    () => formValueErrors.dossierTemplate.message,
                    ""
                  )}
                  onChange={handleDossierTemplateChange}
                >
                  <MenuItem value={"0"}>&nbsp;</MenuItem>
                  {dossierTemplatesSelect.map((dossierTemplate) => {
                    return (
                      <MenuItem
                        key={dossierTemplate.value}
                        value={dossierTemplate.value}
                      >
                        {dossierTemplate.title}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Grid>
          )}
          {dialogType === DIALOGTYPE.ADD && (
            <Grid item className={classes.itemDialog}>
              <Typography className={classes.messageHint}>
                {t("account.users.welcome.hint")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column">
          <Grid
            item
            className={clsx(classes.itemDialog, classes.itemButtonContainer)}
          >
            <Button
              id="cancelButton"
              onClick={() => setOpenDialog(false)}
              color="primary"
              variant="text"
              disabled={fetching || fetchingUpdateUser}
            >
              <Typography className={classes.textButton}>
                {t("general.label.abort")}
              </Typography>
            </Button>
            <Button
              id="buttonFormSubmit"
              onClick={() => handleFormSubmit()}
              color="primary"
              variant="text"
              disabled={fetching || fetchingUpdateUser}
            >
              <Typography className={classes.textButton}>
                {t("general.label.save")}
              </Typography>
              {(fetching || fetchingUpdateUser) && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );

  /**
   * return
   */
  return (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.gridContainerWrapper}
      >
        <Grid item className={classes.gridItem}>
          <Typography variant="body1">
            {t("account.label.user.administration")}
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Button
            id="buttonAddUser"
            onClick={handleAddUser}
            color="primary"
            variant="outlined"
            className={classes.button}
          >
            <AddIcon />
            <Typography className={classes.addButtonText}>
              {t("general.label.add")}
            </Typography>
          </Button>
        </Grid>
        <Grid item className={classes.gridItem}>
          {companyUsers.map((item, index) => {
            if (item.Removed) {
              return (
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={`${classes.gridContainer} ${classes.removed}`}
                  key={index}
                >
                  <Grid item sm={12} className={classes.item}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className={classes.gridContainer}
                    >
                      <Grid
                        item
                        className={clsx(classes.item, classes.itemDelete)}
                      >
                        <Typography>{t("favorites.label.removed")}</Typography>

                        <Typography
                          id={"undoDeleteButton" + item.HashID}
                          className={`${classes.undo} ${classes.link}`}
                          onClick={() => handleUndoDeleteUser(item.HashID)}
                        >
                          {t("general.label.undo")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  className={classes.gridContainer}
                  key={index}
                >
                  <Grid
                    item
                    className={clsx(classes.item, classes.itemAvatar)}
                    xs={3}
                    sm={1}
                  >
                    <Avatar className={classes.avatar}>
                      {item.Firstname.charAt(0) + item.Surname.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    className={clsx(classes.item, classes.itemName)}
                    xs={9}
                    sm={8}
                  >
                    <Typography>
                      {item.Firstname + " " + item.Surname}
                    </Typography>
                    <Typography className={classes.email}>
                      {item.Email}
                    </Typography>
                    {user.hasZipSearchAreas() &&
                      item.SearchAreas.length !== 0 && (
                        <Typography className={classes.email}>
                          {item.SearchAreas[0].Title}
                        </Typography>
                      )}
                  </Grid>
                  <Grid
                    item
                    className={clsx(classes.item, classes.itemButtons)}
                    xs={12}
                    sm={3}
                  >
                    <IconButton
                      id={"editButton" + item.HashID}
                      onClick={() => handleEditUser(item.HashID)}
                    >
                      <EditIcon className={classes.icon} />
                    </IconButton>
                    <IconButton
                      id={"deleteButton" + item.HashID}
                      onClick={() => handleDeleteUser(item.HashID)}
                    >
                      <DeleteIcon className={classes.icon} />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    className={clsx(classes.item, classes.itemDivider)}
                  >
                    <Divider />
                  </Grid>
                </Grid>
              );
            }
          })}
        </Grid>
      </Grid>
      <Dialog open={openDialog} maxWidth="xs" id="manageUsersDialog">
        {ManageUserDialog}
      </Dialog>
    </>
  );
};

export default withAccessControl(AccountUsers, ACL_COMPONENTS.ACCOUNT_USERS);
