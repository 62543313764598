import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import Paper from '@material-ui/core/Paper'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS } from '../../config'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginRight: 0,
    marginLeft: 0
  },
  autocompleteResultWrapper: {
    position: 'relative',
    textAlign: 'center',
    zIndex: 2
  },
  autocompleteResult: {
    position: 'relative', // Could be 'absolute' for hovering autosuggest-result-list
    padding: theme.spacing(3),
    width: '100%'
  },
  textFieldSmall: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 24px)',
      marginRight: 24
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:first-of-type': {
        width: '100%'
      }
    }
  }
}))

// Google search options
const searchOptions = {
  componentRestrictions: { country: 'ch' },
  types: ['(cities)']
}

const addressObject = {
  city: ''
}

/**
 * GooglePlacesAutosuggestCity
 *
 * @returns {*}
 * @constructor
 */
const GooglePlacesAutosuggestCity = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { formValue, formValueSetter, presetValue } = props

  // Check for valid callback
  if (typeof props.onSuggestionSelect === 'undefined') {
    throw Error('No onSuggestionSelect defined')
  }

  /**
   * useEffect
   */
  useEffect(() => {
    if (typeof presetValue !== 'undefined' && presetValue !== '') {
      formValueSetter(presetValue)
    }
  }, [presetValue, formValueSetter])

  /**
   * handleAddressSelect
   *
   * @param address
   */
  const handleAddressSelect = address => {
    formValueSetter(address)
    geocodeByAddress(address)
      .then(results => {
        results[0].address_components.map(item => {
          if (item.types[0] === 'locality') {
            addressObject.city = item.long_name
          }
          return item
        })

        props.onSuggestionSelect({ addressObject })
      })
      .catch(error => {
        ExternalErrorLogger.log({
          text: 'Error handle address select on GooglePlacesAutosuggestCity',
          data: {
            address: JSON.stringify(address),
            error: JSON.stringify(error)
          }
        })
      })
  }

  /**
   * handleChange
   *
   * @param value
   */
  const handleChange = value => {
    if (value === '') {
      props.onSuggestionSelect({
        addressObject
      })
    }
    formValueSetter(value)
  }

  /**
   * return
   */
  return (
    <PlacesAutocomplete
      value={formValue}
      onChange={handleChange}
      onSelect={handleAddressSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <TextField
            {...getInputProps({ autoComplete: 'no' })}
            id='inputCity'
            label={t('form.label.city')}
            className={classes.textFieldSmall}
            margin='normal'
            variant='outlined'
            error={props.autoSuggestError}
            helperText={props.helperText}
            disabled={props.disabled}
          />
          {loading && (
            <div className={classes.autocompleteResultWrapper}>
              <Paper className={classes.autocompleteResult}>
                <List dense>
                  <ListItem id='autocomplete-places-result-loading'>
                    {t('general.label.loading')}
                  </ListItem>
                </List>
              </Paper>
            </div>
          )}
          {!loading && suggestions.length > 0 && (
            <div className={classes.autocompleteResultWrapper}>
              <Paper className={classes.autocompleteResult}>
                <List dense>
                  {suggestions.map((suggestion, index) => (
                    <ListItem
                      key={index}
                      {...getSuggestionItemProps(suggestion)}
                      id={`autocomplete-places-result-${index}`}
                      button
                      component='a'
                    >
                      {suggestion.formattedSuggestion.mainText}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </div>
          )}
        </>
      )}
    </PlacesAutocomplete>
  )
}

export default withAccessControl(
  GooglePlacesAutosuggestCity,
  ACL_COMPONENTS.GOOGLE_PLACES_AUTOSUGGEST_FIELD
)
