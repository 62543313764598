import Joi from "@hapi/joi";
import UserTypes from "../components/Onboarding/UserTypes";
import hash from "object-hash";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import Cookies from "universal-cookie";
import { MASQ_COOKIE_NAME, cookieDomain } from "../config";

/**
 * User
 * @returns {{unsetData: *, getSchemaUserData: *, writeLocalStorage: *, getHash: *, deleteLocalStorage: *, setData: *, isSet: *, getLocalStorage: *, getSchemaCompanyData: *, getData: *}}
 * @constructor
 */
const User = () => {
  const initialData = {
    id: "",
    hashID: "",
    locale: "",
    salutation: 1,
    appAdmin: 0,
    isMasquerade: 0,
    email: "",
    firstName: "",
    lastName: "",
    mode: "free",
    stripePublicKey: "",
    locations: [],
    searchAreas: [],
    company: {
      id: "",
      hashID: "",
      distance: 0,
      chid: "",
      name: "",
      contactPerson: "",
      street: "",
      zip: "",
      city: "",
      phone: "",
      mobile: "",
      fax: "",
      email: "",
      website: "",
      employeeCount: "",
      legalForm: "",
      foundingYear: "",
      masterOperation: "",
      canton: "",
      branchCategory: 0,
      branchSubs: [],
      otherBusinessActivities: [],
      otherBusinessSpecialities: [],
      trialPossible: false,
      micrositeColorScheme: "",
      micrositeDescription: "",
      micrositeEmailApplicationText: "",
      micrositeImages: [],
      micrositeLogo: {},
      additionalMemberships: "",
      micrositeReferences: [],
      micrositeCertified: false,
      micrositeCertifiedUntil: "",
      zipSearchAreas: false,
      zipSearchStatistic: false,
      applicationFollowUpTimeInDays: 0
    },
    dossierTemplates: [],
    permissions: [],
    fulltextSearchRequests: [],
  };

  let data = initialData;

  /**
   * Joi validation schema companyData
   */
  const schemaCompanyData = Joi.object({
    id: Joi.number(),
    hashID: Joi.string().required(),
    adminUserID: Joi.number(),
    chid: Joi.string().allow(""),
    name: Joi.string().required(),
    urlSegment: Joi.string().allow("", null),
    contactPerson: Joi.string().allow(""),
    street: Joi.string().allow(""),
    zip: Joi.string().allow(""),
    city: Joi.string().allow(""),
    phone: Joi.string().allow(""),
    mobile: Joi.string().allow(""),
    website: Joi.string()
      .regex(
        /https?:\/\/(www\.)?[-a-zA-ZöäüÖÄÜ0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/
      )
      .allow(""),
    fax: Joi.string().allow(""),
    email: Joi.string().email({ tlds: false }).allow(""),
    employeeCount: Joi.number().integer().allow(0).allow(""),
    legalForm: Joi.number().min(0).allow(""),
    foundingYear: Joi.string().allow(""),
    masterOperation: Joi.string().allow(""),
    trainingCompany: Joi.string().allow(""),
    canton: Joi.string().allow(""),
    distance: Joi.number().allow(""),
    latitude: Joi.number().allow(null),
    longitude: Joi.number().allow(null),
    branchCategory: Joi.number().min(0).allow(""),
    branchSubs: Joi.array().items(Joi.number()),
    otherBusinessActivities: Joi.array().items(Joi.number()),
    otherBusinessSpecialities: Joi.array().items(Joi.number()),
    users: Joi.array(),
    stripeIsTrialing: Joi.boolean().allow(null),
    stripeTrialingDays: Joi.number().allow(null),
    stripePlanId: Joi.string().allow(null),
    stripeValidThrough: Joi.string().allow(null),
    stripeProductId: Joi.string().allow(null),
    stripeCancelAt: Joi.number(),
    stripeFuturePlans: Joi.array().allow(null),
    trialPossible: Joi.boolean().allow(null),
    memberships: Joi.array(),
    allMemberships: Joi.array(),
    micrositeColorScheme: Joi.string().allow(null, ""),
    micrositeDescription: Joi.string().allow(null, ""),
    micrositeEmailApplicationText: Joi.string().allow(null, ""),
    micrositeImages: Joi.array().allow(null),
    micrositeLogo: Joi.object().allow(null, {}),
    additionalMemberships: Joi.string().allow(null, ""),
    micrositeReferences: Joi.array().allow(null),
    micrositeCertified: Joi.boolean().allow(null),
    micrositeCertifiedUntil: Joi.string().allow(null, ""),
    hasExternalApply: Joi.boolean().allow(null),
    zipSearchAreas: Joi.boolean().allow(null),
    zipSearchStatistic: Joi.boolean().allow(null),
    applicationFollowUpTimeInDays: Joi.number().allow(null),
    constructionSums: Joi.object().allow(null, {}),
    __typename: Joi.string().allow(""), // For API-Caching-Reasons this is required
  });

  /**
   * Joi validation schema userData
   */
  const schemaUserData = Joi.object({
    id: Joi.number(),
    hashID: Joi.string().required(),
    locale: Joi.string().allow(""),
    salutation: Joi.number().allow(""),
    appAdmin: Joi.number(),
    isMasquerade: Joi.number(),
    email: Joi.string().email({ tlds: false }).required(),
    firstName: Joi.string().allow(""),
    lastName: Joi.string().allow(""),
    mode: Joi.string()
      .valid(null, UserTypes.FREE, UserTypes.SMART, UserTypes.PRO)
      .required(),
    stripePublicKey: Joi.string().allow(""),
    creditCard: Joi.object().allow({}),
    company: schemaCompanyData,
    dossierTemplates: Joi.array(),
    locations: Joi.array(),
    permissions: Joi.array(),
    fulltextSearchRequests: Joi.array(),
    searchAreas: Joi.array().allow(null),
    __typename: Joi.string().allow(""), // For API-Caching-Reasons this is required
  });

  /**
   * getHash
   * Builds and returns a hash based on data
   * return String
   */
  const getHash = () => {
    return hash(data);
  };

  /**
   * getData
   * @returns {{mode: string, firstName: string, lastName: string, stripePublicKey: string, permissions: [], fulltextSearchRequests: [], locations: [], company: {zip: string, website: string, city: string, foundingYear: string, branchCategory: number, trialPossible: boolean, masterOperation: string, hashID: string, employeeCount: string, legalForm: string, chid: string, branchSubs: [], phone: string, street: string, name: string, canton: string, id: string, fax: string, email: string}, id: string, salutation: number, locale: string, hashID: string, email: string}}
   */
  const getData = () => {
    return data;
  };

  /**
   * setData
   * Sets and validates data
   * @param userData
   * @returns {boolean}
   */
  const setData = (userData) => {
    if (isValid(userData)) {
      data = _mergeIntoData(userData);
      _writeLocalStorage();
      return true;
    }
    return false;
  };

  /**
   * _mergeIntoData
   * Merges provided data into the current data
   * @param userData
   * @returns {{mode, firstName, lastName, company, id, salutation, email}}
   * @private
   */
  const _mergeIntoData = (userData) => {
    if (typeof userData.company === "undefined") {
      userData.company = {};
    }
    userData.company = {
      ...initialData.company,
      ...data.company,
      ...userData.company,
    };
    return { ...initialData, ...data, ...userData };
  };

  /**
   * isSet
   * Checks if data has been set
   * @returns {boolean}
   */
  const isSet = () => {
    return data.hashID !== "" && data.hashID.length > 0;
  };

  /**
   * isFree
   * Checks if the user is a free user
   * @returns {boolean}
   */
  const isFree = () => {
    return isSet() && data.mode === UserTypes.FREE;
  };

  /**
   * isPro
   * Checks if the user is a pro or proadmin user
   * @returns {boolean|boolean}
   */
  const isPro = () => {
    return (
      isSet() &&
      (data.mode === UserTypes.PRO || data.mode === UserTypes.PROADMIN)
    );
  };

  /**
   * isAbleToUpgrade
   * Checks if the user is able to see the upgrade dialog
   * @returns {boolean|boolean}
   */
  const isAbleToUpgrade = () => {
    return isSet() && (data.mode === UserTypes.FREE || data.mode === null);
  };

  /**
   * isAppAdmin
   * Checks if the user is app administrator (set in cms user form)
   * @returns {boolean|boolean}
   */
  const isAppAdmin = () => {
    return isSet() && (data.appAdmin === 1);
  };

  /**
   * isMasquerade
   * Checks if the user is a masquerade
   * @returns {boolean|boolean}
   */
  const isMasquerade = () => {
    return isSet() && (data.isMasquerade === 1);
  };

  /**
   * hasAddress
   *
   * @returns {boolean}
   */
  const hasAddress = () => {
    return (
      isSet() &&
      data.company.name !== "" &&
      data.company.street !== "" &&
      data.company.zip !== "" &&
      data.company.city !== ""
    );
  };

  const hasZipSearchAreas = () => {
    return isSet() && data.company.zipSearchAreas === true;
  };

  const hasZipSearchStatistic = () => {
    return isSet() && data.company.zipSearchStatistic === true;
  };

  const hasApplicationFollowUpTimInDays = () => {
    return isSet() && data.company.applicationFollowUpTimeInDays > 0;
  };

  /**
   * unsetData
   * Unsets the current data and localStore
   */
  const unsetData = () => {
    data = initialData;
    deleteLocalStorage();
  };

  /**
   * getSchemaUserData
   */
  const getSchemaUserData = () => {
    return schemaUserData;
  };

  /**
   * getSchemaCompanyData
   */
  const getSchemaCompanyData = () => {
    return schemaCompanyData;
  };

  /**
   * getLocalStorage
   * Fetch the data in the localStore
   * @returns {boolean|*|Url|{ext, root, name, dir, base}|null}
   */
  const getLocalStorage = () => {
    const localStoreUserData = window.localStorage.getItem("user");
    if (localStoreUserData && localStoreUserData.length > 0) {
      return JSON.parse(localStoreUserData);
    }
    return false;
  };

  /**
   * _writeLocalStorage
   * @private
   */
  const _writeLocalStorage = () => {
    window.localStorage.setItem("user", JSON.stringify(data));
  };

  /**
   * writeLocalStorage
   * Writes the provided data to the current data, also writes to the localStore
   * @param userData
   */
  const writeLocalStorage = (userData) => {
    if (setData(userData)) {
      _writeLocalStorage();
    }
  };

  /**
   * deleteLocalStorage
   * Removes the user-data from the browsers store, also removes the mask-cookie if present
   */
  const deleteLocalStorage = () => {
    _deleteAppAdminMaskCookie();
    window.localStorage.removeItem("user");
  };

  /**
   * _deleteAppAdminMaskCookie
   * Removes the masquerade cookie
   */
  const _deleteAppAdminMaskCookie = () => {
    if (isMasquerade()) {
      const cookies = new Cookies();
      
      if (typeof cookies.get(MASQ_COOKIE_NAME) !== 'undefined') {
        cookies.remove(MASQ_COOKIE_NAME, {
          path: "/",
          domain: cookieDomain,
        });
      }
    }
  }

  /**
   * isValid
   * @param userData
   * @returns {boolean}
   */
  const isValid = (userData) => {
    const mergedData = _mergeIntoData(userData);
    const { error } = schemaUserData.validate(mergedData, {
      abortEarly: false,
    });
    if (error) {
      ExternalErrorLogger.log({
        text: "Error user validity",
        data: {
          error: error,
          userData: userData,
          mergedData: mergedData,
        },
      });
      return false;
    }
    return true;
  };

  /**
   * Methods accessible from outside
   */
  return {
    getHash,
    getData,
    setData,
    unsetData,
    isSet,
    isFree,
    isPro,
    isAbleToUpgrade,
    isAppAdmin,
    isMasquerade,
    hasAddress,
    hasZipSearchAreas,
    hasZipSearchStatistic,
    hasApplicationFollowUpTimInDays,
    getSchemaUserData,
    getSchemaCompanyData,
    getLocalStorage: getLocalStorage,
    writeLocalStorage: writeLocalStorage,
    deleteLocalStorage: deleteLocalStorage,
  };
};

/**
 * Exports the user instance
 */
export default User();
