/**
 * Check object properties without the need to be existent.
 * Returns a default value if not existent!
 *
 * @param fn
 * @param defaultVal
 * @returns {*}
 */
const getSafe = (fn, defaultVal) => {
  try {
    return fn()
  } catch (e) {
    return defaultVal
  }
}

export default getSafe
