import { SILVERSTRIPE_OAUTH_API } from "../config";
import convertJsonToQuery from "./ConvertJsonToQuery";
import Joi from "@hapi/joi";
import hash from "object-hash";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import { navigate } from "hookrouter";

const TOKEN_LIFETIME_SECONDS = 3600; // Should be 3600 (1 hour), must match with [SS4]/htdocs/app/src/Controllers/OAuth2Controller.php:runTokenServer:setRefreshTokenTTL

/**
 * Token
 * @returns {{unsetData: *, writeLocalStorage: *, getHash: *, deleteLocalStorage: *, isSet: *, setData: *, loadFromLocalStorage: *, api: *, getLocalStorage: *, getData: *}}
 * @constructor
 */
const Token = () => {
  const initialData = {
    access_token: "",
    expires_in: 0,
    refresh_token: "",
    token_type: "Bearer",
    expired_timestamp: 0,
  };

  let data = {
    access_token: "",
    expires_in: 0,
    refresh_token: "",
    token_type: "Bearer",
    expired_timestamp: 0,
  };

  /**
   * Joi validation schema tokenData
   */
  const schemaTokenData = Joi.object({
    access_token: Joi.string().required(),
    expires_in: Joi.number().required(),
    refresh_token: Joi.string().required(),
    token_type: Joi.string().valid("Bearer").required(),
    expired_timestamp: Joi.number().required(),
    __typename: Joi.string().allow(""), // For API-Caching-Reasons this is required
  });

  /**
   * Check if the current data has been set
   * @returns {boolean}
   */
  const isSet = () => {
    return data.access_token !== "";
  };

  /**
   * Check if the current data has a refresh-token set
   * @returns {boolean}
   * @private
   */
  const _isSetRefreshToken = () => {
    return data.refresh_token !== "";
  };

  /**
   * getHash
   * return String
   */
  const getHash = () => {
    return hash(data);
  };

  /**
   * getData
   * @returns {{access_token: string, refresh_token: string, expired_timestamp: number, token_type: string, expires_in: number}}
   */
  const getData = () => {
    return data;
  };

  /**
   * setData
   * @param tokenData
   * @returns {boolean}
   */
  const setData = (tokenData) => {
    if (isValid(tokenData)) {
      if (
        !isSet() ||
        (isSet() && tokenData.access_token !== data.access_token)
      ) {
        _updateExpiredTimestamp(tokenData.expires_in);
        delete tokenData.expired_timestamp;
      }
      data = _mergeIntoData(tokenData);
      _writeLocalStorage();
      return true;
    }
    return false;
  };

  /**
   * setRefreshToken
   * @param refreshToken
   */
  const setRefreshToken = (refreshToken) => {
    data.expires_in = TOKEN_LIFETIME_SECONDS;
    data.refresh_token = refreshToken;
  };

  /**
   * unsetData
   */
  const unsetData = () => {
    data = initialData;
    deleteLocalStorage();
  };

  /**
   * _updateExpiredTimestamp
   * @private
   */
  const _updateExpiredTimestamp = (addExpireTime) => {
    const time = Math.round(new Date().getTime() / 1000);
    data.expired_timestamp = time + addExpireTime;
  };

  /**
   * isValid
   * @param tokenData
   * @returns {boolean}
   */
  const isValid = (tokenData) => {
    const mergedData = _mergeIntoData(tokenData);
    const { error } = schemaTokenData.validate(mergedData, {
      abortEarly: false,
    });
    
    if (error) {
      ExternalErrorLogger.log({
        text: "Error token validity - Token has been removed just now",
        data: {
          error: error,
          tokenData: tokenData,
          mergedData: mergedData,
        }
      });

      unsetData();
      return false;
    }
    return true;
  };

  /**
   * _mergeIntoData
   * @param tokenData
   * @returns {{access_token, refresh_token, expired_timestamp, token_type, expires_in}}
   * @private
   */
  const _mergeIntoData = (tokenData) => {
    return { ...initialData, ...data, ...tokenData };
  };

  /**
   * getLocalStorage
   * @returns {boolean|*|Url|{ext, root, name, dir, base}|null}
   */
  const getLocalStorage = () => {
    const localStoreTokenData = window.localStorage.getItem("token");
    if (localStoreTokenData && localStoreTokenData.length > 0) {
      return JSON.parse(localStoreTokenData);
    }
    return false;
  };

  /**
   * loadFromLocalStorage
   * @returns {boolean}
   */
  const loadFromLocalStorage = () => {
    const localStoreTokenData = getLocalStorage();
    if (localStoreTokenData && isValid(localStoreTokenData)) {
      data = localStoreTokenData;
      return true;
    }
    return false;
  };

  /**
   * _writeLocalStorage
   * @private
   */
  const _writeLocalStorage = () => {
    window.localStorage.setItem("token", JSON.stringify(data));
  };

  /**
   * writeLocalStorage
   * @param tokenData
   */
  const writeLocalStorage = (tokenData) => {
    if (setData(tokenData)) {
      _writeLocalStorage();
    }
  };

  /**
   * deleteLocalStorage
   */
  const deleteLocalStorage = () => {
    window.localStorage.removeItem("token");
  };

  /**
   * api
   * API handling for the token
   * @returns {{refreshOnDemand: *, refresh: *, authorize: *, isRefreshing: *}}
   */
  const api = () => {
    let _inRefresh = false;

    /**
     * authorize
     * Authorize against the api via username and password
     * @param username
     * @param password
     * @returns {Promise<boolean|*|Url|{ext, root, name, dir, base}|null>}
     */
    const authorize = async (username, password) => {
      const result = await window.fetch(SILVERSTRIPE_OAUTH_API.urls.authorize, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "client", // no-referrer, *client
        body: convertJsonToQuery(
          Object.assign({}, SILVERSTRIPE_OAUTH_API.data.authorize, {
            username: username,
            password: password,
          })
        ), // body data type must match "Content-Type" header
      });
      if (result.status === 200) {
        const fulfilledResult = await result.text();

        if (!fulfilledResult.includes("Authorization failed")) {
          return JSON.parse(fulfilledResult);
        }
      }
      return false;
    };

    /**
     * refresh
     * Authorize against the api via refresh-token
     * @param refreshToken
     * @returns {Promise<boolean|*|Url|{ext, root, name, dir, base}|null>}
     */
    const refresh = async (refreshToken) => {
      try {
        const result = await window.fetch(SILVERSTRIPE_OAUTH_API.urls.refresh, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "client", // no-referrer, *client
          body: convertJsonToQuery(
            Object.assign({}, SILVERSTRIPE_OAUTH_API.data.refresh, {
              refresh_token: refreshToken,
            })
          ), // body data type must match "Content-Type" header
        });

        if (result.status === 200) {
          return JSON.parse(await result.text());
        }
        return false;
      } catch (e) {
        ExternalErrorLogger.log({
          text: "Error refreshing token",
          data: {
            error: e,
            refreshToken: refreshToken,
          },
        });
      }
    };

    /**
     * refreshOnDemand
     * Check if the token data need to be refreshed (fetch new token using the stored refresh-token)
     * In case the end-of-life of the access-token has been reached, trigger a refresh, optional this can be forced via param
     * Optionally a setState-Function can be passed to update the token-store with the refreshed token-data
     * @param setStateFunction
     * @param force
     * @returns {Promise<void>}
     */
    const refreshOnDemand = async (setStateFunction = false, force = false) => {
      const currTime = Math.round(new Date().getTime() / 1000);
      const tokenEndOfLife = data.expired_timestamp;
      if (force || currTime > tokenEndOfLife) {
        _inRefresh = true;
        try {
          if (_isSetRefreshToken()) {
            const authorizationResponseData = await refresh(data.refresh_token);
            // TODO: move set of _inRefresh here
            if (authorizationResponseData) {
              if (
                Array.isArray(authorizationResponseData) &&
                authorizationResponseData[0].includes("User forced logout")
              ) {
                throw new Error(authorizationResponseData[0]);
              }

              _inRefresh = false;
              setData(authorizationResponseData);
              if (setStateFunction) {
                const newToken = Token();
                newToken.setData(getData());
                setStateFunction(newToken);
              }
            }
          } else {
            console.error(
              "A token-refresh-on-demand is required but no refresh-token has been set"
            );
            _inRefresh = false;
          }
        } catch (e) {
          _inRefresh = false;
          console.error(e);
          if (e.message.includes("User forced logout")) {
            navigate("/logout");
          }
        }
      }
    };

    /**
     * isRefreshing
     * Timing-Helper
     * @returns {boolean}
     */
    const isRefreshing = () => {
      return _inRefresh;
    };

    /**
     * return
     */
    return {
      authorize,
      refresh,
      refreshOnDemand,
      isRefreshing,
    };
  };

  /**
   * return
   */
  return {
    getHash,
    isSet,
    getData,
    setRefreshToken,
    setData,
    unsetData,
    getLocalStorage,
    writeLocalStorage,
    deleteLocalStorage,
    loadFromLocalStorage,
    api: api(),
  };
};

/**
 * Export the token instance
 */
export default Token();
