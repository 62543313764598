import { createClient, fetchExchange, mapExchange } from 'urql'
import cacheExchange from './Exchanges/CacheExchange';
import authExchange from './Exchanges/AuthExchange';
import { MASQ_COOKIE_NAME, SILVERSTRIPE_GRAPHQL_API } from '../config'
import Cookies from 'universal-cookie';

const urqlFetchOptions = () => {
  const cookies = new Cookies();
  
  if (typeof cookies.get(MASQ_COOKIE_NAME) !== "undefined") {
    return {
        headers: {
        'x-cms-user-id': cookies.get(MASQ_COOKIE_NAME)
      }
    }
  }

  return {}
}

/**
 * URQL Client for silverstripe
 *
 * @returns {*}
 */
const GraphQLClientSilverstripe = createClient({
  url: SILVERSTRIPE_GRAPHQL_API.url,
  requestPolicy: 'cache-and-network',
  fetchOptions: urqlFetchOptions(),
  exchanges: [
    cacheExchange,
    authExchange,
    mapExchange({
      onError(error) {
        console.error(error);
      },
    }),
    fetchExchange
  ],
})

export default GraphQLClientSilverstripe
