/**
 * FormatHelper
 *
 * Formats date, distance, directoryName
 *
 * @returns {{formatDate: (function(*=): string), formatDistance: (function(*): string), getTimestampFromDate: (function(*=): number), isValidDate: (function(*=): (boolean)), formatDirectoryName: (function(*): *)}}
 * @constructor
 */
const FormatHelper = () => {
  /**
   * buildDateObject
   *
   * @param date
   * @returns {Date|*}
   */
  const buildDateObject = date => {
    if (date !== null && date.length > 10) {
      return new Date(date.substring(0, 10))
    }
    return date
  }

  /**
   * isValidDate
   *
   * @param date
   * @returns {boolean|boolean}
   */
  const isValidDate = date => {
    const dateObj = buildDateObject(date)
    return dateObj instanceof Date && !isNaN(dateObj)
  }

  /**
   * formatDate
   *
   * @param date
   * @returns {string}
   */
  const formatDate = date => {
    if (isValidDate(date)) {
      const dateObj = buildDateObject(date)
      const day = ('0' + dateObj.getDate().toString(10)).slice(-2)
      const month = ('0' + (dateObj.getMonth() + 1).toString(10)).slice(-2)
      const year = dateObj.getFullYear()

      return day + '.' + month + '.' + year
    } else {
      return ''
    }
  }

  /**
   * getTimestampFromDate
   *
   * @param date
   * @returns {number}
   */
  const getTimestampFromDate = date => {
    if (isValidDate(date)) {
      const dateObj = buildDateObject(date)
      return dateObj.getTime()
    }
  }

  /**
   * formatDistance
   *
   * @param distance
   * @returns {string}
   */
  const formatDistance = distance => {
    const pd = Math.pow(10, 2)
    const roundedDistance = Math.floor(distance * pd) / pd
    return roundedDistance.toString(10).replace('.', ',')
  }

  /**
   * formatDirectoryName
   *
   * formats a directory name to be valid as url part
   *
   * @param directoryName
   */
  const formatDirectoryName = directoryName => {
    // eslint-disable-next-line no-extend-native
    String.prototype.allReplace = function (obj) {
      let retStr = this
      for (const element in obj) {
        retStr = retStr.replace(new RegExp(element, 'g'), obj[element])
      }
      return retStr
    }

    const replacements = {
      // prettier-ignore
      \u00dc: 'UE', // Umlaut replacement
      // prettier-ignore
      \u00c4: 'AE', // Umlaut replacement
      // prettier-ignore
      \u00d6: 'OE', // Umlaut replacement
      // prettier-ignore
      \u00fc: 'ue', // Umlaut replacement
      // prettier-ignore
      \u00e4: 'ae', // Umlaut replacement
      // prettier-ignore
      \u00f6: 'oe', // Umlaut replacement
      // prettier-ignore
      \u00df: 'ss', // ß replacement
      '&amp;': '-and-', // & replacements
      '&': '-and-', // & replacements
      '(\\s)|\\+': '-', // remove whitespace/plus
      '[_.]+': '-', // underscores and dots to dashes
      '[^A-Za-z0-9-]+': '', // remove non-ASCII chars, only allow alphanumeric and dashes
      '[/?=#:]+': '-', // remove forward slashes, question marks, equal signs, hashes and colons in case multibyte is allowed (and non-ASCII chars aren't removed)
      '[-]{2,}': '-', // remove duplicate dashes
      '^[-]+': '', // Remove all leading dashes
      '[-]+$': '' // Remove all trailing dashes
    }

    return directoryName.allReplace(replacements)
  }

  const boolToString = value => {
    if (typeof value === 'boolean') {
      if (value === true) {
        return '1'
      }

      return '0'
    }

    return value
  }

  /**
   * return
   */
  return {
    formatDate,
    formatDistance,
    isValidDate,
    getTimestampFromDate,
    formatDirectoryName,
    boolToString
  }
}

export default FormatHelper()
