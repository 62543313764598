import React, { useContext, useEffect, useState } from "react";
import withAccessControl from "../HOC/AccessControl";
import { ACL_COMPONENTS } from "../../config";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import { useMutation, useQuery } from "urql";
import { green } from "@material-ui/core/colors";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CompanyDirectoryNameDialog from "./CompanyDirectoryNameDialog";
import CompanyInfoForm from "./CompanyInfoForm";
import FormatHelper from "../../lib/FormatHelper";
import {
  initialFormValuesState,
  initialFormValueErrorsState,
  createFileFromUrl,
  readFile,
  updateCompanyMutation,
  formatImagesArray,
  schema,
  querySmartconextConf,
} from "./CompanyInfoShared";
import UpdateCompanyDataDialog from "../UpdateCompanyDataDialog";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  checkboxGridItem: {
    // width: '50%',
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    color: "#ffffff",
    width: "100%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formLabel: {
    color: theme.palette.text.primary,
  },
  input: {
    marginLeft: 60,
  },
  logoImg: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonImg: {
    color: "#ffffff",
    marginBottom: "20px",
  },
  label: {
    color: theme.palette.text.primary,
  },
  root: {
    width: "100%",
    // maxWidth: 444
  },
  membershipsLogo: {
    width: 80,
    height: "auto",
    paddingRight: 20,
  },
  dialogRoot: {
    flexGrow: 1,
  },
  dialog: {
    width: "100%",
    paddingBottom: 56,
    "&::before": theme.watermarkLogo,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  startButton: {
    width: "100%",
    color: "#fff",
    fontWeight: "bold",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: 0,
  },
  listItemPointer: {
    cursor: "pointer",
  },
  tooltipItem: {
    maxWidth: 350,
    borderRadius: 5,
    background: "#006A7A",
    fontSize: 16,
    textAlign: "center",
    "& div": {
      margin: 0,
      color: "#fff",
    },
  },
  tooltipInfo: {
    maxWidth: "100%",
    background: theme.palette.background.default,
    "& div": {
      maxWidth: "100%",
      color: theme.palette.text.primary,
      fontSize: 12,
      lineHeight: "20px",
    },
    "@media (min-width:768px)": {
      maxWidth: 450,
    },
  },
  tooltipTitle: {
    display: "flex",
    alignItems: "center",
  },
}));

/**
 * CompanyInfo
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, setUser, token, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );
  const [currentFileObjects, setCurrentFileObjects] = useState([]);

  const [verifyDialogOpen, setVerifyDialogOpen] = useState(
    user.getData().company.urlSegment
  );
  const [colorSchemes, setColorSchemes] = useState([]);
  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);
  const [result, executeQuerySmartconextConf] = useQuery({
    query: querySmartconextConf,
    pause: true,
  });

  /**
   * useEffect executeQuerySmartconextConf
   */
  useEffect(() => {
    if (colorSchemes.length === 0) {
      executeQuerySmartconextConf();
    }
  }, [colorSchemes, executeQuerySmartconextConf]);

  /**
   * useEffect set FormData
   */
  useEffect(() => {
    const userData = user.getData();
    setFormValues({
      directoryName:
        userData.company.urlSegment || initialFormValuesState.directoryName,
      employeeCount:
        userData.company.employeeCount || initialFormValuesState.employeeCount,
      legalForm: userData.company.legalForm || initialFormValuesState.legalForm,
      foundingYear:
        userData.company.foundingYear || initialFormValuesState.foundingYear,
      masterOperation:
        FormatHelper.boolToString(userData.company.masterOperation) ||
        initialFormValuesState.masterOperation,
      trainingCompany:
        FormatHelper.boolToString(userData.company.trainingCompany) ||
        initialFormValuesState.trainingCompany,
      colorScheme: initialFormValuesState.colorScheme,
      memberships:
        userData.company.memberships || initialFormValuesState.memberships,
      furtherMemberships: initialFormValuesState.furtherMemberships,
      micrositeColorScheme:
        userData.company.micrositeColorScheme ||
        initialFormValuesState.micrositeColorScheme,
      micrositeDescription:
        userData.company.micrositeDescription ||
        initialFormValuesState.micrositeDescription,
      micrositeEmailApplicationText:
        userData.company.micrositeEmailApplicationText ||
        initialFormValuesState.micrositeEmailApplicationText,
      micrositeLogo:
        userData.company.micrositeLogo || initialFormValuesState.micrositeLogo,
      micrositeImages:
        userData.company.micrositeImages ||
        initialFormValuesState.micrositeImages,
      additionalMemberships:
        userData.company.additionalMemberships ||
        initialFormValuesState.additionalMemberships,
    });
  }, [user]);

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching smartconext conf on CompanyInfo",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
          return <div>{t("error.general")}</div>;
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readSmartconextConf !== "undefined"
        ) {
          if (colorSchemes.length === 0) {
            setColorSchemes(
              result.data.readSmartconextConf[0].microsite_color_schemes
            );
            setFormValues({
              ...formValues,
              colorScheme: user.getData().company.micrositeColorScheme || "3", // Default colorScheme = "Schweiz"
            });
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = async () => {
    formValues.memberships.map((item) => {
      delete item.__typename;
      return item;
    });

    formValues.micrositeImages.map((item) => {
      delete item.__typename;
      return item;
    });

    const formattedImagesArray = formatImagesArray(formValues);

    const fileObjs = await Promise.all(
      formattedImagesArray.map(async (initialFile) => {
        let file;
        if (typeof initialFile.Src === "string") {
          file = await createFileFromUrl(initialFile.Src);
        } else {
          file = initialFile.Src;
        }
        const data = await readFile(file);

        return {
          file,
          data,
        };
      })
    );

    const convertedImagesArray = [];
    fileObjs.map((item) => {
      if (item.data) {
        convertedImagesArray.push({
          Src: item.data,
          Title: item.file.name,
          Alt: item.file.name,
        });
      } else {
        convertedImagesArray.push(item);
      }
      return item;
    });

    const logoData = { Src: "", Alt: "", Title: "" };

    if (currentFileObjects[0]) {
      logoData.Src = currentFileObjects[0].data;
      logoData.Alt = currentFileObjects[0].file.name;
      logoData.Title = currentFileObjects[0].file.name;
    } else if (
      formValues.micrositeLogo.Src !== null &&
      formValues.micrositeLogo.Src !== "" &&
      formValues.micrositeLogo.Src !== undefined
    ) {
      const logoArray = [formValues.micrositeLogo];
      const logoFile = await Promise.all(
        logoArray.map(async (initialFile) => {
          let file;
          if (typeof initialFile.Src === "string") {
            file = await createFileFromUrl(initialFile.Src);
          } else {
            file = initialFile.Src;
          }
          const data = await readFile(file);

          return {
            file,
            data,
          };
        })
      );
      const convertedLogoArray = [];
      logoFile.map((item) => {
        if (item.data) {
          convertedLogoArray.push({
            Src: item.data,
            Title: item.file.name,
            Alt: item.file.name,
          });
        } else {
          convertedLogoArray.push(item);
        }
        return item;
      });
      logoData.Src = convertedLogoArray[0].Src;
      logoData.Alt = convertedLogoArray[0].Alt;
      logoData.Title = convertedLogoArray[0].Title;
    } else {
      console.log("DELETE_LOGO");
    }

    const formData = {
      hashID: user.getData().company.hashID,
      employeeCount: formValues.employeeCount,
      legalForm: formValues.legalForm,
      foundingYear: formValues.foundingYear,
      masterOperation: formValues.masterOperation,
      micrositeDescription: formValues.micrositeDescription,
      memberships: formValues.memberships,
      trainingCompany: formValues.trainingCompany,
      micrositeImages: convertedImagesArray,
      micrositeLogo: logoData,
      additionalMemberships: formValues.additionalMemberships,
      micrositeEmailApplicationText: formValues.micrositeEmailApplicationText,
      micrositeColorScheme: formValues.colorScheme,
    };
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      console.log(error);
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      window.scrollTo(0, 0);
    } else {
      setFormValueErrors(initialFormValueErrorsState);

      setLoadingOverlayOpen(true);

      executeCompanyUpdateMutation({
        hashId: user.getData().company.hashID,
        numberOfEmployees: formValues.employeeCount,
        legalForm: formValues.legalForm,
        foundingYear: formValues.foundingYear,
        masterBusiness: formValues.masterOperation,
        trainingCompany: formValues.trainingCompany,
        description: formValues.micrositeDescription,
        memberships: formValues.memberships,
        colorScheme: formValues.colorScheme,
        images: convertedImagesArray,
        logo: logoData,
        additionalMemberships: formValues.additionalMemberships,
        emailApplicationText: formValues.micrositeEmailApplicationText,
      }).then((result) => {
        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submitting data on CompanyInfo",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          setMessage(MESSAGE_TYPES.SUCCESS, t("general.label.save.success"));
          user.setData({ company: { ...formData } });
          const micrositeImages = [];
          result.data.updateCompany[0].MicrositeImages.edges.map((item) => {
            micrositeImages.push(item.node);
            return micrositeImages;
          });
          user.setData({ company: { micrositeImages: micrositeImages } });
          setUser(user);
        }
      });
    }
  };

  /**
   * return
   */
  return (
    <>
      <CompanyDirectoryNameDialog
        formValues={formValues}
        setFormValues={setFormValues}
        verifyDialogOpen={verifyDialogOpen}
        setVerifyDialogOpen={setVerifyDialogOpen}
      />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        margin="normal"
        padding="normal"
        className={classes.gridContainer}
      >
        <CompanyInfoForm
          user={user}
          classes={classes}
          formValues={formValues}
          formValueErrors={formValueErrors}
          setFormValues={setFormValues}
          createFileFromUrl={createFileFromUrl}
          readFile={readFile}
          setCurrentFileObjects={setCurrentFileObjects}
          colorSchemes={colorSchemes}
          currentFileObjects={currentFileObjects}
        />
        <Grid item className={classes.gridItem}>
          <Button
            id="buttonFormSubmit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleFormSubmit}
            disabled={companyIsUpdating}
          >
            {t("account.profile.save.changes")}
            {companyIsUpdating && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          {loadingOverlayOpen &&
            UpdateCompanyDataDialog({
              setLoadingOverlayOpen,
              loadingOverlayOpen,
              t,
            })}
        </Grid>
      </Grid>
    </>
  );
};

export default withAccessControl(CompanyInfo, ACL_COMPONENTS.COMPANY_INFO);
