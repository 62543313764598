import React, { useContext } from 'react'
import GlobalContext from '../../lib/GlobalContext'
import { ACL_ACTIVE, ACL_COMPONENTS } from '../../config'
import { navigate } from 'hookrouter'
import Typography from '@material-ui/core/Typography'
import { Translation } from 'react-i18next'

const accessDeniedStyle = {
  margin: 20,
  paddingBottom: 10
}

const allTimeAvailableComponents = [
  'Login',
  'Onboarding',
  'OnboardingMarketing'
]

/**
 * Parse the permissions array from api to a single-dimensional array
 */
export const parsePermissions = permissionsFromAPI => {
  const permissionsArray = []
  if (Array.isArray(permissionsFromAPI)) {
    for (let i = 0; i < permissionsFromAPI.length; i++) {
      permissionsArray.push(permissionsFromAPI[i].Name)
    }
  }
  return permissionsArray
}

/**
 * AccessControl
 *
 * @param WrappedComponent
 * @param componentName
 * @param fallbackPath
 * @returns {*}
 * @constructor
 */
const withAccessControl = (
  WrappedComponent,
  componentName = null,
  fallbackPath = null
) => {
  return props => {
    const componentNameExists = Object.values(ACL_COMPONENTS).includes(
      componentName
    )

    /**
     * restrictAccessOutput
     *
     * @returns {*}
     */
    const restrictAccessOutput = () => {
      return (
        <Typography style={accessDeniedStyle}>
          <Translation>{t => t('general.label.access.denied')}</Translation>
        </Typography>
      )
    }

    if (!componentName) {
      console.error('componentName has to be set')
      restrictAccessOutput()
    }

    if (!componentNameExists) {
      console.error('componentName does not exists in ACL')
      restrictAccessOutput()
    }

    // Skip acl checking if not active
    if (!ACL_ACTIVE) {
      return <WrappedComponent {...props} />
    }

    // Start acl checking
    const { user } = useContext(GlobalContext)
    const userPermissions = user.getData().permissions
    const checkAccess = userPermissions.length > 0

    // Check users access to the component
    if (checkAccess) {
      // Initial access state
      let access = false

      // ACL check
      if (Array.isArray(userPermissions)) {
        // User need to have the correct access-rights to see the component
        if (userPermissions.indexOf(componentName) > -1) {
          access = true
        }
      }

      if (!access) {
        console.warn('Access denied to: ' + componentName)
        if (fallbackPath) {
          navigate('/' + fallbackPath)
        } else {
          return (
            <Typography style={accessDeniedStyle}>
              <Translation>{t => t('general.label.access.denied')}</Translation>
            </Typography>
          )
        }
      }
      return <WrappedComponent {...props} />
    } else {
      if (allTimeAvailableComponents.includes(componentName)) {
        return <WrappedComponent {...props} />
      } else {
        return (
          <Typography style={accessDeniedStyle}>
            <Translation>{t => t('general.label.access.denied')}</Translation>
          </Typography>
        )
      }
    }
  }
}

export default withAccessControl
