import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { List, ListItem, makeStyles, Paper, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS, isDev } from '../../config'
import { navigate, usePath } from 'hookrouter'
import { useQuery } from 'urql'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'
import { MESSAGE_TYPES } from '../PopupMessages'
// import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'

const queryReadMyself = `
  {
    readMyself {
      FulltextSearchRequests {
        ID
        Title
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  searchTextHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    position: 'relative',
    width: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: 288
    }
  },
  textField: {
    width: '100%',
    marginTop: 10,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
    '& > div': {
      marginTop: 0,
      borderRadius: 4,
      border: 0,
      background: 'rgba(255,255,255,0.2)',
      color: '#ffffff',
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      }
    },
    '& label': {
      display: 'none'
    },
    '& input': {
      paddingTop: 10,
      paddingRight: 50,
      paddingBottom: 10,
      paddingLeft: 50,
      background: 'transparent',
      '&::placeholder': {
        fontSize: 13
      }
    },
    '& fieldset': {
      border: 'none'
    },
    '& legend': {
      display: 'none'
    }
  },
  iconButtonStartHolder: {
    position: 'absolute',
    top: '50%',
    left: 10,
    transform: 'translate(0,-50%)',
    color: '#ffffff'
  },
  iconButtonCloseHolder: {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translate(0,-50%)',
    lineHeight: '1',
    cursor: 'pointer',
    color: '#ffffff'
  },
  autocompleteResultWrapper: {
    position: 'absolute',
    top: '100%',
    right: 0,
    width: '100%',
    textAlign: 'center',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      width: '100vw',
      maxWidth: 408
    }
  },
  autocompleteResult: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  autocompleteResultList: {
    padding: 0,
    '& li': {
      paddingTop: 20,
      paddingRight: 0,
      paddingBottom: 20,
      paddingLeft: 0,
      borderBottom: '1px solid #d3d3d3',
      '&:last-child': {
        borderBottom: 0
      }
    }
  },
  listResultHeader: {
    color: '#999'
  },
  listResultItems: {
    cursor: 'pointer'
  }
}))

let trigger = false

/**
 * LayoutHeaderSearchfield
 *
 * @returns {*}
 * @constructor
 */
const LayoutHeaderSearchfield = () => {
  const path = usePath()
  const classes = useStyles()
  const { t } = useTranslation()
  const [
    localProjectSearchParameter,
    setLocalProjectSearchParameter
  ] = useState('')
  const [searchHistoryOverlay, setSearchHistoryOverlay] = useState(false)
  const [searchHistoryContent, setSearchHistoryContent] = useState({})
  const {
    user,
    setMessage,
    unsetUser,
    token,
    unsetToken,
    projectSearchParameter,
    setProjectSearchParameter,
    removeProjectSearchParameter,
    setRemoveProjectSearchParameter,
    projectData
  } = useContext(GlobalContext)

  /* eslint-disable */
  const updateSearchHistoryContent = () => {
    const searchHistoryContent = {}
    const fulltextSearchRequests = user.getData().fulltextSearchRequests
    if (fulltextSearchRequests.length > 0) {
      for (let index = 0; index < fulltextSearchRequests.length; index++) {
        const fulltextSearchRequest = fulltextSearchRequests[index]
        searchHistoryContent[index] = fulltextSearchRequest.Title
      }
      setSearchHistoryContent(searchHistoryContent)
    }
  }
  /* eslint-enable */

  const [resultReadMyselfQuery, executeReadMyselfQuery] = useQuery({
    query: queryReadMyself,
    requestPolicy: 'network-only',
    pause: true
  })

  // Search history GraphQL trigger and result handling
  if (trigger && !resultReadMyselfQuery.fetching) {
    if (resultReadMyselfQuery.error) {
      // Check if the user need to be logged out
      if (
        resultReadMyselfQuery.error.message.indexOf('User forced logout') !== -1
      ) {
        setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
        unsetUser()
        unsetToken()
        navigate('/')
      } else {
        // The query did not return any results!
        ExternalErrorLogger.log({
          text: 'Error fetching myself on LayoutHeaderSearchfield',
          data: {
            token: JSON.stringify(token.getData()),
            user: JSON.stringify(user.getData()),
            errorMessage: resultReadMyselfQuery.error.message
          }
        })
      }
    } else {
      if (
        typeof resultReadMyselfQuery.data !== 'undefined' &&
        typeof resultReadMyselfQuery.data.readMyself !== 'undefined'
      ) {
        user.setData({
          fulltextSearchRequests:
            resultReadMyselfQuery.data.readMyself[0].FulltextSearchRequests
        })
      }
    }
    trigger = false
  }

  /* eslint-disable */
  useEffect(() => {
    if (removeProjectSearchParameter === true) {
      setLocalProjectSearchParameter('')
      setRemoveProjectSearchParameter(false)
    }
  }, [removeProjectSearchParameter])

  useEffect(() => {
    updateSearchHistoryContent()
  }, [user, user.getData().fulltextSearchRequests])

  useEffect(() => {
    if (
      typeof projectSearchParameter !== 'undefined' &&
      projectSearchParameter.length > 0
    ) {
      executeReadMyselfQuery()
      trigger = true
    }
  }, [projectData])
  /* eslint-enable */

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleSearchfieldChange = event => {
    if (event.key === 'Enter') {
      if (localProjectSearchParameter.length > 0 || isDev) {
        setSearchHistoryOverlay(false)
        setProjectSearchParameter(localProjectSearchParameter)
        if (path !== '/projects-search') {
          navigate('/projects-search')
        }
      }
    } else {
      setLocalProjectSearchParameter(event.target.value)
    }
  }

  const handleSearchTermSelect = (searchTermId, searchTermValue) => {
    setSearchHistoryOverlay(false)
    setLocalProjectSearchParameter(searchTermValue)
    setProjectSearchParameter(searchTermValue)
    if (path !== '/projects-search') {
      navigate('/projects-search')
    }
  }

  const renderSearchTermListElements = () => {
    const searchTermListElements = []
    const searchTermIds = Object.keys(searchHistoryContent)
    for (let index = 0; index < searchTermIds.length; index++) {
      const searchTermValue = searchHistoryContent[searchTermIds[index]]
      searchTermListElements.push(
        <ListItem
          className={classes.listResultItems}
          key={searchTermIds[index]}
          onClick={() => {
            handleSearchTermSelect(searchTermIds[index], searchTermValue)
          }}
        >
          {searchTermValue}
        </ListItem>
      )
    }
    return searchTermListElements
  }

  /**
   * return
   */
  return (
    <div
      onMouseLeave={() => setSearchHistoryOverlay(false)}
      className={classes.searchTextHolder}
    >
      <TextField
        id='inputSearchfield'
        className={clsx(classes.searchText, classes.textField)}
        // variant='outlined'
        size='small'
        align='left'
        label={t('form.label.search')}
        value={localProjectSearchParameter}
        placeholder={t('form.label.search')}
        onChange={handleSearchfieldChange}
        onKeyDown={handleSearchfieldChange}
        onFocus={() => setSearchHistoryOverlay(true)}
        onClick={() => setSearchHistoryOverlay(true)}
        margin='normal'
        InputProps={{
          startAdornment: (
            <InputAdornment
              position='start'
              className={classes.iconButtonStartHolder}
            >
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          )
        }}
      />
      <span
        className={classes.iconButtonCloseHolder}
        onClick={() => {
          setSearchHistoryOverlay(false)
          setLocalProjectSearchParameter('')
          setProjectSearchParameter('')
          if (path === '/projects-search') {
            navigate('/projects')
          }
        }}
      >
        {localProjectSearchParameter.length > 0 ? (
          <CloseIcon className={classes.icon} />
        ) : null}
      </span>
      {searchHistoryOverlay && Object.keys(searchHistoryContent).length > 0 && (
        <div className={classes.autocompleteResultWrapper}>
          <Paper className={classes.autocompleteResult}>
            <List className={classes.autocompleteResultList} dense>
              <ListItem className={classes.listResultHeader}>
                {t('form.list.last.search')}
              </ListItem>
              {renderSearchTermListElements()}
            </List>
          </Paper>
        </div>
      )}
    </div>
  )
}

export default withAccessControl(
  LayoutHeaderSearchfield,
  ACL_COMPONENTS.LAYOUT_HEADER_SEARCHFIELD
)
