import Joi from '@hapi/joi'

/**
 * Joi validation schema
 */
export const schema = Joi.object({
  id: Joi.number(),
  hashID: Joi.string().required(),
  branchCategory: Joi.number()
    .min(0)
    .allow(''),
  branchSubs: Joi.array().items(Joi.number()),
  otherBusinessActivities: Joi.array().items(Joi.number()),
  otherBusinessSpecialities: Joi.array().items(Joi.number())
})

export const queryBranches = `
  {
    readBusinessActivities (
      sortBy: [{field: Sort, direction: ASC}],
    ){
      ID,
      Title,
      NogaCode,
      BusinessSpecialities {
        edges {
            node {
            ID,
            Title
          }
        }
      }
    }
  }
`

export const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $businessActivityID: ID,
    $businessSpecialties: [BusinessSpecialityInput],
    $otherBusinessActivities: [BusinessActivityInput],
    $otherBusinessSpecialities: [BusinessSpecialityInput]
  ) {
    updateCompany(
      HashID: $hashId, 
      BusinessActivityID: $businessActivityID
      BusinessSpecialities: $businessSpecialties
      OtherBusinessActivities: $otherBusinessActivities
      OtherBusinessSpecialities: $otherBusinessSpecialities
    ) {
      BusinessActivityID
      BusinessSpecialities {
        edges {
          node {
            ID
            Title
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
    }
  }
`

export const initialFormValuesState = {
  id: '',
  branchCategory: 0,
  branchSubs: [],
  otherBusinessActivities: [],
  otherBusinessSpecialities: []
}

export const initialFormValueErrorsState = {
  id: { hasError: false, message: '' },
  branchCategory: { hasError: false, message: '' }
}

export const LOADING_OVERLAY_TIMEOUT = 5000
