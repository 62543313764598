import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS } from '../../config'
import { useQuery } from 'urql'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../../lib/GlobalContext'
import { makeStyles } from '@material-ui/core'
import { MESSAGE_TYPES } from '../PopupMessages'
import { navigate } from 'hookrouter'
import Grid from '@material-ui/core/Grid'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingTop: 20
  },
  gridItem: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      padding: '0 20px'
    },
    '@media (max-width:768px)': {
      width: '100%',
      padding: '0 20px'
    }
  },
  gridItemHeader: {
    display: 'block',
    paddingTop: 60,
    paddingBottom: 25
  },
  icon: {
    fontSize: 24,
    color: '#999'
  },
  listItem: {
    position: 'relative',
    paddingTop: 20,
    paddingBottom: 20,
    '&::before': {
      position: 'absolute',
      top: 0,
      right: 16,
      left: 72,
      height: 1,
      background: '#d3d3d3',
      content: '" "'
    },
    '&:first-of-type': {
      '&::before': {
        display: 'none'
      }
    }
  },
  listItemText: {
    display: 'inline-block'
  },
  listItemLink: {
    color: theme.palette.primary.main
  },
  listItemDate: {
    display: 'block',
    color: theme.palette.text.primary,
    fontSize: 14
  },
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const queryInvoices = `
 {
    readMyselfInvoices {
      id
      created
      invoice_pdf
      hosted_invoice_url
      period_end
      period_start
  }
 }
`

const AccountInvoices = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    setMessage,
    unsetUser,
    unsetToken,
    user
  } = useContext(GlobalContext)
  const [invoices, setInvoices] = useState(false)

  // GraphQL query for branches, gets triggered by calling "executeInvoicesQuery()"
  const [result, executeInvoicesQuery] = useQuery({
    query: queryInvoices,
    variables: {},
    requestPolicy: 'network-only',
    pause: true
  })

  /**
   * useEffect
   */
  useEffect(() => {
    executeInvoicesQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        if (result.error.message.indexOf('User forced logout') !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else if (result.error.message.includes('No upcoming')) {
          if (!invoices) {
            setInvoices([])
          }
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: 'Error fetching invoices on AccountInvoices',
            data: {
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message
            }
          })
          return <Typography>{t('error.general')}</Typography>
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== 'undefined' &&
          typeof result.data.readMyselfInvoices !== 'undefined' &&
          !invoices
        ) {
          setInvoices(result.data.readMyselfInvoices)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * getInvoiceList
   *
   * @returns {[]|*}
   */
  const getInvoiceList = () => {
    const invoiceList = []

    if (invoices.length > 0) {
      for (let i = 0; i < invoices.length; i++) {
        invoiceList.push(
          <ListItem key={invoices[i].id} className={classes.listItem}>
            <ListItemIcon>
              <DescriptionOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              <a
                href={invoices[i].hosted_invoice_url}
                target='_new'
                className={classes.listItemLink}
                alt={t('account.label.invoice')}
                title={t('account.label.invoice')}
              >
                {t('account.label.invoice')}{' '}
                {moment.unix(invoices[i].period_start).format('MM/YYYY')}
              </a>
              <span className={classes.listItemDate}>
                {moment.unix(invoices[i].created).format('DD.MM.YYYY')}
              </span>
            </ListItemText>
          </ListItem>
        )
      }
    } else {
      return (
        <ListItem key='noinvoice' className={classes.listItem}>
          <ListItemText className={clsx(classes.listItemText)}>
            {invoices && !result.fetching && t('account.label.noinvoice')}
            {(!invoices || result.fetching) && (
              <CircularProgress size={36} className={classes.loadingProgress} />
            )}
          </ListItemText>
        </ListItem>
      )
    }
    return invoiceList
  }

  /**
   * return
   */
  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      margin='normal'
      padding='normal'
      className={classes.gridContainer}
    >
      {invoices.length !== 0 && (
        <Grid item className={clsx(classes.gridItem)}>
          <Typography className={classes.gridItemHeader}>
            {t('account.label.invoices.description')}
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.gridItem}>
        <List component='ul'>{getInvoiceList()}</List>
      </Grid>
    </Grid>
  )
}

export default withAccessControl(
  AccountInvoices,
  ACL_COMPONENTS.ACCOUNT_INVOICES
)
