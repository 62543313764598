import React, { useContext, useEffect, useState } from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import { useQuery } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import GlobalContext from "../../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import GetSafe from "../../lib/GetSafe";
import TextField from "@material-ui/core/TextField/TextField";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  buttonNext: {
    width: "100%",
    color: "#fff",
    marginRight: "auto",
    marginBottom: 20,
    fontWeight: "bold",
  },
  cardHolder: {
    width: "100%",
    margin: "20px 0",
    background: "#fff",
    borderRadius: "4px",
    border: "1px solid #D3D3D3",
  },
  cardElement: {
    display: "inline-block",
    width: "auto",
    marginLeft: 0,
    padding: "0",
    color: "#a6a6a6",
    fontSize: 12,
    textAlign: "left",
  },
  cardItem: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  stripeElement: {
    display: "block",
    margin: 0,
    maxWidth: "100%",
    padding: "10px 0",
    boxShadow: 0,
    border: 0,
    outline: 0,
    borderRadius: 4,
    background: "#fff",
  },
  InputElement: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
}));

const queryStripeSetupIntent = `
  {
    readStripeSetupIntent {
      client_secret
    }
  }
`;

/**
 * CreditCardForm
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CreditCardForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setMessage,
    setPaymentMethodId,
    user,
    unsetUser,
    token,
    unsetToken,
  } = useContext(GlobalContext);

  const [formValues, setFormValues] = useState({
    cardHolder: "",
  });

  const [stripeSubmitClicked, setStripeSubmitClicked] = useState(false);

  const [formValueErrors, setFormValueErrors] = useState({
    cardHolder: { hasError: false, message: "" },
  });

  const [result, executeQueryStripeSetupIntent] = useQuery({
    query: queryStripeSetupIntent,
    pause: true,
  });

  /**
   * useEffect
   */
  useEffect(() => {
    setFormValues({
      cardHolder: user.getData().firstName + " " + user.getData().lastName,
    });
    setFormValueErrors({ cardHolder: { hasError: false, message: "" } });

    executeQueryStripeSetupIntent();
  }, [executeQueryStripeSetupIntent, user]);

  /**
   * handleCardHolderChange
   *
   * @param event
   */
  const handleCardHolderChange = (event) => {
    setFormValueErrors({
      cardHolder: { hasError: false, message: "" },
    });
    setFormValues({ ...formValues, cardHolder: event.target.value });
  };

  /**
   * handleSubmit
   */
  const handleSubmit = () => {
    setStripeSubmitClicked(true);

    if (user.getData().creditCard.id) {
      setPaymentMethodId(user.getData().creditCard.id);
    } else {
      if (formValues.cardHolder === "") {
        setFormValueErrors({
          cardHolder: { hasError: true, message: t("error.form.required") },
        });
        setStripeSubmitClicked(false);
      }

      if (!result.fetching) {
        if (result.error) {
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.setupintent"));
            setStripeSubmitClicked(false);
            ExternalErrorLogger.log({
              text: "Error fetching setupintent on CreditCardForm",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          if (
            result.data &&
            typeof result.data !== "undefined" &&
            typeof result.data.readStripeSetupIntent !== "undefined" &&
            result.data.readStripeSetupIntent &&
            typeof result.data.readStripeSetupIntent.client_secret !==
              "undefined"
          ) {
            // See our getElement documentation for more:
            // https://stripe.com/docs/stripe-js/reference#elements-get-element
            // const cardElement = props.elements.getElement('cardNumber')
            const cardElement = props.elements.getElement("card");
            const cardholderName = document.getElementById("cardholder-name");
            // See our createPaymentMethod documentation for more:
            // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
            props.stripe
              .createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                  name: cardholderName.value,
                },
              })
              .then((paymentResult) => {
                if (paymentResult.error) {
                  switch (paymentResult.error.code) {
                    case "incomplete_number":
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.payment.incomplete_number")
                      );
                      break;
                    case "incomplete_expiry":
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.payment.incomplete_expiry")
                      );
                      break;
                    case "incomplete_cvc":
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.payment.incomplete_cvc")
                      );
                      break;
                    case "incomplete_zip":
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.payment.incomplete_zip")
                      );
                      break;
                    case "invalid_expiry_year":
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.payment.invalid_expiry_year")
                      );
                      break;
                    case "invalid_expiry_year_past":
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.payment.invalid_expiry_year_past")
                      );
                      break;
                    default:
                      setMessage(
                        MESSAGE_TYPES.ERROR,
                        t("error.save.creditcard")
                      );
                      ExternalErrorLogger.log({
                        text: "Error create stripe payment method on CreditCardForm",
                        data: {
                          token: JSON.stringify(token.getData()),
                          user: JSON.stringify(user.getData()),
                          errorMessage: JSON.stringify(paymentResult.error),
                        },
                      });
                  }
                  setStripeSubmitClicked(false);
                } else {
                  // See our confirmCardSetup documentation for more:
                  // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-setup
                  props.stripe
                    .confirmCardSetup(
                      result.data.readStripeSetupIntent.client_secret,
                      {
                        payment_method: {
                          card: cardElement,
                        },
                      }
                    )
                    .then((paymentConfirmResult) => {
                      if (paymentConfirmResult.error) {
                        setMessage(
                          MESSAGE_TYPES.ERROR,
                          t("error.save.creditcard")
                        );
                        setStripeSubmitClicked(false);
                        ExternalErrorLogger.log({
                          text: "Error save creditcard on CreditCardForm",
                          data: {
                            token: JSON.stringify(token.getData()),
                            user: JSON.stringify(user.getData()),
                            errorMessage: JSON.stringify(
                              paymentConfirmResult.error
                            ),
                          },
                        });
                      } else {
                        setPaymentMethodId(paymentResult.paymentMethod.id);
                      }
                    });
                }
              });
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.setupintent"));
            setStripeSubmitClicked(false);
            ExternalErrorLogger.log({
              text: "Error fetching setupintent on CreditCardForm",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: JSON.stringify(result.error),
              },
            });
          }
        }
      }
    }
  };

  /**
   * return
   */
  return (
    <>
      {user.getData().creditCard.id ? (
        <>
          <div className={classes.cardElement}>
            {t("form.label.cardHolder")}
            <p>{user.getData().creditCard.name}</p>
          </div>
          <div className={classes.cardElement}>
            {t("form.label.cardDetails")}
            <p>**** **** **** {user.getData().creditCard.last4}</p>
          </div>
        </>
      ) : (
        <>
          <TextField
            id="cardholder-name"
            className={classes.textField}
            label={t("form.label.cardHolder")}
            align="left"
            value={formValues.cardHolder}
            error={GetSafe(() => formValueErrors.cardHolder.hasError, false)}
            helperText={GetSafe(() => formValueErrors.cardHolder.message, "")}
            onChange={handleCardHolderChange}
            margin="normal"
            variant="outlined"
          />
          <fieldset className={classes.cardHolder}>
            <legend className={classes.cardElement}>
              <span className={classes.cardItem}>
                {t("form.label.cardDetails")}
              </span>
            </legend>
            <label>
              <CardElement className={classes.stripeElement} />
            </label>
          </fieldset>
        </>
      )}

      <Button
        id="buttonFormNext"
        variant="contained"
        color="primary"
        align="left"
        className={classes.buttonNext}
        onClick={handleSubmit}
        disabled={
          stripeSubmitClicked || props.submitIsDisabled || result.fetching
        }
      >
        {t("general.label.premium.buy")}
      </Button>
    </>
  );
};

export default injectStripe(CreditCardForm);
