import { STRIPE_PLAN_IDS } from '../config'

/**
 * StripePlanIdToNameConverter
 *
 * @param stripePlanId
 * @returns {string}
 * @constructor
 */
const StripePlanIdToNameConverter = stripePlanId => {
  if (stripePlanId.includes('gratis')) {
    return 'free'
  } else if (
    stripePlanId.includes('smart') ||
    stripePlanId.includes(STRIPE_PLAN_IDS.SMART.YEARLY)
  ) {
    return 'smart'
  } else if (
    stripePlanId.includes('pro') ||
    stripePlanId.includes(STRIPE_PLAN_IDS.PRO.YEARLY)
  ) {
    return 'pro'
  }
}

export default StripePlanIdToNameConverter
