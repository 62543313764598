import Joi from '@hapi/joi'

export const LOADING_OVERLAY_TIMEOUT = 5000

export const initialFormValuesState = {
  directoryName: '',
  employeeCount: '',
  legalForm: 0,
  foundingYear: '',
  masterOperation: '',
  trainingCompany: '',
  colorScheme: '',
  memberships: [],
  furtherMemberships: '',
  micrositeColorScheme: '',
  micrositeDescription: '',
  micrositeEmailApplicationText: '',
  micrositeLogo: { Alt: '', Src: '', Title: '' },
  micrositeImages: [],
  additionalMemberships: ''
}

export const initialFormValueErrorsState = {
  directoryName: { hasError: false, message: '' },
  employeeCount: { hasError: false, message: '' },
  legalForm: { hasError: false, message: '' },
  foundingYear: { hasError: false, message: '' },
  masterOperation: { hasError: false, message: '' },
  trainingCompany: { hasError: false, message: '' },
  colorScheme: { hasError: false, message: '' },
  furtherMemberships: { hasError: false, message: '' },
  micrositeColorScheme: { hasError: false, message: '' },
  micrositeDescription: { hasError: false, message: '' },
  micrositeEmailApplicationText: { hasError: false, message: '' },
  micrositeImages: { hasError: false, message: '' },
  additionalMemberships: { hasError: false, message: '' }
}

/**
 * Joi validation schema
 */
export const schema = Joi.object({
  hashID: Joi.string().required(),
  employeeCount: Joi.number()
    .integer()
    .allow(0)
    .required(),
  legalForm: Joi.number()
    .min(1)
    .required(),
  foundingYear: Joi.string().required(),
  masterOperation: Joi.string().allow(''),
  trainingCompany: Joi.string().allow(''),
  memberships: Joi.array(),
  micrositeDescription: Joi.string().allow(''),
  micrositeImages: Joi.array(),
  micrositeLogo: Joi.object(),
  additionalMemberships: Joi.string().allow(''),
  micrositeEmailApplicationText: Joi.string().allow(''),
  micrositeColorScheme: Joi.string().allow('')
})

/**
 * createFileFromUrl
 *
 * @param url
 * @returns {Promise<File>}
 */
export const createFileFromUrl = async url => {
  const response = await window.fetch(url)
  const data = await response.blob()
  const metadata = { type: data.type }
  const filename = url
    .replace(/\?.+/, '')
    .split('/')
    .pop()
  return new window.File([data], filename, metadata)
}

/**
 * readFile
 *
 * @param file
 * @returns {Promise<unknown>}
 */
export const readFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.onload = event => {
      if (event && event.target) {
        resolve(event.target.result)
      }
    }
    reader.onerror = event => {
      reader.abort()
      reject(event)
    }
    reader.readAsDataURL(file)
  })
}

/**
 * formatImagesArray
 *
 * @returns {[]}
 */
export const formatImagesArray = formValues => {
  const formattedImagesArray = []
  formValues.micrositeImages.map(item => {
    if (item.data) {
      formattedImagesArray.push({
        Src: item.data,
        Title: item.file.name,
        Alt: item.file.name
      })
    } else {
      formattedImagesArray.push(item)
    }
    return item
  })

  return formattedImagesArray
}

export const querySmartconextConf = `
  {
    readSmartconextConf {
      microsite_color_schemes {
        Code
        Name
      }
    }
  }
`

export const updateCompanyMutation = `
mutation UpdateCompany(
  $hashId: String!,
  $numberOfEmployees: String!,
  $legalForm: String!,
  $foundingYear: String!,
  $masterBusiness: String!,
  $trainingCompany: String!,
  $memberships: [MembershipInput],
  $description: String,
  $colorScheme: String,
  $images: [MicrositeImageInput],
  $logo: MicrositeLogoInput,
  $additionalMemberships: String,
  $emailApplicationText: String
) {
  updateCompany(
    HashID: $hashId,
    NumberOfEmployees: $numberOfEmployees,
    LegalForm: $legalForm,
    FoundingYear: $foundingYear,
    MasterBusiness: $masterBusiness,
    TrainingCompany: $trainingCompany,
    Memberships: $memberships,
    MicrositeDescription: $description,
    MicrositeColorScheme: $colorScheme,
    MicrositeImages: $images,
    MicrositeLogo: $logo,
    AdditionalMemberships: $additionalMemberships,
    MicrositeEmailApplicationText: $emailApplicationText
  ) {
    NumberOfEmployees
    LegalForm
    FoundingYear
    MasterBusiness
    TrainingCompany
    MicrositeDescription
    MicrositeColorScheme
    AdditionalMemberships
    MicrositeEmailApplicationText
    MicrositeLogo {
      Src
      Title
      Alt
    }
    MicrositeImages {
      edges {
        node {
          Src
          Title
          Alt
        }
      }
    }
  }
}
`
