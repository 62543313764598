import React, { useContext, useState, useEffect /* , useMemo */ } from 'react'
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker
} from '@react-google-maps/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import GlobalContext from './GlobalContext'
import { GOOGLE_API_KEY } from '../config'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

/**
 * useStyles
 */
const useStyles = makeStyles(() => ({
  progress: {
    color: green[500],
    top: '50%',
    left: '50%'
  }
}))

/**
 * GoogleMapComponent
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const GoogleMapComponent = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { googleMapsIsLoaded, googleMapsLoadError } = useContext(GlobalContext)
  const [response, setResponse] = useState(null)
  const [coordinates, setCoordinates] = useState({
    lat: props.origin.lat,
    lon: props.origin.lon
  })

  /**
   * useEffect
   */
  useEffect(() => {
    const fetchCoords = async () => {
      const response = await window.fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${props.location}&region=ch&key=${GOOGLE_API_KEY}`
      )

      return response.json()
    }

    if (coordinates.lat === null || coordinates.lon === null) {
      fetchCoords().then(result => {
        setCoordinates({
          lat: result.results[0].geometry.location.lat,
          lon: result.results[0].geometry.location.lng
        })
      })
    }
  }, [coordinates, props.location])

  /**
   * directionsCallback
   *
   * @param MapResponse
   */
  const directionsCallback = MapResponse => {
    if (response === null && MapResponse) {
      if (MapResponse.status === 'OK') {
        setResponse(MapResponse)
      } else {
        ExternalErrorLogger.log({
          text: 'Error in directionsCallback of GoogleMapComponent',
          data: {
            response: MapResponse
          }
        })
      }
    }
  }

  /**
   * render the google map, show spinner or handle errors
   */
  const renderMap = () => {
    if (googleMapsLoadError) {
      return <div>{t('error.loading.map')}</div>
    }

    if (
      googleMapsIsLoaded &&
      (coordinates.lat !== null || coordinates.lon !== null)
    ) {
      if (typeof props.destination !== 'undefined') {
        return (
          <GoogleMap
            mapContainerStyle={{ height: '140px' }}
            center={{
              lat: parseFloat(coordinates.lat),
              lng: parseFloat(coordinates.lon)
            }}
            zoom={8}
          >
            <DirectionsService
              // required
              options={{
                destination: `${props.destination.lat}, ${props.destination.lon}`,
                origin: `${coordinates.lat}, ${coordinates.lon}`,
                travelMode: 'DRIVING'
              }}
              // required
              callback={directionsCallback}
            />
            {response !== null && (
              <DirectionsRenderer
                // required
                options={{
                  directions: response
                }}
              />
            )}
          </GoogleMap>
        )
      }

      const position = {
        lat: parseFloat(coordinates.lat),
        lng: parseFloat(coordinates.lon)
      }

      return (
        <GoogleMap
          mapContainerStyle={{ height: '140px' }}
          center={position}
          zoom={8}
        >
          <Marker position={position} />
        </GoogleMap>
      )
    }

    return <CircularProgress className={classes.progress} />
  }

  // TODO: set back to useMemo when tested with API-Data
  return renderMap()
  // /**
  //  * return
  //  */
  // return useMemo(() => {
  //   return renderMap()
  //   // eslint-disable-next-line
  // }, [response])
}

export default GoogleMapComponent
