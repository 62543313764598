import React, { useContext, useEffect } from "react";
import { useQuery } from "urql";
import GlobalContext from "../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import UserTypes from "../components/Onboarding/UserTypes";
import OnboardingFreeUserWelcomeDialog from "../components/Onboarding/OnboardingFreeUserWelcomeDialog";
import OnboardingPremiumUserWelcomeDialog from "../components/Onboarding/OnboardingPremiumUserWelcomeDialog";
import { navigate } from "hookrouter";
import withAccessControl from "../components/HOC/AccessControl";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { ACL_COMPONENTS } from "../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import FormatHelper from "../lib/FormatHelper";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dialog: {
    width: "100%",
    paddingBottom: 56,
    "&::before": theme.watermarkLogo,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
}));

/**
 * queryMyself
 *
 * @type {string}
 */
const queryMyself = `
{
  readMyself {
    ID
    HashID
    Locale
    Email
    Firstname
    Surname
    Salutation
    StripePublicKey
    CreditCard {
      id
      name
      last4
      expired
    }
    Locations {
      edges {
        node {
          HashID
          Title
          Street
          Zip
          City
          Longitude
          Latitude
          Radius
          Version
          Sort
          Removed
        }
      }
    }
    Company {
      ID
      AdminUserID
      HashID
      FeatureSet
      OnboardingType
      ZipSearchAreas
      ZipSearchStatistic
      ApplicationFollowUpTimeInDays
      StripeIsTrialing
      StripeTrialingDays
      StripePlanID
      StripeValidThrough
      StripeProductID
      StripeCancelAt
      Name
      URLSegment
      ContactPerson
      Street
      Zip
      City
      Canton
      Distance
      Latitude
      Longitude
      Phone
      Mobile
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
      FoundingYear
      MasterBusiness
      TrainingCompany
      BusinessActivityID
      BusinessSpecialities{
        edges {
          node {
            ID
            Title
            NogaCode
            SCCodeCompetence
            BoostScore
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
      User{
        edges {
          node {
            HashID
            Email
            Firstname
            Surname
            Removed
            SearchAreas {
              HashID
              Title
              ZipList
            }
          }
        }
      }
      Memberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      AllMemberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      MicrositeCertified
      MicrositeCertifiedUntil
      AdditionalMemberships
      MicrositeColorScheme
      MicrositeDescription
      MicrositeEmailApplicationText
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeLogo {
        Src
        Title
        Alt
      }
      MicrositeReferences {
          edges {
            node {
              Sort
              Title
              Content
              Location
              Year
              Month
              ButtonLink
              ButtonTitle
              MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
            }
          }
        }
        ApiAccess {
          edges {
            node {
              RequestUrl
            }
          } 
        }  
    }
    DossierTemplates {
      edges {
        node {
          HashID
          TemplateName
        }
      }
    }
    Permissions {
      Name
      RequireAdminStatus
    }
    FulltextSearchRequests {
      ID
      Title
    }
    SearchAreas {
      HashID
      Title
      ZipList
    }
  }
}
`;

/**
 * Onboarding
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Onboarding = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    openWelcomeDialog,
    token,
    upgradeToPremiumClose,
    setUpgradeToPremiumClose,
    upgradeToPremiumOpen,
    setUpgradeToPremiumOpen,
    setMessage,
    unsetUser,
    unsetToken,
    setUser,
  } = useContext(GlobalContext);

  /**
   * GraphQL query for user, gets triggered by calling "executeUsersQuery()"
   */
  const [result] = useQuery({
    query: queryMyself,
  });

  /**
   * Set the refresh-token to enable the ability
   * to fetch a token for the first time or on demand
   */
  useEffect(() => {
    if (!token.isSet()) {
      token.setRefreshToken(props.refreshToken);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable */
  useEffect(() => {
    if (
      props.id.length > 0 &&
      user.isSet() &&
      props.id !== user.getData().hashID
    ) {
      unsetUser();
    }
  }, [props.id, user]);
  /* eslint-enable */

  useEffect(() => {
    // After the upgrade-to-premium process redirect to start-page
    if (user.isSet() && upgradeToPremiumClose && !openWelcomeDialog) {
      navigate("/");
    }
  }, [user, upgradeToPremiumClose, openWelcomeDialog]);

  /**
   * Parse the permissions array from api to a single-dimensional array
   */
  const parsePermissions = (permissionsFromAPI) => {
    const permissionsArray = [];
    if (Array.isArray(permissionsFromAPI)) {
      for (let i = 0; i < permissionsFromAPI.length; i++) {
        permissionsArray.push(permissionsFromAPI[i].Name);
      }
    }
    return permissionsArray;
  };

  /**
   * determineMode
   *
   * @param featureSet
   * @param onboardingType
   * @returns {string}
   */
  const determineMode = (featureSet, onboardingType) => {
    if (featureSet === null) {
      if (onboardingType === null) {
        return UserTypes.FREE;
      } else {
        return onboardingType.toLocaleLowerCase();
      }
    } else {
      return featureSet.toLocaleLowerCase();
    }
  };

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // Something went very wrong
          ExternalErrorLogger.log({
            text: "Error fetching myself on Onboarding",
            data: {
              user: user.getData(),
              token: token.getData(),
              errorMessage: result.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.user"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readMyself !== "undefined" &&
          !user.isSet()
        ) {
          const branchSubsData = [];
          result.data.readMyself[0].Company.BusinessSpecialities.edges.map(
            (item) => {
              branchSubsData.push(parseInt(item.node.ID));
              return branchSubsData;
            }
          );

          const memberships = [];
          result.data.readMyself[0].Company.Memberships.edges.map((item) => {
            memberships.push(item.node);
            return memberships;
          });

          const allMemberships = [];
          result.data.readMyself[0].Company.AllMemberships.edges.map((item) => {
            allMemberships.push(item.node);
            return allMemberships;
          });

          const locationData = [];
          result.data.readMyself[0].Locations.edges.map((item) => {
            const constructionSums = [];
            item.node.ConstructionSumList.edges.map((constructionSum) => {
              constructionSums.push(constructionSum.node.Code);
              return constructionSums;
            });

            item.node.ConstructionSums = constructionSums;

            locationData.push(item.node);
            return locationData;
          });

          locationData.sort((a, b) => {
            return a.Sort - b.Sort;
          });

          const companyUsersData = [];
          result.data.readMyself[0].Company.User.edges.map((item) => {
            if (item.node.HashID !== result.data.readMyself[0].HashID) {
              companyUsersData.push(item.node);
            }
            return companyUsersData;
          });

          const dossierTemplatesData = [];
          result.data.readMyself[0].DossierTemplates.edges.map((item) => {
            dossierTemplatesData.push(item.node);
            return dossierTemplatesData;
          });

          companyUsersData.sort((a, b) => {
            if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
              return -1;
            }
            if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          // Map the user-data
          user.setData({
            id: result.data.readMyself[0].ID,
            hashID: result.data.readMyself[0].HashID,
            locale: result.data.readMyself[0].Locale || "",
            email: result.data.readMyself[0].Email,
            firstName: result.data.readMyself[0].Firstname || "",
            lastName: result.data.readMyself[0].Surname || "",
            mode: determineMode(
              result.data.readMyself[0].Company.FeatureSet,
              result.data.readMyself[0].Company.OnboardingType
            ),
            stripePublicKey: result.data.readMyself[0].StripePublicKey || "",
            creditCard: result.data.readMyself[0].CreditCard,
            company: {
              id: result.data.readMyself[0].Company.ID,
              hashID: result.data.readMyself[0].Company.HashID,
              adminUserID: result.data.readMyself[0].Company.AdminUserID,
              name:
                result.data.readMyself[0].Company.Name || "TODO COMPANY-NAME", // TODO: Remove fallback company name for production (requires consistent data)
              street: result.data.readMyself[0].Company.Street || "",
              zip: result.data.readMyself[0].Company.Zip || "",
              city: result.data.readMyself[0].Company.City || "",
              canton: result.data.readMyself[0].Company.Canton || "",
              distance: result.data.readMyself[0].Company.Distance || 50,
              latitude: result.data.readMyself[0].Company.Latitude || null,
              longitude: result.data.readMyself[0].Company.Longitude || null,
              phone: result.data.readMyself[0].Company.Phone || "",
              mobile: result.data.readMyself[0].Company.Mobile || "",
              fax: result.data.readMyself[0].Company.Fax || "",
              email: result.data.readMyself[0].Company.Email || "",
              website: result.data.readMyself[0].Company.Website || "",
              employeeCount:
                result.data.readMyself[0].Company.NumberOfEmployees || "",
              legalForm: result.data.readMyself[0].Company.LegalForm || "",
              foundingYear:
                result.data.readMyself[0].Company.FoundingYear || "",
              masterOperation:
                FormatHelper.boolToString(
                  result.data.readMyself[0].Company.MasterBusiness
                ) || "",
              trainingCompany:
                FormatHelper.boolToString(
                  result.data.readMyself[0].Company.TrainingCompany
                ) || "",
              branchCategory:
                result.data.readMyself[0].Company.BusinessActivityID || "",
              branchSubs: branchSubsData || [],
              users: companyUsersData || [],
              stripeIsTrialing:
                result.data.readMyself[0].Company.StripeIsTrialing,
              stripeTrialingDays:
                result.data.readMyself[0].Company.StripeTrialingDays,
              stripePlanId: result.data.readMyself[0].Company.StripePlanID,
              stripeValidThrough:
                result.data.readMyself[0].Company.StripeValidThrough,
              stripeProductId:
                result.data.readMyself[0].Company.StripeProductID,
              stripeCancelAt: result.data.readMyself[0].Company.StripeCancelAt,
              stripeFuturePlans:
                result.data.readMyself[0].Company.StripeFuturePlans,
              memberships: memberships,
              allMemberships: allMemberships,
              zipSearchAreas: result.data.readMyself[0].Company.ZipSearchAreas,
              zipSearchStatistic: result.data.readMyself[0].Company.ZipSearchStatistic,
              applicationFollowUpTimeInDays: result.data.readMyself[0].Company.ApplicationFollowUpTimeInDays
            },
            dossierTemplates: dossierTemplatesData,
            permissions: parsePermissions(
              result.data.readMyself[0].Permissions
            ),
            fulltextSearchRequests:
              result.data.readMyself[0].FulltextSearchRequests,
            searchAreas: result.data.readMyself[0].SearchAreas,
          });
        }

        setUser(user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  /**
   * LoadingIndicator
   */
  const LoadingIndicator = (
    <Grid item>
      <CircularProgress />
    </Grid>
  );

  /**
   * UserNotFoundDialog
   */
  const UserNotFoundDialog = (
    <Grid item>
      <div>{t("error.fetching.user")}</div>
    </Grid>
  );

  /**
   * getDialogByUserMode
   *
   * @returns {*}
   */
  const getDialogByUserMode = () => {
    switch (user.getData().mode) {
      case null:
      case UserTypes.FREE:
        if (!upgradeToPremiumClose) {
          setUpgradeToPremiumClose(true);
        }
        return <OnboardingFreeUserWelcomeDialog />;
      case UserTypes.SMART:
        return (
          <OnboardingPremiumUserWelcomeDialog
            upgradeToPremiumOpen={upgradeToPremiumOpen}
            setUpgradeToPremiumOpen={setUpgradeToPremiumOpen}
          />
        );
      case UserTypes.PRO:
        return (
          <OnboardingPremiumUserWelcomeDialog
            upgradeToPremiumOpen={upgradeToPremiumOpen}
            setUpgradeToPremiumOpen={setUpgradeToPremiumOpen}
          />
        );
      default:
        return <div>{t("error.general")}</div>;
    }
  };

  /**
   * return
   */
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        {result.fetching && LoadingIndicator}
      </Grid>
      {/* {console.log('MOEP', user.getData())} */}
      {!result.fetching && (
        <Dialog
          fullWidth
          maxWidth="md"
          className={classes.root}
          open={openWelcomeDialog}
        >
          <DialogContent className={classes.dialog}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              {user.isSet() && getDialogByUserMode()}
              {!user.isSet() && UserNotFoundDialog}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default withAccessControl(Onboarding, ACL_COMPONENTS.ONBOARDING);
