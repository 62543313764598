import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import GetSafe from '../lib/GetSafe'
import FormatHelper from '../lib/FormatHelper'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import GoogleMapComponent from '../lib/GoogleMapComponent'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Info from '@material-ui/icons/Info'
import CustomSCDetailAddition from '../components/CustomSC/CustomSCDetailAddition'

const props = {
  data: {
    HashID: 'ac77e49d7202bd4afba29339d2fc3bad09806bc4',
    Final_title:
      'Böschungssicherung mittels Steinkörben, Sihlstrasse, 6313 Menzingen',
    Date: '2021-10-29T00:00:00+02:00',
    Canton: 'ZG',
    Content:
      'Einwohnergemeinde Menzingen, Rathaus/Postfach, 6313 Menzingen: Böschungssicherung mittels Steinkörben, Sihlstrasse, 6313 Menzingen, Assek.-Nr. -, GS-Nr. 239, GS-Nr. 213. Ausserhalb der Bauzone, Koordinaten 1226320/2687930. Einsprachefrist bis und mit 17. November 2021. Die Profile sind erstellt',
    Calculated_distance: null,
    Distance_calculated_from: null,
    Read: null,
    Construction_site: {
      Address: {
        Street: '',
        Number: '',
        Zip: '',
        City: 'Menzingen',
        Geo: {
          lat: 47.1822236,
          lon: 8.5980911
        }
      }
    },
    Building_owner: {
      Name: 'Einwohnergemeinde Menzingen, Rathaus/Postfach',
      Address: {
        Street: '',
        Number: '',
        Zip: '6313',
        City: 'Menzingen',
        Geo: {
          lat: 47.1758438,
          lon: 8.5919634
        }
      }
    },
    Project_management: {
      Name: '',
      Phone: '',
      Email: '',
      Website: '',
      Email_other: [],
      Address: {
        Street: '',
        Number: '',
        Zip: '',
        City: '',
        Geo: {
          lat: 39.889037,
          lon: -105.9564261
        }
      }
    }
  },
  originCoordinates: {
    lat: 47.378441,
    lon: 8.510033
  },
  destination: {
    lat: 47.2428467,
    lon: 8.7960707
  },
  location: 'Zürich'
}

const useStyles = makeStyles(theme => ({
  removed: {
    opacity: 0.5
  },
  undo: {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  link: {
    cursor: 'pointer'
  },
  icon: {
    padding: 8
  },
  tableCellColumnsbody: {
    // width: 'calc(100% * 1 / 4)',
    minWidth: 120,
    padding: 14,
    '&:nth-child(1)': {
      minWidth: 215
    },
    '&:nth-child(2)': {
      minWidth: 130
    },
    '&:nth-child(3)': {
      minWidth: 130
    },
    '@media (max-width:640px)': {
      '&:first-of-type': {
        minWidth: 'auto'
      },
      '&:nth-child(2)': {
        minWidth: 320
      }
    }
  },
  toggleTitle: {
    color: '#999',
    fontWeight: 'bold'
  },
  toggleTableCell: {
    paddingTop: 0,
    verticalAlign: 'top'
  },
  toggleTableCellMap: {},
  tableInnerRow: {
    borderTop: '2px solid #fff'
  },
  pointer: {
    cursor: 'pointer'
  },
  tooltipItem: {
    width: '100%',
    maxWidth: 350,
    borderRadius: 5,
    background: '#006A7A',
    fontSize: 16,
    textAlign: 'center',
    '& div': {
      margin: 0,
      color: '#fff'
    }
  },
  tooltipInfo: {
    maxWidth: '100%',
    background: theme.palette.background.default,
    '& div': {
      maxWidth: '100%',
      color: theme.palette.text.primary,
      fontSize: 12,
      lineHeight: '20px'
    },
    '@media (min-width:768px)': {
      maxWidth: 450
    }
  },
  tooltipTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  headerWrapper: {
    width: '100%',
    maxWidth: 1320,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: '#4ab3cf',
    '-webkit-print-color-adjust': 'exact'
  },
  logo: {
    paddingLeft: 0
  },
  button: {
    color: '#ffffff',
    width: '60%',
    marginTop: '20px',
    marginBottom: '40px'
  }
}))

const Project = ({ id }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const columns = [
    { id: 'projects', label: t('general.label.projects'), maxWidth: 720 },
    {
      id: 'city',
      label: t('general.label.address'),
      maxWidth: 100,
      align: 'left'
    },
    {
      id: 'route',
      label: t('general.label.distance'),
      maxWidth: 100,
      align: 'left'
    },
    {
      id: 'date',
      label: t('general.label.date'),
      maxWidth: 100,
      align: 'left'
    }
  ]

  /**
   * isProcessable
   *
   * @param {*} value
   */
  const isProcessable = value => {
    return !(value === null || value === undefined)
  }

  /**
   * getDestinationCoordinates
   *
   * @returns {{lon: *, lat: *}}
   */
  const getDestinationCoordinates = () => {
    return {
      lat: GetSafe(
        () => parseFloat(props.data.Construction_site.Address.Geo.lat),
        0
      ),
      lon: GetSafe(
        () => parseFloat(props.data.Construction_site.Address.Geo.lon),
        0
      )
    }
  }

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableColumnsHead}>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ maxWidth: column.maxWidth }}
                    className={classes.tableCellColumnsHeader}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.pointer}>
                {
                  <>
                    <TableCell
                      className={classes.tableCellColumnsbody}
                      style={{
                        fontSize: 16
                      }}
                    >
                      {props.data.Final_title}
                    </TableCell>
                    <TableCell className={classes.tableCellColumnsbody}>
                      {GetSafe(
                        () => props.data.Construction_site.Address.City,
                        ''
                      )}{' '}
                      {GetSafe(
                        () => props.data.Construction_site.Address.Street,
                        ''
                      )}{' '}
                      {GetSafe(
                        () => props.data.Construction_site.Address.Number,
                        ''
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCellColumnsbody}>
                      {GetSafe(
                        () =>
                          FormatHelper.formatDistance(
                            props.data.Calculated_distance
                          ) + ' km',
                        ''
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCellColumnsbody}>
                      {GetSafe(
                        () => FormatHelper.formatDate(props.data.Date),
                        ''
                      )}
                    </TableCell>
                  </>
                }
              </TableRow>
              <TableRow
                className={classes.tableInnerRow}
                id={`favorite-details-${props.data.HashID}`}
              >
                <TableCell
                  className={clsx(
                    classes.toggleTableCell,
                    classes.toggleTableCellMap
                  )}
                  colSpan={2}
                >
                  <p className={classes.toggleTitle}>
                    {t('favorites.label.fastest.route')}
                  </p>
                  <GoogleMapComponent
                    origin={props.originCoordinates}
                    destination={getDestinationCoordinates()}
                    location={props.location}
                  />
                  <CustomSCDetailAddition
                    favorite={props.data}
                    filterFor={props.filterFor}
                  />
                </TableCell>
                <TableCell className={classes.toggleTableCell}>
                  <p className={classes.toggleTitle}>
                    {t('projects.label.owner')}
                  </p>
                  <p>
                    {GetSafe(() => props.data.Building_owner.Name, '')}
                    <br />
                    {GetSafe(
                      () => props.data.Building_owner.Address.Street,
                      ''
                    )}{' '}
                    {GetSafe(
                      () => props.data.Building_owner.Address.Number,
                      ''
                    )}
                    <br />
                    {GetSafe(
                      () => props.data.Building_owner.Address.Zip,
                      ''
                    )}{' '}
                    {GetSafe(() => props.data.Building_owner.Address.City, '')}
                  </p>
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={props.data.Content || ''}
                    PopperProps={{
                      className: clsx(classes.tooltipItem, classes.tooltipInfo)
                    }}
                  >
                    <span className={classes.tooltipTitle}>
                      <Info style={{ fontSize: 30 }} color='primary' />
                      {t('projects.label.original.project')}
                    </span>
                  </Tooltip>
                  {/* {showApplyButton() && ( */}
                  {true && (
                    <Button
                      id='buttonApply'
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      // onClick={handleApplyButton}
                      disabled={props.data.Applied}
                    >
                      {/* {getApplyButtonText()} */}
                      {t('general.label.apply')}
                    </Button>
                  )}
                  <Button
                    id='buttonApplicationPrint'
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    // onClick={handleApplicationPrintButton}
                  >
                    {/* {getApplicationPrintButtonText()} */}
                    {t('company.label.print.application')}
                  </Button>
                </TableCell>
                <TableCell className={classes.toggleTableCell}>
                  <p className={classes.toggleTitle}>
                    {t('projects.label.architect')}
                  </p>
                  <p>
                    {GetSafe(() => props.data.Project_management.Name, '')}
                    <br />
                    {GetSafe(
                      () => props.data.Project_management.Address.Street,
                      ''
                    )}{' '}
                    {GetSafe(
                      () => props.data.Project_management.Address.Number,
                      ''
                    )}
                    <br />
                    {GetSafe(
                      () => props.data.Project_management.Address.Zip,
                      ''
                    )}{' '}
                    {GetSafe(
                      () => props.data.Project_management.Address.City,
                      ''
                    )}
                    <br />
                    {isProcessable(
                      GetSafe(() => props.data.Project_management.Phone, '')
                    ) && (
                      <a
                        href={
                          'tel:' +
                          GetSafe(() => props.data.Project_management.Phone, '')
                        }
                      >
                        {GetSafe(() => props.data.Project_management.Phone, '')}
                      </a>
                    )}
                    <br />
                    {isProcessable(
                      GetSafe(() => props.data.Project_management.Email, '')
                    ) && (
                      <a
                        href={
                          'mailto:' +
                          GetSafe(() => props.data.Project_management.Email, '')
                        }
                      >
                        {GetSafe(() => props.data.Project_management.Email, '')}
                      </a>
                    )}
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    </>
  )
}

export default Project
