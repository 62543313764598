import React from "react";
import { Link, makeStyles } from "@material-ui/core";
import User from "../../lib/User";

const useStyles = makeStyles((theme) => ({
  notice: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '20px',
    background: 'red',
    textAlign: 'center',
    color: 'white',
    letterSpacing: '2px',
    fontSize: '14px',
    fontWeight: 'bold'
  }
}));

const handleEndMasquerade = () => {
  if(User.isMasquerade()) {
    User.deleteLocalStorage()
    window.location.reload()
  }
}

/**
 * LayoutHeaderDebugNotice
 *
 * @returns {*}
 * @constructor
 */
const LayoutHeaderDebugNotice = (props) => {
  const classes = useStyles();

  /**
   * return
   */
  return User.isMasquerade() && (
    <div className={classes.notice}>
      MASQUERADED AS {User.getData().email} / ID: {User.getData().id} | <Link style={{cursor: "pointer"}} onClick={handleEndMasquerade}>END MASQ.</Link>
    </div>
  );
};

export default LayoutHeaderDebugNotice;
