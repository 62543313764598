import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "urql";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TablePaginationActions from "../components/Table/TablePaginationActions";
import ProjectsSearchTableRow from "../components/ProjectsSearch/ProjectsSearchTableRow";
import GlobalContext from "../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import withAccessControl from "../components/HOC/AccessControl";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { ACL_COMPONENTS } from "../config";
import Tooltip from "@material-ui/core/Tooltip";
import { navigate, usePath } from "hookrouter";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    borderRadius: 4,
    overflowX: "auto",
  },
  tableHead: {
    width: "100%",
    height: 64,
    background: "#F7F9FA",
  },
  tableCellHeader: {
    position: "relative",
    height: 64,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  tableColumnsHead: {
    height: 96,
  },
  tableCellColumnsHeader: {
    padding: "40px 14px 16px 14px",
    fontSize: theme.typography.fontSize,
    color: "#999999",
    fontWeight: "bold",
    "&:first-of-type": {
      paddingLeft: 24,
    },
    "@media (max-width:640px)": {
      "&:first-of-type": {
        paddingLeft: 14,
      },
    },
  },
  tableCellColumnsBody: {
    padding: "20px 14px",
  },
  tableCell: {
    height: 64,
  },
  tableRows: {
    paddingLeft: 36,
  },
  locationHead: {
    fontWeight: "bold",
  },
  locationHandler: {
    position: "absolute",
    top: "50%",
    right: 10,
    width: 200,
    transform: "translate(0, -50%)",
  },
  icon: {
    padding: 8,
  },
  listContent: {
    paddingLeft: 36,
  },
  paginationInput: {
    height: 28,
    width: 25,
  },
  paginationSelectIcon: {
    color: theme.palette.primary.main,
  },
  dashed: {
    borderBottom: "1px dashed #999999",
  },
  tableHeadSearch: {
    background: theme.palette.primary.main,
  },
  tableCellHeaderSearch: {
    color: "#fff",
    borderBottom: "1px solid #006a7a",
  },
  tableHeadSearchBody: {
    background: "#fff",
  },
  iconCloseSearch: {
    borderRadius: "50%",
    background: "#fff",
    color: theme.palette.primary.main,
    fontSize: "2rem",
  },
  searchFilterGroup: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "auto",
      maxWidth: 200,
    },
  },
  searchFilterItem: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    "@media (max-width:810px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  searchFilterButtonGroup: {
    display: "inline-block",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchFilterButton: {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 14,
    fontWeight: "normal",
    [theme.breakpoints.down("xs")]: {
      marginTop: 4,
      marginBottom: 4,
    },
    "&.MuiChip-colorPrimary": {
      color: "#fff",
    },
    "&.active": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const queryProjectsSearch = `
  query ReadSearchProjects (
    $lat: Float,
    $long: Float,
    $searchParameter: String,
    $filterFor: [String],
    $sortByFirstField: ReadSearchProjectsSortFieldType!,
    $sortByFirstDirection: SortDirection,
    $sortBySecondField: ReadSearchProjectsSortFieldType!,
    $sortBySecondDirection: SortDirection,
    $sortByThirdField: ReadSearchProjectsSortFieldType!,
    $sortByThirdDirection: SortDirection,
    $limit: Int,
    $offset: Int
  ) {
    readSearchProjects(
        lat: $lat,
        long: $long,
        QueryString: $searchParameter,
        ConstructionTypeContains: $filterFor,
        sortBy: [
                  {field: $sortByFirstField, direction: $sortByFirstDirection},
                  {field: $sortBySecondField, direction: $sortBySecondDirection},
                  {field: $sortByThirdField, direction: $sortByThirdDirection}
                ],
        limit: $limit,
        offset: $offset
    ) {
      HashID,
      Final_title,
      Canton,
      Content,
      Calculated_distance,
      Distance_calculated_from,
      Date
      Construction_sum
      Approval_date
      Type
      Notice_number
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Phone
        Email
        Website
        Email_other
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
    }
  }
`;

const queryFavorites = `
  query ReadFavorites (
    $userHashID: ID
  ) {
    readFavorites(ID: $userHashID) {
      HashID
      ProjectHashID
      Removed
    }
  }
`;

export const CHANGE_TYPES = {
  INIT: "init",
  PAGE: "page",
  TAB: "tab",
};

/**
 * Projects
 *
 * @returns {*}
 * @constructor
 */
const ProjectsSearch = () => {
  let shownProjects = [];
  const path = usePath();
  const classes = useStyles();
  const {
    setRemoveProjectSearchParameter,
    projectData,
    setProjectData,
    token,
    setToken,
    user,
    setVisibleProjects,
    visibleProjects,
    setMessage,
    unsetUser,
    unsetToken,
    projectSearchParameter,
    setProjectSearchParameter,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formerResult, setFormerResult] = useState([]);
  const [showNoResultMessage, setShowNoResultMessage] = useState(false);
  const [changeType, setChangeType] = useState(CHANGE_TYPES.INIT);
  const [querySearchVariables, setQuerySearchVariables] = useState({});
  const [querySearchFilter, setQuerySearchFilter] = useState([]);
  const [querySearchSortBy, setQuerySearchSortBy] = useState("score");

  // GraphQL query for projects by search parameters, gets triggered by calling "executeProjectsSearchQuery()"
  const [searchResult, executeProjectsSearchQuery] = useQuery({
    query: queryProjectsSearch,
    variables: querySearchVariables,
    requestPolicy: "network-only",
    pause: true,
  });

  // GraphQL query for favorites, gets triggered by calling "executeFavoritesQuery()"
  const [favoritesResult, executeFavoritesQuery] = useQuery({
    query: queryFavorites,
    variables: user.getData().hashID,
    requestPolicy: "network-only",
    pause: true,
  });

  const emptyShownProjects = () => {
    shownProjects = [];
  };

  /* eslint-disable */
  useEffect(() => {
    return () => {
      setRemoveProjectSearchParameter(true);
    };
  }, []);

  useEffect(() => {
    if (projectSearchParameter !== "") {
      setPage(0);
      setFormerResult([]);
      setChangeType(CHANGE_TYPES.TAB);
      setVisibleProjects([]);
      emptyShownProjects();
      triggerProjectSearch();
    }
  }, [querySearchFilter, querySearchSortBy, projectSearchParameter]);
  /* eslint-enable */

  useEffect(() => {
    if (Object.keys(querySearchVariables).length !== 0) {
      executeProjectsSearchQuery();
    } else {
      setShowNoResultMessage(true);
    }
  }, [querySearchVariables, executeProjectsSearchQuery]);

  /**
   * fetch data useEffect
   */
  useEffect(() => {
    if (page % 3 === 0 && Object.keys(querySearchVariables).length !== 0) {
      executeProjectsSearchQuery();
      executeFavoritesQuery();
    }
  }, [
    token.api,
    setToken,
    executeProjectsSearchQuery,
    page,
    executeFavoritesQuery,
    querySearchVariables,
  ]);

  useEffect(() => {
    if (shownProjects.length && !visibleProjects.length) {
      setVisibleProjects(shownProjects);
    }
  }, [setVisibleProjects, shownProjects, visibleProjects]);

  useEffect(() => {
    const offset = { offset: rowsPerPage * page };
    if (Object.entries(querySearchVariables).length !== 0) {
      setQuerySearchVariables({
        ...querySearchVariables,
        ...offset,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  /**
   * table column heads
   */
  const columns = [
    { id: "actions", label: t("general.label.actions"), minWidth: 200 },
    {
      id: "projects",
      label: t("general.label.projects"),
      minWidth: 100,
      fontSize: 16,
    },
    {
      id: "city",
      label: t("general.label.address"),
      minWidth: 120,
      align: "left",
    },
    {
      id: "route",
      label: t("general.label.distance"),
      minWidth: 120,
      align: "left",
    },
    {
      id: "date",
      label: t("general.label.date"),
      minWidth: 120,
      align: "left",
    },
  ];

  useEffect(() => {
    if (!searchResult.fetching) {
      if (searchResult.error) {
        // Check if the user need to be logged out
        if (
          searchResult.error.message.indexOf("User forced logout") !== -1 ||
          searchResult.error.message.indexOf(
            "The resource owner or authorization server denied the request"
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          if (!showNoResultMessage) {
            setShowNoResultMessage(true);
          }

          if (formerResult.length !== 0) {
            setFormerResult([]);
          }
        }
      } else {
        // Query not fetching right now
        if (
          typeof searchResult.data !== "undefined" &&
          typeof searchResult.data.readSearchProjects !== "undefined"
        ) {
          // Add the projects to the global context
          const actualResult = searchResult.data.readSearchProjects;

          if (JSON.stringify(formerResult) !== JSON.stringify(actualResult)) {
            setFormerResult(actualResult);

            if (changeType === CHANGE_TYPES.PAGE) {
              const mergedArray = [...projectData, ...actualResult];
              // mergedArray have duplicates, lets remove the duplicates using Set
              const set = new Set();
              const unionArray = mergedArray.filter((item) => {
                if (!set.has(item.HashID)) {
                  set.add(item.HashID);
                  return true;
                }
                return false;
              }, set);
              // console.table(unionArray)
              setProjectData(unionArray);
            } else {
              setProjectData(actualResult);
            }

            setShowNoResultMessage(false);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult])

  /**
   * handleChangePage
   *
   * @param event
   * @param newPage
   */
  const handleChangePage = (event, newPage) => {
    if (shownProjects.length) {
      setVisibleProjects(shownProjects);
      shownProjects = [];
    }
    setChangeType(CHANGE_TYPES.PAGE);
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  /**
   * handleChangeRowsPerPage
   *
   * @param event
   */
  const handleChangeRowsPerPage = (event) => {
    if (shownProjects.length) {
      setVisibleProjects(shownProjects);
      shownProjects = [];
    }
    setChangeType(CHANGE_TYPES.PAGE);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * addRowToVisibleProjects
   *
   * @param project
   */
  const addRowToVisibleProjects = (project) => {
    if (project.Read === null) {
      shownProjects.push(project);
    }
  };

  const triggerProjectSearch = (page) => {
    const sortBy = ["_score", "ASC", "Date", "DESC", "AirDistance", "ASC"];
    switch (querySearchSortBy) {
      case "distance":
        sortBy[0] = "AirDistance";
        sortBy[1] = "ASC";
        sortBy[2] = "_score";
        sortBy[3] = "ASC";
        sortBy[4] = "Date";
        sortBy[5] = "DESC";
        break;
      case "date":
        sortBy[0] = "Date";
        sortBy[1] = "DESC";
        sortBy[2] = "AirDistance";
        sortBy[3] = "ASC";
        sortBy[4] = "_score";
        sortBy[5] = "ASC";
        break;
      default:
        // Also "score"-selection
        sortBy[0] = "_score";
        sortBy[1] = "ASC";
        sortBy[2] = "Date";
        sortBy[3] = "DESC";
        sortBy[4] = "AirDistance";
        sortBy[5] = "ASC";
    }
    const limit = rowsPerPage * 4;
    const offset = rowsPerPage * page;

    setQuerySearchVariables({
      lat: user.getData().company.latitude,
      long: user.getData().company.longitude,
      searchParameter: projectSearchParameter,
      filterFor: querySearchFilter,
      sortByFirstField: sortBy[0],
      sortByFirstDirection: sortBy[1],
      sortBySecondField: sortBy[2],
      sortBySecondDirection: sortBy[3],
      sortByThirdField: sortBy[4],
      sortByThirdDirection: sortBy[5],
      limit: limit,
      offset: offset,
    });
  };

  const toggleQuerySearchFilterValue = (filterValue) => {
    let newQuerySearchFilter = [];
    const currentQuerySearchFilter = [];

    for (let index = 0; index < querySearchFilter.length; index++) {
      currentQuerySearchFilter.push(querySearchFilter[index]);
    }

    if (currentQuerySearchFilter.includes(filterValue)) {
      newQuerySearchFilter = currentQuerySearchFilter.filter(
        (element) => element !== filterValue
      );
    } else {
      currentQuerySearchFilter.push(filterValue);
      newQuerySearchFilter = currentQuerySearchFilter;
    }

    setQuerySearchFilter(newQuerySearchFilter);
  };

  /**
   * return
   */
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow
              className={clsx(classes.tableHead, classes.tableHeadSearch)}
            >
              <TableCell
                align="left"
                className={clsx(
                  classes.tableCellHeader,
                  classes.tableCellHeaderSearch
                )}
                colSpan={4}
              >
                {projectSearchParameter.length > 0 &&
                  `${t("projects.search.results")} "${projectSearchParameter}"`}
                {projectSearchParameter.length === 0 &&
                  t("projects.search.no.param")}
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  classes.tableCellHeader,
                  classes.tableCellHeaderSearch
                )}
              >
                <span
                  onClick={() => {
                    setProjectSearchParameter("");
                    if (path === "/projects-search") {
                      navigate("/projects");
                    }
                  }}
                >
                  <CloseIcon
                    className={clsx(classes.icon, classes.iconCloseSearch)}
                  />
                </span>
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableHead, classes.tableHeadSearchBody)}
            >
              <TableCell
                align="right"
                className={clsx(
                  classes.tableCellHeader,
                  classes.tableCellHeaderSearchBody
                )}
                colSpan={5}
              >
                <Grid container className={classes.searchFilterGroup}>
                  <Grid item className={classes.searchFilterItem}>
                    <Grid item className={classes.searchFilterButtonGroup}>
                      {t("projects.search.sort")}
                    </Grid>
                    <Chip
                      color={
                        querySearchSortBy === "score" ? "primary" : "default"
                      }
                      label={t("projects.search.score")}
                      component="a"
                      clickable
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        setQuerySearchSortBy("score");
                      }}
                    />
                    <Chip
                      color={
                        querySearchSortBy === "distance" ? "primary" : "default"
                      }
                      label={t("projects.search.distance")}
                      component="a"
                      clickable
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        setQuerySearchSortBy("distance");
                      }}
                    />
                    <Chip
                      color={
                        querySearchSortBy === "date" ? "primary" : "default"
                      }
                      label={t("projects.search.date")}
                      component="a"
                      clickable
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        setQuerySearchSortBy("date");
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.searchFilterItem}>
                    <Grid item className={classes.searchFilterButtonGroup}>
                      Filter:
                    </Grid>
                    <Chip
                      color={
                        querySearchFilter.includes("unread")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.unread")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("unread") ? (
                          <DoneIcon />
                        ) : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("unread");
                      }}
                    />
                    <Chip
                      color={
                        querySearchFilter.includes("new")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.new")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("new") ? <DoneIcon /> : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("new");
                      }}
                    />
                    <Chip
                      color={
                        querySearchFilter.includes("modification")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.modification")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("modification") ? (
                          <DoneIcon />
                        ) : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("modification");
                      }}
                    />
                    <Chip
                      color={
                        querySearchFilter.includes("demolition")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.demolition")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("demolition") ? (
                          <DoneIcon />
                        ) : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("demolition");
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableColumnsHead}>
              {columns.map((column) => {
                if (column.id === "route") {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        maxWidth: column.maxWidth,
                      }}
                      className={classes.tableCellColumnsHeader}
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={t("general.label.traveldistance.tooltip")}
                        arrow
                      >
                        <span className={classes.dashed}>{column.label}</span>
                      </Tooltip>
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      maxWidth: column.maxWidth,
                    }}
                    className={classes.tableCellColumnsHeader}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!searchResult.fetching && showNoResultMessage && (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{
                    paddingTop: 32,
                    paddingBottom: 32,
                    fontSize: 16,
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  {t("general.label.no.search.projects")}
                </TableCell>
              </TableRow>
            )}
            {searchResult.fetching && (
              <React.Fragment key={0}>
                <TableRow>
                  <TableCell
                    colSpan="5"
                    component="th"
                    scope="row"
                    className={classes.tableRows}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
            {!searchResult.fetching &&
              !showNoResultMessage &&
              projectData.length !== 0 &&
              projectData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  addRowToVisibleProjects(row);
                  const favoritesData = favoritesResult.data
                    ? favoritesResult.data.readFavorites
                    : null;
                  return (
                    <ProjectsSearchTableRow
                      key={row.HashID}
                      row={row}
                      classes={classes}
                      location={user.getData().company.canton}
                      originCoordinates={{
                        lat: user.getData().company.latitude,
                        lon: user.getData().company.longitude,
                      }}
                      favorites={favoritesData}
                    />
                  );
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={5}
                count={projectData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false,
                  renderValue: () => <></>,
                }}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from} - ${to === -1 ? count : to}`;
                }}
                labelRowsPerPage={
                  <>
                    {rowsPerPage} {t("general.label.rows")}
                  </>
                }
                classes={{
                  input: classes.paginationInput,
                  selectIcon: classes.paginationSelectIcon,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
};

export default withAccessControl(
  ProjectsSearch,
  ACL_COMPONENTS.PROJECTS,
  "projects-free"
);
