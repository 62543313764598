import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { MESSAGE_TYPES } from "../PopupMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import GlobalContext from "../../lib/GlobalContext";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import parseApplicationText from "../../lib/Application";
import { useMutation, useQuery } from "urql";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import Joi from "@hapi/joi";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import GetSafe from "../../lib/GetSafe";
import MenuItem from "@material-ui/core/MenuItem";
import {
  DOSSIER_AVAILABLE_LOCALES,
  DEFAULT_LOCALE_FOR_LANGUAGE,
} from "../../config";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    flexGrow: 1,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: 0,
  },
  dialog: {
    width: "100%",
    paddingBottom: 56,
    "&::before": theme.watermarkLogo,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  startButton: {
    width: "100%",
    color: "#fff",
    fontWeight: "bold",
  },
  textFieldSmall: {
    width: "50%",
    "&:first-of-type": {
      width: "calc(50% - 24px)",
      marginRight: 24,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        width: "100%",
      },
    },
  },
}));

const queryBusinessActivities = `
  query ReadBusinessActivities ($id: ID)
  {
    readBusinessActivities (
      sortBy: [{field: Sort, direction: ASC}],
      Filter: {
        ID__in: [$id]
      }
    ){
      ID,
      Title,
      NogaCode,
      BusinessSpecialities {
        edges {
            node {
            ID,
            Title
          }
        }
      }
    }
  }
`;

const queryDossierTemplates = `
  query ReadDossierTemplates (
    $language: String!
  ) {
    readDossierTemplates(Language: $language) {
      HashID
      Email
      Locale
      TemplateName
      BusinessActivityID
      MicrositeEmailApplicationText
      BusinessSpecialities {
        edges {
          node {
            ID
            Title
          }
        }
      }
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeReferences {
        edges {
          node {
            Title
            Content
          }
        }
      }
    }
  }
`

const createApplicationSubmissionMutation = `
  mutation (
    $SubmissionType: String!,
    $ApplicationText: String!,
    $ProjectIDHash: String!,
    $RecipientName: String!,
    $RecipientCity: String!,
    $RecipientStreet: String!,
    $RecipientZip: String!,
    $SubjectLine: String!,
    $ProjectName: String!,
    $ProjectLocation: String!,
    $Canton: String,
    $Type_of_building: String,
    $Type_of_construction: String,
    $TemplateHashID: String,
    $Language: String
  ) {
    submitApplication (
      SubmissionType: $SubmissionType,
      ApplicationText: $ApplicationText,
      ProjectIDHash: $ProjectIDHash,
      RecipientName: $RecipientName,
      RecipientCity: $RecipientCity,
      RecipientStreet: $RecipientStreet,
      RecipientZip: $RecipientZip,
      SubjectLine: $SubjectLine,
      ProjectName: $ProjectName,
      ProjectLocation: $ProjectLocation,
      Canton: $Canton,
      TypeOfBuilding: $Type_of_building,
      TypeOfConstruction: $Type_of_construction,
      TemplateHashID: $TemplateHashID,
      Language: $Language
    ) {
      Status
      Error
      PDFLink
    }
  }
`;

const initialAddressData = {
  name: null,
  street: null,
  zip: null,
  city: null,
};

const initialFormValueErrorsState = {
  ApplicationText: { hasError: false, message: "" },
  RecipientName: { hasError: false, message: "" },
  RecipientCity: { hasError: false, message: "" },
  RecipientStreet: { hasError: false, message: "" },
  RecipientZip: { hasError: false, message: "" },
  SubjectLine: { hasError: false, message: "" },
  DossierTemplate: { hasError: false, message: "" },
};

const initialLanguage = "de";

/**
 * FavoriteApplicationPrintDialog
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FavoriteApplicationPrintDialog = (props) => {
  let dialogContent;
  const classes = useStyles();

  const {
    applicationPrintDialog,
    setApplicationPrintDialog,
    projectManagementData,
    buildingOwnerData,
    favorite,
  } = props;

  const { user, token, setMessage, unsetUser, unsetToken } =
    useContext(GlobalContext);

  const { t } = useTranslation();
  const [businessActivities, setBusinessActivities] = useState(false);
  const [applicationText, setApplicationText] = useState(null);

  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );

  const [sendDownloadFetching, setSendDownloadFetching] = useState(false);
  const [addressData, setAddressData] = useState(initialAddressData);
  const [subjectLine, setSubjectLine] = useState("");
  const [selectedDossierTemplate, setSelectedDossierTemplate] = useState(null);
  const [dossierTemplates, setDossierTemplates] = useState([]);
  const [dossierTemplatesByHashID, setDossierTemplatesByHashID] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
  const [enableLanguageSelector, setEnableLanguageSelector] = useState(false);

  const [
    { fetchingUpdateApplicationSubmission },
    executeApplicationSubmissionMutation,
  ] = useMutation(createApplicationSubmissionMutation);

  // GraphQL query for business-activities, gets triggered by calling "executeBusinessActivitiesQuery()"
  const [queryBusinessActivitiesResult, executeBusinessActivitiesQuery] =
    useQuery({
      query: queryBusinessActivities,
      variables: {
        id: user.getData().company.branchCategory,
      },
      requestPolicy: "cache-and-network",
      pause: true,
    });

  // GraphQL query for dossier-templates
  const [queryDossierTemplatesResult] = useQuery({
    query: queryDossierTemplates,
    variables: {
      language: "all",
    },
    requestPolicy: "cache-and-network",
  });

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    ApplicationText: Joi.string().required(),
    RecipientName: Joi.string().allow(""),
    RecipientCity: Joi.string().allow(""),
    RecipientStreet: Joi.string().allow(""),
    RecipientZip: Joi.string().allow(""),
    SubjectLine: Joi.string().required(),
    DossierTemplate:
      dossierTemplates.length > 1
        ? Joi.string().allow(null).required()
        : Joi.string().allow(null),
  });

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!queryBusinessActivitiesResult.fetching) {
      if (queryBusinessActivitiesResult.error) {
        // Check if the user need to be logged out
        if (
          queryBusinessActivitiesResult.error.message.indexOf(
            "User forced logout"
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        }
      } else {
        if (
          !businessActivities &&
          typeof queryBusinessActivitiesResult.data !== "undefined" &&
          typeof queryBusinessActivitiesResult.data.readBusinessActivities !==
            "undefined"
        ) {
          // Query not fetching right now
          setBusinessActivities(
            queryBusinessActivitiesResult.data.readBusinessActivities[0]
          );
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryBusinessActivitiesResult]);

  /**
   * useEffect
   */
  useEffect(() => {
    executeBusinessActivitiesQuery();
  }, [executeBusinessActivitiesQuery]);

  /**
   * setApplicationText useEffect
   */
  useEffect(() => {
    if (businessActivities) {
      let text = user.getData().company.micrositeEmailApplicationText;

      if (applicationText !== null) {
        text = applicationText;
      }

      setApplicationText(parseEmailApplicationText(text));
    }
    // eslint-disable-next-line
  }, [applicationText, setApplicationText, user, businessActivities]);

  /**
   * setAddressData useEffect
   */
  useEffect(() => {
    if (addressData === initialAddressData) {
      if (
        (projectManagementData.Name ? projectManagementData.Name : "") !== "" &&
        (projectManagementData.Address
          ? projectManagementData.Address.Street
          : "") !== "" &&
        (projectManagementData.Address
          ? projectManagementData.Address.Zip
          : "") !== "" &&
        (projectManagementData.Address
          ? projectManagementData.Address.City
          : "") !== ""
      ) {
        setAddressData({
          name: projectManagementData.Name,
          street:
            projectManagementData.Address.Street +
            " " +
            projectManagementData.Address.Number,
          zip: projectManagementData.Address.Zip,
          city: projectManagementData.Address.City,
        });
      } else {
        setAddressData({
          name: buildingOwnerData.Name,
          street:
            buildingOwnerData.Address.Street +
            " " +
            buildingOwnerData.Address.Number,
          zip: buildingOwnerData.Address.Zip,
          city: buildingOwnerData.Address.City,
        });
      }
    }
  }, [setAddressData, addressData, projectManagementData, buildingOwnerData]);

  /**
   * Handle dossiert-templates after user has changed
   */
  useEffect(() => {
    setDossierTemplates([
      ...[{ HashID: null }],
      ...user.getData().dossierTemplates,
    ]);
  }, [user]);

  /**
   * Initially set the first dossier-templates-selector option as active
   */
  useEffect(() => {
    setSelectedDossierTemplate(dossierTemplatesList()[0].props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * In case selected dossier-template changes to a custom one, enable the language-selector
   */
  useEffect(() => {
    let state = false;

    if (GetSafe(() => selectedDossierTemplate.HashID, false)) {
      state = true;
    }

    setEnableLanguageSelector(state);
  }, [selectedDossierTemplate]);

  /**
   * Triggers as soon as the selected dossier-template has been changed
   */
  useEffect(() => {
    let applicationText = null;
    let selectedDossierTemplateLangDependant = null;

    if (selectedDossierTemplate) {
      selectedDossierTemplateLangDependant =
        getDossierTemplateByHashIdAndLanguage(
          selectedDossierTemplate.HashID,
          selectedLanguage
        );
      applicationText =
        selectedDossierTemplateLangDependant.MicrositeEmailApplicationText;
    }

    if (!applicationText) {
      applicationText = user.getData().company.micrositeEmailApplicationText;
    }

    setApplicationText(parseEmailApplicationText(applicationText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDossierTemplate, selectedLanguage, businessActivities]);

  /**
   * Read the query result for dossier-templates and assign them to the application-dropdown
   */
  useEffect(() => {
    const dossiersByLocale = {}
    const dossiersByHashID = {}
    const languageDependantDossierTemplates = []

    if (!queryDossierTemplatesResult.fetching) {
      if (
        GetSafe(
          () => queryDossierTemplatesResult.data.readDossierTemplates,
          false
        )
      ) {
        if (queryDossierTemplatesResult.data.readDossierTemplates.length > 0) {
          queryDossierTemplatesResult.data.readDossierTemplates.forEach(
            dossierTemplate => {
              if (
                typeof DOSSIER_AVAILABLE_LOCALES[dossierTemplate.Locale] !==
                'undefined'
              ) {
                languageDependantDossierTemplates.push(dossierTemplate)

                if (dossierTemplate.Locale) {
                  if (
                    typeof dossiersByLocale[dossierTemplate.Locale] ===
                    'undefined'
                  ) {
                    dossiersByLocale[dossierTemplate.Locale] = []
                  }

                  dossiersByLocale[dossierTemplate.Locale].push(dossierTemplate)
                }

                if (dossierTemplate.HashID) {
                  if (
                    typeof dossiersByHashID[dossierTemplate.HashID] ===
                    'undefined'
                  ) {
                    dossiersByHashID[dossierTemplate.HashID] = []
                  }

                  dossiersByHashID[dossierTemplate.HashID].push(dossierTemplate)
                }

                if (
                  selectedDossierTemplate &&
                  dossierTemplate.HashID === selectedDossierTemplate.HashID
                ) {
                  setSelectedDossierTemplate(dossierTemplate)
                }
              }
            }
          )
        }
      }
    }

    setDossierTemplatesByHashID(dossiersByHashID);

    setDossierTemplates([
      ...[{ HashID: null }],
      ...getUniqueDossierTemplatesByHashID(dossiersByHashID),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDossierTemplatesResult]);

  /**
   * Handle dossiert-template change
   *
   * @param event
   */
  const handleDossierTemplateChange = (event) => {
    let dossierTemplate = null;

    if (event.target.value === null || event.target.value === 0) {
      dossierTemplate = event.target.value;
    } else {
      dossierTemplate = getDossierTemplateByHashIdAndLanguage(
        event.target.value.HashID,
        initialLanguage
      );
    }

    setSelectedLanguage(initialLanguage);
    setSelectedDossierTemplate(dossierTemplate);
  };

  /**
   * Handle language change
   *
   * @param {*} event
   */
  const handleSelectedLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  /**
   * Taks a array of templates and removes duplicates by HashID
   * In case a duplicate has different languages, it keeps the current selected language duplicate
   *
   * @param {*} dossiersByHashID
   * @returns array
   */
  const getUniqueDossierTemplatesByHashID = (dossiersByHashID) => {
    const uniqueDossierTemplates = [];

    Object.keys(dossiersByHashID).forEach((index) => {
      if (dossiersByHashID[index].length > 1) {
        let foundForLanguage = false;

        dossiersByHashID[index].forEach((dossierTemplate) => {
          if (
            dossierTemplate.Locale ===
            DEFAULT_LOCALE_FOR_LANGUAGE[selectedLanguage]
          ) {
            uniqueDossierTemplates.push(dossierTemplate);
            foundForLanguage = true;
          }
        });

        if (!foundForLanguage) {
          uniqueDossierTemplates.push(dossiersByHashID[index][0]);
        }
      } else {
        uniqueDossierTemplates.push(dossiersByHashID[index][0]);
      }
    });

    return uniqueDossierTemplates;
  };

  /**
   * Fetch the selected dossier-template for the selected language from the pool#
   *
   * @param {*} hashID
   * @param {*} language
   * @returns
   */
  const getDossierTemplateByHashIdAndLanguage = (hashID, language) => {
    // Get the selected dossier-template for the selected language
    for (let i = 0; i < dossierTemplatesByHashID[hashID].length; i++) {
      if (
        dossierTemplatesByHashID[hashID][i].HashID === hashID &&
        DOSSIER_AVAILABLE_LOCALES[
          dossierTemplatesByHashID[hashID][i].Locale
        ] === language
      ) {
        return dossierTemplatesByHashID[hashID][i];
      }
    }

    // Since there is no dossier-template for the requested language,
    // fallback to first available dossier-template and its language
    setSelectedLanguage(
      DOSSIER_AVAILABLE_LOCALES[dossierTemplatesByHashID[hashID][0].Locale]
    );
    return dossierTemplatesByHashID[hashID][0];
  };

  /**
   * Generate available language-items for dropdown based on selected dossier-template
   *
   * @returns
   */
  const getDossierTemplateAvailableLanguages = () => {
    const avalableLanguages = {};
    const menuItems = [];

    if (selectedDossierTemplate === null || selectedDossierTemplate === 0) {
      return menuItems;
    }

    dossierTemplatesByHashID[selectedDossierTemplate.HashID].forEach(
      (dossierTemplate) => {
        if (
          typeof DOSSIER_AVAILABLE_LOCALES[dossierTemplate.Locale] !==
          "undefined"
        ) {
          avalableLanguages[DOSSIER_AVAILABLE_LOCALES[dossierTemplate.Locale]] =
            dossierTemplate.Locale;
        }
      }
    );

    if (avalableLanguages["de"]) {
      menuItems.push(
        <MenuItem key={0} value={"de"}>
          Deutsch
        </MenuItem>
      );
    }

    if (avalableLanguages["fr"]) {
      menuItems.push(
        <MenuItem key={1} value={"fr"}>
          Französisch
        </MenuItem>
      );
    }

    return menuItems;
  };

  /**
   * dossierTemplateList
   *
   * @returns {[]}
   */
  const dossierTemplatesList = () => {
    const dossierTemplatesList = [];

    dossierTemplatesList.push(
      <MenuItem key={0} value={0}>
        {t("form.label.please.select")}
      </MenuItem>
    );

    dossierTemplates.map((value, key) =>
      dossierTemplatesList.push(
        <MenuItem key={key + 1} value={value}>
          {value.TemplateName}
        </MenuItem>
      )
    );

    return dossierTemplatesList;
  };

  /**
   * parseEmailApplicationText
   * Parse the application text and replace the placeholder with actual values
   */
  const parseEmailApplicationText = (emailApplicationTextTemplate) => {
    return parseApplicationText(
      user,
      emailApplicationTextTemplate,
      businessActivities,
      favorite,
      t("general.label.and")
    );
  };

  /**
   * handleCompanyNameChange
   *
   * @param event
   */
  const handleCompanyNameChange = (event) => {
    setAddressData({ ...addressData, name: event.target.value });
  };

  /**
   * handleStreetChange
   *
   * @param event
   */
  const handleStreetChange = (event) => {
    setAddressData({ ...addressData, street: event.target.value });
  };

  /**
   * handleZipChange
   *
   * @param event
   */
  const handleZipChange = (event) => {
    setAddressData({ ...addressData, zip: event.target.value });
  };

  /**
   * handleCityChange
   *
   * @param event
   */
  const handleCityChange = (event) => {
    setAddressData({ ...addressData, city: event.target.value });
  };

  /**
   * handleSubjectLineChange
   *
   * @param event
   */
  const handleSubjectLineChange = (event) => {
    setSubjectLine(event.target.value);
  };

  /**
   * closeDialogue
   */
  const closeDialogue = () => {
    setApplicationPrintDialog(false);
  };

  /**
   * handleApplicationTextChange
   *
   * @param event
   */
  const handleApplicationTextChange = (event) => {
    setApplicationText(event.target.value);
  };

  /**
   * handleSubmit
   */
  const handleSubmit = () => {
    setSendDownloadFetching(true);

    const formData = {
      ApplicationText: applicationText,
      RecipientName: addressData.name,
      RecipientCity: addressData.city,
      RecipientStreet: addressData.street,
      RecipientZip: addressData.zip,
      SubjectLine: subjectLine,
      DossierTemplate: selectedDossierTemplate.HashID,
    };
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      dialogContent.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      setFormValueErrors({ ...formErrors });
      setSendDownloadFetching(false);
    } else {
      executeApplicationSubmissionMutation({
        SubmissionType: "print",
        ApplicationText: applicationText,
        RecipientName: addressData.name,
        RecipientCity: addressData.city,
        RecipientStreet: addressData.street,
        RecipientZip: addressData.zip,
        SubjectLine: subjectLine,
        ProjectIDHash: favorite.ProjectHashID,
        ProjectName: favorite.Final_title,
        ProjectLocation: favorite.Construction_site.Address.City,
        Canton: favorite.Canton,
        Type_of_building: favorite.Type_of_building,
        Type_of_construction: favorite.Type_of_construction,
        TemplateHashID: selectedDossierTemplate.HashID,
        Language: selectedLanguage,
      }).then((result) => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error requesting print document",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: JSON.stringify(result.error),
              },
            });
          }
        } else {
          if (result.data.submitApplication.Status !== "true") {
            setMessage(
              MESSAGE_TYPES.ERROR,
              result.data.submitApplication.Error !== ""
                ? result.data.submitApplication.Error
                : t("error.form.submit.application.not.donwloadable")
            );
          } else {
            window.open(result.data.submitApplication.PDFLink, "_blank");
            closeDialogue();
          }
        }
        setSendDownloadFetching(false);
      });
    }
  };

  /**
   * isFetching
   *
   * @returns {boolean|*}
   */
  const isFetching = () => {
    return (
      sendDownloadFetching ||
      queryBusinessActivities.fetching ||
      fetchingUpdateApplicationSubmission ||
      queryDossierTemplatesResult.fetching
    );
  };

  /**
   * return
   */
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      className={classes.dialogRoot}
      open={applicationPrintDialog}
    >
      <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        <IconButton onClick={closeDialogue}>
          <CloseIcon id="closeDialog" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          margin="normal"
          padding="normal"
          className={classes.gridContainer}
          ref={(node) => {
            dialogContent = node;
          }}
        >
          {(user.hasZipSearchAreas() || user.isPro()) && dossierTemplates.length > 1 && (
            <Grid item className={classes.gridItem}>
              <TextField
                id="dossierTemplateSelect"
                select
                label={t("form.label.select.dossier")}
                fullWidth
                value={selectedDossierTemplate}
                disabled={queryDossierTemplatesResult.fetching}
                onChange={(event) => {
                  handleDossierTemplateChange(event);
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                error={GetSafe(
                  () => formValueErrors.DossierTemplate.hasError,
                  false
                )}
                helperText={GetSafe(
                  () => formValueErrors.DossierTemplate.message,
                  ""
                )}
              >
                {dossierTemplatesList()}
              </TextField>
            </Grid>
          )}
          {(user.hasZipSearchAreas() || user.isPro()) && enableLanguageSelector && (
            <Grid item className={classes.gridItem}>
              <TextField
                id="languageSelect"
                select
                label={t("form.label.select.language")}
                fullWidth
                value={selectedLanguage}
                disabled={
                  !enableLanguageSelector ||
                  queryDossierTemplatesResult.fetching
                }
                onChange={(event) => {
                  handleSelectedLanguageChange(event);
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
                helperText={t("form.description.select.language")}
              >
                {getDossierTemplateAvailableLanguages()}
              </TextField>
            </Grid>
          )}
          <Grid item className={clsx(classes.gridItem, classes.itemHeader)}>
            <h1 className={classes.title}>
              {t("company.label.print.application")}
            </h1>
            <p>{t("company.label.print.application.intro")}</p>
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id="inputCompany"
              label={t("form.label.company")}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              error={false}
              helperText=""
              value={addressData.name}
              onChange={handleCompanyNameChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id="inputStreet"
              label={t("form.label.street")}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              error={false}
              helperText=""
              value={addressData.street}
              onChange={handleStreetChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id="inputZip"
              label={t("form.label.zip")}
              className={classes.textFieldSmall}
              margin="normal"
              variant="outlined"
              error={false}
              helperText=""
              value={addressData.zip}
              onChange={handleZipChange}
            />
            <TextField
              id="inputCity"
              label={t("form.label.city")}
              className={classes.textFieldSmall}
              margin="normal"
              variant="outlined"
              error={false}
              helperText=""
              value={addressData.city}
              onChange={handleCityChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id="inputSubjectLine"
              label={t("form.label.subjectLine")}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              error={GetSafe(() => formValueErrors.SubjectLine.hasError, false)}
              helperText={GetSafe(
                () => formValueErrors.SubjectLine.message,
                ""
              )}
              value={subjectLine}
              onChange={handleSubjectLineChange}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              id="inputApplicationText"
              variant="outlined"
              align="left"
              multiline
              error={GetSafe(
                () => formValueErrors.ApplicationText.hasError,
                false
              )}
              label={t("form.label.company.application.text")}
              value={applicationText || ""}
              helperText={GetSafe(
                () => formValueErrors.ApplicationText.message,
                ""
              )}
              onChange={handleApplicationTextChange}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button
              id="buttonFormSubmit"
              variant="contained"
              color="primary"
              className={classes.startButton}
              onClick={handleSubmit}
              disabled={isFetching()}
            >
              {t("company.label.email.application.print")}
              {isFetching() ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : null}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FavoriteApplicationPrintDialog;
