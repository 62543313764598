import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LayoutHeader from './Layout/LayoutHeader'
import LayoutFooter from './Layout/LayoutFooter'
import GlobalContext from '../lib/GlobalContext'
import Message, { MESSAGE_TYPES } from './PopupMessages'
import { useQuery } from 'urql'
import { navigate } from 'hookrouter'
import { useTranslation } from 'react-i18next'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

const useStyles = makeStyles(theme => ({
  sheet: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  children: {
    width: '100%',
    maxWidth: 1320,
    margin: '0 auto auto',
    padding: 20
  }
}))

const queryCmsLinks = `
{
  readLinks {
    Title
    Links {
      Title
      AbsoluteLink
      Position
    }
  }
}`

/**
 * Layout
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Layout = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    message,
    cmsLinks,
    setCmsLinks,
    setMessage,
    unsetUser,
    unsetToken
  } = useContext(GlobalContext)

  const [result, executeCmsLinksQuery] = useQuery({
    query: queryCmsLinks,
    requestPolicy: 'cache-and-network',
    pause: true
  })

  /**
   * useEffect
   */
  useEffect(() => {
    // Load CMS-Menu data
    if (cmsLinks === null) {
      executeCmsLinksQuery()
    }
  }, [cmsLinks, executeCmsLinksQuery])

  // GraphQL trigger and result handling
  useEffect(() => {
    if (!result.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf('User forced logout') !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t('error.user.forced.logout'))
          unsetUser()
          unsetToken()
          navigate('/')
        } else {
          ExternalErrorLogger.log({
            text: 'Error read cms links on Layout',
            data: {
              errorMessage: result.error.message
            }
          })
        }
      } else {
        // Query not fetching right now
        if (
          typeof result.data !== 'undefined' &&
          typeof result.data.readLinks !== 'undefined'
        ) {
          setCmsLinks(result.data.readLinks)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  /**
   * return
   */
  return (
    <div className={classes.sheet}>
      <LayoutHeader />
      <div className={classes.children}>
        {message && <Message />}
        {props.children}
      </div>
      <LayoutFooter
        setDebugToolsEnabled={props.setDebugToolsEnabled}
      />
    </div>
  )
}

export default Layout
