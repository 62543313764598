import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'urql'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import GlobalContext from '../lib/GlobalContext'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ExternalErrorLogger from '@ennit/react-external-errorlogger'
import { MESSAGE_TYPES } from './PopupMessages'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  searchField: {
    width: 328
  },
  menu: {
    marginTop: theme.spacing(7.5)
  },
  menuList: {
    width: 328
  },
  autocompleteResultWrapper: {
    position: 'relative',
    textAlign: 'center',
    zIndex: 2
  },
  autocompleteResult: {
    position: 'absolute',
    padding: theme.spacing(3),
    width: '100%'
  }
}))

const minCompanyLengthForSuggest = 3
const startSuggestDelay = 350

const queryZefixCompanySuggest = `
  query ReadZefixCompanySuggest (
    $searchParameter: String!
    $csrfToken: String
    $companyHashID: String
  ) {
    readZefixCompanySuggest(
      Name: $searchParameter,
      Token: $csrfToken,
      CompanyHashID: $companyHashID
    ) {
      Name
      CHID
    }
  }
`

const queryZefixCompanyDetails = `
  query ReadZefixCompanyDetails (
    $chidParameter: String!
    $csrfToken: String
    $companyHashID: String
  ) {
    readZefixCompanyDetails(
      CHID: $chidParameter,
      Token: $csrfToken,
      CompanyHashID: $companyHashID,
      FetchCompany: true
    ) {
      Name
      CHID
      LegalForm {
        LegalFormID
        LegalFormUID
        LegalFormName {
          Name
          LanguageCode
        }
      }
      Address {
        AddressInformation {
          Street
          Town
          SwissZipCode
          Country
        }
      }
      CompanyHashID
      Token
    }
  }
`

let queryTimeout

/**
 * ZefixCompanyAutocompleteField
 * @param props
 * @returns {*}
 * @constructor
 */
const ZefixCompanyAutocompleteField = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const { token, setToken, setMessage } = useContext(GlobalContext)
  const [
    zefixCompanySearchParameter,
    setZefixCompanySearchParameter
  ] = useState('')
  const [zefixCompanyCHIDParameter, setZefixCompanyCHIDParameter] = useState('')
  const [zefixCompanies, setZefixCompanies] = useState(false)
  const [zefixCompanyDetails, setZefixCompanyDetails] = useState(false)

  /**
   * GraphQL query for zefix-company-data, gets triggered by calling "executeZefixCompanySuggestQuery()"
   */
  const [resultSuggest, executeZefixCompanySuggestQuery] = useQuery({
    query: queryZefixCompanySuggest,
    variables: {
      searchParameter: zefixCompanySearchParameter,
      csrfToken: props.csrfToken,
      companyHashID: props.companyHashID
    },
    pause: true
  })

  /**
   * GraphQL query for zefix-company-data details, gets triggered by calling "executeZefixCompanyDetailsQuery()"
   */
  const [resultDetails, executeZefixCompanyDetailsQuery] = useQuery({
    query: queryZefixCompanyDetails,
    variables: {
      chidParameter: zefixCompanyCHIDParameter,
      csrfToken: props.csrfToken,
      companyHashID: props.companyHashID
    },
    pause: true
  })

  useEffect(() => {
    if (
      zefixCompanySearchParameter &&
      zefixCompanySearchParameter.length >= minCompanyLengthForSuggest
    ) {
      // Timed query to prevent graph spam
      queryTimeout = setTimeout(() => {
        executeZefixCompanySuggestQuery()
      }, startSuggestDelay)
    }
  }, [
    zefixCompanySearchParameter,
    executeZefixCompanySuggestQuery,
    token,
    setToken,
    props.csrfToken,
    props.companyHashID
  ])

  useEffect(() => {
    if (zefixCompanyCHIDParameter && zefixCompanyCHIDParameter.length > 0) {
      executeZefixCompanyDetailsQuery()
    }
  }, [
    zefixCompanyCHIDParameter,
    executeZefixCompanyDetailsQuery,
    token,
    setToken,
    props.csrfToken,
    props.companyHashID
  ])

  useEffect(() => {
    if (!resultSuggest.fetching) {
      if (resultSuggest.error) {
        ExternalErrorLogger.log({
          text: 'Error fetching Zefix companies on ZefixCompanyAutocompleteField',
          data: {
            errorMessage: resultSuggest.error
          }
        })
        setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.zefix.companies"));
      } else {
        // Query not fetching right now
        if (
          typeof resultSuggest.data !== 'undefined' &&
          typeof resultSuggest.data.readZefixCompanySuggest !== 'undefined' &&
          !zefixCompanies
        ) {
          // Add the companies to the result-array
          setZefixCompanies(resultSuggest.data.readZefixCompanySuggest)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSuggest])

  // GraphQL trigger and result handling for details
  useEffect(() => {
    if (!resultDetails.fetching) {
      if (resultDetails.error) {
        // Something went very wrong
        ExternalErrorLogger.log({
          text:
            'Error fetching Zefix company details on ZefixCompanyAutocompleteField',
          data: {
            errorMessage: resultDetails.error
          }
        })
        setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.zefix.companies"));
      } else {
        // Query not fetching right now
        if (
          typeof resultDetails.data !== 'undefined' &&
          typeof resultDetails.data.readZefixCompanyDetails !== 'undefined'
        ) {
          // Add the companies to the result-array
          setZefixCompanyDetails(resultDetails.data.readZefixCompanyDetails)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultDetails])

  useEffect(() => {
    if (zefixCompanyDetails) {
      if (props.onAutocompleteSelect) {
        props.onAutocompleteSelect(zefixCompanyDetails)
      } else {
        console.error(
          'Autocomplete was not able to notify the parent about the select, please add the fn-prop: onAutocompleteSelect'
        )
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zefixCompanyDetails])

  /**
   * handleCompanySearch
   * @param event
   */
  const handleCompanySearch = event => {
    clearTimeout(queryTimeout)

    // Enable result list but stay focus on input
    setAnchorEl(event.target)
    event.currentTarget.focus()

    // Reset current search-result
    setZefixCompanies(false)

    // Set the current search parameter
    setZefixCompanySearchParameter(event.target.value)
  }

  /**
   * handleCompanySelect
   * @param company
   */
  const handleCompanySelect = company => {
    setZefixCompanyCHIDParameter(company.CHID)
    setAnchorEl(null)
  }

  /**
   * handleClearInput
   */
  const handleClearInput = () => {
    // Disable result list
    setAnchorEl(null)

    // Reset current search-result
    setZefixCompanies(false)

    // Set the current search parameter
    setZefixCompanySearchParameter('')
  }

  /**
   * handleClickAway
   */
  const handleClickAway = () => {
    setAnchorEl(null)
  }

  /**
   * renderList
   * @returns {null|*}
   */
  const renderList = () => {
    if (
      !resultSuggest.fetching &&
      zefixCompanies
    ) {
      return (
        <ClickAwayListener onClickAway={handleClickAway}>
          <List dense open={Boolean(anchorEl)}>
            {zefixCompanies.length > 0 &&
              zefixCompanies.map(company => (
                <ListItem
                  id={`autocomplete-company-result-${company.CHID}`}
                  key={company.CHID}
                  button
                  component='a'
                  onClick={() => {
                    handleCompanySelect(company)
                  }}
                >
                  {company.Name}
                </ListItem>
              ))}
            {zefixCompanies.length === 0 && (
              <ListItem key='Empty'>{t('error.no.zefix.results')}</ListItem>
            )}
          </List>
        </ClickAwayListener>
      )
    }
    return null
  }

  /**
   * return
   */
  return (
    <>
      {resultDetails.fetching && <CircularProgress />}
      {!resultDetails.fetching && (
        <TextField
          id='inputCompanySearch'
          align='left'
          className={classes.searchField}
          label={t('form.label.search.company')}
          margin='normal'
          variant='outlined'
          onChange={handleCompanySearch}
          helperText={props.helperText}
          error={props.error}
          autoFocus
          value={zefixCompanySearchParameter}
          disabled={props.disabled || false}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  id='inputCompanySearch-clear-button'
                  edge='end'
                  aria-label='Clear input'
                  onClick={handleClearInput}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      {!resultDetails.fetching && Boolean(anchorEl) && (
        <div className={classes.autocompleteResultWrapper}>
          <Paper className={classes.autocompleteResult}>
            {renderList()}
            {resultSuggest.fetching && (
              <CircularProgress />
            )}
          </Paper>
        </div>
      )}
    </>
  )
}

export default ZefixCompanyAutocompleteField
