/**
 * Converts a flat key-value json object to query-string.
 * Used for converting json-data to post-data
 *
 * @param flatJsonObject
 * @returns string
 */
const convertJsonToQuery = flatJsonObject => {
  return Object.keys(flatJsonObject)
    .map(key => {
      return (
        encodeURIComponent(key) + '=' + encodeURIComponent(flatJsonObject[key])
      )
    })
    .join('&')
}

export default convertJsonToQuery
