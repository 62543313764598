import { cacheExchange } from "@urql/exchange-graphcache";

const exchange = cacheExchange({
  keys: {
    ApplicationInput: () => null,
    BusinessActivityInput: () => null,
    BusinessActivity: () => null,
    BusinessSpecialityInput: () => null,
    BusinessSpeciality: () => null,
    Canton: () => null,
    CompanyLocation: () => null,
    Company: () => null,
    ConstructionComponent: () => null,
    ConstructionSum: () => null,
    CreditCard: () => null,
    DossierTemplate: () => null,
    ElasticLatestProject: () => null,
    ElasticNewBuildingApplicationLocation: (data) => data.HashID,
    ElasticNewBuildingApplication: () => null,
    ElasticNewBuildingApplicationSearchArea: (data) => data.HashID,
    ElasticProjectAddress: () => null,
    ElasticProjectBuildingOwner: () => null,
    ElasticProjectConstructionSite: () => null,
    ElasticProjectGeo: () => null,
    ElasticProjectProjectManagement: () => null,
    ElasticProject: (data) => data.HashID+data.UserID+data.LocationID,
    EnterpriseElasticProject: () => null,
    EnterpriseElasticResult: () => null,
    ExternalApplicationInput: () => null,
    FavoriteHistoryItem: () => null,
    FavoriteInput: () => null,
    FavoriteNoteInput: () => null,
    FavoriteNote: () => null,
    FavoriteState: () => null,
    Favorite: (data) => data.HashID,
    Feature: () => null,
    FulltextSearchRequest: () => null,
    Invoice: () => null,
    LinkList: () => null,
    Link: () => null,
    LocationInput: () => null,
    Location: () => null,
    MembershipInput: () => null,
    Membership: () => null,
    MicrositeColorScheme: () => null,
    MicrositeImageInput: () => null,
    MicrositeImage: () => null,
    MicrositeInput: () => null,
    MicrositeLogoInput: () => null,
    MicrositeLogo: () => null,
    MicrositeReferencesImageInput: () => null,
    MicrositeReferencesImage: () => null,
    MicrositeReferencesInput: () => null,
    MicrositeReferences: () => null,
    Novalytica: () => null,
    Plan: () => null,
    ProductMetadata: () => null,
    Product: () => null,
    Result: () => null,
    SearchArea: () => null,
    SmartconextConf: () => null,
    StatisticApplication: () => null,
    StatisticDataset: () => null,
    StripeCustomerPortalLink: () => null,
    StripeSetupIntent: () => null,
    UserInput: () => null,
    User: () => null,
    ZefixCompanyAddressInformation: () => null,
    ZefixCompanyAddress: () => null,
    ZefixCompanyDetails: () => null,
    ZefixCompanyLegalFormName: () => null,
    ZefixCompanyLegalForm: () => null,
    ZefixCompanySuggest: () => null,
  },
});

export default exchange;
