import { GOOGLE_API_KEY, googlePlacesApiEndpoint } from '../config'

/**
 * GooglePlacesAPIHelper
 *
 * @returns {{getCantonByCity: (function(*): Promise<any>)}}
 * @constructor
 */
const GooglePlacesAPIHelper = () => {
  /**
   * getCantonByCity
   *
   * @param city
   * @returns {Promise<any>}
   */
  const getCantonByCity = async city => {
    const apiUrl = `${googlePlacesApiEndpoint}/canton/${city}/`
    const result = await window.fetch(apiUrl, {
      // eslint-disable-next-line no-undef
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
    return result.json()
  }

  /**
   * getCoordsByAddress
   *
   * @param address
   * @returns {Promise<any>}
   */
  const getCoordsByAddress = async address => {
    const response = await window.fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`
    )

    return response.json()
  }

  /**
   * return
   */
  return {
    getCantonByCity,
    getCoordsByAddress
  }
}

export default GooglePlacesAPIHelper()
