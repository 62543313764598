import React, { useContext, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQuery } from "urql";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TablePaginationActions from "../components/Table/TablePaginationActions";
import ProjectsTableRow from "../components/Projects/ProjectsTableRow";
import GlobalContext from "../lib/GlobalContext";
import ProjectsLocationsHandler, {
  parseConstructionSumList,
} from "../components/Projects/ProjectsLocationsHandler";
import { useTranslation } from "react-i18next";
import LocationTabs from "../components/LocationTabs";
import withAccessControl from "../components/HOC/AccessControl";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS, isNotLive } from "../config";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import { Chip, Grid } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import BuildingApplicationTypeTabs, {
  BUILDING_APPLICATION_TYPE,
} from "../components/BuildingApplicationTypeTabs";
import GetSafe from "../lib/GetSafe";
import DebugProjects from "../components/Debug/DebugProjects";
import User from "../lib/User";

let keyIndex = 0;
let currentPageRowsDataChunkCache = [];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 1280,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  tableHead: {
    width: "100%",
    height: 64,
    background: "#F7F9FA",
  },
  tableCellHeader: {
    position: "relative",
    height: 64,
    fontWeight: "bold",
  },
  tableCellHeaderInner: {
    display: "flex",
    maxWidth: 1280,
  },
  tableColumnsHead: {
    height: 96,
  },
  tableCellColumnsHeader: {
    padding: "40px 14px 16px 14px",
    fontSize: theme.typography.fontSize,
    color: "#999999",
    fontWeight: "bold",
    "&:first-of-type": {
      paddingLeft: 24,
    },
    "@media (max-width:640px)": {
      "&:first-of-type": {
        paddingLeft: 14,
      },
    },
  },
  tableCellColumnsBody: {
    padding: "20px 14px",
  },
  tableCell: {
    height: 64,
  },
  tableRows: {
    paddingLeft: 36,
  },
  locationHead: {
    fontWeight: "bold",
  },
  locationHandler: {
    width: "auto",
    whiteSpace: "nowrap",
    zIndex: "2",
  },
  icon: {
    padding: 8,
  },
  listContent: {
    paddingLeft: 36,
  },
  paginationInput: {
    height: 28,
    width: 25,
  },
  paginationSelectIcon: {
    color: theme.palette.primary.main,
  },
  dashed: {
    borderBottom: "1px dashed #999999",
  },
  searchFilterGroup: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "auto",
      maxWidth: 200,
    },
  },
  searchFilterItem: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    "@media (max-width:810px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  searchFilterButtonGroup: {
    display: "inline-block",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchFilterButton: {
    marginRight: 4,
    marginLeft: 4,
    fontSize: 14,
    fontWeight: "normal",
    [theme.breakpoints.down("xs")]: {
      marginTop: 4,
      marginBottom: 4,
    },
    "&.MuiChip-colorPrimary": {
      color: "#fff",
    },
    "&.active": {
      background: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const queryProjects = `
  query ReadElasticProjects (
    $locationID: ID,
    $isMainLocation: Boolean,
    $radius: Int,
    $lat: Float,
    $long: Float,
    $limit: Int,
    $offset: Int,
    $filterFor: [String],
    $types: [String],
    $constructionSums: [String]
  ) {
    readElasticProjects (
      IsMainLocation: $isMainLocation,
      sortBy: [{field: Date, direction: DESC}, {field: Sc_score, direction: DESC}, {field: Calculated_distance, direction: ASC}],
      Filter: {LocationID: $locationID, Radius: $radius, Lat: $lat, Long: $long, Types: $types},
      limit: $limit,
      offset: $offset,
      ConstructionTypeContains: $filterFor
      ConstructionSums: $constructionSums
    ){
      HashID
      Final_title
      Notice_number
      Type
      Date
      Canton
      Content
      Calculated_distance
      Distance_calculated_from
      Read
      Approval_date
      Construction_sum
      Construction_site {
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Building_owner {
        Name
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
      Project_management {
        Name
        Phone
        Email
        Website
        Email_other
        Address {
          Street
          Number
          Zip
          City
          Geo {
            lat
            lon
          }
        }
      }
    }
  }
`;

const queryZipSearchAreas = `
  query ReadElasticProjectsByZipSearchArea (
    $searchArea: String,
    $filterFor: [String],
    $types: [String],
    $limit: Int,
    $offset: Int
  ) {
    readElasticProjectsByZipSearchArea(
        Filter: {
            CompanySearchAreaID: $searchArea,
            Types: $types
        },
        ConstructionTypeContains: $filterFor,
        sortBy: [
            {
                field: Date,
                direction: DESC
            }
        ],
        limit: $limit,
        offset: $offset
    )
    {
        HashID
        Final_title
        Import_date
        Canton
        Calculated_distance
        Calculated_duration
        Distance_calculated_from
        Date
        Content
        Read
        Approval_date
        Construction_sum
        Construction_site {
            Address {
                City
                Street
                Number
                Zip
                City
                Geo {
                    lat
                    lon
                }
            }
        }
        Building_owner {
            Name
            Address {
                City
                Street
                Number
                Zip
                City
                Geo {
                    lat
                    lon
                }
            }
        }
        Project_management {
            Name
            Phone
            Email
            Website
            Email_other
            Address {
                City
                Street
                Number
                Zip
                City
                Geo {
                    lat
                    lon
                }
            }
        }
    }
}
`;

const queryFavorites = `
  query ReadFavorites (
    $userHashID: ID
  ) {
    readFavorites(ID: $userHashID) {
      HashID
      ProjectHashID
      Removed
    }
  }
`;

const mutationUpdateElasticBuildingApplicationsNewStatus = `
  mutation (
    $Locations: [InputElasticNewBuildingApplicationLocation]!,
    $LocationHashIDsToMarkAsRead: [String],
    $ProjectIDsToMarkAsRead: [String]
  ) {
    updateElasticBuildingApplicationsNewStatus(
        Locations: $Locations,
        LocationHashIDsToMarkAsRead: $LocationHashIDsToMarkAsRead,
        ProjectIDsToMarkAsRead: $ProjectIDsToMarkAsRead
    ) {
      NewCountPerLocation {
          HashID
          NewCount
          NewCountBaugesuch
          NewCountBaupublikation
          NewCountSubmission
          NewCountBaugesuche
      }
      Projects {
        HashID
        Read
      }
    }
  }
`;

const mutationUpdateSearchAreaBuildingApplicationsNewStatus = `
 mutation (
    $SearchAreaHashIDs: [String]!,
    $SearchAreaHashIDsToMarkAsRead: [String],
    $ProjectIDsToMarkAsRead: [String]
 ) {
    updateSearchAreaBuildingApplicationsNewStatus(
        SearchAreaHashIDs: $SearchAreaHashIDs,
        SearchAreaHashIDsToMarkAsRead: $SearchAreaHashIDsToMarkAsRead,
        ProjectIDsToMarkAsRead: $ProjectIDsToMarkAsRead
    ) {
      NewCountPerSearchArea {
          HashID
          NewCount
          NewCountBaugesuch
          NewCountBaupublikation
          NewCountSubmission
          NewCountBaugesuche
      }
      Projects {
        HashID
        Read
      }
    }
  }
`;

/**
 * Projects
 *
 * @returns {*}
 * @constructor
 */
const Projects = () => {
  let shownProjects = [];
  const classes = useStyles();
  const {
    projectData,
    setProjectData,
    token,
    setToken,
    user,
    selectedLocationTabValue,
    setVisibleProjects,
    visibleProjects,
    setMessage,
    unsetUser,
    unsetToken,
    setSelectedLocationTabValue,
    selectedBuildingApplicationTypeTabValue,
    setSelectedBuildingApplicationTypeTabValue,
    debugToolsEnabled,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [queryVariables, setQueryVariables] = useState({});
  const [localSelectedLocationTabValue, setLocalSelectedLocationTabValue] =
    useState(user.getData().company.hashID);
  const [showNoResultMessage, setShowNoResultMessage] = useState(false);
  const [changeType, setChangeType] = useState(CHANGE_TYPES.INIT);
  const [querySearchFilter, setQuerySearchFilter] = useState([]);
  const [hasNewApplications, setHasNewApplications] = useState(false);
  const [hasNewSubmissions, setHasNewSubmissions] = useState(false);

  // GraphQL query for projects, gets triggered by calling "executeProjectsQuery()"
  const [queryElasticProjectsResult] = useQuery({
    query: user.hasZipSearchAreas() ? queryZipSearchAreas : queryProjects,
    variables: queryVariables,
  });

  // GraphQL query for favorites, gets triggered by calling "executeFavoritesQuery()"
  const [favoritesResult, executeFavoritesQuery] = useQuery({
    query: queryFavorites,
    variables: user.getData().hashID,
    pause: true,
  });

  const [, executeMutationUpdateElasticBuildingApplicationsNewStatus] =
    useMutation(mutationUpdateElasticBuildingApplicationsNewStatus);

  const [, executemutationUpdateSearchAreaBuildingApplicationsNewStatus] =
    useMutation(mutationUpdateSearchAreaBuildingApplicationsNewStatus);

  const emptyShownProjects = () => {
    shownProjects = [];
  };

  const prevQuerySearchFilter = useRef(querySearchFilter);

  /* eslint-disable */
  useEffect(() => {
    if (prevQuerySearchFilter.current !== querySearchFilter) {
      const queryVariablesHandler = () => {
        setPage(0);
        setVisibleProjects([]);
        emptyShownProjects();
        setQueryVariables({ ...queryVariables, filterFor: querySearchFilter });
        prevQuerySearchFilter.current = querySearchFilter;
      };

      if (!querySearchFilter.includes("unread")) {
        markVisibleProjectsRead(queryVariablesHandler);
      } else {
        queryVariablesHandler();
      }
    }
  }, [querySearchFilter]);

  /**
   * setup building application switch and query variables useEffect
   */
  useEffect(() => {
    if (
      selectedBuildingApplicationTypeTabValue ===
      BUILDING_APPLICATION_TYPE.SUBMISSION
    ) {
      setQueryVariables({
        ...queryVariables,
        types: [BUILDING_APPLICATION_TYPE.SUBMISSION],
      });
    } else {
      setQueryVariables({
        ...queryVariables,
        types: [
          BUILDING_APPLICATION_TYPE.PROJECT,
          BUILDING_APPLICATION_TYPE.PUBLICATION,
        ],
      });
    }
  }, [selectedBuildingApplicationTypeTabValue]);

  /**
   * Handle tab and pagination changes
   */
  useEffect(() => {
    if (selectedLocationTabValue) {
      if (selectedLocationTabValue !== localSelectedLocationTabValue) {
        setPage(0);
      }

      setLocalSelectedLocationTabValue(selectedLocationTabValue);

      let currentApplicationTabValue = selectedBuildingApplicationTypeTabValue;
      if (currentApplicationTabValue === BUILDING_APPLICATION_TYPE.PROJECT) {
        currentApplicationTabValue = [
          BUILDING_APPLICATION_TYPE.PROJECT,
          BUILDING_APPLICATION_TYPE.PUBLICATION,
        ];
      }

      let lat = user.getData().company.latitude;
      let long = user.getData().company.longitude;
      let radius = user.getData().company.distance;
      let constructionSums = [];

      const limit = rowsPerPage * 4;
      const offset = rowsPerPage * (page % 3 == 0 ? page : page - (page % 3));

      if (selectedLocationTabValue === user.getData().company.hashID) {
        setQueryVariables({
          locationID: user.getData().company.hashID,
          isMainLocation: true,
          radius: radius,
          lat: lat,
          long: long,
          limit: limit,
          offset: offset,
          filterFor: querySearchFilter,
          types: currentApplicationTabValue,
          searchArea: user.hasZipSearchAreas()
            ? selectedLocationTabValue
            : null,
          constructionSums: parseConstructionSumList(
            GetSafe(() => user.getData().company.constructionSums.edges, [])
          ),
        });
      } else {
        user.getData().locations.map((item) => {
          if (item.HashID === selectedLocationTabValue) {
            radius = item.Radius;
            lat = item.Latitude;
            long = item.Longitude;
            constructionSums = item?.ConstructionSums;
          }
          return item;
        });

        setQueryVariables({
          locationID: user.getData().company.hashID,
          radius: radius,
          lat: lat,
          long: long,
          limit: limit,
          offset: offset,
          filterFor: querySearchFilter,
          types: currentApplicationTabValue,
          searchArea: user.hasZipSearchAreas()
            ? selectedLocationTabValue
            : null,
          constructionSums: constructionSums,
        });
      }
    }
  }, [
    selectedLocationTabValue,
    user,
    rowsPerPage,
    page,
    localSelectedLocationTabValue,
  ]);
  /* eslint-enable */

  useEffect(() => {
    if (page % 3 === 0 && Object.keys(queryVariables).length !== 0) {
      executeFavoritesQuery();
    }
  }, [executeFavoritesQuery, queryVariables, setToken, token.api, page]);

  /**
   * setVisibleProjects useEffect
   */
  useEffect(() => {
    if (shownProjects.length && !visibleProjects.length) {
      setVisibleProjects(shownProjects);
    }
  }, [setVisibleProjects, shownProjects, visibleProjects]);

  /**
   * Mark all projects within shownProjects read that are currently not marked read
   */
  const markVisibleProjectsRead = (callback = null) => {
    const currentPageRowsDataChunk = getProjectRowsChunkForCurrentPage();

    if (currentPageRowsDataChunk.length > 0) {
      const toMarkRead = [];

      const userHashID = user.getData().hashID;
      const userCompanyHashID = user.getData().company.hashID;

      const indexCurrentPageRowsDataChunk = currentPageRowsDataChunk.reduce(
        (prev, curr) => `${prev}${curr.HashID}`,
        ""
      );
      const indexCurrentPageRowsDataChunkCache =
        currentPageRowsDataChunkCache.reduce(
          (prev, curr) => `${prev}${curr.HashID}`,
          ""
        );

      currentPageRowsDataChunkCache = currentPageRowsDataChunk;

      if (
        indexCurrentPageRowsDataChunk !== indexCurrentPageRowsDataChunkCache
      ) {
        currentPageRowsDataChunk.forEach((currentPageProject) => {
          if (!currentPageProject.Read) {
            toMarkRead.push(
              `${userCompanyHashID}-${userHashID}-${currentPageProject.HashID}`
            );
          }
        });

        if (toMarkRead.length) {
          const locations = [];

          if (!user.hasZipSearchAreas()) {
            locations.push({
              IsCompanyLocation: true,
              HashID: user.getData().company.hashID,
              Radius: user.getData().company.distance,
              Lat: user.getData().company.latitude,
              Lon: user.getData().company.longitude,
              ConstructionSums: parseConstructionSumList(
                GetSafe(() => user.getData().company.constructionSums.edges, [])
              ),
            });

            user.getData().locations.map((item) => {
              locations.push({
                IsCompanyLocation: false,
                HashID: item.HashID,
                Radius: item.Radius,
                Lat: item.Latitude,
                Lon: item.Longitude,
                ConstructionSums: item?.ConstructionSums,
              });
              return true;
            });

            // Mark applications as read
            executeMutationUpdateElasticBuildingApplicationsNewStatus({
              Locations: locations,
              ProjectIDsToMarkAsRead: toMarkRead,
            }).then(() => {
              if (callback) {
                callback();
              }
            });
          } else {
            const searchAreaHashIDs = [];

            user.getData().searchAreas.map((item) => {
              searchAreaHashIDs.push(item.HashID);
              return true;
            });

            // Mark applications as read based on zip search-areas
            executemutationUpdateSearchAreaBuildingApplicationsNewStatus({
              SearchAreaHashIDs: searchAreaHashIDs,
              ProjectIDsToMarkAsRead: toMarkRead,
            }).then(() => {
              if (callback) {
                callback();
              }
            });
          }
        } else {
          if (callback) {
            callback();
          }
        }
      } else {
        if (callback) {
          callback();
        }
      }
    } else {
      if (callback) {
        callback();
      }
    }
  };

  /**
   * table column heads
   */
  const columns = [
    { id: "actions", label: t("general.label.actions"), minWidth: 200 },
    {
      id: "projects",
      label: t("general.label.projects"),
      minWidth: 100,
      fontSize: 16,
    },
    {
      id: "city",
      label: t("general.label.address"),
      minWidth: 120,
      align: "left",
    },
    !user.hasZipSearchAreas()
      ? {
          id: "route",
          label: t("general.label.distance"),
          minWidth: 120,
          align: "left",
        }
      : {},
    {
      id: "date",
      label: t("general.label.date"),
      minWidth: 120,
      align: "left",
    },
  ];

  // Unmount effect
  useEffect(
    () => () => {
      markVisibleProjectsRead();
      setSelectedLocationTabValue(false)
      setSelectedBuildingApplicationTypeTabValue(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    // Handling project data coming in via graphql
    if (!queryElasticProjectsResult.fetching) {
      if (queryElasticProjectsResult.error) {
        // Check if the user need to be logged out
        if (
          queryElasticProjectsResult.error.message.indexOf(
            "User forced logout"
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          if (!showNoResultMessage) {
            setShowNoResultMessage(true);
          }
        }
      } else {
        if (
          typeof queryElasticProjectsResult.data !== "undefined" &&
          (typeof queryElasticProjectsResult.data.readElasticProjects !==
            "undefined" ||
            typeof queryElasticProjectsResult.data
              .readElasticProjectsByZipSearchArea !== "undefined")
        ) {
          // Add the projects to the global context, either we have project results within the readElasticProjects or readElasticProjectsByZipSearchArea property
          const actualResult =
            typeof queryElasticProjectsResult.data.readElasticProjects !==
            "undefined"
              ? queryElasticProjectsResult.data.readElasticProjects
              : queryElasticProjectsResult.data
                  .readElasticProjectsByZipSearchArea;

          if (changeType === CHANGE_TYPES.PAGE) {
            // Prevent duplicates by taking previous data into account
            setProjectData((prev) => {
              const updatedProjectData = prev.map((project) => {
                const existingProject = actualResult.find(
                  (newProject) => newProject.HashID === project.HashID
                );
                return existingProject ?? project;
              });

              const filteredNewProjects = actualResult.filter((project) => {
                return (
                  updatedProjectData.findIndex(
                    (updatedProject) => updatedProject.HashID === project.HashID
                  ) < 0
                );
              });

              return [...updatedProjectData, ...filteredNewProjects];
            });
          } else {
            setProjectData(actualResult);
          }

          setShowNoResultMessage(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryElasticProjectsResult,
    changeType,
    user,
    token,
    showNoResultMessage,
  ]);

  const handleChangePage = (event, newPage) => {
    markVisibleProjectsRead();

    if (shownProjects.length) {
      setVisibleProjects(shownProjects);
      shownProjects = [];
    }

    setChangeType(CHANGE_TYPES.PAGE);
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  /**
   * handleChangeRowsPerPage
   *
   * @param event
   */
  const handleChangeRowsPerPage = (event) => {
    markVisibleProjectsRead();

    setChangeType(CHANGE_TYPES.PAGE);
    setRowsPerPage(parseInt(event.target.value, 10));

    // Reset the current project-page data
    shownProjects = [];
    setPage(0);
    setProjectData([]);
    setChangeType(CHANGE_TYPES.TAB);
    setVisibleProjects([]);
    emptyShownProjects();
    setQueryVariables({ ...queryVariables, filterFor: querySearchFilter });
  };

  /**
   * handleTabChange
   *
   * @param event
   * @param newValue
   */
  const handleTabChange = (event, newValue) => {
    if (selectedLocationTabValue !== newValue) {
      markVisibleProjectsRead();

      setSelectedLocationTabValue(newValue);
      setChangeType(CHANGE_TYPES.TAB);

      // Reset the current project-page data
      shownProjects = [];
      setPage(0);
      setProjectData([]);
      setChangeType(CHANGE_TYPES.TAB);
      setVisibleProjects([]);
      emptyShownProjects();
      setQueryVariables({ ...queryVariables, filterFor: querySearchFilter });
    }
  };

  const handleApplicationTypeTabChange = (event, newValue) => {
    markVisibleProjectsRead();

    if (newValue === null) {
      newValue = selectedBuildingApplicationTypeTabValue;
    }

    if (selectedBuildingApplicationTypeTabValue !== newValue) {
      setSelectedBuildingApplicationTypeTabValue(newValue);
      setChangeType(CHANGE_TYPES.TAB);

      // Reset the current project-page data
      shownProjects = [];
      setPage(0);
      setProjectData([]);
      setChangeType(CHANGE_TYPES.TAB);
      setVisibleProjects([]);
      emptyShownProjects();
      setQueryVariables({ ...queryVariables, filterFor: querySearchFilter });
    }
  };

  const addRowToVisibleProjects = (project) => {
    if (project.Read === null) {
      shownProjects.push(project);
    }
  };

  const toggleQuerySearchFilterValue = (filterValue) => {
    let newQuerySearchFilter = [];
    const currentQuerySearchFilter = [];

    for (let index = 0; index < querySearchFilter.length; index++) {
      currentQuerySearchFilter.push(querySearchFilter[index]);
    }

    if (currentQuerySearchFilter.includes(filterValue)) {
      newQuerySearchFilter = currentQuerySearchFilter.filter(
        (element) => element !== filterValue
      );
    } else {
      currentQuerySearchFilter.push(filterValue);
      newQuerySearchFilter = currentQuerySearchFilter;
    }

    setQuerySearchFilter(newQuerySearchFilter);
  };

  const getProjectRowsChunkForCurrentPage = () => {
    if (!projectData) {
      return [];
    }

    return projectData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  const getRenderedProjectRowsChunkForCurrentPage = () => {
    const projectRows = [];
    const slicedProjectData = getProjectRowsChunkForCurrentPage();

    slicedProjectData.map((projectRowData) => {
      addRowToVisibleProjects(projectRowData);
      const favoritesData = favoritesResult.data
        ? favoritesResult.data.readFavorites
        : null;

      projectRows.push(
        <ProjectsTableRow
          key={projectRowData.HashID}
          row={projectRowData}
          classes={classes}
          location={user.getData().company.canton}
          originCoordinates={{
            lat: user.getData().company.latitude,
            lon: user.getData().company.longitude,
          }}
          favorites={favoritesData}
        />
      );

      return true;
    });

    return projectRows;
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell
                align="left"
                padding="none"
                className={classes.tableCellHeader}
                colSpan={5}
              >
                <div className={classes.tableCellHeaderInner}>
                  <LocationTabs
                    setHasNewApplications={setHasNewApplications}
                    setHasNewSubmissions={setHasNewSubmissions}
                    company={user.getData().company}
                    locations={
                      user.hasZipSearchAreas()
                        ? user.getData().searchAreas
                        : user.getData().locations
                    }
                    handleTabChange={handleTabChange}
                  />
                  {!user.hasZipSearchAreas() && (
                    <div className={classes.locationHandler}>
                      <ProjectsLocationsHandler
                        setPage={setPage}
                        setChangeType={setChangeType}
                      />
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
            <TableRow
              className={clsx(classes.tableHead, classes.tableHeadSearchBody)}
            >
              <TableCell
                align="left"
                className={clsx(
                  classes.tableCellHeader,
                  classes.tableCellHeaderSearchBody
                )}
                colSpan={2}
              >
                <BuildingApplicationTypeTabs
                  hasNewApplications={hasNewApplications}
                  hasNewSubmissions={hasNewSubmissions}
                  handleApplicationTypeTabChange={
                    handleApplicationTypeTabChange
                  }
                />
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  classes.tableCellHeader,
                  classes.tableCellHeaderSearchBody
                )}
                colSpan={5}
              >
                <Grid container className={classes.searchFilterGroup}>
                  <Grid item className={classes.searchFilterItem}>
                    <Grid
                      key={"chip-title"}
                      item
                      className={classes.searchFilterButtonGroup}
                    >
                      Filter:
                    </Grid>
                    <Chip
                      key={"chip1"}
                      color={
                        querySearchFilter.includes("unread")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.unread")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("unread") ? (
                          <DoneIcon />
                        ) : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("unread");
                      }}
                    />
                    <Chip
                      key={"chip2"}
                      color={
                        querySearchFilter.includes("new")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.new")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("new") ? <DoneIcon /> : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("new");
                      }}
                    />
                    <Chip
                      key={"chip3"}
                      color={
                        querySearchFilter.includes("modification")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.modification")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("modification") ? (
                          <DoneIcon />
                        ) : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("modification");
                      }}
                    />
                    <Chip
                      key={"chip4"}
                      color={
                        querySearchFilter.includes("demolition")
                          ? "primary"
                          : "default"
                      }
                      label={t("projects.search.demolition")}
                      component="a"
                      clickable
                      icon={
                        querySearchFilter.includes("demolition") ? (
                          <DoneIcon />
                        ) : null
                      }
                      className={classes.searchFilterButton}
                      onClick={(event) => {
                        toggleQuerySearchFilterValue("demolition");
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableColumnsHead}>
              {columns.map((column) => {
                if (column.id === "route") {
                  return (
                    <TableCell
                      key={column.label}
                      align={column.align}
                      style={{
                        maxWidth: column.maxWidth,
                      }}
                      className={classes.tableCellColumnsHeader}
                    >
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={t("general.label.traveldistance.tooltip")}
                        arrow
                      >
                        <span className={classes.dashed}>{column.label}</span>
                      </Tooltip>
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={keyIndex++}
                    align={column.align}
                    style={{
                      maxWidth: column.maxWidth,
                    }}
                    className={classes.tableCellColumnsHeader}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!queryElasticProjectsResult.fetching && showNoResultMessage && (
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{
                    paddingTop: 32,
                    paddingBottom: 32,
                    fontSize: 16,
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  {t("general.label.no.projects")}
                </TableCell>
              </TableRow>
            )}
            {queryElasticProjectsResult.fetching && (
              <React.Fragment key={0}>
                <TableRow>
                  <TableCell
                    colSpan="5"
                    component="th"
                    scope="row"
                    className={classes.tableRows}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
            {!queryElasticProjectsResult.fetching &&
              !showNoResultMessage &&
              projectData && projectData.length !== 0 &&
              getRenderedProjectRowsChunkForCurrentPage()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={5}
                count={projectData ? projectData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false,
                  renderValue: () => <></>,
                }}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from} - ${to === -1 ? count : to}`;
                }}
                labelRowsPerPage={
                  <>
                    {rowsPerPage} {t("general.label.rows")}
                  </>
                }
                classes={{
                  input: classes.paginationInput,
                  selectIcon: classes.paginationSelectIcon,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
      {(!User.isMasquerade() && (isNotLive || User.isAppAdmin())) && debugToolsEnabled && <DebugProjects />}
    </Paper>
  );
};

export const CHANGE_TYPES = {
  INIT: "init",
  PAGE: "page",
  TAB: "tab",
};

export default withAccessControl(
  Projects,
  ACL_COMPONENTS.PROJECTS,
  "projects-free"
);
