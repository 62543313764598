import React, { useContext, useState } from 'react'
import withAccessControl from '../HOC/AccessControl'
import { ACL_COMPONENTS } from '../../config'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CompanyDirectoryNameDialog from './CompanyDirectoryNameDialog'
import GlobalContext from '../../lib/GlobalContext'
import CompanyReferencesForm from './CompanyReferencesForm'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '100%',
    maxWidth: 1280,
    padding: '16px 24px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start'
    }
  },
  gridItem: {
    width: '70%',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  button: {
    color: '#ffffff',
    width: '100%',
    marginTop: '20px',
    marginBottom: '40px'
  },
  img: {
    width: '20%',
    padding: '8px 8px 8px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  label: {
    color: theme.palette.text.primary
  },
  root: {
    width: '100%'
  },
  dialogRoot: {
    flexGrow: 1
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 0
  },
  dialog: {
    width: '100%',
    paddingBottom: 56,
    '&::before': theme.watermarkLogo,
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingBottom: 20
    }
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '30px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  },
  startButton: {
    width: '100%',
    color: '#fff',
    fontWeight: 'bold'
  },
  textFieldSmall: {
    width: '50%',
    '&:first-of-type': {
      width: 'calc(50% - 24px)',
      marginRight: 24
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      '&:first-of-type': {
        width: '100%'
      }
    }
  },
  buttonImg: {
    color: '#ffffff',
    marginBottom: '20px'
  },
  logoImg: {
    width: '50%',
    padding: '8px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  imagesError: {
    fontSize: '0.75rem',
    marginLeft: 14,
    marginRight: 14,
    color: '#ff1744'
  },
  addButtonText: {
    paddingTop: 3,
    paddingRight: 15,
    paddingBottom: 3,
    paddingLeft: 15,
    fontWeight: 'bold'
  },
  addReferenceButton: {
    color: theme.palette.primary.main,
    border: '1px solid rgba(0, 179, 207, 0.5)',
    backgroundColor: 'white'
  },
  listItemPointer: {
    cursor: 'pointer'
  },
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const initialFormValues = {
  Sort: null,
  Title: '',
  Content: '',
  Location: '',
  Month: '',
  Year: '',
  Images: []
}

const initialFormValueErrorsState = {
  Title: { hasError: false, message: '' },
  Content: { hasError: false, message: '' },
  Location: { hasError: false, message: '' },
  Month: { hasError: false, message: '' },
  Year: { hasError: false, message: '' },
  Images: { hasError: false, message: '' }
}

/**
 * CompanyReferences
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyReferences = () => {
  const dialogContent = null
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(GlobalContext)
  const [references, setReferences] = useState(null)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  )
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(
    user.getData().company.urlSegment
  )

  /**
   * return
   */
  return (
    <>
      <CompanyDirectoryNameDialog
        formValues={formValues}
        setFormValues={setFormValues}
        verifyDialogOpen={verifyDialogOpen}
        setVerifyDialogOpen={setVerifyDialogOpen}
      />
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        margin='normal'
        padding='normal'
        className={classes.gridContainer}
      >
        <CompanyReferencesForm
          classes={classes}
          formValues={formValues}
          setFormValues={setFormValues}
          setFormValueErrors={setFormValueErrors}
          formValueErrors={formValueErrors}
          initialFormValues={initialFormValues}
          initialFormValueErrorsState={initialFormValueErrorsState}
          references={references}
          setReferences={setReferences}
          dialogContent={dialogContent}
          introText={t('company.label.references.intro')}
        />
      </Grid>
    </>
  )
}

export default withAccessControl(
  CompanyReferences,
  ACL_COMPONENTS.COMPANY_REFERENCES
)
