import React from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'
import withAccessControl from '../HOC/AccessControl'
import CreditCardForm from './CreditCardForm'
import { ACL_COMPONENTS } from '../../config'

/**
 * CreditCard
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CreditCard = props => {
  /**
   * return
   */
  return (
    <StripeProvider apiKey={props.stripePublicKey}>
      <Elements>
        <CreditCardForm submitIsDisabled={props.submitIsDisabled} />
      </Elements>
    </StripeProvider>
  )
}

export default withAccessControl(CreditCard, ACL_COMPONENTS.CREDIT_CARD)
