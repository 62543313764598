import React, { useContext, useEffect, useState } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import GlobalContext from '../lib/GlobalContext'

/**
 * MESSAGE_TYPES
 */
export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const variantDuration = {
  success: 6000,
  warning: 6000,
  error: null,
  info: 6000
}

const useStylesSnackbarContentWrapper = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: '#006A7A'
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

/**
 * MySnackbarContentWrapper
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const MySnackbarContentWrapper = props => {
  const classes = useStylesSnackbarContentWrapper()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={classes.icon} />
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </span>
      }
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
          id='close_message'
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}))

/**
 * Message
 *
 * @returns {*}
 * @constructor
 */
const Message = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { message } = useContext(GlobalContext)

  /**
   * useEffect
   */
  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message])

  /**
   * handleClose
   *
   * @param event
   * @param reason
   */
  function handleClose (event, reason) {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  /**
   * return
   */
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={open}
        autoHideDuration={variantDuration[message.code]}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={message.code}
          className={classes.margin}
          message={message.message}
        />
      </Snackbar>
    </div>
  )
}

export default Message
