import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import { navigate } from "hookrouter";
import Joi from "@hapi/joi";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import GetSafe from "../../lib/GetSafe";
import CantonShortener from "../../lib/CantonShortener";
import { useMutation, useQuery } from "urql";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { MESSAGE_TYPES } from "../PopupMessages";
import { ACL_COMPONENTS, CANTON_LIST, STRIPE_PLAN_IDS } from "../../config";
import withAccessControl, { parsePermissions } from "../HOC/AccessControl";
import GooglePlacesAPIHelper from "../../lib/GooglePlacesAPIHelper";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { externalLinkTitles, getAbsoluteLink } from "../../lib/CmsLinkHandler";
import FormHelperText from "@material-ui/core/FormHelperText";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import FormatHelper from "../../lib/FormatHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  startButton: {
    width: "100%",
    maxWidth: 330,
    color: "#fff",
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
  },
  textFieldSmall: {
    width: 160,
    "&:first-of-type": {
      marginRight: 5,
    },
    "&:last-of-type": {
      marginLeft: 5,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        marginRight: 0,
        marginBottom: 20,
      },
      "&:last-of-type": {
        marginLeft: 0,
      },
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  item: {
    width: "100%",
    maxWidth: 330,
    paddingTop: 20,
    paddingBottom: 20,
  },
  itemHeader: {
    maxWidth: 400,
    paddingTop: 44,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  itemPassword: {
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
  itemCanton: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

/**
 * updateMyselfMutation
 *
 * @type {string}
 */
const updateMyselfMutation = `
  mutation UpdateMyself(
    $email: String!,
    $password: String,
    $firstName: String, 
    $lastName: String
  ) {
    updateMyself(
      Email: $email,
      Password: $password,
      Firstname: $firstName,
      Surname: $lastName
      RegistrationHash: ""
    ) {
      Email
      Firstname
      Surname
    }
  }
`;

/**
 * updateCompanyMutation
 *
 * @type {string}
 */
const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $canton: String,
    $latitude: Float,
    $longitude: Float,
    $futurePlanID: String
  ) {
    updateCompany(
      HashID: $hashId, 
      Canton: $canton,
      Latitude: $latitude,
      Longitude: $longitude,
      FuturePlanID: $futurePlanID
    ) {
      Name
      FeatureSet
      Street
      Zip
      City
      Canton
      Phone
      Mobile
      Fax
      Website
      Email
      BusinessActivityID
      BusinessSpecialities{
        edges{
          node{
            ID
            Title
          }
        }
      }
    }
  }
`;

const queryMyself = `
{
  readMyself {
    ID
    HashID
    Locale
    Email
    Firstname
    Surname
    Salutation
    StripePublicKey
    CreditCard {
      id
      name
      last4
      expired
    }
    Locations {
      edges {
        node {
          HashID
          Title
          Street
          Zip
          City
          Longitude
          Latitude
          Radius
          Version
          Sort
          Removed
        }
      }
    }
    Company {
      ID
      AdminUserID
      HashID
      FeatureSet
      ZipSearchAreas
      ZipSearchStatistic
      ApplicationFollowUpTimeInDays
      StripeIsTrialing
      StripeTrialingDays
      StripePlanID
      StripeValidThrough
      StripeProductID
      StripeCancelAt
      Name
      URLSegment
      ContactPerson
      Street
      Zip
      City
      Canton
      Distance
      Latitude
      Longitude
      Phone
      Mobile
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
      FoundingYear
      MasterBusiness
      TrainingCompany
      BusinessActivityID
      BusinessSpecialities{
        edges {
          node {
            ID
            Title
            NogaCode
            SCCodeCompetence
            BoostScore
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
      User{
        edges {
          node {
            HashID
            Email
            Firstname
            Surname
            Removed
            SearchAreas {
              HashID
              Title
              ZipList
            }
          }
        }
      }
      Memberships {
        edges {
          node {
            ID
            Title
            LogoUrl
          }
        }
      }
      AllMemberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      MicrositeCertified
      MicrositeCertifiedUntil
      AdditionalMemberships
      MicrositeColorScheme
      MicrositeDescription
      MicrositeEmailApplicationText
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeLogo {
        Src
        Title
        Alt
      }
      MicrositeReferences {
          edges {
            node {
              Sort
              Title
              Content
              Location
              Year
              Month
              ButtonLink
              ButtonTitle
              MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
            }
          }
        }
        ApiAccess {
          edges {
            node {
              RequestUrl
            }
          } 
        }  
    }
    DossierTemplates {
      edges {
        node {
          HashID
          TemplateName
        }
      }
    }
    Permissions {
      Name
      RequireAdminStatus
    }
    FulltextSearchRequests {
      ID
      Title
    }
    SearchAreas {
      HashID
      Title
      ZipList
    }
  }
  readProduct {
    plans {
      id
      trial_possible
    }
  }
}
`;

/**
 * OnboardingFreeUserWelcomeDialog
 *
 * @returns {*}
 * @constructor
 */
const OnboardingFreeUserWelcomeDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setOpenWelcomeDialog,
    user,
    setUser,
    token,
    setMessage,
    unsetUser,
    unsetToken,
    setUpgradeToPremiumOpen,
    cmsLinks,
  } = useContext(GlobalContext);
  const [formValues, setFormValues] = useState({
    password: "",
    showPassword: false,
    firstName: "",
    lastName: "",
    canton: CantonShortener(user.getData().company.canton),
    termsPolicy: false,
  });
  const [formValueErrors, setFormValueErrors] = useState({
    password: { hasError: false, message: "" },
    firstName: { hasError: false, message: "" },
    lastName: { hasError: false, message: "" },
    termsPolicy: { hasError: false, message: "" },
  });
  const [disableButton, setDisableButton] = useState(false);

  const [{ fetching }, executeMutation] = useMutation(updateMyselfMutation);
  const [{ fetchingUpdateCompany }, executeUpdateCompanyMutation] = useMutation(
    updateCompanyMutation
  );

  /**
   * GraphQL query for user-data (myself), gets triggered by calling "executeMyselfQuery()"
   */
  const [resultMyself, executeMyselfQuery] = useQuery({
    query: queryMyself,
    variables: {},
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * useEffect
   */
  useEffect(() => {
    setFormValues({
      password: "",
      showPassword: false,
      firstName: user.getData().firstName,
      lastName: user.getData().lastName,
      canton: CantonShortener(user.getData().company.canton),
      termsPolicy: false,
    });
  }, [user]);

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    password: Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    showPassword: Joi.boolean(),
    canton: Joi.string().required(),
    termsPolicy: Joi.boolean().invalid(false),
  });

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!resultMyself.fetching && token.isSet()) {
      if (resultMyself.error) {
        // Check if the user need to be logged out
        if (resultMyself.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text: "Error fetching user data on OnboardingFreeUserWelcomeDialog",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultMyself.error.message,
            },
          });
        }
      } else {
        // Query not fetching right now
        if (
          typeof resultMyself.data !== "undefined" &&
          typeof resultMyself.data.readMyself !== "undefined"
        ) {
          const branchSubsData = [];
          resultMyself.data.readMyself[0].Company.BusinessSpecialities.edges.map(
            (item) => {
              branchSubsData.push(parseInt(item.node.ID));
              return branchSubsData;
            }
          );

          const memberships = [];
          resultMyself.data.readMyself[0].Company.Memberships.edges.map(
            (item) => {
              memberships.push(item.node);
              return memberships;
            }
          );

          const allMemberships = [];
          resultMyself.data.readMyself[0].Company.AllMemberships.edges.map(
            (item) => {
              allMemberships.push(item.node);
              return allMemberships;
            }
          );

          const locationData = [];
          resultMyself.data.readMyself[0].Locations.edges.map((item) => {
            const constructionSums = [];
            item.node.ConstructionSumList.edges.map((constructionSum) => {
              constructionSums.push(constructionSum.node.Code);
              return constructionSums;
            });

            item.node.ConstructionSums = constructionSums;

            locationData.push(item.node);
            return locationData;
          });

          locationData.sort((a, b) => {
            return a.Sort - b.Sort;
          });

          const companyUsersData = [];
          resultMyself.data.readMyself[0].Company.User.edges.map((item) => {
            if (item.node.HashID !== resultMyself.data.readMyself[0].HashID) {
              companyUsersData.push(item.node);
            }
            return companyUsersData;
          });

          const dossierTemplatesData = [];
          resultMyself.data.readMyself[0].DossierTemplates.edges.map((item) => {
            dossierTemplatesData.push(item.node);
            return dossierTemplatesData;
          });

          companyUsersData.sort((a, b) => {
            if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
              return -1;
            }
            if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          let trialPossible = false;

          resultMyself.data.readProduct.map((productItem) => {
            productItem.plans.map((item) => {
              if (item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY)) {
                trialPossible = item.trial_possible;
              }
              return item;
            });
            return productItem;
          });

          // Map the user-data
          user.setData({
            id: resultMyself.data.readMyself[0].ID,
            hashID: resultMyself.data.readMyself[0].HashID,
            locale: resultMyself.data.readMyself[0].Locale || "",
            email: resultMyself.data.readMyself[0].Email,
            firstName: resultMyself.data.readMyself[0].Firstname,
            lastName: resultMyself.data.readMyself[0].Surname,
            salutation: resultMyself.data.readMyself[0].Salutation || "",
            mode:
              resultMyself.data.readMyself[0].Company.FeatureSet === null
                ? "FREE"
                : resultMyself.data.readMyself[0].Company.FeatureSet.toLowerCase(),
            stripePublicKey:
              resultMyself.data.readMyself[0].StripePublicKey || "",
            creditCard: resultMyself.data.readMyself[0].CreditCard,
            locations: locationData || [],
            company: {
              id: resultMyself.data.readMyself[0].Company.ID,
              hashID: resultMyself.data.readMyself[0].Company.HashID,
              name:
                resultMyself.data.readMyself[0].Company.Name ||
                "TODO COMPANY-NAME", // TODO: Remove fallback company name for production (requires consistent data)
              street: resultMyself.data.readMyself[0].Company.Street || "",
              zip: resultMyself.data.readMyself[0].Company.Zip || "",
              city: resultMyself.data.readMyself[0].Company.City || "",
              canton: resultMyself.data.readMyself[0].Company.Canton || "",
              distance: resultMyself.data.readMyself[0].Company.Distance || 50,
              latitude: resultMyself.data.readMyself[0].Company.Latitude || null,
              longitude:
                resultMyself.data.readMyself[0].Company.Longitude || null,
              phone: resultMyself.data.readMyself[0].Company.Phone || "",
              mobile: resultMyself.data.readMyself[0].Company.Mobile || "",
              fax: resultMyself.data.readMyself[0].Company.Fax || "",
              email: resultMyself.data.readMyself[0].Company.Email || "",
              website: resultMyself.data.readMyself[0].Company.Website || "",
              employeeCount:
                resultMyself.data.readMyself[0].Company.NumberOfEmployees || "",
              legalForm: resultMyself.data.readMyself[0].Company.LegalForm || "",
              foundingYear:
                resultMyself.data.readMyself[0].Company.FoundingYear || "",
              masterOperation:
                FormatHelper.boolToString(
                  resultMyself.data.readMyself[0].Company.MasterBusiness
                ) || "",
              trainingCompany:
                FormatHelper.boolToString(
                  resultMyself.data.readMyself[0].Company.TrainingCompany
                ) || "",
              branchCategory:
                resultMyself.data.readMyself[0].Company.BusinessActivityID || "",
              branchSubs: branchSubsData || [],
              users: companyUsersData || [],
              stripeIsTrialing:
                resultMyself.data.readMyself[0].Company.StripeIsTrialing,
              stripeTrialingDays:
                resultMyself.data.readMyself[0].Company.StripeTrialingDays,
              stripePlanId: resultMyself.data.readMyself[0].Company.StripePlanID,
              stripeValidThrough:
                resultMyself.data.readMyself[0].Company.StripeValidThrough,
              stripeProductId:
                resultMyself.data.readMyself[0].Company.StripeProductID,
              stripeCancelAt:
                resultMyself.data.readMyself[0].Company.StripeCancelAt,
              stripeFuturePlans:
                resultMyself.data.readMyself[0].Company.StripeFuturePlans,
              trialPossible: trialPossible,
              memberships: memberships,
              allMemberships: allMemberships,
              zipSearchAreas:
                resultMyself.data.readMyself[0].Company.ZipSearchAreas,
              zipSearchStatistic:
                resultMyself.data.readMyself[0].Company.ZipSearchStatistic,
              applicationFollowUpTimeInDays:
                resultMyself.data.readMyself[0].Company.ApplicationFollowUpTimeInDays
            },
            dossierTemplates: dossierTemplatesData,
            permissions: parsePermissions(
              resultMyself.data.readMyself[0].Permissions
            ),
            fulltextSearchRequests:
              resultMyself.data.readMyself[0].FulltextSearchRequests,
            searchAreas: resultMyself.data.readMyself[0].SearchAreas,
          });

          // Add the user to global context
          setUser(user);

          // Close upgrade process
          setUpgradeToPremiumOpen(false);

          // Close the welcome dialog
          setOpenWelcomeDialog(false);

          // Redirect
          navigate("/projects-free");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultMyself, token]);

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = () => {
    setDisableButton(true);
    const { error } = schema.validate(formValues, { abortEarly: false });
    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      setDisableButton(false);
    } else {
      executeMutation({
        email: user.getData().email,
        password: formValues.password,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
      }).then((result) => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
            ExternalErrorLogger.log({
              text: "Error submit user data on OnboardingFreeUserWelcomeDialog",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
          }
        } else {
          GooglePlacesAPIHelper.getCoordsByAddress(
            CANTON_LIST[formValues.canton]
          ).then((result) => {
            const lat = result.results[0].geometry.location.lat;
            const lon = result.results[0].geometry.location.lng;

            executeUpdateCompanyMutation({
              hashId: user.getData().company.hashID,
              canton: CANTON_LIST[formValues.canton],
              latitude: lat,
              longitude: lon,
              futurePlanID: "plan_gratis",
            }).then((updateCompanyMutationResult) => {
              if (updateCompanyMutationResult.error) {
                // Check if the user need to be logged out
                if (
                  updateCompanyMutationResult.error.message.indexOf(
                    "User forced logout"
                  ) !== -1
                ) {
                  setMessage(
                    MESSAGE_TYPES.ERROR,
                    t("error.user.forced.logout")
                  );
                  unsetUser();
                  unsetToken();
                  navigate("/");
                } else {
                  setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
                  ExternalErrorLogger.log({
                    text: "Error update company data on OnboardingFreeUserWelcomeDialog",
                    data: {
                      token: JSON.stringify(token.getData()),
                      user: JSON.stringify(user.getData()),
                      errorMessage: updateCompanyMutationResult.error,
                    },
                  });
                }
              } else {
                executeMyselfQuery();
              }
            });
          });
        }
      });
    }
  };

  /**
   * handlePasswordChange
   *
   * @param event
   */
  const handlePasswordChange = (event) => {
    setFormValues({ ...formValues, password: event.target.value });
  };

  /**
   * handleFirstNameChange
   *
   * @param event
   */
  const handleFirstNameChange = (event) => {
    setFormValues({ ...formValues, firstName: event.target.value });
  };

  /**
   * handleLastNameChange
   *
   * @param event
   */
  const handleLastNameChange = (event) => {
    setFormValues({ ...formValues, lastName: event.target.value });
  };

  /**
   * handleCantonChange
   *
   * @param event
   */
  const handleCantonChange = (event) => {
    setFormValues({ ...formValues, canton: event.target.value });
  };

  /**
   * handleClickShowPassword
   */
  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  /**
   * handleCheckTermsPolicyChange
   */
  const handleCheckTermsPolicyChange = () => {
    setFormValues({ ...formValues, termsPolicy: !formValues.termsPolicy });
  };

  /**
   * menuItems
   *
   * @returns {[]}
   */
  const menuItems = () => {
    const menuItemArray = [];
    Object.keys(CANTON_LIST).forEach((index) => {
      menuItemArray.push(
        <MenuItem key={index} value={index}>
          {CANTON_LIST[index]}
        </MenuItem>
      );
    });
    return menuItemArray;
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.greeting")}</h1>
        <p>{t("onboarding.intro")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemPassword)}>
        <TextField
          id="outlined-adornment-password"
          className={classes.textField}
          variant="outlined"
          align="left"
          error={GetSafe(() => formValueErrors.password.hasError, false)}
          type={formValues.showPassword ? "text" : "password"}
          label={t("form.label.password")}
          value={formValues.password}
          helperText={GetSafe(() => formValueErrors.password.message, "")}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id="inputFirstName"
          className={classes.textFieldSmall}
          label={t("form.label.firstName")}
          variant="outlined"
          align="left"
          value={formValues.firstName}
          error={GetSafe(() => formValueErrors.firstName.hasError, false)}
          helperText={GetSafe(() => formValueErrors.firstName.message, "")}
          onChange={handleFirstNameChange}
        />
        <TextField
          id="inputLastName"
          className={classes.textFieldSmall}
          label={t("form.label.lastName")}
          variant="outlined"
          align="left"
          value={formValues.lastName}
          error={GetSafe(() => formValueErrors.lastName.hasError, false)}
          helperText={GetSafe(() => formValueErrors.lastName.message, "")}
          onChange={handleLastNameChange}
        />
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemCanton)}>
        <TextField
          id="selectCanton"
          className={classes.textField}
          label={t("form.label.canton")}
          variant="outlined"
          select
          align="left"
          value={formValues.canton}
          onChange={handleCantonChange}
        >
          {menuItems()}
        </TextField>
      </Grid>
      <Grid item className={classes.item}>
        <FormControl
          required
          error={GetSafe(() => formValueErrors.termsPolicy.hasError, false)}
          component="fieldset"
          className={classes.formControl}
        >
          <FormControlLabel
            className={classes.checkboxControl}
            control={
              <Checkbox
                id="checkbox-condition-2"
                checked={formValues.termsPolicy}
                color="primary"
                onChange={handleCheckTermsPolicyChange}
                value={1}
              />
            }
            label={
              <div>
                {t("form.label.i.accept")}{" "}
                <Link
                  target="_blank"
                  href={getAbsoluteLink(
                    cmsLinks,
                    "CmsMenu",
                    externalLinkTitles.TERMS
                  )}
                >
                  {t("form.label.terms.of.use")}
                </Link>{" "}
                {t("general.label.and")}{" "}
                <Link
                  target="_blank"
                  href={getAbsoluteLink(
                    cmsLinks,
                    "CmsMenu",
                    externalLinkTitles.DATAPROTECTION
                  )}
                >
                  {t("form.label.privacyPolicy")}.
                </Link>
              </div>
            }
          />
          {GetSafe(() => formValueErrors.termsPolicy.hasError, false) && (
            <FormHelperText id="checkbox-condition-2-helper-text">
              {formValueErrors.termsPolicy.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item className={classes.item}>
        <Button
          id="buttonFormSubmit"
          variant="contained"
          color="primary"
          className={classes.startButton}
          onClick={handleFormSubmit}
          disabled={disableButton}
        >
          {t("form.label.start.free")}
          {(disableButton || fetching || fetchingUpdateCompany) && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </>
  );
};

export default withAccessControl(
  OnboardingFreeUserWelcomeDialog,
  ACL_COMPONENTS.ONBOARDING_FREE_USER_WELCOME_DIALOG
);
