/**
 * Configuration file for the react-external-errorlogger
 *
 * https://bitbucket.org/eformation/react-external-errorlogger/
 */
import ExternalErrorLogger from '@ennit/react-external-errorlogger'

ExternalErrorLogger.configure(
  window.location.origin + '/rc', // nginx proxy setting
  '#prj-smartconext-errors',
  'Smartconext.Error.Bot',
  'https://www.smartconext-bau.ch/assets/Images/Favicons/smartconext-favicon-32x32.png'
)
