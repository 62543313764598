import { CANTON_LIST } from '../config'

/**
 * Fetches the short term for a canton (key)
 * Defaults to ZH (Zürich)
 *
 * @param canton
 * @returns string
 */
const getCantonShort = canton => {
  let foundShort = 'ZH'
  Object.keys(CANTON_LIST).forEach(index => {
    if (CANTON_LIST[index] === canton) {
      foundShort = index
    }
  })
  return foundShort
}

export default getCantonShort
