import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(8)
  }
}))

/**
 * TablePaginationActions
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TablePaginationActions = props => {
  const classes = useStyles()
  const { count, page, rowsPerPage, onChangePage } = props

  /**
   * handleFirstPageButtonClick
   *
   * @param event
   */
  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  /**
   * handleBackButtonClick
   *
   * @param event
   */
  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  /**
   * handleNextButtonClick
   *
   * @param event
   */
  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  /**
   * return
   */
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        color='primary'
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        color='primary'
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        color='primary'
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}

export default TablePaginationActions
