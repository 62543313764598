import React, { useContext, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../lib/GlobalContext";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import { useMutation } from "urql";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { MESSAGE_TYPES } from "../PopupMessages";
import withAccessControl from "../HOC/AccessControl";
import GooglePlacesAPIHelper from "../../lib/GooglePlacesAPIHelper";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import Joi from "@hapi/joi";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import CompanyBasicSettingsForm from "./CompanyBasicSettingsForm";
import UpdateCompanyDataDialog from "../UpdateCompanyDataDialog";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: "100%",
    maxWidth: 1280,
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  gridItem: {
    width: "50%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textFieldSmall: {
    width: "50%",
    "&:first-of-type": {
      width: "calc(50% - 24px)",
      marginRight: 24,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:first-of-type": {
        width: "100%",
      },
    },
  },
  button: {
    color: "#ffffff",
    width: "100%",
    marginTop: "20px",
    marginBottom: "40px",
  },
  branchSubCategories: {
    width: "50%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  radioButtonHelperText: {
    color: "#ff1645",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formLabel: {
    color: theme.palette.text.primary,
  },
  input: {
    marginLeft: 60,
  },
  itemPhone: {
    height: 80,
    overflow: "hidden",
    "&.state-phoneField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
  itemMobile: {
    height: 80,
    overflow: "hidden",
    "&.state-mobileField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
  itemFax: {
    height: 80,
    overflow: "hidden",
    "&.state-faxField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
}));

const initialFormValuesState = {
  id: "",
  name: "",
  contactPerson: "",
  street: "",
  zip: "",
  city: "",
  phone: "",
  mobile: "",
  fax: "",
  website: "",
  email: "",
};

const initialFormValueErrorsState = {
  id: { hasError: false, message: "" },
  name: { hasError: false, message: "" },
  contactPerson: { hasError: false, message: "" },
  street: { hasError: false, message: "" },
  zip: { hasError: false, message: "" },
  city: { hasError: false, message: "" },
  phone: { hasError: false, message: "" },
  mobile: { hasError: false, message: "" },
  fax: { hasError: false, message: "" },
  website: { hasError: false, message: "" },
  email: { hasError: false, message: "" },
};

const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $name: String!,
    $contactPerson: String!,
    $street: String!,
    $zip: String!,
    $city: String!,
    $lat: Float,
    $lon: Float,
    $phone: String!,
    $mobile: String!,
    $fax: String!,
    $website: String!,
    $email: String!
  ) {
    updateCompany(
      HashID: $hashId, 
      Name: $name,
      ContactPerson: $contactPerson,
      Street: $street,
      Zip: $zip,
      City: $city,
      Latitude: $lat,
      Longitude: $lon,
      Phone: $phone,
      Mobile: $mobile,
      Fax: $fax,
      Website: $website,
      Email: $email
    ) {
      Name
      ContactPerson
      Street
      Zip
      City
      Latitude
      Longitude
      Phone
      Mobile
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
    }
  }
`;

/**
 * CompanyBasicSettings
 *
 * @returns {*}
 * @constructor
 */
const CompanyBasicSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    setUser,
    token,
    setMessage,
    unsetUser,
    unsetToken,
    googleMapsIsLoaded,
  } = useContext(GlobalContext);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );
  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);
  const [streetFormValue, setStreetFormValue] = useState("");
  const [zipFormValue, setZipFormValue] = useState("");
  const [cityFormValue, setCityFormValue] = useState("");

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    id: Joi.number(),
    hashID: Joi.string().required(),
    name: Joi.string().required(),
    contactPerson: Joi.string().required(),
    street: Joi.string().trim().required(),
    zip: Joi.string().trim().required(),
    city: Joi.string().trim().required(),
    phone: Joi.string().allow(""),
    mobile: Joi.string().allow(""),
    website: Joi.string()
      .regex(
        /https?:\/\/(www\.)?[-a-zA-ZöäüÖÄÜ0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/
      )
      .allow(""),
    fax: Joi.string().allow(""),
    email: Joi.string().email({ tlds: false }).allow(""),
  });

  /**
   * useEffect
   */
  useEffect(() => {
    const userData = user.getData();
    setFormValues({
      id: userData.company.hashID,
      name: userData.company.name || initialFormValuesState.name,
      contactPerson:
        userData.company.contactPerson !== ""
          ? userData.company.contactPerson
          : userData.firstName + " " + userData.lastName,
      street: userData.company.street || initialFormValuesState.street,
      zip: userData.company.zip || initialFormValuesState.zip,
      city: userData.company.city || initialFormValuesState.city,
      phone: userData.company.phone || initialFormValuesState.phone,
      mobile: userData.company.mobile || initialFormValuesState.mobile,
      fax: userData.company.fax || initialFormValuesState.fax,
      website: userData.company.website || initialFormValuesState.website,
      email: userData.company.email || initialFormValuesState.email,
    });
  }, [user]);

  /**
   * determineCountry
   *
   * checks if googlePlacesResult is in CH.
   *
   * @param googlePlacesResult
   * @returns {number}
   */
  const determineCountry = (googlePlacesResult) => {
    let isInCH = [];
    googlePlacesResult.results.map((item) => {
      isInCH = item.address_components.filter((addressComponent) => {
        return addressComponent.short_name === "CH";
      });
      return item;
    });
    return isInCH.length;
  };

  /**
   * determineZip
   *
   * @param googlePlacesResult
   * @returns {*}
   */
  const determineZip = (googlePlacesResult) => {
    let zip = formValues.zip;
    googlePlacesResult.results.map((item) => {
      item.address_components.map((comp) => {
        if (comp.types[0] === "postal_code") {
          zip = comp.long_name;
        }
        return zip;
      });
      return zip;
    });
    return zip;
  };

  /**
   * handleFormSubmit
   */
  const handleFormSubmit = () => {
    if (
      formValues.street.trim() === "" ||
      formValues.street !== streetFormValue
    ) {
      formValues.street = streetFormValue;
    }
    if (formValues.zip !== zipFormValue) {
      formValues.zip = zipFormValue;
    }
    if (formValues.city.trim() === "" || formValues.city !== cityFormValue) {
      formValues.city = cityFormValue;
    }

    if (
      formValues.website !== "" &&
      !formValues.website.includes("http://") &&
      !formValues.website.includes("https://")
    ) {
      formValues.website = "https://" + formValues.website;
    }

    const formData = {
      hashID: user.getData().company.hashID,
      name: formValues.name,
      contactPerson: formValues.contactPerson,
      street: formValues.street,
      zip: formValues.zip,
      city: formValues.city,
      phone: formValues.phone,
      mobile: formValues.mobile,
      fax: formValues.fax,
      website: formValues.website,
      email: formValues.email,
    };
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      console.log(error);
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      window.scrollTo(0, 0);
    } else {
      user.setData({ company: { ...formData } });
      setFormValueErrors(initialFormValueErrorsState);

      const addressString = `${formValues.street}+${
        typeof formValues.number !== "undefined" ? formValues.number + "+" : ""
      }${formValues.zip}+${formValues.city}`;

      GooglePlacesAPIHelper.getCoordsByAddress(addressString).then((result) => {
        if (result.status === "OK" && determineCountry(result)) {
          const lat = result.results[0].geometry.location.lat;
          const lon = result.results[0].geometry.location.lng;

          user.setData({ company: { latitude: lat, longitude: lon } });

          setLoadingOverlayOpen(true);

          const foundZip = determineZip(result);

          executeCompanyUpdateMutation({
            hashId: user.getData().company.hashID,
            name: formValues.name,
            contactPerson: formValues.contactPerson,
            street: formValues.street,
            zip: foundZip,
            city: formValues.city,
            lat: lat,
            lon: lon,
            phone: formValues.phone,
            mobile: formValues.mobile,
            fax: formValues.fax,
            website: formValues.website,
            email: formValues.email,
          }).then((result) => {
            // clearTimeout(showLoadingOverlayTimeout)
            // setLoadingOverlayOpen(false)

            if (result.error) {
              if (result.error.message.indexOf("User forced logout") !== -1) {
                setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
                unsetUser();
                unsetToken();
                navigate("/");
              } else {
                setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
                ExternalErrorLogger.log({
                  text: "Error submitting data on CompanyBasicSettings",
                  data: {
                    token: JSON.stringify(token.getData()),
                    user: JSON.stringify(user.getData()),
                    errorMessage: result.error.message,
                  },
                });
              }
            } else {
              setMessage(
                MESSAGE_TYPES.SUCCESS,
                t("general.label.save.success")
              );
              setUser(user);
              if (foundZip && foundZip !== formValues.zip) {
                setFormValues({ ...formValues, zip: foundZip });
              }
            }
          });
        } else if (result.status === "OK" && !determineCountry(result)) {
          setFormValueErrors({
            street: {
              hasError: true,
              message: t("error.form.address.not.ch"),
            },
            zip: {
              hasError: true,
              message: t("error.form.address.not.ch"),
            },
            city: {
              hasError: true,
              message: t("error.form.address.not.ch"),
            },
          });
          window.scrollTo(0, 0);
        } else if (result.status === "ZERO_RESULTS") {
          setFormValueErrors({
            street: {
              hasError: true,
              message: t("error.form.invalid.address"),
            },
            zip: {
              hasError: true,
              message: t("error.form.invalid.address"),
            },
            city: {
              hasError: true,
              message: t("error.form.invalid.address"),
            },
          });
          window.scrollTo(0, 0);
        } else {
          let errorMessage = "";
          if (result.error_message !== undefined) {
            errorMessage =
              "<br />" +
              '<span style="font-weight: bold">' +
              t("general.label.error.message") +
              "</span>" +
              " " +
              result.error_message;
          }
          setMessage(
            MESSAGE_TYPES.ERROR,
            t("error.general.short") +
              "<br />" +
              "<br />" +
              '<span style="font-weight: bold">' +
              t("general.label.status.code") +
              "</span>" +
              " " +
              result.status +
              errorMessage +
              "<br />" +
              "<br />" +
              t("general.label.contact.smartconext")
          );
          ExternalErrorLogger.log({
            text: "Error fetching Geocoords on CompanyBasicSettings",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error_message,
            },
          });
        }
      });
    }
  };

  /**
   * return
   */
  return (
    googleMapsIsLoaded && (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        margin="normal"
        padding="normal"
        className={classes.gridContainer}
      >
        <CompanyBasicSettingsForm
          classes={classes}
          formValues={formValues}
          formValueErrors={formValueErrors}
          setFormValues={setFormValues}
          streetFormValue={streetFormValue}
          setStreetFormValue={setStreetFormValue}
          zipFormValue={zipFormValue}
          setZipFormValue={setZipFormValue}
          cityFormValue={cityFormValue}
          setCityFormValue={setCityFormValue}
        />
        <Grid item className={classes.gridItem}>
          <Button
            id="buttonFormSubmit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleFormSubmit}
            disabled={companyIsUpdating}
          >
            {t("account.profile.save.changes")}
            {companyIsUpdating && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          {loadingOverlayOpen &&
            UpdateCompanyDataDialog({
              setLoadingOverlayOpen,
              loadingOverlayOpen,
              t,
            })}
        </Grid>
      </Grid>
    )
  );
};

export default withAccessControl(
  CompanyBasicSettings,
  ACL_COMPONENTS.COMPANY_BASIC_SETTINGS
);
